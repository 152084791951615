import { api } from 'core/services/api.service';
import {
  TaskmanagerEventDto,
  TaskmanagerEventTypeEnum,
  TaskmanagerScheduleDtoEntries,
  TaskmanagerScheduleListDto,
} from 'gateway-api';
import { QueryClient, useMutation, useQueryClient } from 'react-query';
import {
  IIndexSchedule,
  scheduleKeys,
} from 'schedule/core/queries/schedule.keys';

interface Props {
  id: string;
  event: TaskmanagerEventDto;
}

export const cleanDateEntry = (
  entry: TaskmanagerScheduleDtoEntries,
  userHours: number,
  eventType?: TaskmanagerEventTypeEnum,
): TaskmanagerScheduleDtoEntries => {
  const hoursScheduled: number = entry.events.reduce((previous, current) => {
    if (current.event_type === eventType) {
      return previous + current.hours;
    }
    return previous;
  }, 0);

  entry.hours_remaining = userHours - hoursScheduled;

  const memoHoursRemaining = { ...entry.hours_remaining_by_type };

  if (eventType) {
    memoHoursRemaining[eventType] = userHours - hoursScheduled;
  }
  entry.hours_remaining_by_type = memoHoursRemaining;
  entry.events = entry.events.map((obj, index) => {
    const memoEvent = { ...obj };
    memoEvent.priority = index;
    return memoEvent;
  });

  return entry;
};

/**
 * Function `onMutate`.
 */

const onMutate =
  (queryClient: QueryClient, params: IIndexSchedule) =>
  async (patchedEvent: Props) => {
    await queryClient.cancelQueries(scheduleKeys.base);

    const { event } = patchedEvent;

    const queryKey = scheduleKeys.indexSchedule(params);

    const previousQueries = queryClient.getQueryData<
      TaskmanagerScheduleListDto | undefined
    >(queryKey);

    queryClient.setQueryData<TaskmanagerScheduleListDto | undefined>(
      queryKey,
      (old) => {
        if (old) {
          return {
            pagination: old.pagination,
            items: old.items.map((schedule) => {
              if (schedule.user.id === event.assignee_user?.id) {
                // Loop through and update the event if it matches the patch
                schedule.entries = schedule.entries.map((entry) => {
                  const entryDate = entry.date_from.split(' ')[0];
                  const eventDate = event.date_start.split(' ')[0];
                  if (entryDate !== eventDate) return entry;

                  entry.events = entry.events.map((eventItem) => {
                    if (eventItem.id !== event.id) return eventItem;
                    return event;
                  });
                  return cleanDateEntry(
                    entry,
                    schedule.user.profile.available_hours,
                    event.event_type,
                  );
                });
              }
              return schedule;
            }),
          };
        }
        return old;
      },
    );
    return { previousQueries };
  };

async function patchEvent(props: Props): Promise<TaskmanagerEventDto> {
  const { id, event } = props;
  const response = await api.eventService.modifyTaskmanagerEvent(id, event);
  return response.data;
}

const useResizeMutation = (params: IIndexSchedule) => {
  const queryClient = useQueryClient();

  return useMutation(patchEvent, {
    onMutate: onMutate(queryClient, params),
    onSettled() {
      queryClient.invalidateQueries(scheduleKeys.index());
    },
    onError(error, variables, context) {
      queryClient.setQueriesData(
        scheduleKeys.indexSchedule(params),
        context?.previousQueries,
      );
    },
  });
};

export default useResizeMutation;

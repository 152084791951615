import { EventTypeCalendar } from 'schedule/core/components/event-type-calendar/event-type-calendar';
import { EventTypeLeave } from 'schedule/core/components/event-type-leave/event-type-leave';
import { EventTypeTicket } from 'schedule/core/components/event-type-ticket/event-type-ticket';
import { TaskmanagerEventDto } from 'gateway-api';
import { EventTypeBankHoliday } from 'schedule/core/components/event-type-bank-holiday/event-type-bank-holiday';
import { useMemo } from 'react';
import { EventTypeWorkcation } from 'schedule/core/components/event-type-workcation/event-type-workcation';

interface IUserEvent {
  /** The width of the container that this component resides in */
  containerWidth: number;
  /** The total available hours for this user */
  availableHours: number;
  event: TaskmanagerEventDto;
  // date: string;
}

/**
 * User Event
 *
 * This component is used to determine the correct UI for the events pot type.
 *
 * PotTypeEnum contains all available pot types that can be used in the component.
 */

export const UserEvent = ({
  event,
  availableHours,
  containerWidth,
}: // date,
IUserEvent) => {
  const hourWidth = containerWidth / availableHours;
  const eventWidth = hourWidth * event.hours;
  const calendarEvent = event.pots_by_type.calendar_event;
  const leave =
    event.pots_by_type.leave_booking?.name !== 'Workcation' &&
    event.pots_by_type.leave_booking?.name;
  const bankHoliday = event.pots_by_type.public_holiday;
  const workcation =
    event.pots_by_type.leave_booking?.name === 'Workcation' &&
    event.pots_by_type.leave_booking?.name;

  const isScheduleTicket = useMemo(
    () => !calendarEvent && !leave && !bankHoliday && !workcation,
    [calendarEvent, leave, bankHoliday, workcation],
  );

  return (
    <>
      {calendarEvent && <EventTypeCalendar event={event} width={eventWidth} />}
      {isScheduleTicket && (
        <EventTypeTicket
          event={event}
          width={eventWidth}
          containerWidth={containerWidth}
          remainingTime={availableHours}
        />
      )}
      {leave && !bankHoliday && (
        <EventTypeLeave event={event} width={eventWidth} />
      )}
      {bankHoliday && <EventTypeBankHoliday width={eventWidth} />}
      {workcation && <EventTypeWorkcation width={eventWidth} />}
    </>
  );
};

import DateRangeType from 'date-range/models/date-range-type.enum';
import {
  dateRangeState,
  DateRangeState,
} from 'date-range/reducers/date-range/date-range.state';
import { DateTime } from 'luxon';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

const getHeaderDates = (state: DateRangeState) => {
  const { dateRangeType, startDate, endDate } = state;

  // DateRangeType.month should only show the current month
  if (dateRangeType === DateRangeType.month) {
    return [startDate];
  }

  // Duration to add to iterating date.
  // DateRangeType.year should increment in months
  // Everything else should increment in days
  const duration = dateRangeType === DateRangeType.year ? 'month' : 'day';

  // The last day for the headerDates iterator.
  // DateRangeType.week should only show Monday - Friday
  // Everything else should use the end date currently set in state
  const lastDay =
    dateRangeType === DateRangeType.week
      ? startDate.plus({ days: 5 })
      : endDate;

  const headerDates: DateTime[] = [];
  let date = startDate;
  while (date < lastDay) {
    headerDates.push(date);
    date = date.plus({ [duration]: 1 });
  }

  return headerDates;
};

const getCalendarDates = (state: DateRangeState) => {
  const { dateRangeType, startDate, endDate } = state;
  //  // DateRangeType.month should only show the current month
  //  if (dateRangeType === DateRangeType.month) {
  //   return [startDate];
  // }

  // Duration to add to iterating date.
  // DateRangeType.year should increment in months
  // Everything else should increment in days
  const duration = dateRangeType === DateRangeType.year ? 'week' : 'day';

  // The last day for the headerDates iterator.
  // DateRangeType.week should only show Monday - Friday
  // Everything else should use the end date currently set in state
  const lastDay =
    dateRangeType === DateRangeType.week
      ? startDate.plus({ days: 5 })
      : endDate;

  const headerDates: DateTime[] = [];
  let date = startDate;
  while (date < lastDay) {
    headerDates.push(date);
    date = date.plus({ [duration]: 1 });
  }

  return headerDates;
};

interface IDateRange {
  state: DateRangeState;
  nextDateRequested: () => void;
  prevDateRequested: () => void;
  dateRangeTypeChanged: (dateRangeType: DateRangeType) => void;
}

/**
 * Names and types are important to the redux devtools.
 */

export const useDateRange = create<IDateRange>()(
  devtools(
    (set) => ({
      state: dateRangeState.inititial(null, null),
      nextDateRequested: () =>
        set(
          ({ state }) => {
            state.startDate = state.startDate.plus({
              [state.dateRangeType]: 1,
            });
            if (state.dateRangeType === DateRangeType.week) {
              state.endDate = state.startDate
                .endOf(state.dateRangeType)
                .minus({ days: 2 });
            } else {
              state.endDate = state.startDate.endOf(state.dateRangeType);
            }
            state.headerDates = getHeaderDates(state);
            state.calendarDates = getCalendarDates(state);
            return { state };
          },
          false,
          { type: 'date-range-store__nextDateRequested' },
        ),
      prevDateRequested: () =>
        set(
          ({ state }) => {
            state.startDate = state.startDate.minus({
              [state.dateRangeType]: 1,
            });
            if (state.dateRangeType === DateRangeType.week) {
              state.endDate = state.startDate
                .endOf(state.dateRangeType)
                .minus({ days: 2 });
            } else {
              state.endDate = state.startDate.endOf(state.dateRangeType);
            }
            state.headerDates = getHeaderDates(state);
            state.calendarDates = getCalendarDates(state);
            return { state };
          },
          false,
          { type: 'date-range-store__prevDateRequested' },
        ),
      dateRangeTypeChanged: (dateRangeType) =>
        set(
          ({ state }) => {
            state.dateRangeType = dateRangeType;
            state.startDate = DateTime.now().startOf(dateRangeType);
            if (dateRangeType === DateRangeType.week) {
              state.endDate = DateTime.now()
                .endOf(dateRangeType)
                .minus({ days: 2 });
            } else {
              state.endDate = DateTime.now().endOf(dateRangeType);
            }
            state.headerDates = getHeaderDates(state);
            state.calendarDates = getCalendarDates(state);
            return { state };
          },
          false,
          { type: 'date-range-store__dateRangeTypeChanged', dateRangeType },
        ),
    }),
    {
      name: 'date-range-store',
    },
  ),
);

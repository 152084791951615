import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import styled from '@emotion/styled';
import Form from 'core/components/form-components/form';
import PrimaryButton from 'core/components/buttons/primary-button';
import { defaultTheme } from 'theme/theme';
import {
  Popover,
  IconButton as MuiIconButton,
  Typography,
} from '@mui/material';
import TextInput from 'core/components/form-components/text-input';

export const AddNoteIcon = styled(NoteAddOutlinedIcon)`
  && {
    color: ${defaultTheme.colors.primaryColor};
    height: 20px;
    width: 20px;
  }
`;

export const ViewNoteIcon = styled.img`
  && {
    background-color: ${defaultTheme.colors.accentColor};
    min-height: 24px;
    min-width: 24px;
    padding: 2px;
    border-radius: 100%;
  }
`;

export const IconButton = styled(MuiIconButton)`
  && {
    background-color: transparent;
    box-shadow: none;
    max-width: 24px;
    margin-left: 1.5rem;
  }
`;

export const NotePopover = styled(Popover)`
  && {
    z-index: 100;
    margin-top: 1rem;
    background-color: transparent;
  }
`;

export const NoteForm = styled(Form)`
  && {
    color: ${defaultTheme.colors.textLight};
    background-color: #ffffff;
    padding: 11px;
    border-radius: 0.25rem;
    border: solid 1px ${defaultTheme.colors.borderGrey};
  }
`;

export const NoteHeading = styled(Typography)`
  && {
    font-size: 0.875em;
    font-weight: bold;
    color: ${defaultTheme.colors.textPrimary};
  }
`;

export const NoteBody = styled(TextInput)`
  + p {
    position: unset;
  }
  &&.MuiFormControl-root {
    color: ${defaultTheme.colors.textSecondary} !important;
    font-weight: 300;
    margin: 0.5rem 0;
    border-radius: 0.5rem;
    min-height: 6rem;

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    && label {
      font-size: 14px;
    }

    && .MuiInputBase-root {
      padding: 0;
    }

    && textarea {
      color: ${defaultTheme.colors.textSecondary} !important;
      border-radius: 4px;
      -webkit-text-fill-color: unset;
    }
  }
`;

export const SaveButton = styled(PrimaryButton)`
  && {
    display: block;
    margin: 0.25rem 0 0.125rem auto;
  }
`;

// export const NoteContainer = styled.div`
//   color: ${defaultTheme.colors.textLight};
//   background-color: #ffffff;
//   padding: 1rem 0.5rem;
//   min-width: 180px;
//   max-width: 180px;
//   min-height: 200px;
//   border-radius: 0.25rem;
//   border: solid 1px ${defaultTheme.colors.borderGrey};
// `;

// export const NoteContainerBody = styled.div`
//   color: ${defaultTheme.colors.textSecondary};
//   font-weight: normal;
//   background-color: ${defaultTheme.colors.borderGrey};
//   margin: 0.5rem 0;
//   border-radius: 0.5rem;
//   min-height: 6rem;
//   padding: 0.5rem;
// `;

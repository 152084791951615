/**
 * Module dependencies.
 */

import SwitchFilter from 'core/components/filters/switch-filter';
import RadioButtonStyle from 'core/components/form-components/radio-button-style';
import { ChangeEvent } from 'react';
import { searchKeys } from 'schedule/search/queries/search.keys';
import { useIndexSearch } from 'schedule/search/queries/use-index-search';

import * as S from 'schedule/search/components/category-filter/category-filter.styles';
import { useSearchStore } from 'schedule/search/store/search/search.store';

export const CategoryFilter = () => {
  const { state, setCategoryId, setStatus } = useSearchStore();
  const { data: categories, isLoading } = useIndexSearch(
    searchKeys.indexSearch({
      category: state.category,
    }),
  );

  const handleCategoryChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCategoryId(e.target.value);
  };

  const handleStatus = (e: ChangeEvent<HTMLInputElement>) => {
    setStatus(e.target.checked);
  };

  return (
    <>
      {categories?.items && (
        <>
          <S.CompanyFilter>
            {!isLoading &&
              categories?.items.map((cat) => (
                <RadioButtonStyle
                  id={cat.id}
                  key={cat.id}
                  value={cat.id}
                  name='company'
                  variant='outlined'
                  onChange={handleCategoryChange}
                >
                  {cat.name.toLowerCase()}
                </RadioButtonStyle>
              ))}
            <RadioButtonStyle
              value='all'
              name='company'
              variant='outlined'
              checked={state.categoryId === 'all'}
              onChange={handleCategoryChange}
            >
              all
            </RadioButtonStyle>
          </S.CompanyFilter>
          <SwitchFilter
            id='status'
            labelLeft='Off'
            labelRight='On'
            checked={state.status}
            legend='Show Complete'
            onChange={handleStatus}
          />
        </>
      )}
    </>
  );
};

/**
 * Module dependencies.
 */

import styled from '@emotion/styled/macro';
import { TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import * as S from 'statistics/components/stats-custom-attributes/stats-custom-attributes.style';
import UserAvatar from 'user/components/user-avatar/user-avatar';

interface IStatListTableProps {
  custom?: boolean;
}

interface IStatTableProps {
  pot_id?: string | undefined;
  custom?: boolean;
}

export const StyledTableContainer = styled(TableContainer)<IStatTableProps>`
  && {
    margin-bottom: 22px;

    @media only screen and (min-width: 480px) {
      overflow-x: visible;
    }
    .MuiTableCell-root {
      font-size: 0.875em;
      padding: ${(props) => (props.custom ? '10px' : '8px 5px')};
    }
  }
`;

export const SelectedStat = styled(Typography)`
  margin-left: 0.25rem;
  font-weight: 600;
  font-size: 1em;
  color: #07334a;
`;

export const StyledStatTable = styled(S.StyledTable)<IStatTableProps>`
  && {
    width: 100%;
    min-width: ${(props) => (props.custom ? 'auto' : '0')};
  }
`;

export const StyledTableHeader = styled(TableHead)`
  .MuiTableCell-root {
    border-right: solid 1px grey;
    &:first-child {
      border-right: none;
    }

    &:last-child {
      border-right: none;
    }
  }
`;

export const StyledTableRow = styled(TableRow)<IStatListTableProps>`
  && {
    &:nth-of-type(odd) {
      background-color: #f0f0f0;
    }

    &:first-child {
      .MuiTableCell-root {
        border-top: ${(props) =>
          props.custom ? 'solid #a0ced9 1px !important' : 'none'};
      }
    }

    .MuiTableCell-root {
      border-right: solid 1px grey;
      border-bottom: 0;
      &:first-child {
        text-transform: capitalize;

        background-color: ${(props) => (props.custom ? '#a0ced9' : 'auto')};
        border-bottom: ${(props) =>
          props.custom ? 'solid white 1px' : 'none'};
        border-top: ${(props) => (props.custom ? 'solid white 1px' : 'none')};
        border-right: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &:last-child {
        border-right: none;
      }
    }
  }
`;

export const StyledUserData = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledUser = styled.div`
  padding: 5px 10px;
  border-radius: 5px;

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 49%;

    &:nth-child(4n),
    &:nth-child(4n + 3) {
      background-color: #f0f0f0;
    }
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    width: 33%;

    &:nth-of-type(even) {
      background-color: #f0f0f0;
    }
  }
`;

export const StyledUserAvatar = styled(UserAvatar)`
  && {
    display: flex;
    justify-content: center;

    .MuiAvatar-circular {
      font-size: 0.875em;
      background-color: #cecece;
      font-weight: normal;
    }
  }
`;

export const UserDataTitle = styled(Typography)`
  && {
    width: 100%;
    margin-bottom: 12px;
    font-weight: bold;
  }
`;

export const StyledUserName = styled(Typography)`
  && {
    text-align: center;
    margin-top: 6px;
    margin-bottom: 12px;
  }
`;

export const StyledUserPotTitle = styled(Typography)`
  && {
    font-weight: bold;
    text-transform: capitalize;
  }
`;

export const StyledUserPotName = styled(Typography)`
  && {
  }
`;

export const StyledUserDate = styled(Typography)`
  && {
    font-weight: bold;
  }
`;

export const NoStatsTitle = styled(Typography)`
  color: #07334a;
`;

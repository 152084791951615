import { MouseEventHandler } from 'react';
import * as S from 'gantt/components/project-tag/project-tag.style';
import { TaskmanagerPotGanttDto } from 'gateway-api';

interface IProjectTagProps {
  project: TaskmanagerPotGanttDto;
  hideClose?: boolean;
  onRemove?: MouseEventHandler<HTMLDivElement> | undefined;
}

const ProjectTag = ({
  hideClose = false,
  onRemove,
  project,
}: IProjectTagProps): JSX.Element => (
  <S.Chip label={project.name} onDelete={onRemove} />
);

export default ProjectTag;

/**
 * Module dependencies.
 */

import styled from '@emotion/styled/macro';
import { css, FormGroup, RadioGroup, Typography } from '@mui/material';
import { defaultTheme } from 'theme/theme';
import PrimaryButton from 'core/components/buttons/primary-button';
import { TaskmanagerEventDto } from 'gateway-api';

interface IPotLabel {
  company: string;
}

export const FlexButton = styled.div<{ isEvent?: TaskmanagerEventDto }>`
  display: inline-block;

  ${(props) =>
    props.isEvent &&
    css`
      align-self: flex-end;
    `}

  ${(props: any) => props.theme.breakpoints.down('md')} {
    margin-top: 30px;
  }
`;

export const StyledDeleteButton = styled(PrimaryButton)`
  && {
    justify-content: flex-start;
    padding: 0;
    min-width: 0;
    .MuiSvgIcon-root {
      font-size: 29px;
      color: #2e559f;
    }
  }
`;

export const ConfirmButton = styled(PrimaryButton)`
  && {
    ${(props) =>
      props.disabled &&
      css`
        color: ${props.theme.colors.primaryColor};
        background-color: ${props.theme.colors.lightGrey};
      `};
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FlexCol = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 28px;
  flex-wrap: wrap;

  ${(props: any) => props.theme.breakpoints.down('md')} {
    margin-bottom: 15px;
  }

  &:last-child {
    margin-bottom: 0;
    align-items: flex-end;
  }
`;

export const FlexContent = styled.div`
  display: inline-flex;
  align-items: center;

  ${(props: any) => props.theme.breakpoints.down('md')} {
    flex-wrap: wrap;
  }
`;

export const PotLabel = styled(Typography)<IPotLabel>`
  && {
    background-color: ${(props) =>
      props.company === 'arch' ? '#A0CED9' : '#f27777'};
    padding: 1px 15px;
    font-weight: bold;
    border-radius: 8px;
    color: #07334a;
    margin-right: 14px;
    display: inline-block;
    text-align: center;

    ${(props: any) => props.theme.breakpoints.down('md')} {
      order: 2;
    }
  }
`;

export const PotTitle = styled(Typography)`
  && {
    font-weight: bold;
    font-size: 24px;

    ${(props: any) => props.theme.breakpoints.down('md')} {
      order: 1;
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

export const FlexFormField = styled.div`
  width: 48%;

  ${(props: any) => props.theme.breakpoints.down('md')} {
    width: 100%;
    margin-bottom: 20px;
  }

  .MuiOutlinedInput-root {
    width: 100%;
  }
`;

export const StyledFieldset = styled.fieldset`
  border: 0;

  .MuiFormControl-root {
    margin-bottom: 20px;
  }
`;

export const StyledLegend = styled.legend`
  font-size: 18px;
  color: ${defaultTheme.colors.primaryColor};
  font-weight: 500;
  margin-bottom: 13px;
`;

export const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 10px;
`;

export const FlexRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: row;

  div {
    margin-right: 12px;
  }
`;

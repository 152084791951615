import AppRouter from 'routes/app-router';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { defaultTheme } from 'theme/theme';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from 'theme/global-styles';
import { Suspense } from 'react';
import CustomHelmet from 'core/components/custom-helmet';
import { Global, ThemeProvider } from '@emotion/react';
import { SnackbarProvider } from 'notistack';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 1000 } },
});

const App = (): JSX.Element => (
  <QueryClientProvider client={queryClient} contextSharing>
    <SnackbarProvider
      autoHideDuration={2000}
      maxSnack={1}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
    >
      <HelmetProvider>
        <CustomHelmet />

        <Suspense fallback={<></>}>
          <MuiThemeProvider theme={defaultTheme}>
            <CssBaseline />

            <Global styles={GlobalStyles} />

            <AppRouter />
          </MuiThemeProvider>
        </Suspense>
      </HelmetProvider>
    </SnackbarProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

export default App;

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import useFormField from 'core/hooks/use-form-field';
import { useFormContext } from 'react-hook-form';
import { OrganisationDto, UserDtoOrganisations } from 'gateway-api';
import React, { useEffect, useState } from 'react';
import * as S from 'users/components/company-checkbox/company-checkbox.styles';

interface IOrganisations {
  id: string;
  hideError?: boolean;
  organisations: OrganisationDto[];
  userOrganisations?: UserDtoOrganisations[];
}

export const CompanyCheckbox = ({
  id,
  hideError,
  organisations,
  userOrganisations,
}: IOrganisations) => {
  const { errorLabel, hasError } = useFormField(id);
  const { formState, setValue } = useFormContext();
  const [selectedIds, setSelectedIds] = useState<string[]>(
    formState?.defaultValues?.organisations
      ? formState.defaultValues.organisations.map(
          (item: UserDtoOrganisations) => item.id,
        )
      : [],
  );

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkboxId = event.target.id;

    if (event.target.checked) {
      setSelectedIds((prevSelectedIds) => [...prevSelectedIds, checkboxId]);
    } else {
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter((selectedId) => selectedId !== checkboxId),
      );
    }
  };

  useEffect(() => {
    setValue(
      id,
      selectedIds?.map((item) => ({
        id: item,
        role:
          userOrganisations
            ?.filter((orgId) => orgId.id === item)
            ?.map((org) => org.role)[0] ?? 'viewer',
      })),
    );
  }, [id, selectedIds]);

  return (
    <FormControl error={hasError}>
      <S.FlexContainer>
        {organisations.map((organisation) => (
          <FormControlLabel
            key={organisation.id}
            label={organisation.name}
            control={
              <Checkbox
                id={organisation.id}
                checked={selectedIds?.includes(organisation.id) ?? false}
                onChange={handleCheckboxChange}
              />
            }
          />
        ))}
      </S.FlexContainer>
      {hasError && !hideError && <FormHelperText>{errorLabel}</FormHelperText>}
    </FormControl>
  );
};

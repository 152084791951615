import styled from '@emotion/styled/macro';
import { useEffect, useRef, useState } from 'react';
import useComponentOpen from 'core/hooks/use-component-open';
import SearchIcon from '@mui/icons-material/Search';
import { Typography } from '@mui/material';
import { StyledButton } from 'core/components/buttons/primary-button';

interface ISearchContainerProps {
  active: boolean;
  debounce?: boolean;
  width?: string;
}

const SearchContainer = styled.div<ISearchContainerProps>`
  position: relative;
`;

const SearchInput = styled.input<ISearchContainerProps>`
  cursor: ${(props) => (props.active ? 'text' : 'pointer')};
  position: relative;
  height: 100%;
  max-width: 100%;
  width: ${(props) => (props.width ? props.width : '100%')};
  border-radius: 10px;
  border: 1px solid #053349;
  transition: width 0.4s ease;
  background: none;
  box-sizing: border-box;
  padding: 10px;
  &:focus {
    outline: none;
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  cursor: pointer;
  top: 50%;
  right: -10px;
  transform: translate(-50%, -50%);
  font-size: 30px;
`;

const RecentSearchContainer = styled.div`
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #053349;
  position: absolute;
  width: 100%;
  padding: 20px;
  z-index: 9999;
  background-color: white;
`;

const RecentSearchContainerHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid #707070 1px;
`;

const RecentSearchContent = styled.div`
  && {
    .MuiTypography-body1 {
      margin-top: 20px;
    }
    .MuiButton-root {
      display: block;
      min-width: 100%;
      text-align: left;
      text-transform: none;
      font-size: 16px;
      padding: 5px 0;
      font-weight: 400;
    }
  }
`;

interface ISearchProps {
  expanded?: boolean;
  debounce?: boolean;
  width?: string;
  className?: string;
  onSubmit?: (query: string) => void;
  onSearchChange?: (query: string) => void;
  onSearchActive?: (active: boolean) => void;
  placeholder?: string;
  recentSearches?: string[];
  localStorageName?: string;
  defaultValue?: string;
}
const Search = ({
  expanded,
  debounce = true,
  width,
  onSearchActive,
  className,
  onSubmit,
  onSearchChange,
  recentSearches,
  localStorageName,
  placeholder,
  defaultValue = '',
}: ISearchProps): JSX.Element => {
  const searchRef = useRef<HTMLInputElement>(null);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentOpen(false);
  const [search, setSearch] = useState('');

  const [showRecent, setShowRecent] = useState(false);

  // on first load of component it sets the search to default value - which is either a string you set as a prop or a blank string
  useEffect(() => {
    setSearch(defaultValue);
  }, [defaultValue]);

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (e.code === 'Enter') {
      if (isComponentVisible) {
        if (onSubmit !== undefined) {
          onSubmit(search);
          setShowRecent(false);
        } else {
          setSearch('');
        }
      }
    }

    if (debounce && e.code === 'Backspace') {
      if (onSearchChange !== undefined) {
        onSearchChange(search);

        if (search.length < 2) {
          setSearch(' ');
        }
      } else {
        setSearch('');
      }
    }
  };

  const handleClick = () => {
    if (isComponentVisible) {
      if (onSubmit !== undefined) {
        onSubmit(search);
      } else {
        setSearch('');
      }
    }

    setIsComponentVisible((state) => {
      onSearchActive?.(!state);
      return !state;
    });
  };

  const handleChange = (query: string) => {
    setSearch(query);

    if (onSearchChange !== undefined) {
      onSearchChange(query);
      setShowRecent(false);
    }

    if (query === '') {
      setShowRecent(true);

      setTimeout(() => {
        setShowRecent(false);
      }, 50000);
    }
  };

  const handleRemoveHistory = () => {
    if (localStorageName) {
      localStorage.removeItem(localStorageName);
      setShowRecent(false);
    }
    // removes any current text in the search bar
    setSearch('');
    handleChange('');
    setShowRecent(false);
  };

  const handleRecentChange = (query: string) => {
    handleChange(query);
    setShowRecent(false);
  };

  // this is to close recent search clicking outside of the recent search div
  useEffect(() => {
    // Function for click event
    function handleCloseRecentClick(event: { target: any }) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowRecent(false);
      }
    }

    // Adding click event listener
    document.addEventListener('click', handleCloseRecentClick);
    return () => document.removeEventListener('click', handleCloseRecentClick);
  }, [ref]);

  const filteredRecentSearch = recentSearches?.filter(
    (item, index) => recentSearches?.indexOf(item) === index,
  );

  return (
    <SearchContainer
      onClick={
        isComponentVisible
          ? undefined
          : () => {
              onSearchActive?.(true);
              setIsComponentVisible(true);
              setShowRecent(true);
            }
      }
      className={className}
      ref={ref}
      active={expanded || isComponentVisible}
      debounce={debounce}
    >
      <SearchInput
        onKeyDown={handleKeyDown}
        value={search}
        onChange={({ target }) => handleChange(target.value)}
        type="text"
        ref={searchRef}
        active={isComponentVisible}
        width={width}
        className="searchInput"
        placeholder={placeholder}
      />
      <StyledSearchIcon onClick={handleClick} />
      {filteredRecentSearch && filteredRecentSearch?.length > 0 ? (
        <>
          {showRecent ? (
            <RecentSearchContainer>
              <RecentSearchContainerHeader>
                <Typography variant="body1">Past Search</Typography>
                <StyledButton variant="text" onClick={handleRemoveHistory}>
                  Clear
                </StyledButton>
              </RecentSearchContainerHeader>
              <RecentSearchContent>
                {filteredRecentSearch &&
                  filteredRecentSearch
                    .reverse()
                    .slice(0, 10)
                    .map((item) => (
                      // eslint-disable-next-line react/jsx-key
                      <StyledButton
                        variant="text"
                        onClick={() => handleRecentChange(item)}
                      >
                        {item}
                      </StyledButton>
                    ))}
              </RecentSearchContent>
            </RecentSearchContainer>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </SearchContainer>
  );
};

export default Search;

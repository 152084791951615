/**
 * Module dependencies.
 */

import UserAvatar from 'user/components/user-avatar/user-avatar';
import { useTranslation } from 'react-i18next';
import useUser from 'user/hooks/use-show-user';
import { useSnackbar } from 'notistack';
import Loading from 'core/components/loading';
import Scaffold from 'core/components/scaffolds/scaffold';
import useAuthUser from 'auth/hooks/use-auth-user';
import * as S from 'user/pages/user-profile/user-profile.style';
import useOrganisations from 'core/hooks/queries/organisations/use-organisations';

const UserProfilePage = (): JSX.Element => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const user = useAuthUser();

  const { items: organisationsList } = useOrganisations();

  const { data: userData, error, isLoading } = useUser(user?.id ?? '');

  if (error) {
    enqueueSnackbar('An error occurred whilst loading your user.', {
      variant: 'error',
    });
  }

  const initials = `${userData?.data?.first_name?.charAt(
    0,
  )}${userData?.data?.last_name?.charAt(0)}`;

  return (
    <Scaffold headerTitle={t('user_profile.title')}>
      <S.ContentSection>
        <S.ContentArticle className='userInfo'>
          <>
            {error &&
              'Your user cannot be displayed at this time. Please contact your system administrator.'}
            {!error &&
              (isLoading ? (
                <Loading />
              ) : (
                <>
                  <UserAvatar
                    avatarWidth='94px'
                    avatarHeight='94px'
                    initials={initials}
                  />
                  <S.Username variant='h2'>
                    {`${userData && userData.data.first_name} ${
                      userData && userData.data.last_name
                    }`}
                  </S.Username>
                  <S.ProfileTitle variant='body1'>
                    {t('user_profile.skills')}:
                  </S.ProfileTitle>
                  <S.UserTags>
                    {userData &&
                      userData?.data?.skills?.map((skill: string) => (
                        <S.Tag key={skill}>{skill}</S.Tag>
                      ))}
                    {!userData?.data?.skills?.length &&
                      'Your user currently has no skills.'}
                  </S.UserTags>
                  <S.ProfileDetailsList>
                    <S.ProfileDetailsItem>
                      <S.ProfileTitle variant='body1'>
                        {t('user_profile.roles')}:
                      </S.ProfileTitle>
                      <S.ProfileDetail>
                        {userData?.data.services.taskmanager.roles?.map(
                          (role: string) => (
                            <S.UserRole key={role}>{role}</S.UserRole>
                          ),
                        )}
                        {!userData?.data.services.taskmanager.roles && (
                          <S.UserRole>No role assigned</S.UserRole>
                        )}
                      </S.ProfileDetail>
                    </S.ProfileDetailsItem>
                    <S.ProfileDetailsItem>
                      <S.ProfileTitle variant='body1'>
                        {t('user_profile.company')}:
                      </S.ProfileTitle>
                      <S.ProfileDetail>
                        <>
                          {organisationsList?.map((organisation) =>
                            userData?.data.organisations?.map(
                              (org) =>
                                org.id === organisation.id && (
                                  <span key={organisation.id}>
                                    {organisation.name}{' '}
                                  </span>
                                ),
                            ),
                          )}
                        </>
                      </S.ProfileDetail>
                    </S.ProfileDetailsItem>
                    <S.ProfileDetailsItem className='itemFull'>
                      <S.ProfileTitle variant='body1'>
                        {t('user_profile.email')}:
                      </S.ProfileTitle>
                      <S.ProfileDetail>
                        {userData && userData.data.username}
                      </S.ProfileDetail>
                    </S.ProfileDetailsItem>
                  </S.ProfileDetailsList>
                </>
              ))}
          </>
        </S.ContentArticle>
        <S.ContentArticle />
      </S.ContentSection>
    </Scaffold>
  );
};

export default UserProfilePage;

import { QueryFunctionContext, useQuery } from 'react-query';
import { api } from 'core/services/api.service';

import { searchKeys } from 'schedule/search/queries/search.keys';

type IndexSearch = ReturnType<typeof searchKeys['indexSearch']>;

const indexSearch = async ({
  queryKey: [
    {
      search,
      pageNumber,
      category,
      status,
      favourite,
      categoryId,
      jira_query,
      // jira_type,  // future use
      jql_search,
      sort,
    },
  ],
}: QueryFunctionContext<IndexSearch>) => {
  let statusParams = {};
  if (status) {
    statusParams = {
      'status[like]': status ? 'done' : undefined,
      'status[not]': status ? undefined : 'done',
    };
  }
  let jiraParams = {};
  if (jql_search) {
    // in order to do a jql jira type needs to be issue
    jiraParams = {
      jira_query,
      jira_type: 'issue',
    };
  }
  const { data } = await api.potsService.indexTaskmanagerPot({
    params: {
      limit: 21,
      'parent_pots@name,label,name[like]': search, // pot label and pot name
      category_pot_id: categoryId, // companyid
      'pot_type@name': category ? 'category' : 'ticket',
      'favorite_users@id': favourite,
      sort_by: sort,
      page: pageNumber,
      ...jiraParams,
      ...statusParams,
    },
  });

  return data;
};

export const useIndexSearch = (queryKey: IndexSearch) =>
  useQuery(queryKey, indexSearch);

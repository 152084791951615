import { api } from 'core/services/api.service';
import DateRangeType from 'date-range/models/date-range-type.enum';
import { QueryFunctionContext, useQuery } from 'react-query';
import { scheduleKeys } from 'schedule/core/queries/schedule.keys';

export type IndexSchedule = ReturnType<typeof scheduleKeys['indexSchedule']>;

const indexSchedule = async ({
  queryKey: [{ eventType, startDate, endDate, dateRangeType, selectedUsers }],
}: QueryFunctionContext<IndexSchedule>) => {
  const { data } = await api.scheduleService.indexTaskmanagerSchedule({
    params: {
      event_type: eventType.join(','),
      date_format: dateRangeType === DateRangeType.year ? 'week' : 'day',
      'date_start[lte]': endDate,
      'date_start[gte]': startDate,
      'user@id[in]': selectedUsers?.join(','),
    },
  });

  return data;
};

export const useIndexSchedule = (queryKey: IndexSchedule) =>
  useQuery(queryKey, indexSchedule);

import { DateTime, DayNumbers } from 'luxon';

/**
 * Use Date Format
 * @returns Returns date helper functions to help standardize the formatting of dates throughout the application.
 */
export const DateFormats = {
  /**
   * Get Day With Suffix
   * @returns {string} Returns day passed, formatted to "1st" | "2nd" | "3rd" | "4th"
   */
  getDayWithSuffix: (day: DayNumbers) => {
    const suffix = ['th', 'st', 'nd', 'rd'];
    const idx = day % 100;
    const value = day + (suffix[(idx - 20) % 10] || suffix[idx] || suffix[0]);
    return ` ${value} `;
  },

  /**
   * Get Week Format
   * @returns {string} Returns the date passed, formatted to "Monday 1st January 1970"
   */
  getWeekFormat: (date: DateTime) => {
    const dayOfWeek = date.toFormat('cccc');
    const dayOfMonth = DateFormats.getDayWithSuffix(date.day);
    const monthAndYear = date.toFormat('MMMM yyyy');
    return `${dayOfWeek} ${dayOfMonth} ${monthAndYear}`;
  },

  /**
   * Get Week Format
   * @returns {string} Returns the date passed, formatted to "Monday 1st January 1970"
   */
  getNewWeekFormat: (date: DateTime) => {
    const dayOfWeek = date.toFormat('cccc');
    const dayOfMonth = DateFormats.getDayWithSuffix(date.day);
    const monthAndYear = date.toFormat('MMM');
    return `${dayOfWeek} ${dayOfMonth} ${monthAndYear}`;
  },

  /**
   * Get Month Format
   * @returns {string} Returns the date passed, formatted to "January"
   */
  getMonthFormat: (date: DateTime) => date.toFormat('MMMM'),

  /**
   * Get Year Format
   * @returns {string} Returns the date passed, formatted to "Jan"
   */
  getYearFormat: (date: DateTime) => date.toFormat('MMM'),

  /**
   * To Api Date
   *
   * Used to format dates before they are sent in api calls.
   *
   * @returns {string} Returns the date passed, formatted to "1970-01-01"
   */
  toApiDate: (date: DateTime) => date.toFormat('yyyy-MM-dd'),
};

import { Skeleton } from '@mui/material';
import * as S from 'users/components/user-tile-shimmer/user-tile-shimmer.style';

const UserTileShimmer = (): JSX.Element => (
  <S.UserList>
    <S.UserItem>
      <Skeleton variant="rectangular" width="100%" height={177} />
    </S.UserItem>
    <S.UserItem>
      <Skeleton variant="rectangular" width="100%" height={177} />
    </S.UserItem>
    <S.UserItem>
      <Skeleton variant="rectangular" width="100%" height={177} />
    </S.UserItem>
    <S.UserItem>
      <Skeleton variant="rectangular" width="100%" height={177} />
    </S.UserItem>
    <S.UserItem>
      <Skeleton variant="rectangular" width="100%" height={177} />
    </S.UserItem>
    <S.UserItem>
      <Skeleton variant="rectangular" width="100%" height={177} />
    </S.UserItem>
  </S.UserList>
);

export default UserTileShimmer;

import StoreStatus from 'core/models/store-status.enum';
import { Reducer } from 'react';
import { AuthAction } from 'auth/reducers/auth/auth.action';
import { AuthState } from 'auth/reducers/auth/auth.state';

const AuthReducer: Reducer<AuthState, AuthAction> = (state, action) => {
  switch (action.type) {
    case 'initial':
      return { status: StoreStatus.initial };
    case 'login_success':
      return { status: StoreStatus.success, user: action.payload };
    case 'logout':
      return { status: StoreStatus.initial, user: undefined };
    case 'loading':
      return { status: StoreStatus.loading };
    case 'error':
      return { status: StoreStatus.error, message: action.payload };
    default:
      throw new Error();
  }
};

export default AuthReducer;

import {
  Dispatch,
  JSXElementConstructor,
  ReactElement,
  ReactFragment,
  ReactPortal,
  SetStateAction,
} from 'react';

import * as S from 'schedule/core/components/event-tooltip/event-tooltip.styles';

interface IEventTooltip {
  color?: string;
  tip:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | ReactFragment
    | ReactPortal;
  children: ReactElement<any, any>;
  open?: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  eventId?: string;
}

export const EventTooltip = ({
  color,
  tip,
  children,
  open,
  setOpen,
  eventId,
  ...rest
}: IEventTooltip) => (
  <S.ToolTip
    {...rest}
    arrow
    open={open}
    onOpen={() => setOpen(true)}
    onClose={() => setOpen(false)}
    title={tip}
    color={color}
    enterDelay={225}
    leaveDelay={195}
    disableFocusListener
    PopperProps={{
      style: { zIndex: 1200 },
    }}
  >
    <S.Container>{children}</S.Container>
  </S.ToolTip>
);

import {
  Autocomplete,
  Chip,
  TextField,
  createFilterOptions,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled/macro';
import { useController, useFormContext } from 'react-hook-form';
import { defaultTheme } from 'theme/theme';
import { SyntheticEvent } from 'react';

const Input = styled(TextField)`
  border-color: ${({ theme }) => theme.colors.borderGrey};

  .MuiAutocomplete-tag {
    background-color: #a0b1d3;
  }

  .MuiChip-deleteIcon {
    color: #07334a;
  }

  .MuiInputBase-root {
    background-color: #eaeaea;
    padding: 18px 20px;
  }
  && {
    .MuiInputBase-input {
      padding: 0;
    }
  }

  .MuiInputLabel-root {
    margin-bottom: 13px;
  }

  && .MuiFormLabel-root,
  & .MuiOutlinedInput-root {
    color: ${({ theme }) => theme.colors.textLight};
    font-family: ${({ theme }) => theme.fonts.sanSerif};
    font-weight: normal;
    font-size: 16px;
    opacity: 1;
    position: relative;
    transform: none;
  }
  && .MuiFormLabel-root {
    margin-right: 7px;
    font-size: 18px;
    color: ${defaultTheme.colors.primaryColor};
    font-weight: 500;
    white-space: initial;
  }

  &:hover {
    & fieldset.MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.colors.borderGrey};
      outline: none;
    }
  }

  && fieldset.MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.borderGrey};
    border-radius: 0;
    legend {
      width: 0;
    }
  }
`;

export interface ITaggingTextInputProps<T> {
  className?: string;
  value?: string[];
  id: Extract<keyof T, string>;
  label: string;
  placeholder?: string;
  inputValue?: string;
  onInputChange?: (event: SyntheticEvent, value: string) => void;
  options?: string[] | { label: string; id: string }[];
  defaultValues?: string[] | { label: string; id: string }[];
  freeSolo?: boolean;
  showFilterOption?: boolean;
  disableFilter?: boolean;
}

const TaggingTextInput = <T extends {}>({
  className,
  id,
  options,
  disableFilter = false,
  inputValue,
  label,
  placeholder,
  onInputChange,
  freeSolo = true,
  showFilterOption = false,
}: ITaggingTextInputProps<T>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({ control, name: id });

  const filter = createFilterOptions();

  return (
    <>
      <Autocomplete<
        string | { label: string; id: string },
        boolean,
        boolean,
        boolean
      >
        defaultValue={value}
        className={className}
        multiple
        id={id}
        value={
          options && typeof options[0] !== 'string'
            ? (options as { label: string; id: string }[]).filter(
                (obj: { label: string; id: string }) => {
                  if (!value || value.length === 0) return false;
                  return (value as { label: string; id: string }[]).some(
                    (val) => obj.id === val.id,
                  );
                },
              )
            : value
        }
        freeSolo={freeSolo}
        onInputChange={onInputChange}
        inputValue={inputValue}
        filterOptions={
          disableFilter
            ? (x) => x
            : (opts, params: any) => {
                const filtered = filter(opts, params);

                if (showFilterOption) {
                  const isExisting = opts.some(
                    (option) => params.inputValue === option,
                  );
                  if (params.inputValue !== '' && !isExisting) {
                    filtered.push(`${params.inputValue}`);
                  }
                }

                return filtered as any;
              }
        }
        renderTags={(val, getTagProps) =>
          val.map((option, index) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              variant='outlined'
              label={typeof option === 'string' ? option : option.label}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <Input
            {...params}
            InputLabelProps={{ shrink: true }}
            label={label}
            placeholder={placeholder}
          />
        )}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.label
        }
        options={options ?? []}
        onChange={(_, newValue: any) => {
          onChange(newValue);
        }}
      />
      {errors[id] && (
        <Typography variant='body2' color={defaultTheme.colors.errorColor}>
          {errors[id]?.message as unknown as string}
        </Typography>
      )}
    </>
  );
};

export default TaggingTextInput;

import { Droppable } from 'react-beautiful-dnd';
import useEventTypeProvider, {
  PageEnum,
} from 'schedule/core/providers/event-type-provider';
import { RemainingHours } from 'schedule/core/components/remaining-hours/remaining-hours';
import * as S from 'schedule/core/components/user-day-drop-area/user-day-drop-area.styles';
import { useMemo } from 'react';
import { TaskmanagerEventTypeEnum } from 'gateway-api';

interface IUserDayDropArea {
  index: number;
  droppableId: string;
  remainingTime: number;
  children?: React.ReactNode;
  date?: string;
  userId: string;
  eventType: TaskmanagerEventTypeEnum;
  isDisabled?: boolean;
}

/**
 * User Day Drop Area
 *
 * This component is used to create a droppable area for all events in the schedule.
 * There is one User Day Drop Area component for every day in the users schedule.
 *
 * The children Within this component are the events for that particular day.
 */
export const UserDayDropArea = ({
  index,
  droppableId,
  remainingTime,
  children,
  date,
  userId,
  eventType,
  isDisabled = false,
}: IUserDayDropArea) => {
  const { scheduleParams, page } = useEventTypeProvider();
  const isDropDisabled: boolean = useMemo(() => {
    if (isDisabled) return true;
    if (page === PageEnum.ADD_TIME) {
      return scheduleParams.eventType[0] === TaskmanagerEventTypeEnum.Schedule;
    }
    if (page === PageEnum.RECORDED) return true;
    return false;
  }, [isDisabled, page, scheduleParams.eventType]);

  return (
    <Droppable
      key={droppableId}
      droppableId={droppableId}
      direction='horizontal'
      isDropDisabled={isDropDisabled}
    >
      {(provided, snapshot) => (
        <S.DropContainer
          isDraggingOver={snapshot.isDraggingOver}
          isEven={index % 2 === 0}
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {children}
          {provided.placeholder}
          {remainingTime !== 0 &&
            date !== undefined &&
            !snapshot.isDraggingOver && (
              <RemainingHours
                eventType={eventType}
                hours={remainingTime}
                date={date}
                userId={userId}
              />
            )}
        </S.DropContainer>
      )}
    </Droppable>
  );
};

/**
 * Module dependencies.
 */

import { DropResult } from 'react-beautiful-dnd';
import { DateTime } from 'luxon';
import { UserDto } from 'sso-api';
import DateRangeType from 'date-range/models/date-range-type.enum';
import { IEventHistory } from 'schedule/core/queries/schedule-move-mutation';
import { PageEnum } from 'schedule/core/providers/event-type-provider';
import {
  TaskmanagerEventDto,
  TaskmanagerEventTypeEnum,
  TaskmanagerPotDto,
} from 'gateway-api';

export interface IEventPayload {
  eventType: TaskmanagerEventTypeEnum;
  selectedUsers: string[];
  startDate: Date;
  endDate: Date;
  dateRangeType: DateRangeType;
}

export interface IAddPot extends TaskmanagerPotDto {
  dateStart: string;
  assigneeId: string;
  eventType: TaskmanagerEventTypeEnum;
}

export interface IUseScheduleDragEndPayload {
  event?: TaskmanagerEventDto;
  pot?: TaskmanagerPotDto;
  // handleCreate: (event: EventEdit) => void;
  handleCreate: (potWithDate: IAddPot) => void;
  handlePatch: (event?: IEventHistory) => void;
  handleDelete: (event: TaskmanagerEventDto) => void;
  handleClone: (event: IEventHistory) => void;
  handleCloneSameSchedule: (event: IEventHistory) => void;
  onAbort: () => void;
  user?: UserDto;
  isCloneKeyDown: boolean;
  page: PageEnum;
}

/**
 * This hook is in charge of taking a drop result and parsing it to the
 * Mutation event type (IPatchEvent).
 */

const useScheduleDragEnd =
  ({
    event,
    handlePatch,
    onAbort,
    pot,
    handleCreate,
    handleDelete,
    handleClone,
    handleCloneSameSchedule,
    user,
    isCloneKeyDown,
    page,
  }: IUseScheduleDragEndPayload) =>
  (result: DropResult) => {
    const { destination } = result;

    if (result.reason === 'CANCEL' || !destination) {
      onAbort();
      return;
    }

    const idParts = destination.droppableId.split('_');

    const destinationDroppable = {
      userId: idParts[0],
      date: new Date(idParts[1]),
      eventType: idParts[2] as TaskmanagerEventTypeEnum,
    };

    if (pot) {
      handleCreate({
        ...pot,
        dateStart: DateTime.fromJSDate(destinationDroppable.date).toFormat(
          'yyyy-MM-dd',
        ),
        assigneeId: destinationDroppable.userId,
        eventType: destinationDroppable.eventType,
      });
    } else if (
      event &&
      event.assignee_user &&
      event.assigner_user &&
      destinationDroppable.eventType === event.event_type &&
      !isCloneKeyDown
    ) {
      handlePatch({
        newEvent: {
          ...event,
          assignee_user: {
            ...event.assignee_user,
            id: destinationDroppable.userId,
          },
          assigner_user: { ...event.assigner_user, id: user?.id ?? '' },
          event_type: destinationDroppable.eventType,
          date_start: DateTime.fromJSDate(destinationDroppable.date).toFormat(
            'yyyy-MM-dd',
          ),
        },
        oldEvent: event,
        patchEvent: {
          id: event.id,
          date_end: DateTime.fromJSDate(destinationDroppable.date).toFormat(
            'yyyy-MM-dd',
          ),
          hours: event.hours,
          hours_from: event.hours_from,
          assignee_user_id: destinationDroppable.userId,
          assigner_user_id: user?.id,
          event_type: destinationDroppable.eventType,
          date_start: DateTime.fromJSDate(destinationDroppable.date).toFormat(
            'yyyy-MM-dd',
          ),
        },
      });
    } else if (event && destination.droppableId === 'DELETE_EVENT') {
      handleDelete(event);
    } else if (
      event &&
      event.assignee_user &&
      event.assigner_user &&
      event.event_type !== destinationDroppable.eventType &&
      !isCloneKeyDown
    ) {
      handleClone({
        newEvent: {
          ...event,
          assignee_user: {
            ...event.assignee_user,
            id: destinationDroppable.userId,
          },
          assigner_user: { ...event.assigner_user, id: user?.id ?? '' },
          event_type: destinationDroppable.eventType,
          date_start: DateTime.fromJSDate(destinationDroppable.date).toFormat(
            'yyyy-MM-dd',
          ),
        },
        oldEvent: event,
        patchEvent: {
          id: event.id,
          date_end: DateTime.fromJSDate(destinationDroppable.date).toFormat(
            'yyyy-MM-dd',
          ),
          hours: event.hours,
          hours_from: event.hours_from,
          assignee_user_id: destinationDroppable.userId,
          assigner_user_id: user?.id,
          event_type: destinationDroppable.eventType,
          date_start: DateTime.fromJSDate(destinationDroppable.date).toFormat(
            'yyyy-MM-dd',
          ),
          time_pot_type_id: event.time_pot?.pot_type_id,
          pot_ids: [`${event.time_pot?.parent_pot_id ?? ''}`],
        },
      });
    } else if (
      event &&
      event.assignee_user &&
      event.assigner_user &&
      isCloneKeyDown
    ) {
      handleCloneSameSchedule({
        newEvent: {
          ...event,
          assignee_user: {
            ...event.assignee_user,
            id: destinationDroppable.userId,
          },
          assigner_user: { ...event.assigner_user, id: user?.id ?? '' },
          event_type: destinationDroppable.eventType,
          date_start: DateTime.fromJSDate(destinationDroppable.date).toFormat(
            'yyyy-MM-dd',
          ),
        },
        oldEvent: event,
        patchEvent: {
          id: event.id,
          date_end: DateTime.fromJSDate(destinationDroppable.date).toFormat(
            'yyyy-MM-dd',
          ),
          hours: event.hours,
          hours_from: event.hours_from,
          assignee_user_id: destinationDroppable.userId,
          assigner_user_id: user?.id,
          event_type: destinationDroppable.eventType,
          date_start: DateTime.fromJSDate(destinationDroppable.date).toFormat(
            'yyyy-MM-dd',
          ),
          time_pot_type_id: event.time_pot?.pot_type_id,
          pot_ids: [`${event.time_pot?.parent_pot_id ?? ''}`],
        },
      });
    }
  };

export default useScheduleDragEnd;

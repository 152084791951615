import styled from '@emotion/styled/macro';
import { Table, TableCell, TableRow } from '@mui/material';
import PrimaryButton from 'core/components/buttons/primary-button';

export const StyledTable = styled(Table)`
  width: 100%;
  font-size: 1em;
`;

export const StyledTableRow = styled(TableRow)`
  background-color: #eaeaea;
`;

export const StyledTableCell = styled(TableCell)`
  border: solid #ffffff 1px;
  text-align: left;
  padding: 0;

  &.MuiTableCell-body {
    font-size: 1em;

    ${(props) => props.theme.breakpoints.down('sm')} {
      font-size: 0.875em;
    }
  }
`;

export const AddButton = styled(PrimaryButton)`
  && {
    padding: 16px;
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    color: #2e559f;
    font-size: 0.875em;
    width: 100%;
    justify-content: left;
  }
`;

export const StyledInput = styled.input`
  padding: 16px;
  border: none;
  background: #eaeaea;
`;

export const Remove = styled(PrimaryButton)`
  && {
    background: none;
    border: none;
    padding: 16px;
    border-radius: 0;
    box-shadow: none;
    color: #2e559f;
    cursor: pointer;
  }
`;

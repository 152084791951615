import { Typography } from '@mui/material';
import TaskCard from 'statistics/components/task-card/task-card';
import SearchIcon from '@mui/icons-material/Search';
import { ChangeEvent, useState } from 'react';
import CustomHelmet from 'core/components/custom-helmet';
import Scaffold from 'core/components/scaffolds/scaffold';
import { useTranslation } from 'react-i18next';
import useIndexProjectTickets from 'statistics/hooks/use-index-project-tickets';
import TaskDetailsStats from 'statistics/components/task-details-statistics/task-details-statistics';
import useIndexProject from 'statistics/hooks/use-index-project';
import useIndexTags from 'statistics/hooks/use-index-tags';
import StatsSearch from 'statistics/components/stats-search';
import TaskDetailsStatsTags from 'statistics/components/task-details-statistics-tags/task-details-statistics-tags';
import * as S from 'statistics/pages/project-statistics/project-statistics.style';
import { TaskmanagerPotDto, TaskmanagerTagDto } from 'gateway-api';

const ProjectStatistics = (): JSX.Element => {
  const {
    items: tickets,
    pageNumber,
    setPageNumber,
    setSearch: setTicketSearch,
    pagination,
    indexPotsLoading,
  } = useIndexProjectTickets('ticket');

  const { items: selectedProjects, setSearch: setProjectSearch } =
    useIndexProject('project');

  const { items: selectedTags, setSearch: setTagSearch } = useIndexTags();

  const { t } = useTranslation();

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  const [selectedTask, setSelectedTask] = useState<TaskmanagerPotDto>();
  const [selectedTag, setSelectedTag] = useState<TaskmanagerTagDto>();
  const [taskOpen, setTaskOpen] = useState(false);
  const [hideTasks, setHideTasks] = useState(false);

  const handleClick = (task: TaskmanagerPotDto) => {
    if (task) {
      setSelectedTask(task);
      setSelectedTag(undefined);
      setTaskOpen(true);
      setHideTasks(true);
    }
  };

  const handleClickTag = (tag: TaskmanagerTagDto) => {
    if (tag) {
      setSelectedTag(tag);
      setSelectedTask(undefined);
      setTaskOpen(true);
      setHideTasks(true);
    }
  };

  const handleBack = () => {
    setTaskOpen(false);
    setHideTasks(false);
  };

  const handleRemoveTag = () => {
    setTicketSearch(undefined);
    setTagSearch('');
    setProjectSearch('');
  };

  return (
    <>
      <CustomHelmet pageName={t('project_statistics.title')} />
      <Scaffold
        headerTitle={t('project_statistics.title')}
        pageTitle={t('project_statistics.title')}
      >
        <S.FlexContainer>
          <S.Tasks mobileView={hideTasks}>
            <S.StyledSearch>
              <StatsSearch
                setTags={setTicketSearch}
                setProjectSearch={setProjectSearch}
                setTagSearch={setTagSearch}
                setPage={setPageNumber}
              />
            </S.StyledSearch>

            {selectedProjects && (
              <>
                {selectedProjects?.map((project: TaskmanagerPotDto) => (
                  <div key={project.id}>
                    <S.StyledChip
                      label={project.name}
                      onDelete={handleRemoveTag}
                      variant="outlined"
                    />

                    <S.StyledTaskCard
                      selectedTicket={project === selectedTask}
                      key={project.id}
                      onClick={() => handleClick(project)}
                    >
                      <S.StyledTaskCardContainer>
                        <Typography fontSize={20}>{project.name}</Typography>
                      </S.StyledTaskCardContainer>
                    </S.StyledTaskCard>
                  </div>
                ))}
              </>
            )}

            {selectedTags && (
              <>
                {selectedTags?.map((tag: TaskmanagerTagDto) => (
                  <div key={tag.id}>
                    <S.StyledChip
                      label={tag.name}
                      onDelete={handleRemoveTag}
                      variant="outlined"
                    />

                    <S.StyledTaskCard
                      selectedTicket={false}
                      key={tag.id}
                      onClick={() => handleClickTag(tag)}
                    >
                      <S.StyledTaskCardContainer>
                        <Typography fontSize={20}>{tag.name}</Typography>
                      </S.StyledTaskCardContainer>
                    </S.StyledTaskCard>
                  </div>
                ))}
              </>
            )}

            {tickets?.length && (
              <>
                <S.TaskList>
                  {tickets?.map((ticket: TaskmanagerPotDto) => (
                    <S.StyledTaskCard
                      selectedTicket={ticket === selectedTask}
                      key={ticket.id}
                      onClick={() => handleClick(ticket)}
                    >
                      <TaskCard pot={ticket} />
                    </S.StyledTaskCard>
                  ))}
                </S.TaskList>

                <S.StyledPagination
                  count={pagination?.page_total}
                  page={pageNumber}
                  onChange={handlePageChange}
                />
              </>
            )}
          </S.Tasks>
          {tickets?.length ? (
            <S.TaskDetails>
              {taskOpen ? (
                <>
                  <S.BackButton onClick={handleBack} variant="text">
                    {t('project_statistics.search_placeholder')}
                  </S.BackButton>
                  {selectedTask && <TaskDetailsStats pot={selectedTask} />}
                  {selectedTag && <TaskDetailsStatsTags tag={selectedTag} />}
                </>
              ) : (
                <></>
              )}
            </S.TaskDetails>
          ) : (
            <S.NoTasks>
              <SearchIcon />
              <Typography>{t('project_statistics.no_task_body')}</Typography>
              <Typography>{t('project_statistics.no_task_body_1')}</Typography>
            </S.NoTasks>
          )}
        </S.FlexContainer>
      </Scaffold>
    </>
  );
};

export default ProjectStatistics;

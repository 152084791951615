/**
 * Module dependencies.
 */

import {
  TimelineConnector,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import DateRangeIcon from '@mui/icons-material/DateRange';
import * as S from 'statistics/components/stats-timeline/stats-timeline.style';
import { TaskmanagerPotStatTimelineCollectionDto } from 'gateway-api';

interface ITimeLineProps {
  timelineData: TaskmanagerPotStatTimelineCollectionDto[] | undefined;
}

const StatsTimeLine = ({ timelineData }: ITimeLineProps): JSX.Element => (
  <S.StyledTimeline>
    {timelineData?.map((timelineDate) =>
      timelineDate.entries.map((timeline) =>
        timeline.users.map((user, index) => (
          <TimelineItem key={`cell-${index}`}>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <S.StyledTimelineContent>
              <S.ItemRows>
                <DateRangeIcon />
                {new Date(timelineDate.date as string).toLocaleString('en-GB', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })}
              </S.ItemRows>
              <S.ItemRows>{user.user_directory?.first_name}</S.ItemRows>
              <S.ItemRows>
                <AccessTimeFilledIcon /> {user.hours} hrs
              </S.ItemRows>
              <S.ItemRows>{timeline.time_pot}</S.ItemRows>
              {timeline.ticket_pot_labels.map((taskLabel, i) => (
                <S.TaskLabel key={`cell-${i}`}>{taskLabel}</S.TaskLabel>
              ))}
            </S.StyledTimelineContent>
          </TimelineItem>
        )),
      ),
    )}
  </S.StyledTimeline>
);

export default StatsTimeLine;

export interface IShowPotStats {
  id: string;
}

export const potStatKeys = {
  base: [{ scope: 'pot' }] as const,
  show: () => [{ ...potStatKeys.base[0], entity: 'show' }] as const,
  showPot: (query: IShowPotStats) =>
    [
      {
        ...potStatKeys.show()[0],
        ...query,
      },
    ] as const,
};

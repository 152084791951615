import styled from '@emotion/styled/macro';
import { Chip as ChipMui } from '@mui/material';

export const Chip = styled(ChipMui)`
  max-width: 130px;
  background-color: #a0b1d3;
  color: #07334ade;
  font-size: 14px;
  height: 26px;
  & .MuiSvgIcon-root {
    color: #07334a;
    height: 15px;
    width: 15px;
  }
`;

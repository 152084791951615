/**
 * Module dependencies.
 */
import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import {
  IUsersPayload,
  IUsersStore,
} from 'schedule/core/store/users/users.state';

const initialState: IUsersPayload = {
  users: undefined,
};

const useUserStore = create<IUsersStore>()(
  immer(
    devtools(
      (set) => ({
        state: initialState,
        setUsers(newUsers) {
          set(
            (store) => {
              store.state.users = newUsers.length ? newUsers : undefined;
            },
            false,
            { type: 'users-store__setUsers', newUsers },
          );
        },
      }),
      {
        name: 'users-store',
      },
    ),
  ),
);

export default useUserStore;

import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { List, ListItemButton } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { defaultTheme } from 'theme/theme';
import RoutePath from 'routes/route-paths';
import { isInRole } from 'routes/protected-route';
import { useSnackbar } from 'notistack';
import useAuth from 'auth/hooks/use-auth';
import { logout } from 'auth/reducers/auth/auth.action';
import NavButton from 'core/components/nav/navigation-button';
import NavItem from 'core/components/nav/navigation-item';

const NavbarList = styled(List)`
  && {
    width: 100%;
    display: inline-flex;
    padding: 0;
    position: relative;

    ${(props) => props.theme.breakpoints.down('lg')} {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 25px 0 10px 0;
    }

    /* ${(props) => props.theme.breakpoints.up('md')} {
      height: 100%;
      justify-content: space-between;
    } */

    ${(props) => props.theme.breakpoints.up('lg')} {
      height: 100%;
      justify-content: flex-start;
    }
  }
`;

const NavBar = styled.nav<MobileMenuProps>`
  flex: 1;

  ${(props) => props.theme.breakpoints.down('lg')} {
    display: ${(props) => {
      if (props.showSideBar !== true) {
        return 'none';
      }
      return 'block';
    }};

    position: absolute;
    top: 50px;
    background-color: #ffffff;
    width: 205px;
    box-shadow: 0px 3px 6px #00000029;
    left: 0;
    height: 90%;
    z-index: 9;
    border-radius: 0 10px 10px 0;
  }

  ${(props) => props.theme.breakpoints.up('lg')} {
    height: 100%;
  }
`;

const StyledLogoutLink = styled(ListItemButton)`
  && {
    display: inline-flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.textPrimary};
    text-decoration: none;
    font-family: ${defaultTheme.fonts.latoSanSerif};

    ${(props) => props.theme.breakpoints.down('lg')} {
      font-size: 0.875em;
    }

    ${(props) => props.theme.breakpoints.up('lg')} {
      font-size: 1em;
    }

    @media only screen and (min-width: 1600px) {
      font-size: 1.1875em;
    }

    .MuiSvgIcon-root {
      fill: #afafaf;
      margin-right: 7px;
    }
  }
`;

const StyledNavButton = styled(NavButton)`
  && {
    font-family: ${defaultTheme.fonts.latoSanSerif};
    text-transform: none;
    font-weight: bold;
    text-align: center;

    ${(props) => props.theme.breakpoints.down('lg')} {
      .MuiButton-startIcon {
        display: none;
      }
    }
  }
` as typeof NavButton;

export interface MobileMenuProps {
  showSideBar?: boolean;
}

export const Navigation = ({ showSideBar }: MobileMenuProps): JSX.Element => {
  const [{ user }, dispatch] = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  // TODO: fix logout with google

  const handleLogout = () => {
    dispatch(logout());
  };

  const logoutFailure = () => {
    enqueueSnackbar('An error occured whislt trying to logout with google', {
      variant: 'error',
    });
  };

  // const { signOut } = useGoogleLogout({
  //   clientId:
  //     "761611647544-kst6mbpo7dqnpmfkvojvthampheafpm3.apps.googleusercontent.com",
  //   onLogoutSuccess: doLogout,
  //   onFailure: logoutFailure,
  // });

  return (
    <NavBar showSideBar={showSideBar}>
      <NavbarList>
        <NavItem>
          <NavButton
            path={RoutePath.DASHBOARD}
            title={t('navigation.nav_item.home')}
          />
        </NavItem>
        {isInRole(user?.roles?.taskmanager, ['admin']) && (
          <NavItem>
            <NavButton
              path={RoutePath.USER_MANAGEMENT}
              title={t('navigation.nav_item.user_management')}
            />
          </NavItem>
        )}

        <NavItem>
          <NavButton path={RoutePath.SCHEDULE} title='Schedule' />
        </NavItem>

        {isInRole(user?.roles?.taskmanager, ['admin']) && (
          <NavItem>
            <NavButton
              path={RoutePath.ESTIMATE}
              title={t('navigation.nav_item.estimate')}
            />
          </NavItem>
        )}

        {isInRole(user?.roles?.taskmanager, ['admin']) && (
          <NavItem>
            <NavButton path={RoutePath.RECORDED} title='Recorded' />
          </NavItem>
        )}

        {isInRole(user?.roles?.taskmanager, ['admin']) && (
          <NavItem>
            <NavButton
              path={RoutePath.PROJECT_STATISTICS}
              title='Project Statistics'
            />
          </NavItem>
        )}

        {isInRole(user?.roles?.taskmanager, ['admin']) && (
          <NavItem>
            <NavButton path={RoutePath.MULTIVIEW} title='Multi View' />
          </NavItem>
        )}

        {isInRole(user?.roles?.taskmanager, ['admin']) && (
          <NavItem>
            <NavButton path={RoutePath.GANT_VIEW} title='Gant Chart' />
          </NavItem>
        )}

        <NavItem>
          <StyledNavButton
            path={RoutePath.ADD_TIME}
            title={t('navigation.nav_item.add_time')}
            icon={<AccessTimeIcon />}
          />
        </NavItem>
        <NavItem>
          <StyledLogoutLink onClick={handleLogout}>
            <LogoutIcon />
            {t('navigation.nav_item.logout')}
          </StyledLogoutLink>
        </NavItem>
      </NavbarList>
    </NavBar>
  );
};

export default Navigation;

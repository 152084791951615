import styled from '@emotion/styled';
import { IconButton, ToggleButton as MuiToggle } from '@mui/material';
import { StarOutline } from '@mui/icons-material';
import StarIcon from '@mui/icons-material/Star';
import { defaultTheme } from 'theme/theme';
import React from 'react';

export interface IFavProps {
  isFavourite?: boolean;
  buttonIcon?: React.ReactNode;
  potId?: string;
  selected?: boolean;
}

export const StarIconButton = styled(IconButton)<IFavProps>`
  && {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
  }
`;

export const StarIconOutlined = styled(StarOutline)`
  && {
    color: ${defaultTheme.colors.primaryColor};
    width: 2rem;
    height: 2rem;
  }
`;

export const StarIconFilled = styled(StarIcon)`
  && {
    color: ${defaultTheme.colors.primaryColor};
    width: 2rem;
    height: 2rem;
  }
`;

export const ToggleButton = styled(MuiToggle)<IFavProps>`
  && {
    color: ${(props) =>
      props.selected ? 'white' : defaultTheme.colors.textPrimary};
    background-color: ${(props) =>
      props.selected ? defaultTheme.colors.primaryColor : 'transparent'};
    width: 160px;
    height: 2.5rem;
    text-transform: capitalize;
    border: solid 0.01rem ${defaultTheme.colors.borderGrey};
    box-shadow: none;

    &.Mui-selected {
      &:hover {
        background-color: ${defaultTheme.colors.primaryColor};
      }
    }
  }
`;

import { Typography } from '@mui/material';
import PrimaryButton from 'core/components/buttons/primary-button';
import styled from '@emotion/styled/macro';

interface IEvent {
  width: number;
  $assignedTask: boolean;
}

interface ITooltip {
  $assignedTask: boolean;
}

export const CalendarContainer = styled(PrimaryButton)<IEvent>`
  && {
    position: relative;
    background-color: ${({ $assignedTask }) =>
      $assignedTask ? '#a7e3b8' : '#07334A'};
    color: ${({ $assignedTask }) => ($assignedTask ? '#07334A' : '#a7e3b8')};
    font-size: 18px;
    border-radius: 4px;
    height: 100%;
    width: ${({ width }) => `${width}px`};
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-shadow: none;
    min-width: auto;
    padding: 0;
    z-index: 1;

    .MuiButton-startIcon {
      margin-left: 0;
      margin-right: 0;
    }

    .MuiSvgIcon-root {
      font-size: 100%;
      max-width: 22px;
      max-height: 22px;
      width: 100%;
      height: 100%;
    }
  }
`;

export const LeaveTitle = styled(Typography)`
  font-size: 1em;
  text-transform: capitalize;
`;

export const TooltipDetails = styled.div<ITooltip>`
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    background-color: ${(props) =>
      props.$assignedTask ? '#a7e3b8' : '#07334A'};
    top: 0;
    height: 8px;
  }
`;

export const TooltipBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 5px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
`;

export const TooltipHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
`;

export const CalendarEventTitle = styled(Typography)<ITooltip>`
  background-color: ${({ $assignedTask }) =>
    $assignedTask ? '#a7e3b8' : '#07334A'};
  padding: 1px 15px;
  font-weight: bold;
  border-radius: 8px;
  color: ${({ $assignedTask }) => ($assignedTask ? '#07334A' : '#ffffff')};
  max-width: calc(100% - 75px);
`;

export const CalendarEventContent = styled.div`
  width: 100%;
`;

import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { api } from 'core/services/api.service';
import { UserRequestDto, UserListDto, UserDto } from 'gateway-api';

interface IReplaceUser {
  id: string;
  user: UserRequestDto;
}
interface IStoreUser {
  user: UserRequestDto;
}

const useStoreUser = () => {
  const queryClient = useQueryClient();

  const {
    isLoading: isUpdatingUser,
    isError: isUpdateError,
    isSuccess: isUpdateSuccess,
    mutate: updateUser,
  } = useMutation(
    ({ id, user }: IReplaceUser) =>
      api.gatewayUserService.replaceUser(id, user),
    {
      onSuccess: (data: AxiosResponse<UserDto>) => {
        queryClient.setQueriesData(['users', 'list'], (prev: any) => {
          if (prev) {
            const newState: UserListDto = {
              ...(prev as UserListDto),
            };
            newState.items = newState.items.map((value) =>
              value.id === data.data.id ? data.data : value,
            );
            return newState;
          }
          return prev;
        });
        // return queryClient.invalidateQueries(["users", "list"]);
      },
    },
  );

  const {
    isLoading: isStoringUser,
    isError: isStoreError,
    isSuccess: isStoreSuccess,
    mutate: storeUser,
  } = useMutation({
    mutationFn: ({ user }: IStoreUser) =>
      api.gatewayUserService.storeUser(user),
    onSuccess: () => queryClient.invalidateQueries(['users', 'list']),
  });

  return {
    isUpdatingUser,
    isUpdateError,
    isUpdateSuccess,
    updateUser,
    isStoringUser,
    isStoreError,
    isStoreSuccess,
    storeUser,
  };
};

export default useStoreUser;

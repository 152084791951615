import { PieChart, Pie, Cell } from 'recharts';

const COLORS = ['#a0b1d3', '#f27777', '#a7e3b9', '#fad1ff'];

interface IChartData {
  pot_name: string;
  hours: number;
}

interface IPieChartProps {
  chartData: IChartData[];
}

const CustomPieChart = ({ chartData }: IPieChartProps): JSX.Element => {
  const RADIAN = Math.PI / 180;

  const renderActiveShape = ({
    cx,
    cy,
    midAngle,
    outerRadius,
    percent,
    pot_name,
  }: any) => {
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 0) * cos;
    const sy = cy + (outerRadius + 0) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke="#000000"
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill="#000000" stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#000000"
        >
          {pot_name}
        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#000000"
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      </g>
    );
  };

  return (
    <PieChart width={400} height={400}>
      <Pie
        isAnimationActive={false}
        activeShape={renderActiveShape}
        data={chartData}
        cx={200}
        cy={200}
        outerRadius={80}
        fill="#f27777"
        label={renderActiveShape}
        dataKey="hours"
      >
        {chartData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default CustomPieChart;

import LandingPageLayout from 'core/components/layouts/landing';
import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';
import CustomHelmet from 'core/components/custom-helmet';
import useAuth from 'auth/hooks/use-auth';
import { useSnackbar } from 'notistack';
import { loginWithGoogle } from 'auth/reducers/auth/auth.action';
import { useEffect } from 'react';
import StoreStatus from 'core/models/store-status.enum';
import * as S from 'auth/pages/login/login.styles';

const LoginPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useAuth();

  useEffect(() => {
    if (state.status === StoreStatus.error) {
      enqueueSnackbar(state.message, { variant: 'error' });
    }
  }, [state.status]);

  const handleOnSuccess = (responseCode: string) => {
    dispatch(loginWithGoogle(responseCode));
  };

  const handleOnFailure = () => {
    dispatch({
      type: 'error',
      payload:
        'An error occured whislt connecting to google. Please contact your system administrator.',
    });
  };

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: (tokenResponse) => {
      handleOnSuccess(tokenResponse.code);
    },
    scope:
      'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
    onError: (errorResponse) => handleOnFailure(),
    redirect_uri: window.location.href.replace(/\/$/, ''),
  });

  return (
    <>
      <CustomHelmet pageName="Login" />
      <LandingPageLayout sectionTitle={t('login.title')}>
        <S.SectionTitle variant="h2">{t('login.description')}</S.SectionTitle>
        <div>
          <S.GoogleContainer onClick={() => googleLogin()}>
            <S.GoogleIconContainer>
              <S.GoogleIcon />
            </S.GoogleIconContainer>
            <S.GoogleParagraph> Sign in with Google</S.GoogleParagraph>
          </S.GoogleContainer>
        </div>
      </LandingPageLayout>
    </>
  );
};

export default LoginPage;

import styled from '@emotion/styled/macro';
import { defaultTheme } from 'theme/theme';

interface IStyledLegend {
  inline?: boolean;
}

export const StyledFieldset = styled.fieldset`
  border: 0;
`;
export const StyledLegend = styled.legend<IStyledLegend>`
  font-size: 18px;
  line-height: 37px;
  color: ${defaultTheme.colors.primaryColor};
  font-weight: 500;
  margin-bottom: ${(props) => (props.inline ? 0 : '25px')};
  font-family: 'Roboto', sans-serif;

  ${(props) => props.theme.breakpoints.down('md')} {
    margin-bottom: 0;
  }
`;

export const FlexCol = styled.div`
  margin-bottom: 35px;

  .MuiFormControl-root {
    ${(props) => props.theme.breakpoints.down('md')} {
      flex-direction: column;
      margin-top: 20px;
      align-items: stretch;
    }
  }

  .MuiFormGroup-root {
    ${(props) => props.theme.breakpoints.down('md')} {
      width: 100%;
    }
  }

  .group-inline {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .user-image {
    .MuiAvatar-root {
      width: 94px;
      height: 94px;
      margin-right: 15px;

      ${(props) => props.theme.breakpoints.down('md')} {
        width: 47px;
        height: 47px;
      }
    }
  }

  .user-role {
    max-width: 425px;
  }

  .user-checkbox {
    .MuiFormControlLabel-root {
      margin-right: 50px;
    }
  }
  .switchButton {
    margin: 0 6px 15px 0;
  }

  .hide-label {
    .MuiInputLabel-formControl {
      font-size: 0;
    }
  }

  &.flex-inline {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    ${(props) => props.theme.breakpoints.down('md')} {
      flex-direction: column;
    }

    .email-field {
      .MuiInputBase-root {
        max-width: 600px;
        width: 100%;

        ${(props) => props.theme.breakpoints.down('md')} {
          max-width: 100%;
        }
      }
    }
  }
  .flex-inline--equal {
    width: 48%;

    .MuiInputBase-root {
      width: 100%;
    }

    ${(props) => props.theme.breakpoints.down('md')} {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  &.flex-right {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
  }
`;

export const StatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 45%;

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    width: 100%;
  }
`;

import { Skeleton } from '@mui/material';
import styled from '@emotion/styled/macro';

const UserShimmer = styled(Skeleton)`
  width: 230px;
  height: 34px;

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 60px;
  }
`;

const ScheduleFiltersShimmer = (): JSX.Element => (
  <UserShimmer variant="text" />
);

export default ScheduleFiltersShimmer;

/**
 * Module dependencies.
 */

import { QueryClient, useMutation, useQueryClient } from 'react-query';
import { api } from 'core/services/api.service';
import {
  IIndexSchedule,
  scheduleKeys,
} from 'schedule/core/queries/schedule.keys';
import { cleanDateEntry } from 'schedule/core/queries/resize-mutation';
import {
  TaskmanagerEventDto,
  TaskmanagerEventPartialDto,
  TaskmanagerScheduleListDto,
} from 'gateway-api';

export interface IEventPatchWithId extends TaskmanagerEventPartialDto {
  id?: string;
}

/**
 * @param `newEvent` necessary to make optimistic update.
 * @param `oldEvent` necessary to make optimistic update.
 * @param `patchEvent` holds the fields necessary to patch an event.
 */

export interface IEventHistory {
  newEvent?: TaskmanagerEventDto;
  oldEvent?: TaskmanagerEventDto;
  patchEvent: IEventPatchWithId;
}

/**
 * Function `onMutate` mutation.
 */

const onMutate =
  (queryClient: QueryClient, params: IIndexSchedule) =>
  async (eventHistory: IEventHistory) => {
    await queryClient.cancelQueries(scheduleKeys.index());

    const queryKey = scheduleKeys.indexSchedule(params);

    const previousQuery =
      queryClient.getQueryData<TaskmanagerScheduleListDto>(queryKey);

    let existingEvent: TaskmanagerEventDto | undefined;

    queryClient.setQueryData<TaskmanagerScheduleListDto | undefined>(
      queryKey,
      (memoOld) => {
        if (memoOld) {
          const old = { ...memoOld };
          return {
            pagination: old.pagination,
            items: old.items.map((schedule) => {
              // remove event from the day that is being dragged
              if (eventHistory.oldEvent) {
                const {
                  assignee_user: oldAssigneeUserObject,
                  date_start: oldDateStart,
                  id: oldEventId,
                } = eventHistory.oldEvent;

                if (schedule.user?.id === oldAssigneeUserObject?.id) {
                  schedule.entries = schedule.entries.map((entry) => {
                    const entryDate = entry.date_from.split(' ')[0];
                    const dragEntryDate = oldDateStart.split(' ')[0];

                    if (entryDate !== dragEntryDate) return entry;

                    entry.events = entry.events.filter(
                      (event) => event.id !== oldEventId,
                    );

                    return cleanDateEntry(
                      entry,
                      schedule.user?.profile.available_hours,
                    );
                  });
                }
              }

              if (eventHistory.newEvent) {
                const {
                  assignee_user: newAssigneeObject,
                  date_start: newEventDateStart,
                  hours: newEventHours,
                  pots_by_type: newPotEvent,
                  event_type: eventType,
                } = eventHistory.newEvent;

                // add the event to the schedule that it was dropped to
                if (schedule.user?.id === newAssigneeObject?.id) {
                  const dropDateEntry = schedule.entries.find(
                    (entry) =>
                      new Date(entry.date_from).toDateString() ===
                      new Date(newEventDateStart).toDateString(),
                  );

                  if (dropDateEntry) {
                    schedule.entries = schedule.entries.map((entry) => {
                      const entryDate = entry.date_from.split(' ')[0];
                      const entryDropDate = newEventDateStart;

                      if (entryDate !== entryDropDate) return entry;

                      existingEvent = entry.events.find(
                        (event) =>
                          event.pots_by_type.ticket &&
                          event.pots_by_type.ticket.id ===
                            newPotEvent.ticket.id &&
                          event.event_type === eventType,
                      );

                      if (!eventHistory.newEvent || !eventHistory.patchEvent)
                        return entry;

                      if (existingEvent) {
                        // when event is present add hours to new event.

                        const newEventE: TaskmanagerEventDto = {
                          ...eventHistory.newEvent,
                          hours: newEventHours + existingEvent.hours,
                        };

                        eventHistory.patchEvent.hours = eventHistory.patchEvent
                          .hours
                          ? existingEvent.hours + eventHistory.patchEvent.hours
                          : newEventE.hours;

                        // remove old event
                        entry.events = entry.events.filter((event) => {
                          if (event.event_type === newEventE.event_type) {
                            return (
                              event.pots_by_type.ticket?.id !==
                              newEventE.pots_by_type.ticket?.id
                            );
                          }
                          return event;
                        });

                        // add new event with corrected hours.
                        entry.events.push(newEventE);

                        // entry.events = entry.events.filter(
                        //   (event) =>
                        //     event.pots_by_type.ticket?.id ===
                        //     newPotEvent.ticket.id,
                        // );
                      } else {
                        entry.events.push(eventHistory.newEvent);
                      }

                      return cleanDateEntry(
                        entry,
                        schedule.user?.profile.available_hours,
                        eventHistory.newEvent.event_type,
                      );
                    });
                  } else {
                    if (!eventHistory.newEvent) return schedule;

                    const availableHours =
                      schedule.user?.profile.available_hours;
                    const eventHours = newEventHours;
                    const hoursRemaining = availableHours - eventHours;

                    schedule.entries.push({
                      date_from: newEventDateStart,
                      hours_remaining_by_type: {
                        [eventHistory.newEvent.event_type]: hoursRemaining,
                      },
                      hours_remaining: hoursRemaining,
                      events: [{ ...eventHistory.newEvent }],
                    });
                  }
                }
              }

              return schedule;
            }),
          };
        }

        return memoOld;
      },
    );

    return { previousQuery, existingEvent };
  };

async function mutateSchedule({
  patchEvent,
}: IEventHistory): Promise<TaskmanagerEventDto> {
  const eventId = patchEvent.id;
  const patchEventPayload = patchEvent;
  delete patchEventPayload.id;

  if (eventId) {
    const response = await api.eventService.modifyTaskmanagerEvent(
      eventId,
      patchEventPayload,
    );

    return response.data;
  }

  // eslint-disable-next-line prefer-promise-reject-errors
  return Promise.reject('No id has been passed.');
}

export const useScheduleMutation = (params: IIndexSchedule) => {
  const queryClient = useQueryClient();

  return useMutation(mutateSchedule, {
    onMutate: onMutate(queryClient, params),
    onSettled: () => {
      queryClient.invalidateQueries(scheduleKeys.indexSchedule(params));
    },
    onError: async (error, variables, context) => {
      queryClient.setQueryData(scheduleKeys.index(), context?.previousQuery);
    },
  });
};

import { ListItemButton, ListItemText } from '@mui/material';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled/macro';

const NavItemLink = styled(ListItemText)`
  && {
    margin: 0;
  }

  .MuiTypography-root {
    color: ${({ theme }) => theme.colors.textSecondary};
    display: flex;
    align-items: center;
  }

  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.textSecondary};
    margin-right: 12px;
  }
`;

interface INavigationItemProps {
  path: string;
  title: string;
  icon?: React.ReactNode;
}

const NavButton = ({
  path,
  title,
  icon,
}: INavigationItemProps): JSX.Element => (
  <ListItemButton component={NavLink} to={path}>
    <NavItemLink>
      {icon}
      <span>{title}</span>
    </NavItemLink>
  </ListItemButton>
);

export default NavButton;

import LightModeIcon from '@mui/icons-material/LightMode';
import DateRangeType from 'date-range/models/date-range-type.enum';
import { useDateRange } from 'date-range/store/date-range';
import * as S from 'schedule/core/components/event-type-bank-holiday/event-type-bank-holiday.styles';

interface IEventTypeBankHoliday {
  width: number;
}

export const EventTypeBankHoliday = ({ width }: IEventTypeBankHoliday) => {
  const dateRangeType = useDateRange((store) => store.state.dateRangeType);
  return (
    <S.BankHolidayContainer width={width}>
      {dateRangeType === DateRangeType.week && (
        <S.BankHolidayTitle>Bank Holiday</S.BankHolidayTitle>
      )}
      <LightModeIcon />
    </S.BankHolidayContainer>
  );
};

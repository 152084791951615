import { FormGroup } from '@mui/material';
import PrimaryButton from 'core/components/buttons/primary-button';
import SwitchField from 'core/components/form-components/switch';
import TaggingTextInput from 'core/components/form-components/tagging-text-input';
import TextInput from 'core/components/form-components/text-input';
import UserCustomAttributeTable from 'users/components/user-custom-attributes/user-custom-attributes';
import { useMemo } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import useOrganisations from 'core/hooks/queries/organisations/use-organisations';
import UserAvatar from 'user/components/user-avatar/user-avatar';
import useRoles from 'core/hooks/queries/roles/use-roles';
import useSkills from 'core/hooks/queries/use-skills';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useStoreUser from 'user/hooks/use-store-user';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import useIndexSalaryBand from 'core/hooks/queries/salary/use-index-salary-band';
import SelectInput from 'core/components/form-components/select-input';
import {
  StyledLegend,
  StyledFieldset,
  FlexCol,
  StatusWrapper,
} from 'users/components/user-form/user-form.styles';
import { UserDto, UserRequestDto } from 'gateway-api';
import { RolesCheckbox } from 'users/components/roles-checkbox/roles-checkbox';
import { CompanyCheckbox } from 'users/components/company-checkbox/company-checkbox';

interface IUserProps {
  user?: UserDto;
  handleClose: Function;
}

interface IUser extends Omit<UserRequestDto, 'services'> {
  roles: string[];
}

export const UserForm = ({ user, handleClose }: IUserProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { items: organisationsList } = useOrganisations();
  const rolesList = useRoles();
  const skillList = useSkills();
  const salaryBandList = useIndexSalaryBand();

  const { updateUser, isUpdatingUser, storeUser, isStoringUser } =
    useStoreUser();

  const validations = useMemo(
    () =>
      yup.object({
        first_name: yup
          .string()
          .required(t('form_validation.required'))
          .matches(
            /^$|^[a-zA-Z']+([ ][a-zA-Z']+)*([-][a-zA-Z']+)*$/,
            'No Special Characters or Numbers are allowed',
          ),
        middle_name: yup
          .string()
          .nullable()
          .matches(
            /^$|^[a-zA-Z']+([ ][a-zA-Z']+)*([-][a-zA-Z']+)*$/,
            'No Special Characters or Numbers are allowed',
          ),
        last_name: yup
          .string()
          .required(t('form_validation.required'))
          .matches(
            /^$|^[a-zA-Z']+([ ][a-zA-Z']+)*([-][a-zA-Z']+)*$/,
            'No Special Characters or Numbers are allowed',
          ),
        username: yup
          .string()
          .required(t('form_validation.required'))
          .matches(
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,253}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,253}[a-zA-Z0-9])?)*$/,
            t('form_validation.email'),
          ),
        roles: yup.array().min(1, 'please select a role'),
        organisations: yup.array().min(1, 'please select an organisation'),
        attributes: yup
          .array()
          .of(yup.object())
          .compact((attr) => attr.name === '' || attr.value === ''),
        absorbed_cost_rate: yup
          .string()
          .matches(
            /^([0]([.][0-9]+)?|[1-9]([0-9]+)?([.][0-9]+)?)$/,
            'absorbed cost rate must be a number',
          ),
      }),
    [t],
  );

  // Default values
  const methods = useForm<IUser>({
    resolver: validations ? yupResolver(validations) : undefined,
    defaultValues: user && {
      username: user.username,
      first_name: user.first_name,
      middle_name: user.middle_name,
      last_name: user.last_name,
      disabled: user.disabled,
      salary_band: user.salary_band ?? '',
      absorbed_cost_rate: user.absorbed_cost_rate ?? 0,
      attributes: user.attributes,
      skills: user.skills ?? [],
      organisations: user.organisations ?? [],
      roles: user.services?.taskmanager?.roles ?? [],
      taskmanager: user.taskmanager ?? {},
    },
  });

  const { handleSubmit } = methods;

  const onSuccess = () => {
    enqueueSnackbar(`User successfully ${user ? 'updated' : 'added'}`, {
      variant: 'success',
    });
    handleClose();
  };

  const onError = (e: unknown) => {
    const error = e as AxiosError;

    let message = `An error occurred whilst ${
      user ? 'updating' : 'adding'
    } your user`;
    if (error.response?.status === 409) {
      message =
        'This user already exists. Please enter a different email address.';
    }

    enqueueSnackbar(message, {
      variant: 'error',
    });
    handleClose();
  };

  // New or Updated USER
  const submit = handleSubmit((updatedUser: IUser) => {
    const newUser = {
      ...updatedUser,
      first_name: updatedUser.first_name,
      middle_name: updatedUser.middle_name,
      last_name: updatedUser.last_name,
      skills: updatedUser.skills,
      disabled: updatedUser.disabled,
      attributes: updatedUser.attributes,
      salary_band: updatedUser.salary_band ?? '',
      absorbed_cost_rate: updatedUser.absorbed_cost_rate,
      organisations: updatedUser.organisations ?? [],
      services: updatedUser.roles
        ? {
            taskmanager: {
              roles: updatedUser.roles ?? [],
            },
            sso: {
              roles: updatedUser.roles ?? [], // hacked until BE fix is in
            },
            user_directory: {
              roles: updatedUser.roles ?? [], // hacked until BE fix is in
            },
          }
        : {},
      taskmanager: updatedUser.taskmanager ?? {},
    };

    if (user) {
      updateUser(
        { id: user.id, user: newUser as unknown as UserDto },
        { onSuccess, onError },
      );
    } else {
      storeUser(
        { user: newUser as unknown as UserDto },
        { onSuccess, onError },
      );
    }
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={submit}>
        <FlexCol className='flex-inline'>
          <UserAvatar className='user-image' />
          <TextInput
            id='username'
            label={`${t('account.form.email')}`}
            disabled={user?.id !== undefined}
            className='email-field hide-label'
          />
        </FlexCol>
        <FlexCol className='flex-inline'>
          <TextInput
            id='first_name'
            label={`${t('account.form.first_name')}`}
          />
          <TextInput
            id='middle_name'
            label={`${t('account.form.middle_name')}`}
          />
          <TextInput id='last_name' label={`${t('account.form.last_name')}`} />
        </FlexCol>
        <FlexCol>
          <FormGroup className='group-inline user-role' id='roles'>
            <StyledFieldset>
              <StyledLegend>{`${t('account.form.roles')}:`}</StyledLegend>
              <RolesCheckbox id='roles' options={rolesList ?? []} />
            </StyledFieldset>
          </FormGroup>
        </FlexCol>
        <FlexCol className='flex-inline'>
          <FormGroup className='flex-inline--equal'>
            <StyledFieldset>
              <TaggingTextInput
                value={user?.skills ?? []}
                options={skillList ?? []}
                label={`${t('account.form.skills')}:`}
                id='skills'
              />
            </StyledFieldset>
          </FormGroup>
          <FormGroup className='flex-inline--equal user-checkbox'>
            <StyledFieldset>
              <StyledLegend>{`${t('account.form.company')}:`}</StyledLegend>
              {organisationsList && (
                <CompanyCheckbox
                  id='organisations'
                  organisations={
                    organisationsList.filter(
                      (org) =>
                        org.name.includes('Recite Me') ||
                        org.name.includes('ARCH'),
                    ) ?? []
                  }
                  userOrganisations={user?.organisations}
                />
              )}
            </StyledFieldset>
          </FormGroup>
        </FlexCol>
        <FlexCol className='flex-inline'>
          {salaryBandList !== undefined ? (
            <FormGroup className='flex-inline--equal'>
              <SelectInput
                id='salary_band'
                label={`${t('account.form.salary')}`}
                labelId='salary-band'
                options={salaryBandList.map((salaryBand) => ({
                  label: salaryBand,
                  id: salaryBand as unknown as number, // hacked until we get the ids back
                }))}
              />
            </FormGroup>
          ) : (
            <></>
          )}

          <FormGroup className='flex-inline--equal'>
            <TextInput
              id='absorbed_cost_rate'
              label={`${t('account.form.cost')}`}
              fieldInline={false}
            />
          </FormGroup>
        </FlexCol>
        <FlexCol>
          <StatusWrapper>
            <StyledLegend inline>{`${t('account.form.status')}:`}</StyledLegend>
            <SwitchField
              id='disabled'
              labelLeft={`${t('account.form.active')}`}
              labelRight={`${t('account.form.disabled')}`}
            />
          </StatusWrapper>
        </FlexCol>
        <FlexCol className='flex-inline'>
          <FormGroup>
            <StyledLegend>{`${t('account.form.attributes')}:`}</StyledLegend>
            <UserCustomAttributeTable />
          </FormGroup>
        </FlexCol>
        <FlexCol className='flex-right'>
          <PrimaryButton
            type='submit'
            loading={isUpdatingUser || isStoringUser}
          >
            {t('account.form.save')}
          </PrimaryButton>
        </FlexCol>
      </form>
    </FormProvider>
  );
};

import { TextField } from '@mui/material';
import styled from '@emotion/styled/macro';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { defaultTheme } from 'theme/theme';
import { useFormContext } from 'react-hook-form';

const InputWrapper = styled.div`
  .MuiTextField-root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .MuiInputLabel-root {
    position: relative;
    transform: none;
    margin-right: 7px;
    font-size: 18px;
    color: ${defaultTheme.colors.primaryColor};
    font-weight: 500;
    white-space: initial;
    margin-bottom: 13px;
  }

  legend {
    width: 0;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.borderGrey};
    border-radius: 0;
  }

  .MuiOutlinedInput-root {
    background-color: #eaeaea;
    border-radius: 0;
  }

  .MuiOutlinedInput-input {
    padding: 19.5px 20px;
  }
`;

interface ITextInputProps {
  defaultValue?: Date;
  label?: string;
  id?: string;
  value: Date;
  handleChange: (
    date: Date | null,
    keyboardInputValue?: string | undefined,
  ) => void;
  disabled?: boolean;
}

/** Text field built from a material pannel-ui base. If there is a {@link FormContext} available above
 *  the text field then it will use that for onChange, value and errors */
const StyledDatePicker = ({
  label,
  defaultValue,
  value,
  id,
  handleChange,
  disabled = false,
}: ITextInputProps): JSX.Element => {
  const { getValues } = useFormContext();

  // const handleChange = (newValue: Date | null) => {
  //   setValue(newValue);
  // };

  return (
    <InputWrapper>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={label}
          inputFormat="dd/MM/yyyy"
          value={value || new Date()}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField id={id} {...params} disabled={disabled} value={value} />
          )}
        />
      </LocalizationProvider>
    </InputWrapper>
  );
};

export default StyledDatePicker;

import { useMutation, useQueryClient } from 'react-query';
import { api } from 'core/services/api.service';
import { userTasksKeys } from 'user/queries/user-tasks.keys';
import { searchKeys } from 'schedule/search/queries/search.keys';
import { TaskmanagerUserPartialDto } from 'gateway-api';

interface IModifyFavourite {
  id: string;
  body: TaskmanagerUserPartialDto;
}

const modifyUserFavourite = ({ id, body }: IModifyFavourite) =>
  api.taskUserService.modifyTaskmanagerUser(id, body);

export const useModifyUserFavourite = () => {
  const queryKey = userTasksKeys.show();
  const queryClient = useQueryClient();

  return useMutation(modifyUserFavourite, {
    // onMutate: async (newFavourite) => {
    //   // cancel ongoing refetch
    //   await queryClient.cancelQueries(queryKey);

    //   // get prev values
    //   const prevFaves = queryClient.getQueryData(queryKey);

    //   // optimistically update to new value
    //   queryClient.setQueryData(queryKey, [prevFaves, newFavourite]);

    //   return { prevFaves };
    // },
    // // If the mutation fails, use context to roll back
    // onError: (err, newFavourite, context) => {
    //   queryClient.setQueryData(queryKey, context?.prevFaves);
    // },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
      queryClient.invalidateQueries(searchKeys.index());
    },
  });
};

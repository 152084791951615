/**
 * Module dependecies.
 */

import styled from '@emotion/styled/macro';
import { Button, MenuItem, Typography, Menu, Checkbox } from '@mui/material';
import { css } from '@emotion/react';
import { ReactComponent as FilterIcon } from 'assets/images/filter-icon.svg';

export const Container = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 15px;
`;

export const StyledFilterIcon = styled(FilterIcon)`
  width: 18px;
  height: 18px;
`;

export const Paragraph = styled(Typography)`
  margin: 0;
  font-weight: 500;
`;

export const StyledButton = styled(Button)`
  display: flex;
  gap: 16px;
  margin-right: 16px;
`;

export const StyledMenu = styled(Menu)`
  .MuiMenu-list {
    min-width: 130px;
  }
`;

export const Divider = styled.div`
  width: 80%;
  height: 1px;
  border: 1px solid #c1c1c1;
`;

export const IconLabelWrapper = styled.div<{ first?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${(props) =>
    props.first &&
    css`
      flex-direction: column;
    `}
`;

export const StyledCheckBox = styled(Checkbox)``;

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

import useAllUser from 'user/hooks/use-all-users';
import useDebounce from 'core/hooks/use-debounce';
import { useState } from 'react';

const useScheduleUserFilter = () => {
  const [search, setSearch] = useState<string | undefined>();

  const {
    items: users,
    pagination,
    usersLoading,
    usersError,
    refetch,
  } = useAllUser({
    disabled: false,
    search,
  });

  const debouncedSearch = useDebounce((value: string) => {
    setSearch(value);
  });

  return {
    debouncedSearch,
    users,
    setSearch,
    usersLoading,
    usersError,
    pagination,
    refetch,
  };
};

export default useScheduleUserFilter;

/**
 * Module dependencies.
 */

import styled from '@emotion/styled/macro';
import { Chip, Pagination } from '@mui/material';
import PrimaryButton from 'core/components/buttons/primary-button';
import { TaskCardContainer } from 'statistics/components/task-card/task-card.style';

interface IProjectStatistics {
  mobileView: boolean;
}

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 1;

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

export const Tasks = styled.div<IProjectStatistics>`
  font-size: 1em;

  ${(props) => props.theme.breakpoints.down('md')} {
    padding-right: 0;
    display: ${(props) => (props.mobileView ? 'none' : 'block')};
    max-width: auto;
  }

  @media only screen and (min-width: 1100px) {
    width: 20%;
    padding-right: 30px;
  }

  ${(props) => props.theme.breakpoints.up('xl')} {
    width: 30%;
  }
`;

export const TaskDetails = styled.div`
  @media only screen and (max-width: 1100px) {
    margin-left: 0;
    width: 100%;
  }

  @media only screen and (min-width: 1100px) {
    flex: 3;
    border-left: 1px solid #d0d0d0;
    padding-left: 42px;
  }
`;

export const NoTasks = styled.div`
  width: 100%;
  text-align: center;
  align-items: center;

  ${(props) => props.theme.breakpoints.up('md')} {
    margin-top: -150px;
  }

  .MuiSvgIcon-root {
    font-size: 2em;
  }

  .MuiTypography-root {
    font-size: 1em;
    &:first-child {
      font-size: 1.125em;
      font-weight: 500;
    }
  }
`;

export const TaskList = styled.div`
  max-height: 600px;
  overflow-y: scroll;
  padding: 20px;
`;

interface ITaskCard {
  selectedTicket: boolean;
}

export const StyledTaskCard = styled.div<ITaskCard>`
  && {
    height: auto;
    margin-bottom: 15.5px;
    margin-right: 7px;
    font-size: 1em;

    transform: ${(props) =>
      props.selectedTicket ? 'scale(1.04)' : 'scale(1)'};

    &:hover {
      transform: 'scale(1.04)';
    }

    .MuiTableContainer-root {
      overflow-x: scroll;
    }
  }
`;

export const StyledPagination = styled(Pagination)`
  && {
    margin-top: 20px;
    .MuiButtonBase-root {
      font-size: 1em;
      min-width: auto;

      &.Mui-selected {
        background-color: transparent;
        font-weight: bold;
      }
    }

    .MuiPagination-ul {
      justify-content: flex-end;
    }

    ${(props) => props.theme.breakpoints.down('md')} {
      .MuiPagination-ul {
        justify-content: center;
      }
    }
  }
`;

export const StyledSearch = styled.div`
  && {
    margin-bottom: 20px;
    max-width: 280px;
  }
`;

export const BackButton = styled(PrimaryButton)`
  && {
    display: none;
    ${(props) => props.theme.breakpoints.down('md')} {
      display: block;
      padding: 3px 0;
      margin-bottom: 15px;
    }
  }
`;

export const StyledTaskCardContainer = styled(TaskCardContainer)`
  && {
    &:after {
      background-color: #2e559f;
    }
  }
`;

export const StyledChip = styled(Chip)`
  && {
    background-color: #a0b1d3;
    margin-bottom: 32px;

    .MuiChip-deleteIcon {
      color: #07334a;
    }
  }
`;

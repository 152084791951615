import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { api } from 'core/services/api.service';

const useTagResults = () => {
  const [tags, setTags] = useState<string[]>([]);

  const {
    data: indexTagsResponse,
    isLoading: indexTagsLoading,
    refetch,
  } = useQuery(
    ['tags'],
    () =>
      api.potGanttService.indexTaskmanagerPotGantt({
        params: {
          'name[in]': tags
            // duplicates
            .filter((item, index) => tags.indexOf(item) === index)
            .join(','),
        },
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    refetch();
  }, [tags]);

  return {
    ...indexTagsResponse?.data,
    indexTagsLoading,
    tags,
    setTags,
    refetch,
  };
};

export default useTagResults;

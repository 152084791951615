import { FormControl, FormHelperText } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React from 'react';
import styled from '@emotion/styled/macro';
import useControlledFormField from 'core/hooks/use-controlled-form-field';

const StyledInputLabel = styled(InputLabel)`
  && {
    position: relative;
    transform: none;
    margin-right: 7px;
    font-size: 18px !important;
    color: #07334a;
    font-weight: 500;
    white-space: initial;
    margin-bottom: 13px;
  }
`;

export const StyledFormControl = styled(FormControl)`
  & .MuiSelect-select {
    height: 1.4375em;
    padding: 18px 20px;
    background-color: #eaeaea;
  }
  & .MuiOutlinedInput-root {
    font-size: 16px;
  }
  && .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.borderGrey};
    border-radius: 0;
  }
`;
export const Option = styled(MenuItem)`
  font-size: 12px;
`;

export interface IOption {
  val: string | number;
  key: string | number;
}
interface ISelectInputProps {
  id: string;
  label: string;
  labelId: string;
  options: { label: string; id: number }[];
}

/** styled wrapper around Material pannel-ui's Select.
 *  If there is a {@link FormContext} available above the text field then it will use that for
 *  onChange, value and errors */
const SelectInput = ({ id, label, options, labelId }: ISelectInputProps) => {
  const {
    hasError,
    errorLabel,
    field: { value, onChange, onBlur },
  } = useControlledFormField(id);

  return (
    <StyledFormControl
      error={hasError}
      size='small'
      fullWidth
      disabled={options.length === 0}
    >
      <StyledInputLabel id={labelId}>{label}</StyledInputLabel>
      <Select
        id={id}
        labelId={labelId}
        value={value || ''}
        onChange={onChange}
        onBlur={onBlur}
      >
        {options.map((option) => (
          <Option key={option.id} value={option.id}>
            {option.label}
          </Option>
        ))}
      </Select>
      {hasError && <FormHelperText>{errorLabel}</FormHelperText>}
    </StyledFormControl>
  );
};
export default SelectInput;

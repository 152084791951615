import { createTheme, Theme } from "@mui/material/styles";

const colors = {
  backgroundColor: "#f6f8f9",
  primaryColor: "#07334a",
  accentColor: "#2E559F",
  textPrimary: "#07334a",
  textSecondary: "#2E559F",
  textLight: "#2E559F",
  errorColor: "#D8151A",
  grey: "#707070",
  lightGrey: "#eaeaea",
  borderGrey: "#DBDBDB",
  boxShadow: "#00000029",
  accentTeal: "#A0CED9",
  accentPink: "#f27777",
} as const;

const fonts = {
  sanSerif: "'Roboto', sans-serif",
  latoSanSerif: "'Lato', sans-serif",
  lexendSanSerif: "'Lexend', sans-serif",
  monospace: "monospace",
} as const;

export interface ITheme {
  colors: typeof colors;
  fonts: typeof fonts;
  bottomNavBarSize: number;
}

/** separate interface to force all future themes to take this shape.
 * Default theme available throughout the app with the theme provider
 */

export const defaultTheme: Theme = createTheme({
  colors,
  fonts,
  bottomNavBarSize: 80,
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 577,
      md: 840,
      lg: 1025,
      xl: 1201,
    },
  },
  typography: {
    fontFamily: fonts.sanSerif,
    body1: {
      fontSize: "1em",
      fontWeight: "normal",
      color: colors.textPrimary,
    },
    body2: {
      fontSize: 14,
      fontWeight: "normal",
      color: colors.textPrimary,
    },
    h1: {
      tag: "h1",
      fontSize: 32,
      fontWeight: "500",
      color: colors.textPrimary,
    },
    h2: {
      tag: "h2",
      fontSize: 25,
      fontWeight: "bold",
      color: colors.textPrimary,
    },
    h3: {
      tag: "h3",
      fontSize: 21,
      fontWeight: "bold",
      color: colors.textPrimary,
    },
    h4: {
      tag: "h4",
      fontSize: 20,
      fontWeight: "bolder",
      color: colors.textPrimary,
    },
    h5: {
      tag: "h5",
      fontSize: 18,
      fontWeight: "bolder",
      color: colors.textPrimary,
    },
    h6: {
      tag: "h6",
      fontSize: 16,
      fontWeight: "bolder",
      color: colors.textPrimary,
    },
  },
  palette: {
    primary: {
      main: colors.primaryColor,
    },
    secondary: {
      main: colors.accentColor,
    },
    error: {
      main: colors.errorColor,
    },
    text: {
      primary: colors.textPrimary,
      secondary: colors.textSecondary,
    },
    background: {
      default: colors.backgroundColor,
    },
  },
});

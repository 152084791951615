import styled from '@emotion/styled/macro';
import { SyntheticEvent, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Chip, TextField } from '@mui/material';
import * as React from 'react';
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from '@mui/base/AutocompleteUnstyled/useAutocomplete';
import { TaskmanagerSearchDto } from 'gateway-api';

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: -10px;
  transform: translate(-50%, -50%);
  font-size: 30px;
`;

const Input = styled(TextField)`
  && {
    border-color: ${({ theme }) => theme.colors.borderGrey};

    .MuiAutocomplete-root {
      position: relative;
    }

    .MuiAutocomplete-tag {
      background-color: #a0b1d3;
      order: 1;
    }

    .MuiInputBase-input {
      order: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      border: 1px solid #053349;
      transition: width 0.4s ease;
      background: none;
      box-sizing: border-box;
      padding: 10px;
    }

    .MuiOutlinedInput-root {
      display: flex;
      padding: 0;
    }

    .MuiChip-deleteIcon {
      color: #07334a;
    }

    .MuiInputBase-input {
      padding: 10px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    .MuiAutocomplete-endAdornment {
      right: 38px;
      top: 5px;
    }
  }
`;

const StyledAutoComplete = styled.div`
  && {
    .MuiAutocomplete-root {
      position: relative;
    }
  }
`;

export interface ISearchOption {
  id: string;
  label: string;
  pot?: TaskmanagerSearchDto;
}

interface ISearchProps {
  label?: string;
  onSubmit?: (tags: string) => void;
  placeholder?: string;
  options?: ISearchOption[];
  onInputChange?: (event: SyntheticEvent, value: string) => void;
  onKeyDown?: (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: ISearchOption[],
  ) => void;
  onChange?: (
    event: React.SyntheticEvent,
    value: ISearchOption[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<ISearchOption>,
  ) => void;
  freeSolo?: boolean;
  showTags?: boolean;
  id?: string;
}
const TaggingSearch = ({
  onSubmit,
  options,
  label,
  showTags = true,
  onInputChange,
  onChange,
  onKeyDown,
  freeSolo = false,
  placeholder,
}: ISearchProps): JSX.Element => {
  const [search, setSearch] = useState<ISearchOption[]>([]);

  const handleClick = () => {
    if (onSubmit) {
      onSubmit(search.map((item) => item.id).join(','));
    }
  };

  return (
    <StyledAutoComplete>
      <Autocomplete<ISearchOption, boolean, boolean, boolean>
        multiple
        onInputChange={onInputChange}
        freeSolo={freeSolo}
        disableClearable
        options={options ?? []}
        onChange={(e, newValue, reason, details) => {
          if (onChange) {
            onChange(e, newValue as ISearchOption[], reason, details);
          }
          setSearch(newValue as ISearchOption[]);
        }}
        renderTags={(value, getTagProps) => {
          if (showTags) {
            return value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option.label}
                {...getTagProps({ index })}
                key={option.id}
              />
            ));
          }
          return null;
        }}
        renderInput={(params) => (
          <>
            <Input
              {...params}
              label={label}
              value={search}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                type: 'search',
                onKeyDown: (e) => {
                  if (onKeyDown) {
                    onKeyDown(e, search);
                  }
                },
              }}
            />
            <StyledSearchIcon onClick={handleClick} />
          </>
        )}
      />
    </StyledAutoComplete>
  );
};

export default TaggingSearch;

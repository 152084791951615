import {
  RegisterOptions,
  useFormContext,
  ValidationRule,
} from 'react-hook-form';

/**
 * simplify the getting of react hook form variables for use in mui components.
 *  - Converts/renames ref into inputRef, for use in Mui components.
 *  - extracts error messages for use in Mui components
 */
const useFormField = (id: string, options?: RegisterOptions) => {
  const {
    register,
    formState: { errors },
    trigger,
  } = useFormContext();

  const { ref: inputRef, ...inputProps } = register(id, { ...options });
  const hasError = Boolean(errors?.[id]);
  return {
    ...{ inputRef, ...inputProps },
    hasError,
    errorLabel: errors?.[id]?.message as string | undefined,
    trigger,
  };
};

export default useFormField;

import { Helmet } from 'react-helmet-async';

interface ICustomHelmetProps {
  titleBase?: string;
  pageName?: string;
}

const CustomHelmet = ({
  titleBase = 'Task Manager',
  pageName,
}: ICustomHelmetProps): JSX.Element => (
  <Helmet>
    <title>
      {pageName ? `${pageName} | ` : ''} {titleBase}
    </title>
  </Helmet>
);

export default CustomHelmet;

import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import produce from 'immer';
import {
  ISearchStore,
  ISearchState,
  ISearchPayload,
} from 'schedule/search/store/search/search.state';

const initialState: ISearchPayload = {
  search: '',
  category: 'all',
  categoryId: 'all',
  status: false,
  favourite: false,
  pageNumber: 1,
  jql_search: false,
  jira_query: '',
  jira_type: 'issue',
  sort: 'label:asc',
};

export const useSearchStore = create<ISearchStore>()(
  immer(
    devtools(
      (set) => ({
        state: initialState,
        setSearch(search) {
          set(
            (store) => {
              store.state.search = search;
            },
            false,
            { type: 'search-store__setSearch', search },
          );
        },
        setStatus(status) {
          set(
            (store) => {
              store.state.status = status;
            },
            false,
            { type: 'search-store__setStatus', status },
          );
        },
        setFavourite(favourite) {
          set(
            (store) => {
              store.state.favourite = favourite;
            },
            false,
            { type: 'search-store__setFavourite', favourite },
          );
        },
        setCategory(category) {
          set(
            (store) => {
              store.state.category = category;
            },
            false,
            { type: 'search-store__setCategory', category },
          );
        },
        setCategoryId(categoryId) {
          set(
            (store) => {
              store.state.categoryId = categoryId;
            },
            false,
            { type: 'search-store__setCategoryId', categoryId },
          );
        },
        setPageNumber(pageNumber) {
          set(
            (store) => {
              store.state.pageNumber = pageNumber;
            },
            false,
            { type: 'search-store__setPageNumber', pageNumber },
          );
        },
        setJqlSearch(jql_search) {
          set(
            (store) => {
              store.state.jql_search = jql_search;
            },
            false,
            { type: 'search-store__setJqlSearch', jql_search },
          );
        },
        setJiraQuery(jira_query) {
          set(
            (store) => {
              store.state.jira_query = jira_query;
            },
            false,
            { type: 'search-store__setJiraQuery', jira_query },
          );
        },
        setJiraType(jira_type) {
          set(
            (store) => {
              store.state.jira_type = jira_type;
            },
            false,
            { type: 'search-store__setJiraType', jira_type },
          );
        },
        setSort(sort) {
          set(
            (store) => {
              store.state.sort = sort;
            },
            false,
            { type: 'search-store__setSort', sort },
          );
        },
        resetStore() {
          set(
            produce((store: ISearchState) => {
              store.state = initialState;
            }),
            false,
            { type: 'search-store__resetStore' },
          );
        },
      }),
      {
        name: 'search-store',
      },
    ),
  ),
);

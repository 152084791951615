/* eslint-disable import/prefer-default-export */

import { Typography } from '@mui/material';
import styled from '@emotion/styled/macro';
import { ReactComponent as GoogleLogo } from 'assets/images/google.svg';

export const SectionTitle = styled(Typography)`
  font-size: 1.125em;
  margin-bottom: 60px;
  font-family: 'Lato', sans-serif;
`;

export const GoogleContainer = styled.button`
  background-color: rgb(66, 133, 244);
  display: inline-flex;
  align-items: center;
  color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
`;

export const GoogleIcon = styled(GoogleLogo)`
  width: 18px;
  height: 18px;
`;

export const GoogleIconContainer = styled.div`
  background: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 2px;
`;

export const GoogleParagraph = styled.p`
  margin: 0 12px;
`;

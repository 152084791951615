import styled from '@emotion/styled/macro';

export const Filters = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
`;

export const CompanyFilter = styled.div`
  margin-right: 20px;
  div {
    margin-right: 10px;
  }
`;

import styled from '@emotion/styled/macro';
import { css } from '@mui/material';

const RadioLabel = styled.label`
  position: relative;
  width: 100%;
  padding: 5px 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  font-size: 14px;
  text-transform: capitalize;
`;

const RadioButton = styled.input`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 9;
  border-radius: 16px;

  &:hover {
    cursor: pointer;
  }
`;

const RadioContainer = styled.div<IRadioStyleSelected>`
  position: relative;
  width: auto;
  display: inline-block;

  ${(props) =>
    props.variant === 'outlined' &&
    css`
      border: solid #0000001f 1px;
      border-radius: 16px;

      label {
        color: #000000;
      }

      input[type='radio']:checked + label {
        color: #315b96de;
        background-color: #315b9614;
      }

      &:hover,
      &:focus {
        label {
          background-color: #315b9614;
        }
      }
    `}

  ${(props) =>
    props.variant === 'solid' &&
    css`
      background: #e4e4e4;
      border-radius: 16px;

      label {
        color: #000000;
      }

      input[type='radio']:checked + label {
        color: white;
        background-color: #a0b1d3;
      }

      &:hover,
      &:focus {
        label {
          background-color: #a0b1d3;
          color: white;
        }
      }
    `}
`;

interface IRadioStyleSelected {
  variant: string;
}

interface IRadioButton {
  name: string;
  id?: string;
  value: string | number;
  variant: string;
  children?: React.ReactNode;
  checked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const RadioButtonStyle = ({
  name,
  id,
  value,
  variant = 'outlined' || 'solid',
  onChange,
  checked = false,
  children,
}: IRadioButton): JSX.Element => (
  <RadioContainer variant={variant}>
    <RadioButton
      type='radio'
      name={name}
      id={id}
      value={value}
      onChange={onChange}
      defaultChecked={checked}
    />
    <RadioLabel>{children}</RadioLabel>
  </RadioContainer>
);

export default RadioButtonStyle;

import StoreStatus from 'core/models/store-status.enum';

export interface UserSelectState {
  status: StoreStatus;
  search?: string;
  pageNumber: number;
  newUserFormOpen: boolean;
  showInactiveAccounts: boolean;
  selectedSkills: string[];
  selectedRoles: string[];
  selectedUsers: string[];
}
export const userSelectInitialState: UserSelectState = {
  pageNumber: 0,
  showInactiveAccounts: false,
  newUserFormOpen: false,
  selectedRoles: [],
  selectedSkills: [],
  selectedUsers: [],
  status: StoreStatus.initial,
};

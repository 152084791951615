import { Droppable } from 'react-beautiful-dnd';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import * as S from 'schedule/core/components/event-delete-button/event-delete-button.style';
import useEventTypeProvider, {
  PageEnum,
} from 'schedule/core/providers/event-type-provider';
import useDragDropStore from 'schedule/core/store/drag-drop/drag-drop.store';
import { TaskmanagerEventTypeEnum } from 'gateway-api';

interface IProps {
  visible: boolean;
}

const ScheduleDelete = ({ visible }: IProps): JSX.Element => {
  const { page } = useEventTypeProvider();
  const event = useDragDropStore((store) => store.state.draggableEvent);

  return (
    <>
      {visible && (
        <>
          <Droppable
            key="DELETE_EVENT"
            droppableId="DELETE_EVENT"
            isDropDisabled={
              event &&
              page === PageEnum.ADD_TIME &&
              event.event_type === TaskmanagerEventTypeEnum.Schedule
            }
          >
            {(provided, snapshot) => (
              <S.DeleteDroppable
                // isDraggingOver={snapshot.isDraggingOver}
                // isVisible={
                //   selectedItem !== undefined &&
                //   selectedItem.type !== DragTypes.Unscheduled
                // }
                isVisible
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <DeleteOutlineIcon />
                {provided.placeholder}
              </S.DeleteDroppable>
            )}
          </Droppable>
        </>
      )}
    </>
  );
};
export default ScheduleDelete;

/**
 * Module dependencies.
 */

import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { TaskmanagerPotStatDto } from 'gateway-api';
import * as S from 'statistics/components/stats-custom-attributes/stats-custom-attributes.style';

interface ITableProps {
  data: TaskmanagerPotStatDto | undefined;
}

const StatsCustomAttributeTable = ({ data }: ITableProps): JSX.Element => (
  <>
    {data ? (
      <TableContainer>
        <S.StyledTable aria-label="Project/Ticket Hours">
          <TableBody>
            <S.StyledTableRow>
              <S.StyledTableCell component="th" scope="row">
                Hours Estimated
              </S.StyledTableCell>
              <S.StyledTableCell align="right">
                {data.estimate_hours_total}
              </S.StyledTableCell>
            </S.StyledTableRow>
            <S.StyledTableRow>
              <S.StyledTableCell component="th" scope="row">
                Hours Spent
              </S.StyledTableCell>
              <S.StyledTableCell align="right">
                {data.complete_hours_total}
              </S.StyledTableCell>
            </S.StyledTableRow>
            <S.StyledTableRow>
              <S.StyledTableCell component="th" scope="row">
                Hours remaining
              </S.StyledTableCell>
              <S.StyledTableCell align="right">
                {data.estimate_hours_total - data.complete_hours_total}
              </S.StyledTableCell>
            </S.StyledTableRow>
          </TableBody>
        </S.StyledTable>
      </TableContainer>
    ) : (
      <></>
    )}
  </>
);

export default StatsCustomAttributeTable;

/**
 * Module dependencies.
 */

import animationData from 'assets/fireworks.json';
import * as S from 'core/components/fireworks-animation/fireworks-animation.style';

/**
 * Function `fireworks` component.
 */

function fireWorks(): JSX.Element {
  return <S.Container animationData={animationData} loop={false} />;
}

export default fireWorks;

import {
  Switch,
  FormControl,
  Stack,
  Typography,
  FormLabel,
  SwitchProps,
} from '@mui/material';
import styled from '@emotion/styled/macro';
import { ChangeEvent } from 'react';

const StyledFormControl = styled(FormControl)`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 1.5rem;
  }
`;

const StyledStack = styled(Stack)`
  && {
    .MuiTypography-root {
      margin-right: 0;
    }
  }
`;

const StyledSwitch = styled(Switch)<SwitchProps>`
  && {
    width: 42px;
    height: 26px;
    padding: 0;

    .MuiSwitch-switchBase {
      padding: 0;
      margin: 2px;
      transition-duration: 300ms;
      border-radius: 50%;

      &.Mui-checked {
        transform: translateX(16px);
        color: rgb(255, 255, 255);

        & + .MuiSwitch-track {
          background-color: #068443;
          opacity: 1;
          border: 0;
        }
      }

      & .MuiSwitch-thumb {
        box-sizing: border-box;
        width: 22px;
        height: 22px;
        border: #b7b7b7 solid 0.97px;
      }
    }

    & .MuiSwitch-track {
      border-radius: 13px;
      background-color: #f27777;
      opacity: 1;
    }
  }
`;

const StyledLegend = styled(FormLabel)`
  && {
    margin-right: 15px;
  }
`;

interface ICheckboxProps {
  id: string;
  labelLeft: string;
  labelRight: string;
  className?: string;
  legend?: string;
  checked?: boolean;
  onChange?:
    | ((event: ChangeEvent<HTMLInputElement>, checked: boolean) => void)
    | undefined;
}

const SwitchFilter = ({
  id,
  labelLeft,
  labelRight,
  legend,
  onChange,
  className,
  checked,
}: ICheckboxProps): JSX.Element => (
  <StyledFormControl className={className}>
    {legend ? <StyledLegend>{legend}</StyledLegend> : <></>}
    <StyledStack direction="row" spacing={1} alignItems="center">
      <Typography>{labelLeft}</Typography>
      <StyledSwitch id={id} onChange={onChange} checked={checked} />
      <Typography>{labelRight}</Typography>
    </StyledStack>
  </StyledFormControl>
);

export default SwitchFilter;

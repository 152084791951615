import DateRangeType from 'date-range/models/date-range-type.enum';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { Typography } from '@mui/material';

interface IUserScheduleItem {
  padding?: number;
  maxWidth?: number | null | undefined;
  $dateRangeType?: DateRangeType;
  $isWeekend?: boolean;
  $showDate?: boolean;
}

export const ScheduleItem = styled.li<IUserScheduleItem>`
  position: relative;
  display: flex;
  table-layout: fixed;
  justify-content: center;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'auto')};
  padding: ${({ padding }) => (padding ? `${padding}px` : 0)};
  align-items: center;
  width: 0;
  flex: 1 1 0;
  border-right: 1px solid #07334a;
  background-color: ${({ $isWeekend }) =>
    $isWeekend ? '#C4C4C4' : 'transparent'};
  counter-increment: date;
  ${({ $dateRangeType, $showDate = false }) =>
    $dateRangeType !== DateRangeType.week &&
    $showDate &&
    css`
      &::before {
        position: absolute;
        top: 0;
        content: counter(date);
        color: #07334a;
      }
    `}

  ${({ $dateRangeType }) =>
    $dateRangeType === DateRangeType.month &&
    css`
      &::before {
        position: absolute;
        top: 0;
        content: counter(date);
        color: #07334a;
      }
    `}
`;

export const ScheduleDroppableContainer = styled.div`
  position: relative;
  display: table-cell;
  width: 100%;
  vertical-align: middle;
`;

export const AnimationContainer = styled.div`
  top: -60px;
  width: 100%;
  height: 80px;
  position: absolute;
`;

export const TableHeadParagraph = styled(Typography)`
  position: absolute;
  font-size: 14px;
  top: 2px;
  left: 9.5px;
`;

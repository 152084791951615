/**
 * Module dependencies.
 */

import styled from '@emotion/styled/macro';
import { Typography } from '@mui/material';

export const TaskTitles = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 65px;

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    align-items: start;
    margin-bottom: 28px;
  }
`;

export const StyledTaskTitle = styled(Typography)`
  display: inline-block;
  width: auto;

  ${(props) => props.theme.breakpoints.down('md')} {
    font-size: 1.125em;
  }
`;

export const TaskDateRange = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 16px;
  margin-bottom: 50px;

  .MuiTypography-root {
    font-weight: 500;
    font-size: 1em;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    margin-bottom: 38px;
  }
`;

export const TaskTimelinesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TimelineCol = styled.div`
  margin-right: 80px;

  &:last-child {
    margin-right: 0;
  }
`;

export const StyledProgressBar = styled.div`
  ${(props) => props.theme.breakpoints.down('md')} {
    display: none;
  }
`;

export const NoData = styled.div`
  background-color: #f6f8f9;
  padding: 30px;
  font-size: 1.25em;
  text-align: center;
`;

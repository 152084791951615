import DateRangeType from 'date-range/models/date-range-type.enum';
import { DateTime } from 'luxon';

export interface DateRangeState {
  /**
   * Start Date - The date in which the date range starts from.
   */
  startDate: DateTime;
  /**
   * End Date - The date in which the date range ends.
   */
  endDate: DateTime;
  /**
   * Date Range Type - The type of date range to render
   *
   * For Example:
   * * DateRangeType.week = "week"
   * * DateRangeType.month = "month"
   * * DateRangeType.year = "year"
   */
  dateRangeType: DateRangeType;
  /**
   * Header Dates - The dates that appear in the header of the calendar control.
   *
   * For example:
   * * When DateRangType.week header dates will be Monday - Friday
   * * When DateRangType.month header dates will be the current month eg January
   * * When DateRangType.year header dates will be January - December
   */
  headerDates: DateTime[];
  /**
   * Schedule Dates - The dates of each cell within the calendar control.
   *
   * For example:
   * * When DateRangType.week schedule dates will be Monday - Friday
   * * When DateRangType.month schedule dates will be 1st - 30th, 31st, 28th
   * * When DateRangType.year schedule dates will be 1 - 52 weeks of the year
   */
  calendarDates: DateTime[];
}

const dateFromParam = (param: string | null): DateTime | null => {
  const date = DateTime.fromFormat(param ?? '', 'yyyy-MM-dd');
  return date.isValid ? date : null;
};

export const dateRangeState = {
  inititial: (
    startDateParam: string | null,
    dateRangeTypeParam: string | null,
  ): DateRangeState => {
    const startDate = dateFromParam(startDateParam);
    const startOfWeek = startDate ?? DateTime.now().startOf(DateRangeType.week);

    // TODO(andy): Add date range type to initial state. Will need to map through all dates between startdate and enddate
    // const dateRangeType =
    //   DateRangeType[(dateRangeTypeParam ?? "week") as DateRangeType] ?? DateRangeType.week;

    return {
      startDate: startDate ?? DateTime.now().startOf(DateRangeType.week),
      endDate: (startDate ?? DateTime.now())
        .endOf(DateRangeType.week)
        .minus({ days: 2 }),
      dateRangeType: DateRangeType.week,
      headerDates: [
        startOfWeek,
        startOfWeek.plus({ days: 1 }),
        startOfWeek.plus({ days: 2 }),
        startOfWeek.plus({ days: 3 }),
        startOfWeek.plus({ days: 4 }),
      ],
      calendarDates: [
        startOfWeek,
        startOfWeek.plus({ days: 1 }),
        startOfWeek.plus({ days: 2 }),
        startOfWeek.plus({ days: 3 }),
        startOfWeek.plus({ days: 4 }),
      ],
    };
  },
};

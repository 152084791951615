import * as React from 'react';
import styled from '@emotion/styled/macro';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  overflow-x: hidden;
`;

interface ContentProps {
  children?: React.ReactNode;
}

const AccountContainer = ({ children }: ContentProps): JSX.Element => (
  <Container>{children}</Container>
);

export default AccountContainer;

/**
 * Module dependencies
 */

import styled from '@emotion/styled/macro';
import Popup from 'core/components/popups/popup';

export const StyledPopup = styled(Popup)`
  overflow: scroll;
  .pop-up-content {
    margin: 20px auto;
    position: relative;
    left: auto;
    top: auto;
    transform: none;
  }
`;

export const MainNavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 60px;
  justify-content: start;
`;

/**
 * Module dependencies.
 */
import { QueryClient, useMutation, useQueryClient } from 'react-query';
import { api } from 'core/services/api.service';
import { IEventHistory } from 'schedule/core/queries/schedule-move-mutation';
import {
  IIndexSchedule,
  scheduleKeys,
} from 'schedule/core/queries/schedule.keys';
import { cleanDateEntry } from 'schedule/core/queries/resize-mutation';
import {
  TaskmanagerEventDto,
  TaskmanagerEventPartialDto,
  TaskmanagerScheduleListDto,
} from 'gateway-api';

export interface IEventPatchWithId extends TaskmanagerEventPartialDto {
  id?: string;
}

// this id is created because the dnd context doesn't update id on clone action to id stored in react-query
const randomId = Math.random();

const onMutate =
  (queryClient: QueryClient, params: IIndexSchedule) =>
  async (eventHistory: IEventHistory) => {
    await queryClient.cancelQueries(scheduleKeys.index());

    const queryKey = scheduleKeys.indexSchedule(params);

    const previousQuery =
      queryClient.getQueryData<TaskmanagerScheduleListDto>(queryKey);

    let existingEvent: TaskmanagerEventDto | undefined;
    const newAssigneeUserId = eventHistory.newEvent?.assignee_user?.id;

    queryClient.setQueryData<TaskmanagerScheduleListDto | undefined>(
      queryKey,
      (memoOld) => {
        if (memoOld) {
          const old = { ...memoOld };
          return {
            pagination: old.pagination,
            items: old.items.map((schedule) => {
              if (eventHistory.newEvent) {
                const {
                  date_start: newEventDateStart,
                  hours: newEventHours,
                  pots_by_type: newPotEvent,
                  event_type: eventType,
                } = eventHistory.newEvent;

                // add the event to the schedule that it was dropped to
                if (schedule.user.id === newAssigneeUserId) {
                  const dropDateEntry = schedule.entries.find(
                    (entry) =>
                      new Date(entry.date_from).toDateString() ===
                      new Date(newEventDateStart).toDateString(),
                  );

                  if (dropDateEntry) {
                    schedule.entries = schedule.entries.map((entry) => {
                      const entryDate = entry.date_from.split(' ')[0];
                      const entryDropDate = newEventDateStart;

                      if (entryDate !== entryDropDate) return entry;

                      existingEvent = entry.events.find(
                        (event) =>
                          event.pots_by_type.ticket &&
                          event.pots_by_type.ticket.id ===
                            newPotEvent.ticket.id &&
                          event.event_type === eventType,
                      );

                      if (!eventHistory.newEvent || !eventHistory.patchEvent)
                        return entry;

                      if (existingEvent) {
                        // when event is present add hours to new event.

                        const newEventE: TaskmanagerEventDto = {
                          ...eventHistory.newEvent,
                          hours: newEventHours + existingEvent.hours,
                        };

                        eventHistory.patchEvent.hours = eventHistory.patchEvent
                          .hours
                          ? existingEvent.hours + eventHistory.patchEvent.hours
                          : newEventE.hours;

                        // remove old event.
                        entry.events = entry.events.filter((event) => {
                          if (event.event_type === newEventE.event_type) {
                            return (
                              event.pots_by_type.ticket?.id !==
                              newEventE.pots_by_type.ticket?.id
                            );
                          }
                          return event;
                        });

                        // add new event with corrected hours.
                        entry.events.push({
                          ...newEventE,
                          id: randomId.toString(),
                        });
                      } else {
                        // add new event to entry without an equal event.
                        entry.events.push({
                          ...eventHistory.newEvent,
                          id: randomId.toString(),
                        });
                      }
                      return cleanDateEntry(
                        entry,
                        schedule.user.profile.available_hours,
                        eventHistory.newEvent.event_type,
                      );
                    });
                  } else {
                    if (!eventHistory.newEvent) return schedule;
                    const availableHours =
                      schedule.user.profile.available_hours;
                    const eventHours = newEventHours;
                    const hoursRemaining = availableHours - eventHours;

                    schedule.entries.push({
                      date_from: newEventDateStart,
                      hours_remaining: hoursRemaining,
                      hours_remaining_by_type: {
                        [eventHistory.newEvent.event_type]: hoursRemaining,
                      },
                      events: [
                        { ...eventHistory.newEvent, id: randomId.toString() },
                      ],
                    });
                  }
                }
              }
              return schedule;
            }),
          };
        }

        return memoOld;
      },
    );
    return { previousQuery, existingEvent };
  };

/**
 * @param `newEvent` necessary to make optimistic update.
 * @param `oldEvent` necessary to make optimistic update.
 * @param `patchEvent` holds the fields necessary to patch an event.
 */

async function mutateSchedule({
  patchEvent,
}: IEventHistory): Promise<TaskmanagerEventDto> {
  const eventId = patchEvent.id;
  const patchEventPayload = patchEvent;
  delete patchEventPayload.id;

  if (eventId) {
    const response = await api.eventService.cloneTaskmanagerEvent(
      eventId,
      patchEventPayload,
    );

    return response.data;
  }

  // eslint-disable-next-line prefer-promise-reject-errors
  return Promise.reject('Clone went wrong');
}

export const useScheduleClone = (params: IIndexSchedule) => {
  const queryClient = useQueryClient();

  return useMutation(mutateSchedule, {
    onMutate: onMutate(queryClient, params),
    onSettled() {
      queryClient.invalidateQueries(scheduleKeys.index());
    },
    onError(error, variables, context) {
      queryClient.setQueriesData(scheduleKeys.index(), context);
      queryClient.invalidateQueries(scheduleKeys.index());
    },
  });
};

/**
 * Module dependencies.
 */

import styled from '@emotion/styled/macro';
import { Typography } from '@mui/material';
import PrimaryButton from 'core/components/buttons/primary-button';
import WatchLaterIcon from '@mui/icons-material/WatchLater';

export const TaskCardContainer = styled.div`
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  position: relative;
  padding: 13px 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;

  &:hover {
    cursor: pointer;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background-color: #f27777;
    border-radius: 5px 5px 0 0;
  }
`;

export const TaskHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  flex-wrap: wrap;
`;

export const TaskTitle = styled(Typography)`
  && {
    background-color: #f27777;
    padding: 1px 15px;
    font-weight: bold;
    border-radius: 8px;
    color: #07334a;
    max-width: calc(100% - 75px);
    margin-bottom: 8px;
    text-align: center;
  }
`;

export const TaskHeaderIcons = styled.div`
  display: flex;
  align-items: center;
  margin-right: -10px;
  margin-top: -4px;
`;

export const TaskStyledLogo = styled.img`
  width: 32px;
  margin-right: 15px;
`;

export const TaskContent = styled.div`
  width: 100%;
`;

export const TicketFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  flex-wrap: wrap;
  align-items: center;
`;

export const TaskStoryPoints = styled.div`
  display: flex;
  align-items: center;
`;

export const TaskWatchIcon = styled(WatchLaterIcon)`
  && {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;

export const TaskStoryPoint = styled(Typography)`
  font-weight: 500;
`;

export const ViewButton = styled(PrimaryButton)`
  && {
    padding: 0;
    text-align: left;
    align-items: start;
    justify-content: start;
    letter-spacing: normal;
    text-transform: none;
    font-weight: normal;
  }
`;

export const StyledLink = styled.a`
  color: #2e559f;
  text-decoration: none;
  font-size: 0.875em;
  text-transform: uppercase;
  font-weight: 500;
`;

/**
 * Module dependencies.
 */

import Scaffold from 'core/components/scaffolds/scaffold';
import { useTranslation } from 'react-i18next';
import { DragDropContext } from 'react-beautiful-dnd';
import ScheduleDelete from 'schedule/core/components/event-delete-button/event-delete-button';
import { Typography } from '@mui/material';
import useDragDropStore from 'schedule/core/store/drag-drop/drag-drop.store';
import { useDateRange } from 'date-range/store/date-range';
import useAuthUser from 'auth/hooks/use-auth-user';
import useScheduleDragEnd from 'schedule/core/hooks/schedule-drag-end';
import { useDestroyEvent } from 'schedule/core/queries/schedule-destroy-event';
import { useScheduleMutation } from 'schedule/core/queries/schedule-move-mutation';
import { DateRangeComponent } from 'date-range/components/date-range/date-range';
import { isInRole } from 'routes/protected-route';
import EditTaskForm from 'schedule/core/components/edit-event-form/edit-event-form';
import {
  EventTypeProvider,
  PageEnum,
} from 'schedule/core/providers/event-type-provider';
import { AvailablePots } from 'schedule/search/components/available-pots/available-pots';
import { ScheduleBody } from 'schedule/core/components/schedule-body/schedule-body';
import { useScheduleClone } from 'schedule/core/queries/clone-event-mutation';
import ScheduleFilters from 'schedule/core/components/schedule-filters/schedule-filters';
import { toApiDate } from 'core/hooks/queries/schedule/use-index-schedule';

import * as S from 'schedule/core/pages/add-time/add-time.style';
import { IIndexSchedule } from 'schedule/core/queries/schedule.keys';
import useUserStore from 'schedule/core/store/users/users.store';
import { TaskmanagerEventTypeEnum } from 'gateway-api';
import useEventTypeStore from 'schedule/core/store/event-type/event-type.store';
import { useEffect } from 'react';
import useUser from 'user/hooks/use-show-user';

const AddTimeNew = (): JSX.Element => {
  // set event type based on page
  const {
    setEventType,
    state: { eventType },
  } = useEventTypeStore();
  const { t } = useTranslation();
  const user = useAuthUser();

  const { data: userData } = useUser(user?.id ?? '');

  const {
    onBeforeCapture,
    state: {
      draggableEvent,
      draggablePot,
      addPot,
      editEventEntry,
      isCloneKeyDown,
    },
    setAddPot,
    clear,
  } = useDragDropStore();

  const {
    state: { users },
  } = useUserStore();

  const {
    state: { endDate, startDate, dateRangeType },
  } = useDateRange();

  const indexScheduleParamsComplete: IIndexSchedule = {
    endDate: toApiDate(endDate.toJSDate()),
    startDate: toApiDate(startDate.toJSDate()),
    eventType: [TaskmanagerEventTypeEnum.Complete],
    dateRangeType,
    selectedUsers: [user?.id ?? ''],
  };

  const { mutate: destroyEvent } = useDestroyEvent(indexScheduleParamsComplete);
  // const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useScheduleMutation(indexScheduleParamsComplete);
  const { mutate: cloneMutation } = useScheduleClone(
    indexScheduleParamsComplete,
  );

  const onDragEnd = useScheduleDragEnd({
    user,
    event: draggableEvent,
    pot: draggablePot,
    isCloneKeyDown,
    page: PageEnum.ADD_TIME,
    handlePatch(event) {
      if (!event) return;
      mutate(event, {
        onSuccess(data, variables, context) {
          if (context.existingEvent) {
            destroyEvent(context.existingEvent);
          }
        },
      });
    },
    handleCreate(pot) {
      setAddPot(pot);
    },
    handleDelete(event) {
      destroyEvent(event);
    },
    handleClone(event) {
      cloneMutation(event, {
        onSuccess(data, variables, context) {
          if (context.existingEvent) {
            destroyEvent(context.existingEvent);
          }
        },
      });
    },
    handleCloneSameSchedule(event) {
      cloneMutation(event, {
        onSuccess(data, variables, context) {
          if (context.existingEvent) {
            destroyEvent(context.existingEvent);
          }
        },
      });
    },
    onAbort: clear,
  });

  useEffect(() => {
    setEventType([TaskmanagerEventTypeEnum.Complete]);
  }, [setEventType]);

  return (
    <Scaffold headerTitle={t('add_time.title')} pageTitle={t('add_time.title')}>
      <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
        <EventTypeProvider
          scheduleParams={{
            ...indexScheduleParamsComplete,
            eventType: [TaskmanagerEventTypeEnum.Schedule],
          }}
          page={PageEnum.ADD_TIME}
        >
          <S.MainNavContainer>
            <ScheduleFilters />
            <DateRangeComponent addTime />
          </S.MainNavContainer>
          <Typography variant='h2'>Schedule View</Typography>

          <ScheduleBody />

          <Typography variant='h2'>Add Time</Typography>

          <ScheduleDelete visible />
        </EventTypeProvider>

        <EventTypeProvider
          scheduleParams={indexScheduleParamsComplete}
          page={PageEnum.ADD_TIME}
        >
          <ScheduleBody />

          <S.StyledPopup
            active={!!addPot || !!editEventEntry}
            closePopup={clear}
            width='800px'
          >
            {addPot ? (
              <EditTaskForm pot={addPot} handleClose={clear} />
            ) : (
              <EditTaskForm event={editEventEntry} handleClose={clear} />
            )}
          </S.StyledPopup>
          <AvailablePots visible />
        </EventTypeProvider>
      </DragDropContext>
    </Scaffold>
  );
};

export default AddTimeNew;

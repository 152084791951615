import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import styled from '@emotion/styled/macro';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { SyntheticEvent } from 'react';
import { IFilterDropDown } from 'core/components/filters/multi-select-filters';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const StyledContainer = styled.div`
  margin-right: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  &:last-child {
    margin-right: 0;
  }
`;

const StyledAutoComplete = styled(Autocomplete)`
  && {
    .MuiInputLabel-root {
      font-weight: 500;
      // position: relative;
      font-size: 1em;
      transform: none;
      top: 9px;
      left: 9px;
      /* transform: none; */

      &.Mui-focused {
        transform: translate(14px, -9px) scale(0.75);
        top: 0;
      }
    }

    .MuiAutocomplete-input {
      padding: 0;
    }

    .MuiInputBase-root {
      border-radius: 10px;
    }

    legend {
      font-size: 1em;
    }

    .MuiAutocomplete-clearIndicator {
      border-radius: 0;
      top: -16px;
      right: 0;
      position: absolute;
      padding: 0 4px;
      background-color: #ffffff;
      z-index: 9999;
      &:after {
        content: 'Clear';
        font-size: 0.5em;
      }
    }
    .MuiAutocomplete-popupIndicatorOpen {
      margin-top: 10px;
      display: none;
    }
  }
` as typeof Autocomplete;

interface ISelectProps {
  options: IFilterDropDown[];
  id?: string;
  label: string;
  labelId?: string;
  selectedValues: string[];
  onChange: (selectedOptions: string[]) => void;
}

const UserFilterSearch = ({
  options,
  id,
  label,
  labelId,
  selectedValues,
  onChange,
}: ISelectProps): JSX.Element => {
  const handleChange = (selected: IFilterDropDown[]) => {
    onChange([...selected.map((item) => item.id!)]);
  };

  return (
    <StyledContainer>
      <StyledAutoComplete
        multiple
        // limitTags={2}
        isOptionEqualToValue={(
          option: IFilterDropDown,
          value: IFilterDropDown,
        ) => option.id === value.id}
        id='checkboxes-tags'
        options={options}
        // options={[{ name: "selectAllLabel", id: "select-all" }, ...options]}
        disableCloseOnSelect
        getOptionLabel={(option) =>
          `${option.name} ${option.id} ${option.lastName}`
        }
        renderOption={(props, option, { selected, inputValue }) => (
          <li key={option.id} {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={
                selectedValues.findIndex((item) => item === option.id) >= 0
              }
              // checked={selectedValues.length === 0 ? false : selected}
            />
            {option.name} {option.lastName}
          </li>
        )}
        style={{ width: 250 }}
        renderTags={() => null}
        onChange={(event, newValue, reason) => handleChange(newValue)}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </StyledContainer>
  );
};

export default UserFilterSearch;

import { useRef, useState, useEffect } from 'react';

/**
 * Used to hide components when clicked outside its component
 *
 * @param initialIsVisible - If component is initially visible
 * @example
 *  const { ref, isComponentVisible, setIsComponentVisible } =
 *   UseComponentVisible(false);
 * <div ref={ref}>
 *   <button onClick={setIsComponentVisible(!isComponentVisible)} />
 *   {isComponentVisible && COMPONENT}
 * </div>
 *
 */

export default function useComponentOpen(initialIsVisible: boolean) {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref: any = useRef();

  const handleClickOutside = (event: MouseEvent | KeyboardEvent) => {
    if (ref != null && ref.current != null) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsComponentVisible(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        handleClickOutside(e);
      }
    });
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      document.removeEventListener('keydown', handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}

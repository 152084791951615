import RadioButtonStyle from 'core/components/form-components/radio-button-style';
import { useSearchStore } from 'schedule/search/store/search/search.store';

import * as S from 'schedule/search/components/pots-sort-filter/pots-sort-filter.styles';

const SortValues = [
  {
    value: 'label:asc',
    label: 'Ascend Tickets',
  },
  {
    value: 'label:desc',
    label: 'Descend Tickets',
  },
];

export const PotsSortFilter = () => {
  const { state, setSort } = useSearchStore();
  const handleSortChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSort(event.target.value);
  };

  return (
    <S.SortContainer>
      {SortValues.map((item) => (
        <RadioButtonStyle
          key={item.label}
          value={item.value}
          name='sort'
          variant='outlined'
          checked={state.sort === item.value}
          onChange={handleSortChange}
        >
          {item.label}
        </RadioButtonStyle>
      ))}
    </S.SortContainer>
  );
};

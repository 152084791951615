import { useQuery } from 'react-query';
import { api } from 'core/services/api.service';

const useIndexPotTypes = () => {
  const {
    data: potTypesResponse,
    isLoading: potTypesLoading,
    error: potTypesError,
  } = useQuery(
    ['pot-types'],
    () =>
      api.potTypeService.indexTaskmanagerPotType({
        params: {
          name: 'time',
        },
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  return { ...potTypesResponse?.data, potTypesLoading, potTypesError };
};

export default useIndexPotTypes;

import { Skeleton, TableBody, TableCell, TableRow } from '@mui/material';
import * as S from 'statistics/components/stats-table/stats-table.style';
import { ScheduleStatsType } from 'schedule/core/components/tooltip/tooltip';
import { TaskmanagerPotStatTimelineUserDto } from 'gateway-api';
import { potStatKeys } from 'schedule/core/queries/pot/pot.keys';
import { useShowPotStats } from 'statistics/hooks/use-pots-statistics';

export interface IStatsTable {
  pot_name: string;
  estimate_hours?: number;
  schedule_hours?: number;
  recorded_hours?: number;
}

interface IStatsUser {
  date: Date;
  time_pot: string;
  hours: number;
  user: TaskmanagerPotStatTimelineUserDto;
}

interface IStatsUpdatedUser {
  startDate?: Date;
  endDate?: Date;
  time_pot?: string;
  hours?: number;
  user?: TaskmanagerPotStatTimelineUserDto;
}

interface IStatTableProps {
  pot_id: string | undefined;
  custom?: boolean;
  statsType?: ScheduleStatsType;
}

const onlyUnique = (value: any, index: number, self: any[]) =>
  self.indexOf(value) === index;

const StatsTable = ({
  pot_id,
  custom,
  statsType,
}: IStatTableProps): JSX.Element => {
  const queryKey = potStatKeys.showPot({
    id: pot_id ?? '',
  });

  const { data: potStats, isLoading } = useShowPotStats(queryKey);

  const statsArray = [
    ...(potStats?.estimate_time_pot_totals ?? []),
    ...(potStats?.schedule_time_pot_totals ?? []),
    ...(potStats?.complete_time_pot_totals ?? []),
  ];

  const distinctArray = statsArray
    .map((stat) => stat.pot_name)
    .filter(onlyUnique);

  const estimateData = potStats?.estimate_time_pot_totals ?? [];
  const scheduleData = potStats?.schedule_time_pot_totals ?? [];
  const recordedData = potStats?.complete_time_pot_totals ?? [];

  const statsTable: IStatsTable[] = [];

  // Loop through all distinct potnames ie coding, meeting, design and push to new array - removes duplicates.
  distinctArray.forEach((item) => {
    const estimateItem = estimateData.find((obj) => obj.pot_name === item);
    const scheduleItem = scheduleData.find((obj) => obj.pot_name === item);
    const recordedItem = recordedData.find((obj) => obj.pot_name === item);

    statsTable.push({
      pot_name: item,
      estimate_hours: estimateItem ? estimateItem.hours : 0,
      schedule_hours: scheduleItem ? scheduleItem.hours : 0,
      recorded_hours: recordedItem ? recordedItem.hours : 0,
    });
  });

  // user time line data
  const estimateUserTimeLine = potStats?.estimate_timeline;

  // first version of users which will include duplicate users, this is needed so we can loop through each user and find users that are the same by id and have the same time pot
  const userStatsData: IStatsUser[] = [];

  if (custom) {
    estimateUserTimeLine?.forEach((estimatedTime) =>
      estimatedTime.entries.forEach((estimatePot) =>
        estimatePot.users.forEach((user) => {
          userStatsData.push({
            date: new Date(estimatedTime.date as string),
            time_pot: estimatePot.time_pot,
            hours: user.hours,
            user,
          });
        }),
      ),
    );
  }

  // final user array - this will store users which have been filtered together based on user id and pot name - should have no duplicates
  const mappedUser: IStatsUpdatedUser[] = [];

  // sets a key for users which will consist of user id and time pot name
  const keyMapping: { [key: string]: IStatsUser } = {};

  // loops through each user and sets a key - at this point there should still be dulpilcate users
  userStatsData.forEach((stat) => {
    keyMapping[`${stat.user.id}_${stat.time_pot}`] = stat;
  });

  // creates a array of all the unique keys - removes duplicate user ids with the same time pot
  const distinctKeys = Object.keys(keyMapping).filter(onlyUnique);

  // loops through string array of unique keys
  distinctKeys.forEach((key) => {
    // gets the user id and time pot out of the key
    const thisUserStats = userStatsData.filter(
      (stat) =>
        stat.user.id === keyMapping[key].user.id &&
        stat.time_pot === keyMapping[key].time_pot,
    );

    // finds the first date out of the user and saves in to a variable which will be used when pushing the data
    const startDate = thisUserStats[0].date;
    // find the last date out of the user and saves in to a variable which will be used when pushing the data
    const endDate = thisUserStats[thisUserStats.length - 1].date;

    // maps through hours of the user and adds them together
    const hours = thisUserStats
      .map((item) => item.user.hours)
      .reduce((a, b) => a + b, 0);

    // creates a user which is now not duplicated - this is the user we want to use for pushing in to the final array
    const mergedUser = thisUserStats.find((item) => item.time_pot);

    // if we get a user push to the final array
    if (mergedUser) {
      mappedUser.push({
        startDate,
        endDate,
        time_pot: mergedUser.time_pot,
        user: mergedUser.user,
        hours,
      });
    }
  });

  return (
    <>
      {isLoading ? (
        <Skeleton height={60} animation='wave' />
      ) : (
        <>
          {custom ? (
            <>
              {statsArray.length > 0 ? (
                <S.StyledTableContainer>
                  <S.StyledStatTable aria-label='Stats Table'>
                    <S.StyledTableHeader>
                      <TableRow>
                        <TableCell>Pot</TableCell>
                        <TableCell>Estimated</TableCell>
                        <TableCell>Schedule</TableCell>
                        <TableCell>Remaining</TableCell>
                      </TableRow>
                    </S.StyledTableHeader>
                    <TableBody>
                      {statsTable.map((item) => (
                        <S.StyledTableRow key={item.pot_name} custom={custom}>
                          <TableCell>{item.pot_name}</TableCell>
                          <TableCell>{item.estimate_hours} hrs</TableCell>
                          <TableCell>{item.schedule_hours} hrs</TableCell>
                          <TableCell>
                            <strong>
                              {/* calculate time remaining if both estimate and schedule */}
                              {item.estimate_hours && item.schedule_hours
                                ? `${
                                    item.estimate_hours - item.schedule_hours
                                  } hrs`
                                : ''}
                              {/* show estimate value in remaining when no schedule */}
                              {item.estimate_hours && !item.schedule_hours
                                ? `${item.estimate_hours - 0} hrs`
                                : ''}
                              {/* show schedule value in remaining when no estimate  */}
                              {!item.estimate_hours && item.schedule_hours
                                ? `${0 - item.schedule_hours} hrs`
                                : ''}
                              {/* show 0 in remaining when no estimate or schedule */}
                              {!item.estimate_hours && !item.schedule_hours
                                ? `${0} hrs`
                                : ''}
                            </strong>
                          </TableCell>
                        </S.StyledTableRow>
                      ))}
                    </TableBody>
                  </S.StyledStatTable>
                </S.StyledTableContainer>
              ) : (
                <S.NoStatsTitle>
                  No Stat Information is available
                </S.NoStatsTitle>
              )}
            </>
          ) : (
            <>
              {statsArray.length > 0 ? (
                <S.StyledTableContainer>
                  {statsType === ScheduleStatsType.ALL && ''}
                  {statsType &&
                    statsType === ScheduleStatsType['EST VS REC'] && (
                      <S.SelectedStat>Estimated vs Recorded</S.SelectedStat>
                    )}
                  {statsType === ScheduleStatsType['EST VS SCHED'] && (
                    <S.SelectedStat>Estimated vs Schedule</S.SelectedStat>
                  )}
                  {statsType === ScheduleStatsType['SCHED VS REC'] && (
                    <S.SelectedStat>Schedule vs Recorded</S.SelectedStat>
                  )}
                  <S.StyledStatTable aria-label='Stats Table'>
                    <S.StyledTableHeader>
                      <TableRow>
                        <TableCell>Pot</TableCell>
                        {statsType === ScheduleStatsType.ALL && (
                          <>
                            <TableCell>Estimated</TableCell>
                            <TableCell>Schedule</TableCell>
                            <TableCell>Recorded</TableCell>
                          </>
                        )}
                        {statsType === ScheduleStatsType['SCHED VS REC'] && (
                          <>
                            <TableCell>Schedule</TableCell>
                            <TableCell>Recorded</TableCell>
                            <TableCell>Remaining</TableCell>
                          </>
                        )}
                        {statsType === ScheduleStatsType['EST VS REC'] && (
                          <>
                            <TableCell>Estimated</TableCell>
                            <TableCell>Recorded</TableCell>
                            <TableCell>Remaining</TableCell>
                          </>
                        )}
                        {statsType === ScheduleStatsType['EST VS SCHED'] && (
                          <>
                            <TableCell>Estimated</TableCell>
                            <TableCell>Schedule</TableCell>
                            <TableCell>Remaining</TableCell>
                          </>
                        )}
                      </TableRow>
                    </S.StyledTableHeader>
                    <TableBody>
                      {statsTable.map((item) => {
                        if (statsType === ScheduleStatsType['EST VS SCHED']) {
                          return (
                            <S.StyledTableRow
                              key={item.pot_name}
                              custom={custom}
                            >
                              <TableCell>{item.pot_name}</TableCell>
                              <TableCell>{item.estimate_hours} hrs</TableCell>
                              <TableCell>{item.schedule_hours} hrs</TableCell>
                              <TableCell>
                                <strong>
                                  {/* calculate time remaining if both estimate and schedule */}
                                  {item.estimate_hours && item.schedule_hours
                                    ? `${
                                        item.estimate_hours -
                                        item.schedule_hours
                                      } hrs`
                                    : ''}
                                  {/* show estimate value in remaining when no schedule */}
                                  {item.estimate_hours && !item.schedule_hours
                                    ? `${item.estimate_hours - 0} hrs`
                                    : ''}
                                  {/* show schedule value in remaining when no estimate  */}
                                  {!item.estimate_hours && item.schedule_hours
                                    ? `${0 - item.schedule_hours} hrs`
                                    : ''}
                                  {/* show 0 in remaining when no estimate or schedule */}
                                  {!item.estimate_hours && !item.schedule_hours
                                    ? `${0} hrs`
                                    : ''}
                                </strong>
                              </TableCell>
                            </S.StyledTableRow>
                          );
                        }

                        if (statsType === ScheduleStatsType.ALL) {
                          return (
                            <S.StyledTableRow
                              key={item.pot_name}
                              custom={custom}
                            >
                              <TableCell>{item.pot_name}</TableCell>
                              <TableCell>{item.estimate_hours} hrs</TableCell>
                              <TableCell>{item.schedule_hours} hrs</TableCell>
                              <TableCell>{item.recorded_hours}</TableCell>
                            </S.StyledTableRow>
                          );
                        }

                        if (statsType === ScheduleStatsType['EST VS REC']) {
                          return (
                            <S.StyledTableRow
                              key={item.pot_name}
                              custom={custom}
                            >
                              <TableCell>{item.pot_name}</TableCell>
                              <TableCell>{item.estimate_hours} hrs</TableCell>
                              <TableCell>{item.recorded_hours} hrs</TableCell>
                              <TableCell>
                                <strong>
                                  {/* calculate time remaining if both schedule and recorded */}
                                  {item.estimate_hours && item.recorded_hours
                                    ? `${
                                        item.estimate_hours -
                                        item.recorded_hours
                                      } hrs`
                                    : ''}
                                  {/* show schedule value in remaining when no recorded */}
                                  {item.estimate_hours && !item.recorded_hours
                                    ? `${item.estimate_hours - 0} hrs`
                                    : ''}
                                  {/* show recorded value in remaining when no schedule  */}
                                  {!item.estimate_hours && item.recorded_hours
                                    ? `${0 - item.recorded_hours} hrs`
                                    : ''}
                                  {/* show 0 in remaining when no schedule or recorded */}
                                  {!item.estimate_hours && !item.recorded_hours
                                    ? `${0} hrs`
                                    : ''}
                                </strong>
                              </TableCell>
                            </S.StyledTableRow>
                          );
                        }

                        return (
                          <S.StyledTableRow key={item.pot_name} custom={custom}>
                            <TableCell>{item.pot_name}</TableCell>
                            <TableCell>{item.schedule_hours} hrs</TableCell>
                            <TableCell>{item.recorded_hours} hrs</TableCell>
                            <TableCell>
                              <strong>
                                {/* calculate time remaining if both schedule and recorded */}
                                {item.schedule_hours && item.recorded_hours
                                  ? `${
                                      item.schedule_hours - item.recorded_hours
                                    } hrs`
                                  : ''}
                                {/* show schedule value in remaining when no recorded */}
                                {item.schedule_hours && !item.recorded_hours
                                  ? `${item.schedule_hours - 0} hrs`
                                  : ''}
                                {/* show recorded value in remaining when no schedule  */}
                                {!item.schedule_hours && item.recorded_hours
                                  ? `${0 - item.recorded_hours} hrs`
                                  : ''}
                                {/* show 0 in remaining when no schedule or recorded */}
                                {!item.schedule_hours && !item.recorded_hours
                                  ? `${0} hrs`
                                  : ''}
                              </strong>
                            </TableCell>
                          </S.StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </S.StyledStatTable>
                </S.StyledTableContainer>
              ) : (
                <S.NoStatsTitle>
                  No Stat Information is available
                </S.NoStatsTitle>
              )}
            </>
          )}

          {custom && mappedUser.length > 0 ? (
            <S.StyledUserData>
              <S.UserDataTitle>Estimated assigned to:</S.UserDataTitle>
              {mappedUser?.map((item, index) => (
                <S.StyledUser key={index}>
                  <S.StyledUserAvatar
                    initials={`${item?.user?.user_directory?.first_name?.charAt(
                      0,
                    )}${item?.user?.user_directory?.last_name?.charAt(0)}`}
                    avatarWidth='44px'
                    avatarHeight='44px'
                  />
                  <S.StyledUserName>
                    {item.user?.user_directory?.first_name}
                  </S.StyledUserName>
                  <S.StyledUserPotTitle>{item.time_pot}:</S.StyledUserPotTitle>
                  <S.StyledUserPotName>{item.hours} hrs</S.StyledUserPotName>
                  <S.StyledUserDate>
                    Start:{' '}
                    {item.startDate?.toLocaleString('en-GB', {
                      day: '2-digit',
                      month: '2-digit',
                    })}
                  </S.StyledUserDate>
                  <S.StyledUserDate>
                    End:{' '}
                    {item.endDate?.toLocaleString('en-GB', {
                      day: '2-digit',
                      month: '2-digit',
                    })}
                  </S.StyledUserDate>
                </S.StyledUser>
              ))}
            </S.StyledUserData>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default StatsTable;

/**
 * Module dependencies.
 */

import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import {
  IEventTypeStore,
  initialState,
} from 'schedule/core/store/event-type/even-type.state';
import { isNil } from 'lodash';
import { TaskmanagerEventTypeEnum } from 'gateway-api';

const useEventTypeStore = create<IEventTypeStore>()(
  immer(
    devtools(
      (set) => ({
        state: initialState,
        setEventType(eventTypeList) {
          set(
            (store) => {
              store.state.eventType = eventTypeList;
            },
            false,
            { type: 'event-type-store__setEventType', eventTypeList },
          );
        },
        setEventTypeView(eventTypeList, checked) {
          set(
            (store) => {
              if (isNil(eventTypeList)) return;
              switch (eventTypeList) {
                case 'All':
                  if (checked) {
                    store.state.eventType = [
                      TaskmanagerEventTypeEnum.Complete,
                      TaskmanagerEventTypeEnum.Schedule,
                      TaskmanagerEventTypeEnum.Estimate,
                    ];
                  } else {
                    store.state.eventType = [TaskmanagerEventTypeEnum.Schedule];
                  }
                  break;
                case TaskmanagerEventTypeEnum.Estimate:
                  if (checked) {
                    store.state.eventType = [
                      ...store.state.eventType,
                      TaskmanagerEventTypeEnum.Estimate,
                    ];
                  } else {
                    if (store.state.eventType.length < 2) return;
                    store.state.eventType = [
                      ...store.state.eventType.filter(
                        (eventType) =>
                          eventType !== TaskmanagerEventTypeEnum.Estimate,
                      ),
                    ];
                  }
                  break;
                case TaskmanagerEventTypeEnum.Schedule:
                  if (checked) {
                    store.state.eventType = [
                      ...store.state.eventType,
                      TaskmanagerEventTypeEnum.Schedule,
                    ];
                  } else {
                    if (store.state.eventType.length < 2) return;
                    store.state.eventType = [
                      ...store.state.eventType.filter(
                        (eventType) =>
                          eventType !== TaskmanagerEventTypeEnum.Schedule,
                      ),
                    ];
                  }
                  break;
                case TaskmanagerEventTypeEnum.Complete:
                  if (checked) {
                    store.state.eventType = [
                      ...store.state.eventType,
                      TaskmanagerEventTypeEnum.Complete,
                    ];
                  } else {
                    if (store.state.eventType.length < 2) return;
                    store.state.eventType = [
                      ...store.state.eventType.filter(
                        (eventType) =>
                          eventType !== TaskmanagerEventTypeEnum.Complete,
                      ),
                    ];
                  }
                  break;
                default:
                  break;
              }
            },
            false,
            {
              type: 'event-type-store__setEventTypeView',
              eventTypeList,
              checked,
            },
          );
        },
      }),
      {
        name: 'event-type-store',
      },
    ),
  ),
);

export default useEventTypeStore;

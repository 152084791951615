import styled from '@emotion/styled';

interface IEventTypeWorkcation {
  width: number;
}

export const WorkcationContainer = styled.div<IEventTypeWorkcation>`
  position: relative;
  background-color: #f6ac69;
  font-size: 1.125em;
  border-radius: 4px;
  height: 100%;
  width: ${({ width }) => `${width}px`};

  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  ${(props) => props.theme.breakpoints.down('md')} {
    min-width: ${({ width }) => `${width}px`};
    font-size: 0;

    p {
      font-size: 0;
    }
  }
`;

import { Typography } from '@mui/material';
import PrimaryButton from 'core/components/buttons/primary-button';
import Search from 'core/components/search';
import { useEffect } from 'react';
import MultiSelectFilter from 'core/components/filters/multi-select-filters';
import useSkills from 'core/hooks/queries/use-skills';
import useRoles from 'core/hooks/queries/roles/use-roles';
import { useSnackbar } from 'notistack';
import Scaffold from 'core/components/scaffolds/scaffold';
import useUserSelect from 'users/hooks/user-select';
import UserFiltersShimmer from 'users/components/user-filters-shimmer';
import UserTileShimmer from 'users/components/user-tile-shimmer/user-tile-shimmer';
import UserManageTile from 'users/components/user-manage-tile/user-manage-tile';
import { UserForm } from 'users/components/user-form/user-form';
import useAllUser from 'user/hooks/use-all-users';
import useDebounce from 'core/hooks/use-debounce';
import {
  SectionHeader,
  SectionHeaderButton,
  SectionHeaderSearch,
  SectionTitle,
  StyledPopup,
  SectionHeaderTitle,
  SectionHeaderFilterSection,
  SectionHeaderFilter,
  SectionHeaderSub,
  StyledUserSwitch,
  UserList,
  SectionContent,
  NoUsers,
  UserItem,
} from 'users/pages/user-management/user-management.styles';
import { UserDto } from 'gateway-api';

const UserManagement = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useUserSelect();

  const searchDebounce = useDebounce((value) =>
    dispatch({ type: 'search_changed', payload: value }),
  );

  const skillsList = useSkills();
  const rolesList = useRoles();

  const {
    items: userList,
    usersLoading,
    usersError,
  } = useAllUser({
    disabled: state.showInactiveAccounts,
    selectedRoles: state.selectedRoles,
    selectedUsers: state.selectedUsers,
    selectedSkills: state.selectedSkills,
    search: state.search,
  });

  useEffect(() => {
    if (usersError)
      enqueueSnackbar('An error occurred whilst retrieving users.', {
        variant: 'error',
      });
  }, [enqueueSnackbar, usersError]);

  return (
    <Scaffold headerTitle='User management'>
      <SectionHeader>
        <SectionHeaderTitle>
          <SectionTitle variant='h1'>User management</SectionTitle>
        </SectionHeaderTitle>
        <SectionHeaderSearch>
          <Search
            onSubmit={(search) => {
              dispatch({ type: 'search_changed', payload: search });
            }}
            className='user-management-search'
            placeholder='Search Team'
            onSearchChange={(search) => {
              searchDebounce(search);
            }}
          />
        </SectionHeaderSearch>
        <SectionHeaderButton>
          <PrimaryButton
            onClick={() => dispatch({ type: 'new_user_form_opened' })}
          >
            Add New User
          </PrimaryButton>
          <StyledPopup
            active={state.newUserFormOpen}
            closePopup={() => dispatch({ type: 'new_user_form_closed' })}
            width='800px'
            title='Member profile'
          >
            <UserForm
              handleClose={() => dispatch({ type: 'new_user_form_closed' })}
            />
          </StyledPopup>
        </SectionHeaderButton>

        <SectionHeaderFilter>
          <SectionHeaderFilterSection>
            {rolesList.length === 0 ? (
              <UserFiltersShimmer />
            ) : (
              rolesList && (
                <MultiSelectFilter
                  options={rolesList.map((role: string) => ({
                    id: role.toString(),
                    name: role,
                  }))}
                  label='Filter by role:'
                  selectedValues={state.selectedRoles}
                  onChange={(selectedRoles) => {
                    dispatch({
                      type: 'roles_selected',
                      payload: selectedRoles,
                    });
                  }}
                />
              )
            )}
          </SectionHeaderFilterSection>
          <SectionHeaderFilterSection>
            {skillsList.length === 0 ? (
              <UserFiltersShimmer />
            ) : (
              skillsList && (
                <MultiSelectFilter
                  options={skillsList.map((skill) => ({
                    id: skill,
                    name: skill,
                  }))}
                  label='Filter by skill:'
                  selectedValues={state.selectedSkills}
                  onChange={(selectedSkills) => {
                    dispatch({
                      type: 'skills_selected',
                      payload: selectedSkills,
                    });
                  }}
                />
              )
            )}
          </SectionHeaderFilterSection>
          <StyledUserSwitch
            id='disabled'
            labelLeft='Off'
            labelRight='On'
            legend='Show active accounts only'
            onChange={(value, checked) =>
              dispatch({ type: 'inactive_accounts_changed', payload: checked })
            }
          />
        </SectionHeaderFilter>
        <SectionHeaderSub>
          <Typography variant='h2'>Manage users</Typography>
        </SectionHeaderSub>
      </SectionHeader>
      <SectionContent>
        {usersLoading ? (
          <UserTileShimmer />
        ) : (
          <UserList>
            {userList?.map((user: UserDto) => (
              <UserItem key={user.id}>
                <UserManageTile user={user} />
              </UserItem>
            ))}
          </UserList>
        )}
        {!userList?.length && !usersLoading && (
          <NoUsers>No Users found</NoUsers>
        )}
        {/* <StyledPagination
              count={pagination?.page_total}
              page={pageNumber}
              onChange={handlePageChange}
            /> */}
      </SectionContent>
    </Scaffold>
  );
};

export default UserManagement;

/**
 * Module dependencies.
 */

import styled from '@emotion/styled/macro';

export const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const Filter = styled.div`
  margin-right: 14px;
`;

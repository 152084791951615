import { useRef, FC } from 'react';
import useResizeObserver from 'date-range/hooks/use-resize-observer';
import { CalendarHeader } from 'date-range/components/calendar-header/calendar-header';
import * as S from 'date-range/components/calendar-container/calendar-container.styles';
import useDragDropStore from 'schedule/core/store/drag-drop/drag-drop.store';

interface ICalendarContainer {
  /** The width of the leading item */
  leadingWidth?: number;
  children: FC<number>;
}

/**
 * Calendar Container
 *
 * A wrapper component around CalendarHeader and the children passed.
 * This component uses useResizeObserver to get the current width of the container and passes that value to the children.
 *
 * @param {FC<number>} children The CalendarBody to be used for your intended calendar control
 * @example <CalendarContainer>{(width) => <p>{width}</p>}</CalendarContainer>
 */
export const CalendarContainer = ({
  children,
  leadingWidth,
}: ICalendarContainer) => {
  const ref = useRef<HTMLUListElement>(null);
  const [width] = useResizeObserver(ref);

  const isCloneKeyDown = useDragDropStore(
    (store) => store.state.isCloneKeyDown,
  );

  return (
    <S.CalendarContainer ref={ref} isCloning={isCloneKeyDown}>
      <CalendarHeader leadingWidth={leadingWidth} />
      {children(width)}
    </S.CalendarContainer>
  );
};

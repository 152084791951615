import { debounce } from '@mui/material';
import { useState, useEffect, useRef } from 'react';

interface IUseDebounceProps {
  delay?: number;
}
/**
 * A wrapper around lodash.debounce for use in react.
 * @param func - the function to debounce
 * @param delay - the time to wait before calling the event. In milliseconds, defaults to 500
 */
const useDebounce = <A extends Array<any>>(
  func: (...args: A) => void,
  { delay = 500 }: IUseDebounceProps = {},
) => {
  const debounceFunc = useRef(debounce(func, delay));
  return debounceFunc.current;
};

export default useDebounce;

/**
 * Module dependencies.
 */

import styled from '@emotion/styled/macro';
import UserAvatar from 'user/components/user-avatar/user-avatar';

export const TicketEventUser = styled(UserAvatar)`
  && {
    margin: 0 8px 8px 0;
    div {
      background-color: #cecece;

      font-size: 14px !important;
    }
  }
`;

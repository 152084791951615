/**
 * Module dependencies.
 */

import { useEffect, useState } from 'react';

interface IProps {
  targetKey: string;
  setIsCloneKeyDown: (isDown: boolean) => void;
}

/**
 * Function `useKeyPress` hook.
 * @param props
 */

function useKeyPress(props: IProps) {
  const { targetKey, setIsCloneKeyDown } = props;
  // State for keeping track of whether key is pressed
  // If pressed key is our target key then set to true
  function downHandler({ key }: { key: string }) {
    if (key === targetKey) {
      setIsCloneKeyDown(true);
    }
  }
  // If released key is our target key then set to false
  const upHandler = ({ key }: { key: string }) => {
    if (key === targetKey) {
      setIsCloneKeyDown(false);
    }
  };
  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount
}

export default useKeyPress;

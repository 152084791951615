import { Skeleton } from '@mui/material';
import styled from '@emotion/styled/macro';

const TasksList = styled.ul`
  display: flex;
  list-style: none;
  flex-wrap: wrap;

  ${(props) => props.theme.breakpoints.up('md')} {
    margin-left: -40px;
  }
`;

const Task = styled.li`
  border-right: solid #d0d0d0 1px;
  padding: 0 40px;
  display: flex;
  width: 100%;

  .MuiSkeleton-root {
    margin-bottom: 15px;
    height: 130px;
    border-radius: 5px;
  }

  .draggable {
    width: 100%;
    margin-top: 15px;
  }

  ${(props) => props.theme.breakpoints.down('lg')} {
    max-width: 49%;

    :nth-of-type(-n + 2) {
      .draggable {
        margin-top: 0;
      }
    }
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    max-width: 100%;
    border-right: 0;
    padding: 0 10px;

    :nth-of-type(-n + 2) {
      .draggable {
        margin-top: 15px;
      }
    }
  }

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 33%;

    :nth-of-type(-n + 3) {
      .draggable {
        margin-top: 0;
      }
    }
  }
`;

const getShimmers = () => {
  const shimmers = [];

  for (let index = 0; index < 21; index++) {
    const key = `task_${index}`;
    shimmers.push(
      <Task key={key}>
        <Skeleton variant="rectangular" width="100%" />
      </Task>,
    );
  }

  return shimmers;
};

const UnscheduledPotsShimmer = (): JSX.Element => (
  <TasksList>{getShimmers()}</TasksList>
);

export default UnscheduledPotsShimmer;

import {
  Dispatch,
  Reducer,
  ReducerAction,
  ReducerState,
  useReducer,
} from 'react';

type Thunk<R extends Reducer<any, any>> = (
  state: ReducerState<R>,
  dispatch: Dispatch<ReducerAction<R>>,
) => void;

type Action<R extends Reducer<any, any>> = Thunk<R> | ReducerAction<R>;

type AsyncDispatch<R extends Reducer<any, any>> = (action: Action<R>) => void;

type UseReducerAsyncReturn<R extends Reducer<any, any>> = [
  ReducerState<R>,
  AsyncDispatch<R>,
];

const useReducerAsync = <R extends Reducer<any, any>>(
  reducer: R,
  initialState: ReducerState<R>,
): UseReducerAsyncReturn<R> => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const asyncDispatch: AsyncDispatch<R> = (action) => {
    if (typeof action === 'function') {
      (action as Thunk<R>)(state, dispatch);
    } else {
      dispatch(action);
    }
  };

  return [state, asyncDispatch];
};

export default useReducerAsync;

/**
 * Module dependencies.
 */

import styled from '@emotion/styled/macro';
import { Typography } from '@mui/material';
import { TaskTitle } from 'statistics/components/task-card/task-card.style';

export const TaskStats = styled.div`
  /* &:hover {
    cursor: pointer;
  } */
`;

export const TaskTitles = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 65px;

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    align-items: start;
    margin-bottom: 28px;
  }
`;

export const StyledTaskLabel = styled(TaskTitle)`
  && {
    display: inline-block;
    width: auto;
    margin-right: 18px;
    margin-bottom: 0;

    ${(props) => props.theme.breakpoints.down('md')} {
      margin-bottom: 10px;
    }
  }
`;

export const StyledTaskTitle = styled(Typography)`
  display: inline-block;
  width: auto;

  ${(props) => props.theme.breakpoints.down('md')} {
    font-size: 1.125em;
  }
`;

export const TaskDateRange = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 16px;
  margin-bottom: 50px;

  .MuiTypography-root {
    font-weight: 500;
    font-size: 1em;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    margin-bottom: 38px;
  }
`;

export const TaskTimelinesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TimelineCol = styled.div`
  margin-right: 80px;

  &:last-child {
    margin-right: 0;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 40px;

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

export const FlexCol = styled.div``;

export const Chart = styled.div`
  text-align: center;
  .recharts-wrapper {
    width: 100% !important;
    height: auto !important;

    .recharts-surface {
      width: 100%;
      height: auto;
    }
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    .recharts-wrapper {
      width: 320px !important;
      height: auto !important;

      .recharts-surface {
        width: 320px;
        height: auto;
      }
    }
  }
`;

export const MoreStats = styled.div`
  border-radius: 10px;
  padding: 28px;
  box-shadow: 0 5px 8px #00000029;
`;

export const StyledProgressBar = styled.div`
  ${(props) => props.theme.breakpoints.down('md')} {
    display: none;
  }
`;

export const NoData = styled.div`
  background-color: #f6f8f9;
  padding: 30px;
  font-size: 1.25em;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const BreakDownTable = styled.div`
  && {
    .MuiTable-root {
      margin-right: 0;

      .MuiTableRow-root {
        display: inline-block;
        margin-right: 50px;

        ${(props) => props.theme.breakpoints.down('md')} {
          margin-right: 0;
          margin-bottom: 20px;
          display: block;
        }

        th {
          min-width: 150px;
          background-color: #ffffff;
          font-weight: 500;
        }

        td {
          min-width: 120px;
        }
      }
    }
  }
`;

export const FlexBoxRow = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const FlexFullCol = styled.div`
  && {
    display: flex;
    box-shadow: 0px 3px 6px #00000024;
    border-radius: 10px;
    flex-direction: column;
    padding: 22px 28px;
    margin-top: 20px;
  }
`;

export const FlexThreeCol = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export const FlexThreeBox = styled.div``;

export const FlexTwoCol = styled.div`
  box-shadow: 0px 3px 6px #00000024;
  width: 49%;
  padding: 22px 28px;
  margin-top: 20px;

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

export const SectionTitles = styled(Typography)`
  && {
    font-size: 1.5em;
    margin-bottom: 33px;
  }
`;

export const TitleGroup = styled.div`
  display: flex;
  align-items: flex-start;

  img {
    margin-right: 10px;
  }
`;

/**
 * Module dependencies.
 */

import styled from '@emotion/styled/macro';

export const UserList = styled.ul`
  display: flex;
  list-style: none;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;

  ${(props) => props.theme.breakpoints.down('sm')} {
    justify-content: space-between;
  }
`;

export const UserItem = styled.li`
  margin-right: 2%;
  margin-bottom: 2%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;

  ${(props) => props.theme.breakpoints.down('xl')} {
    width: 32%;

    &:nth-child(3n + 3) {
      margin-right: 0;
    }
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 49%;
    margin-right: 0;
  }

  ${(props) => props.theme.breakpoints.up('xl')} {
    width: 23.5%;

    &:nth-child(3n + 3) {
      margin-right: 2%;
    }

    &:nth-child(4n + 4) {
      margin-right: 0;
    }
  }
  @media only screen and (min-width: 1440px) {
    width: 15%;

    &:nth-child(3n + 3) {
      margin-right: 2%;
    }

    &:nth-child(4n + 4) {
      margin-right: 2%;
    }

    &:nth-child(6n + 6) {
      margin-right: 0;
    }
  }
`;

/**
 * Module dependencies.
 */

import useEventTypeStore from 'schedule/core/store/event-type/event-type.store';
import * as S from 'schedule/core/components/dropdown-table-view/dropdown-table-view.style';
import { Menu, MenuItem, Button, Checkbox, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { isNil } from 'lodash';
import { TaskmanagerEventTypeEnum } from 'gateway-api';

/**
 * Function `DropDownTableView` component.
 */

function DropDownTableView(): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const storeEventType = useEventTypeStore((store) => store.state.eventType);
  const setEventTypeView = useEventTypeStore((store) => store.setEventTypeView);

  return (
    <S.Container>
      <S.StyledButton
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <S.StyledFilterIcon />
        <S.Paragraph paragraph>Table View</S.Paragraph>
      </S.StyledButton>
      <S.StyledMenu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <S.IconLabelWrapper first>
          <S.CheckBoxWrapper>
            <S.StyledCheckBox
              //
              checked={
                storeEventType.length === 3
                // storeEventType.includes(EventTypeEnum.Complete) &&
                // storeEventType.includes(EventTypeEnum.Estimate) &&
                // storeEventType.includes(EventTypeEnum.Schedule)
              }
              inputProps={{ 'aria-label': 'All' }}
              onChange={(e) => {
                setEventTypeView(
                  e.target.attributes.getNamedItem('aria-label')?.nodeValue,
                  e.target.checked,
                );
              }}
            />
            <Typography>All</Typography>
          </S.CheckBoxWrapper>
          <S.Divider />
        </S.IconLabelWrapper>

        <S.IconLabelWrapper>
          <S.StyledCheckBox
            checked={
              !isNil(
                storeEventType.find(
                  (eventTypeStore) =>
                    eventTypeStore === TaskmanagerEventTypeEnum.Estimate,
                ),
              )
            }
            inputProps={{ 'aria-label': TaskmanagerEventTypeEnum.Estimate }}
            onChange={(e) => {
              setEventTypeView(
                e.target.attributes.getNamedItem('aria-label')?.nodeValue,
                e.target.checked,
              );
            }}
          />
          <Typography>Estimate</Typography>
        </S.IconLabelWrapper>

        <S.IconLabelWrapper>
          <S.StyledCheckBox
            checked={
              !isNil(
                storeEventType.find(
                  (eventTypeStore) =>
                    eventTypeStore === TaskmanagerEventTypeEnum.Schedule,
                ),
              )
            }
            inputProps={{ 'aria-label': TaskmanagerEventTypeEnum.Schedule }}
            onChange={(e) => {
              setEventTypeView(
                e.target.attributes.getNamedItem('aria-label')?.nodeValue,
                e.target.checked,
              );
            }}
          />
          <Typography>Schedule</Typography>
        </S.IconLabelWrapper>

        <S.IconLabelWrapper>
          <S.StyledCheckBox
            checked={
              !isNil(
                storeEventType.find(
                  (eventTypeStore) =>
                    eventTypeStore === TaskmanagerEventTypeEnum.Complete,
                ),
              )
            }
            inputProps={{ 'aria-label': TaskmanagerEventTypeEnum.Complete }}
            onChange={(e) => {
              setEventTypeView(
                e.target.attributes.getNamedItem('aria-label')?.nodeValue,
                e.target.checked,
              );
            }}
          />
          <Typography>Recorded</Typography>
        </S.IconLabelWrapper>
      </S.StyledMenu>
    </S.Container>
  );
}

export default DropDownTableView;

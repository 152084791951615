import {
  FormControl,
  IconButton,
  TextField,
  FormHelperText,
  TextFieldProps,
  BaseTextFieldProps,
} from '@mui/material';
import { useState } from 'react';
import styled from '@emotion/styled/macro';
import { defaultTheme } from 'theme/theme';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { RegisterOptions } from 'react-hook-form';
import useFormField from 'core/hooks/use-form-field';

interface IInputWrapper {
  fieldInline?: boolean;
  hasError?: boolean;
}

export const StyledFormControl = styled(FormControl)`
  position: relative;
  background-color: white;
  border-radius: 40px;
  margin-right: 2px;
  margin-left: 0;
  display: block;
  width: 472px;
  & .MuiInputBase-root {
    border: 1px solid transparent;
  }

  & .Mui-focused {
    border-radius: 40px;
    border: 1px solid ${defaultTheme.colors.primaryColor};
  }
`;

const InputWrapper = styled.div<IInputWrapper>`
  position: relative;
  width: 100%;
  // padding-bottom: ${(props) => (props.hasError ? '40px' : '0')};

  .MuiFormControl-root {
    display: flex;
    justify-content: flex-start;
    align-items: ${(props) => (props.fieldInline ? 'center' : 'flex-start')};

    flex-direction: ${(props) => (props.fieldInline ? 'row' : 'column')};
  }

  .MuiInputLabel-root {
    margin-bottom: ${(props) => (props.fieldInline ? '0' : '13px')};
  }

  /* flex-direction: column;
      align-items: flex-start; */
`;

const StyledFormHelperText = styled(FormHelperText)`
  position: absolute;
  border: none;
  bottom: -20px;
  &.MuiFormHelperText-root {
    color: ${defaultTheme.colors.errorColor};
  }
  &.Mui-error {
    border: none !important;
  }
  &.Mui-focused {
    border: none !important;
  }
`;
export const Input = styled(TextField)`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    align-items: flex-start;
  }

  //height: 100%;
  & .MuiInputBase-input {
    background-color: #eaeaea;
    padding: 18px 20px;
  }

  .MuiOutlinedInput-notchedOutline {
    font-size: 0;

    legend {
      width: 0;
    }
  }

  && .MuiFormLabel-root,
  & .MuiOutlinedInput-root {
    color: ${({ theme }) => theme.colors.textLight};
    font-family: ${({ theme }) => theme.fonts.sanSerif};
    font-weight: normal;
    font-size: 16px;
    opacity: 1;
    position: relative;
    transform: none;
  }
  && .MuiFormLabel-root {
    margin-right: 7px;
    font-size: 18px;
    color: ${defaultTheme.colors.primaryColor};
    font-weight: 500;
    white-space: initial;
  }

  &:hover {
    & fieldset.MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.colors.borderGrey};
      outline: none;
    }
  }

  && fieldset.MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.borderGrey};
    border-radius: 0;
  }
`;

interface ITextInputProps extends BaseTextFieldProps {
  id: string;
  label: string;
  className?: string;
  defaultValue?: string;
  fieldInline?: boolean;
  multiline?: boolean;
  type?: string;
  disabled?: boolean;
  options?: RegisterOptions;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

/** Text field built from a material pannel-ui base. If there is a {@link FormContext} available above
 *  the text field then it will use that for onChange, value and errors */
const TextInput = ({
  id,
  label,
  className,
  defaultValue,
  fieldInline,
  type,
  disabled,
  options,
  ...textFieldProps
}: ITextInputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const { errorLabel, hasError, ...inputProps } = useFormField(id, {
    ...options,
  });

  return (
    <InputWrapper hasError={hasError} fieldInline={fieldInline}>
      <Input
        className={className}
        id={id}
        size="small"
        label={`${label}:`}
        type={type === 'password' && showPassword ? 'text' : type}
        defaultValue={defaultValue}
        disabled={disabled}
        placeholder={label}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment:
            type === 'password' ? (
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            ) : null,
        }}
        {...inputProps}
        {...textFieldProps}
      />
      {hasError && <StyledFormHelperText>{errorLabel}</StyledFormHelperText>}
    </InputWrapper>
  );
};

export default TextInput;

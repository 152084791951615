/**
 * Module dependencies.
 */

import { api } from 'core/services/api.service';
import { useMutation, useQueryClient } from 'react-query';
import { scheduleKeys } from 'schedule/core/queries/schedule.keys';
import { TaskmanagerEventDto, TaskmanagerEventRequestDto } from 'gateway-api';

/**
 *  Function `postEvent` creates an event.
 */

const postEvent = async (
  event: TaskmanagerEventRequestDto,
): Promise<TaskmanagerEventDto> => {
  const { data } = await api.eventService.storeTaskmanagerEvent(event);

  return data;
};

/**
 * Hook `useCreateEvent` function.
 */

const useCreateEvent = () => {
  const queryClient = useQueryClient();

  return useMutation(postEvent, {
    onSettled() {
      queryClient.invalidateQueries(scheduleKeys.index());
    },
  });
};

export default useCreateEvent;

import {
  createContext,
  Dispatch,
  Reducer,
  useContext,
  useReducer,
} from 'react';
import { IProject } from 'gantt/hooks/gant-view/use-tag-search';

const GantContext = createContext({});

type GantViewState = {
  projects: IProject[];
};

type Actions =
  | { type: 'PROJECT_ADDED'; payload: IProject }
  | { type: 'PROJECT_REMOVED'; payload: IProject };

const gantViewReducer: Reducer<GantViewState, Actions> = (state, action) => {
  switch (action.type) {
    case 'PROJECT_ADDED':
      return { ...state, projects: [...state.projects, action.payload] };
    case 'PROJECT_REMOVED':
      return {
        ...state,
        projects: state.projects.filter((p) => p.id !== action.payload.id),
      };
    default:
      return state;
  }
};

const initialState: GantViewState = {
  projects: [],
};

const GantProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(gantViewReducer, initialState);

  return (
    <GantContext.Provider value={[state, dispatch]}>
      {children}
    </GantContext.Provider>
  );
};

export const useGantState = () =>
  useContext(GantContext) as [GantViewState, Dispatch<Actions>];
export default GantProvider;

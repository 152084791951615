import styled from '@emotion/styled/macro';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import {
  Checkbox as MuiCheckbox,
  css,
  FormControlLabel,
  Typography,
} from '@mui/material';
import PrimaryButton from 'core/components/buttons/primary-button';
import { defaultTheme } from 'theme/theme';

interface ICard {
  company: string;
  isSelected: boolean;
}

export const Card = styled.div<ICard>`
  width: ${(props) => (props.isSelected ? '120px' : '100%')} !important;
  height: ${(props) => (props.isSelected ? '60px' : '100%')} !important;
  box-shadow: 0px 3px 6px ${defaultTheme.colors.boxShadow};
  border-radius: 5px;
  position: relative;
  padding: 13px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;

  &:hover {
    cursor: pointer;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background-color: ${(props) =>
      props.company === 'arch'
        ? defaultTheme.colors.accentTeal
        : defaultTheme.colors.accentPink};
    border-radius: 5px 5px 0 0;
  }
`;

export const TaskHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  flex-wrap: wrap;
`;

export interface ITaskTitle {
  company: string;
  isSelected?: boolean;
}

export const TaskTitle = styled(Typography)<ITaskTitle>`
  && {
    background-color: ${(props) =>
      props.company === 'arch'
        ? defaultTheme.colors.accentTeal
        : defaultTheme.colors.accentPink};
    padding: 1px 15px;
    font-weight: bold;
    border-radius: 8px;
    color: ${defaultTheme.colors.textPrimary};
    margin-bottom: 8px;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

export const HeaderIcons = styled.div`
  display: flex;
  align-items: center;
  margin-right: -10px;
  margin-top: -4px;
`;

export const Logo = styled.img`
  width: 32px;
  margin-right: 15px;
`;

export const TaskContent = styled.div`
  width: 100%;
`;

export const TicketFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  // margin-top: 24px;
  margin-top: 10px;
`;

export const TaskStoryPoints = styled.div`
  display: flex;
  align-items: center;
`;

export const WatchIcon = styled(WatchLaterIcon)`
  && {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;

export const StoryPoint = styled(Typography)`
  font-weight: 500;
`;

export const TaskSchedule = styled.div``;

export const DragIcon = styled(DragIndicatorIcon)`
  color: #b2b2b2;
`;

export const Checkbox = styled(MuiCheckbox)`
  padding: 0;
`;

export const FormLabel = styled(FormControlLabel)`
  margin: 0;
`;

export const Link = styled.a`
  color: ${defaultTheme.colors.textSecondary};
  text-decoration: none;
  font-size: 0.875em;
  text-transform: uppercase;
  font-weight: 500;
`;

export const ViewButton = styled(PrimaryButton)`
  && {
    padding: 0;
    text-align: left;
    align-items: start;
    justify-content: start;
    letter-spacing: normal;
    text-transform: none;
    font-weight: normal;
  }
`;

export const DragIconContainer = styled.div``;

import { DraggableProvided } from 'react-beautiful-dnd';
import { useState } from 'react';
import StatsTable from 'statistics/components/stats-table/stats-table';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { FavouriteStar } from 'schedule/search/components/favourite-filter/favourite-star';
import useEventTypeProvider, {
  PageEnum,
} from 'schedule/core/providers/event-type-provider';
import useDragDropStore from 'schedule/core/store/drag-drop/drag-drop.store';

import * as S from 'schedule/search/components/available-pot/available-pot.styles';
import { TaskmanagerPotDto } from 'gateway-api';

interface ITasksProps {
  pot: TaskmanagerPotDto;
  provided: DraggableProvided;
  isSelected?: boolean;
}

export const AvailablePot = ({ pot, provided, isSelected }: ITasksProps) => {
  const { page } = useEventTypeProvider();
  const [selectedPot, setSelectedPot] = useState('');
  const setDraggablePot = useDragDropStore((store) => store.setDraggablePot);

  const [open, setOpen] = useState(false);

  const handleShow = (id: string) => {
    setOpen(!open);
    setSelectedPot(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (isSelected) {
    return (
      <>
        <S.Card
          company={pot.category}
          isSelected
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <S.TaskHeader>
            <S.TaskTitle company={pot.category} isSelected>
              {pot.label}
            </S.TaskTitle>
          </S.TaskHeader>
        </S.Card>
      </>
    );
  }

  return (
    <>
      <S.Card company={pot.category} isSelected={false}>
        <S.TaskHeader>
          <S.TaskTitle company={pot.category}>{pot.label}</S.TaskTitle>

          <S.HeaderIcons onMouseDown={handleClose}>
            <FavouriteStar potId={pot.id} />
            <S.Logo src='images/jira_logo_icon.png' />
            <S.DragIconContainer
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <S.DragIcon onMouseDown={() => setDraggablePot(pot)} />
            </S.DragIconContainer>
          </S.HeaderIcons>
          <S.TaskContent>{pot.name}</S.TaskContent>
        </S.TaskHeader>
        <S.TicketFooter>
          {page === PageEnum.SCHEDULE || page === PageEnum.ESTIMATE ? (
            <S.ViewButton variant='text' onClick={() => handleShow(pot.id)}>
              {open ? (
                <>
                  <ArrowDropDownIcon /> Close
                </>
              ) : (
                <>
                  <ArrowRightIcon /> View More
                </>
              )}
            </S.ViewButton>
          ) : (
            <S.TaskStoryPoints>
              <S.WatchIcon />
              <S.StoryPoint>{pot.hours_spent} hours</S.StoryPoint>
            </S.TaskStoryPoints>
          )}
          <S.TaskSchedule>
            <S.Link rel='noreferrer' target='_blank' href={pot.remote_url}>
              View on Jira
            </S.Link>
          </S.TaskSchedule>
        </S.TicketFooter>
        {page === PageEnum.SCHEDULE || page === PageEnum.ESTIMATE ? (
          <>{open ? <StatsTable custom pot_id={selectedPot} /> : <></>}</>
        ) : (
          <></>
        )}
      </S.Card>
    </>
  );
};

/**
 * Module dependencies.
 */

import styled from '@emotion/styled/macro';

export const Container = styled.div`
  height: calc(100vh - 40px);
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  & > div:last-of-type {
    width: 250px;
  }
`;

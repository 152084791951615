import ProgressBar from 'core/components/progress-bar';
import JiraStatsTable from 'statistics/components/jira-stats-table';
import CustomPieChart from 'statistics/components/pie-chart';
import { useTranslation } from 'react-i18next';
import Skeleton from '@mui/material/Skeleton';
import CustomScatterGraph, {
  IScatterGraphData,
} from 'statistics/components/scatter-graph';
import { Typography } from '@mui/material';
import CustomAttributeTable from 'statistics/components/stats-custom-attributes/stats-custom-attributes';
import StatsCostTable from 'statistics/components/stats-cost-table';
import StatsTimeLine from 'statistics/components/stats-timeline/stats-timeline';
import * as S from 'statistics/components/task-details-statistics/task-details-statistics.style';
import {
  TaskmanagerPotDto,
  TaskmanagerPotStatTimelineCollectionDto,
} from 'gateway-api';
import { useShowPotStats } from 'statistics/hooks/use-pots-statistics';
import { potStatKeys } from 'schedule/core/queries/pot/pot.keys';

interface ITableRow {
  pot_name: string;
  estimate_hours?: number;
  schedule_hours?: number;
}

interface ITaskDetailsProp {
  pot?: TaskmanagerPotDto;
}

const TaskDetailsStats = ({ pot }: ITaskDetailsProp): JSX.Element => {
  const { t } = useTranslation();

  const queryKey = potStatKeys.showPot({
    id: pot?.id ?? '',
  });

  const { data: potStats, isLoading: indexPotsLoading } =
    useShowPotStats(queryKey);

  // const [showMoreStats, setShowMoreStats] = useState(false);

  const startDate = new Date(potStats?.estimate_date_start as string);
  const endDate = new Date(potStats?.estimate_date_end as string);

  // const handleShow = () => {
  //   setShowMoreStats(true);
  // };

  let SpentEstimateTime: number;
  let SpentEstimateCost: number;

  let Data:
    | {
        x: number;
        y: number;
      }[]
    | undefined;

  if (potStats?.complete_hours_total && potStats.estimate_hours_total) {
    SpentEstimateTime =
      (potStats.complete_hours_total / potStats.estimate_hours_total) * 100;
  } else {
    SpentEstimateTime = 0;
  }

  if (potStats?.complete_costs && potStats.estimate_costs) {
    SpentEstimateCost =
      (potStats.complete_costs / potStats.estimate_costs) * 100;
  } else {
    SpentEstimateCost = 0;
  }

  if (SpentEstimateTime && SpentEstimateCost > 0) {
    Data = [
      {
        x: Number(SpentEstimateTime.toFixed(0)),
        y: Number(SpentEstimateCost.toFixed(0)),
      },
    ];
  }

  const estimateData = potStats?.estimate_time_pot_totals;
  const scheduleData = potStats?.schedule_time_pot_totals;

  const handleShowData = () => {
    let estimateArray: ITableRow[];
    let scheduleArray: ITableRow[];
    estimateArray = [];
    scheduleArray = [];
    estimateData?.forEach((estimate) => {
      estimateArray = estimateArray.concat({
        pot_name: estimate.pot_name,
        estimate_hours: estimate.hours,
        schedule_hours: 0,
      });
    });

    scheduleData?.forEach((schedule) => {
      scheduleArray = scheduleArray.concat({
        pot_name: schedule.pot_name,
        estimate_hours: 0,
        schedule_hours: schedule.hours,
      });
    });
  };

  return (
    <>
      {indexPotsLoading ? (
        <Skeleton animation='wave' height={300} />
      ) : (
        <S.TaskStats /* onClick={handleShow} */ onClick={handleShowData}>
          <S.TaskTitles>
            <>
              <S.StyledTaskLabel>{pot?.label}</S.StyledTaskLabel>
              <S.StyledTaskTitle variant='h2'>{pot?.name}</S.StyledTaskTitle>
            </>
          </S.TaskTitles>

          {potStats ? (
            <>
              {potStats.estimate_date_start ? (
                <>
                  <S.StyledProgressBar>
                    {potStats.estimate_hours_total &&
                    potStats.complete_hours_total ? (
                      <ProgressBar
                        percentComplete={
                          (((potStats.complete_hours_total as number) /
                            potStats.estimate_hours_total) as number) * 100
                        }
                        bgColor='#F27777'
                      />
                    ) : (
                      <ProgressBar percentComplete={0} bgColor='#F27777' />
                    )}
                  </S.StyledProgressBar>
                  <S.TaskDateRange>
                    <Typography>
                      Estimated Start:{' '}
                      {startDate.toLocaleString('en-GB', {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                      })}
                    </Typography>
                    <Typography>
                      Estimated End:{' '}
                      {endDate.toLocaleString('en-GB', {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                      })}
                    </Typography>
                  </S.TaskDateRange>
                </>
              ) : (
                <></>
              )}

              <S.FlexFullCol>
                <S.SectionTitles variant='h3'>
                  {t('project_statistics.stats.breakdown')}
                </S.SectionTitles>
                {potStats ? (
                  <S.FlexContainer>
                    <S.BreakDownTable>
                      <CustomAttributeTable data={potStats} />
                    </S.BreakDownTable>
                  </S.FlexContainer>
                ) : (
                  <></>
                )}
                <S.FlexThreeCol>
                  <S.FlexThreeBox>
                    {potStats.estimate_time_pot_totals.length ? (
                      <S.Chart>
                        <Typography variant='h3'>
                          {t('project_statistics.stats.estimated')}
                        </Typography>
                        <CustomPieChart
                          chartData={potStats.estimate_time_pot_totals}
                        />
                      </S.Chart>
                    ) : (
                      <></>
                    )}
                  </S.FlexThreeBox>
                  <S.FlexThreeBox>
                    {potStats.complete_time_pot_totals.length ? (
                      <S.Chart>
                        <Typography variant='h3'>
                          {t('project_statistics.stats.recorded')}
                        </Typography>
                        <CustomPieChart
                          chartData={potStats.complete_time_pot_totals}
                        />
                      </S.Chart>
                    ) : (
                      <></>
                    )}
                  </S.FlexThreeBox>
                  <S.FlexThreeBox>
                    {potStats.estimate_hours_total ||
                    potStats.complete_hours_total ? (
                      <S.FlexContainer>
                        <S.FlexCol>
                          {Data !== undefined ? (
                            <CustomScatterGraph
                              graphData={Data as unknown as IScatterGraphData[]}
                            />
                          ) : (
                            <></>
                          )}
                        </S.FlexCol>
                      </S.FlexContainer>
                    ) : (
                      <></>
                    )}
                  </S.FlexThreeBox>
                </S.FlexThreeCol>
              </S.FlexFullCol>

              <S.FlexBoxRow>
                <S.FlexTwoCol>
                  <S.SectionTitles variant='h3'>
                    {t('project_statistics.stats.project_cost')}
                  </S.SectionTitles>
                  <S.BreakDownTable>
                    <StatsCostTable data={potStats} />
                  </S.BreakDownTable>
                </S.FlexTwoCol>

                <S.FlexTwoCol>
                  <S.TitleGroup>
                    <img
                      src='images/jira_logo_icon.png'
                      alt='Jira Logo'
                      width={32}
                    />
                    <S.SectionTitles variant='h3'>
                      Work Completed
                    </S.SectionTitles>
                  </S.TitleGroup>

                  <S.BreakDownTable>
                    <JiraStatsTable data={potStats.progress} />
                  </S.BreakDownTable>
                </S.FlexTwoCol>
              </S.FlexBoxRow>

              <S.FlexFullCol>
                <S.SectionTitles variant='h3'>Hours estimated:</S.SectionTitles>
                <S.TaskTimelinesContainer>
                  {potStats?.estimate_timeline.length ? (
                    <S.TimelineCol>
                      <Typography variant='h3'>
                        {t('project_statistics.stats.time_estimated')}
                      </Typography>

                      <StatsTimeLine
                        timelineData={potStats?.estimate_timeline}
                      />
                    </S.TimelineCol>
                  ) : (
                    <S.NoData>No Estimated Time</S.NoData>
                  )}
                  {potStats?.complete_timeline.length ? (
                    <S.TimelineCol>
                      <Typography variant='h3'>
                        {t('project_statistics.stats.time_recorded')}
                      </Typography>
                      <StatsTimeLine
                        timelineData={
                          potStats?.complete_timeline as unknown as TaskmanagerPotStatTimelineCollectionDto[]
                        }
                      />
                    </S.TimelineCol>
                  ) : (
                    <S.NoData>No Recorded Time</S.NoData>
                  )}
                </S.TaskTimelinesContainer>
              </S.FlexFullCol>
            </>
          ) : (
            <S.NoData>There are no stats available</S.NoData>
          )}
        </S.TaskStats>
      )}
    </>
  );
};

export default TaskDetailsStats;

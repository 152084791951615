/**
 * Module dependencies.
 */

import { useTranslation } from 'react-i18next';
import CustomHelmet from 'core/components/custom-helmet';
import Scaffold from 'core/components/scaffolds/scaffold';
import useAuthUser from 'auth/hooks/use-auth-user';
import { DateRangeComponent } from 'date-range/components/date-range/date-range';
import { ScheduleBody } from 'schedule/core/components/schedule-body/schedule-body';
import { DragDropContext } from 'react-beautiful-dnd';
import { useDateRange } from 'date-range/store/date-range';
import useScheduleDragEnd from 'schedule/core/hooks/schedule-drag-end';
import EditTaskForm from 'schedule/core/components/edit-event-form/edit-event-form';
import ScheduleDelete from 'schedule/core/components/event-delete-button/event-delete-button';
import useDragDropStore from 'schedule/core/store/drag-drop/drag-drop.store';
import {
  EventTypeProvider,
  PageEnum,
} from 'schedule/core/providers/event-type-provider';
import { useScheduleMutation } from 'schedule/core/queries/schedule-move-mutation';
import { useDestroyEvent } from 'schedule/core/queries/schedule-destroy-event';
import ScheduleFilters from 'schedule/core/components/schedule-filters/schedule-filters';
import { AvailablePots } from 'schedule/search/components/available-pots/available-pots';
import { isInRole } from 'routes/protected-route';
import * as S from 'schedule/core/pages/schedule/schedule.style';
import useUserStore from 'schedule/core/store/users/users.store';
import { IIndexSchedule } from 'schedule/core/queries/schedule.keys';
import { toApiDate } from 'core/hooks/queries/schedule/use-index-schedule';
import { useScheduleClone } from 'schedule/core/queries/clone-event-mutation';
import { TaskmanagerEventTypeEnum } from 'gateway-api';
import useEventTypeStore from 'schedule/core/store/event-type/event-type.store';
import { useEffect } from 'react';

const Schedule = (): JSX.Element => {
  // set event type based on page
  const {
    state: { eventType },
    setEventType,
  } = useEventTypeStore();

  const user = useAuthUser();
  const { t } = useTranslation();
  const {
    onBeforeCapture,
    state: {
      draggableEvent,
      draggablePot,
      addPot,
      editEventEntry,
      isCloneKeyDown,
    },
    setAddPot,
    clear,
  } = useDragDropStore();

  const {
    state: { endDate, startDate, dateRangeType },
  } = useDateRange();

  const {
    state: { users },
  } = useUserStore();

  const indexScheduleParams: IIndexSchedule = {
    endDate: toApiDate(endDate.toJSDate()),
    startDate: toApiDate(startDate.toJSDate()),
    eventType: [TaskmanagerEventTypeEnum.Schedule],
    dateRangeType,
    selectedUsers: users,
  };

  const { mutate: destroyEvent } = useDestroyEvent(indexScheduleParams);
  // const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useScheduleMutation(indexScheduleParams);
  const { mutate: mutateClone } = useScheduleClone(indexScheduleParams);

  const onDragEnd = useScheduleDragEnd({
    user,
    event: draggableEvent,
    pot: draggablePot,
    isCloneKeyDown,
    page: PageEnum.SCHEDULE,
    handlePatch(event) {
      if (!event) return;
      mutate(event, {
        onSuccess(data, variables, context) {
          if (context.existingEvent) {
            destroyEvent(context.existingEvent);
          }
        },
      });
    },
    handleCreate(pot) {
      setAddPot(pot);
    },
    handleDelete(event) {
      destroyEvent(event);
    },
    handleClone() {},
    handleCloneSameSchedule(event) {
      mutateClone(event);
    },
    onAbort: clear,
  });

  useEffect(() => {
    setEventType([TaskmanagerEventTypeEnum.Schedule]);
  }, [setEventType]);

  return (
    <>
      <CustomHelmet pageName='Schedule' />
      <Scaffold headerTitle='Schedule' pageTitle='Schedule'>
        <DragDropContext
          onDragEnd={onDragEnd}
          onBeforeCapture={onBeforeCapture}
        >
          <EventTypeProvider
            scheduleParams={indexScheduleParams}
            page={PageEnum.SCHEDULE}
          >
            <DateRangeComponent />

            <S.MainNavContainer>
              <ScheduleFilters />

              <ScheduleDelete visible />
            </S.MainNavContainer>

            <ScheduleBody />

            <S.StyledPopup
              active={!!addPot || !!editEventEntry}
              closePopup={clear}
              width='800px'
            >
              {addPot ? (
                <EditTaskForm pot={addPot} handleClose={clear} />
              ) : (
                <EditTaskForm event={editEventEntry} handleClose={clear} />
              )}
            </S.StyledPopup>

            <AvailablePots
              visible={isInRole(user?.roles?.taskmanager, ['admin'])}
            />
          </EventTypeProvider>
        </DragDropContext>
      </Scaffold>
    </>
  );
};

export default Schedule;

import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { Typography } from '@mui/material';
import { useDateRangeContext } from 'core/store/date-range-provider';
import { DateRangeType } from 'core/hooks/use-date-range';
import ProjectTag from 'gantt/components/project-tag/project-tag';
import * as S from 'gantt/components/project-item/project-item.style';
import { TaskmanagerPotGanttDto } from 'gateway-api';

interface IProjectViewProps {
  project: TaskmanagerPotGanttDto;
  columnWidth: number;
  children?: React.ReactNode;
  labelWidth: number;
  zIndex: number;
}
const ProjectItem = ({
  project,
  labelWidth,
  columnWidth,
  children,
  zIndex,
}: IProjectViewProps): JSX.Element => {
  const { dateRange, dateRangeType } = useDateRangeContext();
  const start = DateTime.fromISO(project.date_start.slice(0, -9));
  const end = DateTime.fromISO(project.date_end.slice(0, -9));
  const dateRangeEnd = DateTime.fromJSDate(dateRange?.endDate ?? new Date());
  const dateRangeStart = DateTime.fromJSDate(
    dateRange?.startDate ?? new Date(),
  );

  /** True if the project expands before the current view */
  let expandsBefore = false;

  /** True if the project expands after the current view */
  let expandsAfter = false;

  /** The number of days +/- from monday of the current week. */
  const startDay = useMemo(() => {
    // if the start of the project is the before the start of the current view
    // and the end is still in view
    if (start < dateRangeStart && end > dateRangeStart) {
      return 0;
    }

    if (dateRange) {
      const weekStart = DateTime.fromJSDate(dateRange.startDate);
      const projectStartDay = start.diff(weekStart, 'days');
      // if year view
      if (dateRangeType === DateRangeType.year) {
        // make project start in the middle of the week for the year view
        return Math.floor(projectStartDay.days / 7) + 0.5;
      }
      return projectStartDay.days;
    }
    return 0;
  }, [dateRange, dateRangeStart, dateRangeType, end, start]);

  /** Total project length in days */
  const projectDays = (() => {
    // relative start date, if before the current view ignore off screen days
    let startRel = start;
    // if the start of the project is the before the start of the current view
    // and the end is still in view
    if (start < dateRangeStart && end > dateRangeStart) {
      expandsBefore = true;
      startRel = dateRangeStart;
    }

    // If the end date expands past the current view
    if (end > dateRangeEnd) {
      expandsAfter = true;
      return dateRangeEnd.endOf('day').diff(startRel.startOf('day'), 'days')
        .days;
    }
    return end.endOf('day').diff(startRel.startOf('day'), 'days').days;
  })();

  // Add padding to start and end of the projects
  const projectWidth = (() => {
    // If start is after the current view
    if (dateRange && startDay >= dateRange.scheduleDates.length) {
      return 0;
    }

    let fullWidth = columnWidth * projectDays - 1;
    // For full year the bar starts and ends mid week
    if (dateRangeType === DateRangeType.year) {
      return fullWidth / 7;
      // return fullWidth - columnWidth;
    }

    if (!expandsAfter) {
      fullWidth -= 14;
    }
    if (!expandsBefore) {
      fullWidth -= 14;
    }
    return fullWidth;
  })();

  if (end <= dateRangeStart || start >= dateRangeEnd) {
    // project should not be visible
    return <></>;
  }

  return (
    <>
      <S.ProjectBox
        $zIndex={zIndex}
        $dateType={dateRangeType}
        $expandsAfter={expandsAfter}
        $expandsBefore={expandsBefore}
        $width={projectWidth}
        $start={startDay * columnWidth + labelWidth}
      >
        <S.HoverArea>
          <S.ProjectName variant="h5">{children}</S.ProjectName>
        </S.HoverArea>
        <S.Popup>
          <S.PopupBody>
            <ProjectTag hideClose project={project} />
            <Typography variant="h6">
              Start date: <span>{start.toFormat('DDD')}</span>
            </Typography>
            <Typography variant="h6">
              End date: <span>{end.toFormat('DDD')}</span>
            </Typography>
          </S.PopupBody>
        </S.Popup>
      </S.ProjectBox>
    </>
  );
};

export default ProjectItem;

import { api } from 'core/services/api.service';
import { QueryFunctionContext, useQuery } from 'react-query';

import { userKeys } from 'user/queries/user.keys';

type ShowUser = ReturnType<typeof userKeys['showUser']>;

const showUser = async ({
  queryKey: [{ user }],
}: QueryFunctionContext<ShowUser>) => {
  const { data } = await api.gatewayUserService.showUser(user as string);
  return data;
};

export const useShowUser = (queryKey: ShowUser) => useQuery(queryKey, showUser);

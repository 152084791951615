import useEventTicketStore from 'schedule/core/store/resizable/resize.store';
import * as S from 'schedule/core/components/ticket-content/ticket-content.styles';
import { TaskmanagerEventDto } from 'gateway-api';

interface ITicketContent {
  event: TaskmanagerEventDto;
  isLoading: boolean;
  isUnassigned: boolean;
}

export const TicketContent = ({
  event,
  isLoading,
  isUnassigned,
}: ITicketContent) => {
  const isDragDisabled = false;
  const handleOpen = () => {};

  const stateEvent = useEventTicketStore((store) => store.state.event);
  const isResizing = useEventTicketStore((store) => store.isResizing);

  if (
    isResizing(event) &&
    event.pots_by_type[`${event.time_pot?.pot_type.name}`]
  ) {
    return (
      <>
        {isLoading && <S.Loading size={20} />}
        {!isLoading && isUnassigned && (
          <S.UnassignedButton onClick={handleOpen} disabled={isDragDisabled}>
            <p>
              <p>{event.time_pot?.parent_pot?.label}</p>
            </p>
          </S.UnassignedButton>
        )}
        {!isLoading && !isUnassigned && (
          <>
            <S.Tag>
              {event.pots_by_type[`${event.time_pot?.pot_type.name}`].name}
            </S.Tag>
            <p>
              <p>{event.time_pot?.parent_pot?.label}</p>
            </p>
            <S.TaskHours>
              {stateEvent ? stateEvent.hours : event.hours} hrs
            </S.TaskHours>
          </>
        )}
      </>
    );
  }

  return (
    <>
      {isLoading && <S.Loading size={20} />}
      {!isLoading &&
        isUnassigned &&
        event.pots_by_type[`${event.time_pot?.pot_type.name}`] && (
          <S.UnassignedButton onClick={handleOpen} disabled={isDragDisabled}>
            <p>
              <p>{event.time_pot?.parent_pot?.label}</p>
            </p>
          </S.UnassignedButton>
        )}
      {!isLoading &&
        !isUnassigned &&
        event.pots_by_type[`${event.time_pot?.pot_type.name}`] && (
          <>
            <S.Tag>
              {event?.pots_by_type[`${event.time_pot?.pot_type.name}`].name}
            </S.Tag>
            <p>{event.time_pot?.parent_pot?.label}</p>
            <S.TaskHours>{event.hours} hrs</S.TaskHours>
          </>
        )}
    </>
  );
};

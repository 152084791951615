import DateRangeType from 'date-range/models/date-range-type.enum';
import { TaskmanagerEventTypeEnum } from 'gateway-api';

export interface IIndexSchedule {
  eventType: TaskmanagerEventTypeEnum[];
  startDate: string;
  endDate: string;
  dateRangeType: DateRangeType;
  selectedUsers?: string[];
}

export const scheduleKeys = {
  base: [{ scope: 'schedule' }] as const,
  index: () => [{ ...scheduleKeys.base[0], entity: 'index' }] as const,
  indexSchedule: (query: IIndexSchedule) =>
    [
      {
        ...scheduleKeys.index()[0],
        ...query,
      },
    ] as const,
};

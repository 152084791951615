import i18n, { BackendModule, Resource, ResourceLanguage } from 'i18next';

const cachedLang = (() => {
  const loaded: { [key: string]: ResourceLanguage } = {};
  const loading: { [key: string]: Promise<ResourceLanguage> } = {};
  return (lang: string): Resource | Promise<Resource> => {
    if (loaded[lang]) {
      return { [lang]: loaded };
    }
    if (!loading[lang]) {
      loading[lang] = import(
        /* webpackPrefetch: true */ /* webpackChunkName: "i18n/locale" */ `./locales/${lang}`
      );
    }
    return loading[lang].then((res) => {
      const { common, ...rest } = res;
      loaded[lang] = { ...rest, ...(common as ResourceLanguage) };
      return { [lang]: loaded[lang] };
    });
  };
})();

export const asyncBackend: BackendModule = {
  type: 'backend',
  init() {},
  read(language, namespace, callback) {
    const data = cachedLang(language);
    if (data instanceof Promise) {
      return data.then((res) => {
        Object.keys(res).forEach((lang) => {
          Object.keys(res[lang]).forEach((ns) => {
            i18n.addResourceBundle(lang, ns, res[lang][ns]);
          });
        });
        callback(null, res[language][namespace]);
      });
    }
    return callback(null, data[language][namespace]);
  },
};

/**
 * Module dependencies
 */

import { TaskmanagerPotStatUserDto } from 'gateway-api';
import { uniqueId } from 'lodash';
import React from 'react';
import * as S from 'schedule/core/components/assigned-users/assigned-users.style';

interface IProps {
  usersData: TaskmanagerPotStatUserDto[];
}

const limitOfPeople = 3;

const AssignedUsers = (props: IProps) => {
  const { usersData } = props;
  const usersMap = [
    ...new Set(
      usersData.map(
        (value) =>
          `${value.user_directory?.first_name} ${value.user_directory?.last_name}`,
      ),
    ),
  ];

  return (
    <>
      {usersMap.map((value, key) => {
        if (key < limitOfPeople) {
          return (
            <S.TicketEventUser
              key={uniqueId(`${key}`)}
              avatarWidth='44px'
              avatarHeight='44px'
              initials={`${value.split(' ')[0].charAt(0)}${value
                .split(' ')[1]
                .charAt(0)}`}
            />
          );
        }

        if (key === limitOfPeople) {
          return (
            <S.TicketEventUser
              key={uniqueId(`${key}`)}
              avatarWidth='44px'
              avatarHeight='44px'
              initials={`+${usersData.length - limitOfPeople}`}
            />
          );
        }

        return <React.Fragment key={uniqueId(`${key}`)} />;
      })}
    </>
  );
};

export default AssignedUsers;

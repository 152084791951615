import styled from '@emotion/styled/macro';
import { css } from '@mui/material';

export const UserSchedule = styled.li<{ multiView?: boolean }>`
  min-height: 118px;
  &:nth-of-type(even) {
    background: #f7f7f7;
  }

  ${(props) =>
    props.multiView &&
    css`
      border-bottom: 2px solid black;
    `}
`;

export const UserScheduleItems = styled.ul<{
  backgroundColor: boolean;
  multiView: boolean;
}>`
  display: flex;
  height: 100%;
  justify-content: space-between;
  list-style: none;
  counter-reset: date;
  background-color: transparent;

  ${({ backgroundColor, multiView }) => {
    if (backgroundColor && multiView) {
      return css`
        background-color: #f7f7f7;
      `;
    }
    if (!backgroundColor && multiView) {
      return css`
        background-color: #ffffff;
      `;
    }

    return css`
      background-color: transparent;
    `;
  }}
`;

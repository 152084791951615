/**
 * Module dependencies.
 */

import styled from '@emotion/styled/macro';

export const DeleteDroppable = styled.div<{ isVisible: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 0;
  justify-content: center;
  height: 65px;
  border-radius: 4px;
  width: 300px;
  margin: 0 0 0 auto;
  text-align: center;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  background: #c7c7c7;
  transition: 0.4s ease;

  .MuiSvgIcon-root {
    font-size: 33px;
  }

  &:hover,
  &:focus {
    background-color: #f7f7f7;
  }
`;

import useDebounce from 'core/hooks/use-debounce';
import { ChangeEvent } from 'react';
import { Switch } from '@mui/material';
import * as S from 'schedule/search/components/search/search.styles';
import { useSearchStore } from 'schedule/search/store/search/search.store';

export const Search = () => {
  const { state, setSearch, setJqlSearch, setJiraQuery, setPageNumber } =
    useSearchStore();

  // Passing search string into debounce
  const searchDebounce = useDebounce((value) => {
    setSearch(value);
    setPageNumber(1);
  });

  // Passing search string into debounce
  const jqlDebounce = useDebounce((value) => {
    setJiraQuery(value);
    setPageNumber(1);
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setJqlSearch(e.target.checked);
  };

  return (
    <>
      <Switch checked={state.jql_search} onChange={handleChange} />
      {state.jql_search ? (
        <S.RecentSearchContainer>
          <S.SearchList
            onSearchChange={(query) => {
              jqlDebounce(query);
            }}
            placeholder='project = TRP AND Feature journey stage[Dropdown] = Ready for development order by created DESC'
            width='1000px'
            defaultValue=''
          />
        </S.RecentSearchContainer>
      ) : (
        <S.RecentSearchContainer>
          <S.SearchList
            placeholder='Search List'
            onSearchChange={(query) => {
              searchDebounce(query);
              setPageNumber(1);
            }}
            defaultValue={state.search}
          />
        </S.RecentSearchContainer>
      )}
    </>
  );
};

import { Navigate, useParams } from 'react-router-dom';
import { UserDto } from 'sso-api';
import useAuth from 'auth/hooks/use-auth';
import StoreStatus from 'core/models/store-status.enum';
import RoutePath from 'routes/route-paths';

/**
 * Is In Role
 *
 * This function is used to check if the logged in user is in the specified role.
 *
 * @param userRoles - string[] of the logged in users roles.
 * @param requiredRoles - string[] of the roles to check permissions for.
 * @returns True or False depending on if the required roles contains any of the logged in users roles.
 */
export const isInRole = (
  userRoles: string[] | undefined,
  requiredRoles: string[] | undefined,
): boolean => {
  if (!userRoles && requiredRoles?.length) return false;
  if (requiredRoles && requiredRoles.length && userRoles && userRoles.length) {
    return userRoles.filter((role) => requiredRoles.includes(role)).length > 0;
  }
  return true;
};

export interface IProtectedRoute {
  children: React.ReactNode;
  routeGuard?: (user: UserDto, params: Record<any, any>) => boolean;
  requiredRoles?: string[];
}

/**
 * Protected Route
 *
 * This component is used to protect routes against none authenticated users as well as users that don't have permissions to access the resource.
 *
 * @returns The requested page if the user authenticated and has the specified role. If the user is not authenticated then they are redirected to the login page. If the user is authenticated but doesn't have the correct permissions they are redirected to the 403 (Forbidden) page.
 */
const ProtectedRoute = ({
  children,
  routeGuard,
  requiredRoles,
}: IProtectedRoute): JSX.Element => {
  const [{ user, status }] = useAuth();

  if (user) {
    if (isInRole(user.roles?.taskmanager, requiredRoles)) {
      return <>{children}</>;
    }
    return <Navigate to={{ pathname: RoutePath.FORBIDDEN }} />;
  }

  // User is loading
  if (status === StoreStatus.loading) return <></>;

  // The user is not logged in
  return <Navigate to={{ pathname: RoutePath.HOME }} />;
};

export default ProtectedRoute;

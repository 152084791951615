import useTagSearch from 'gantt/hooks/gant-view/use-tag-search';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import TaggingSearch, { ISearchOption } from 'core/components/tagging-search';

interface ISearchTagProps {
  setTags: Dispatch<SetStateAction<string | undefined>>;
  setProjectSearch: Dispatch<SetStateAction<string | undefined>>;
  setTagSearch: Dispatch<SetStateAction<string | undefined>>;
  setPage: Dispatch<SetStateAction<number>>;
}

const StatsSearch = ({
  setTags,
  setProjectSearch,
  setTagSearch,
  setPage,
}: ISearchTagProps): JSX.Element => {
  const { t } = useTranslation();

  const [search, { data }] = useTagSearch();

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: ISearchOption[],
  ) => {
    if (e.code === 'Enter') {
      e.stopPropagation();
    }
  };

  return (
    <TaggingSearch
      placeholder={t('project_statistics.search_placeholder')}
      showTags={false}
      freeSolo
      onChange={(_, __, ___, details) => {
        if (details?.option.label) {
          setTags(details.option.label);
          setProjectSearch(details.option.label);
          setTagSearch(details.option.label);
          setPage(1);
        }
      }}
      // Stop enter from loosing focus. It doesn't need to search.
      onKeyDown={handleKeyDown}
      options={data?.map((value) => ({ id: value, label: value })) ?? []}
      onInputChange={(event, value) => {
        search(value);
      }}
    />
  );
};

export default StatsSearch;

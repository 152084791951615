import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { api } from 'core/services/api.service';

const useIndexPotsStatisticsTag = (key: string) => {
  const {
    data: indexPotsResponse,
    isLoading: indexPotsLoading,
    refetch,
  } = useQuery(
    ['pot-stats-tag', key],
    () =>
      api.potStatService.indexTaskmanagerPotStat({
        params: {
          tags: key,
        },
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    ...indexPotsResponse?.data,
    indexPotsLoading,
    refetch,
  };
};

export default useIndexPotsStatisticsTag;

import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useMemo, useState } from 'react';
import { isInRole } from 'routes/protected-route';
import useAuthUser from 'auth/hooks/use-auth-user';
import { UserDto } from 'sso-api';
import { useStoreNotes } from 'schedule/notes/queries/notes/use-store-notes';
import { useModifyNotes } from 'schedule/notes/queries/notes/use-modify-notes';

import * as S from 'schedule/notes/components/notes/notes.styles';
import * as yup from 'yup';
import { TaskmanagerNoteDto, TaskmanagerNoteRequestDto } from 'gateway-api';

interface INoteProps {
  date: string;
  icon: React.ReactNode;
  note?: TaskmanagerNoteDto;
}

export const Notes = ({ date, icon, note }: INoteProps) => {
  const { roles } = useAuthUser() as UserDto;
  const isAdmin = isInRole(roles?.taskmanager, ['admin']);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { mutate: store } = useStoreNotes();
  const { mutate: modify } = useModifyNotes();

  const validations = useMemo(
    () =>
      yup.object({
        message: yup.string().required('Notes is required'),
      }),
    [],
  );

  const handleNoteSubmit = (noteEdit: TaskmanagerNoteRequestDto) => {
    if (note) {
      modify(
        { id: note.id, note: noteEdit },
        { onSuccess: () => setAnchorEl(null) },
      );
    } else {
      store(noteEdit as TaskmanagerNoteRequestDto, {
        onSuccess: () => setAnchorEl(null),
      });
    }
  };

  // button text
  const buttonText = note?.id ? 'SAVE' : 'ADD';

  return (
    <>
      <S.IconButton size='small' aria-describedby={id} onClick={handleOpen}>
        {icon}
      </S.IconButton>
      <ClickAwayListener onClickAway={handleClose}>
        <>
          <S.NotePopover
            open={open}
            id={id}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <S.NoteForm
              onSubmit={(data) =>
                handleNoteSubmit(data as TaskmanagerNoteRequestDto)
              }
              initialValues={{ ...note, date }}
              validations={validations}
            >
              <S.NoteBody
                id='message'
                label='Notes'
                multiline
                placeholder=''
                minRows={2}
                maxRows={3}
                disabled={!isAdmin}
              />
              {isAdmin && (
                <S.SaveButton variant='contained' type='submit'>
                  {buttonText}
                </S.SaveButton>
              )}
            </S.NoteForm>
          </S.NotePopover>
        </>
      </ClickAwayListener>
    </>
  );
};

import styled from '@emotion/styled/macro';

export const CalendarContainer = styled.ul<{ isCloning: boolean }>`
  display: flex;
  flex-direction: column;
  list-style: none;
  transition: flex-grow 1000ms linear;
  padding-bottom: 60px;
  cursor: ${(props) => (props.isCloning ? 'copy' : 'default')};
`;

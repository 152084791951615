import LuggageOutlinedIcon from '@mui/icons-material/LuggageOutlined';
import * as S from 'schedule/core/components/event-type-workcation/event-type-workcation.styles';

interface IEventTypeWorkcation {
  width: number;
}

export const EventTypeWorkcation = ({ width }: IEventTypeWorkcation) => (
  <S.WorkcationContainer width={width}>
    <LuggageOutlinedIcon />
  </S.WorkcationContainer>
);

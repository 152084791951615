/**
 * Module dependencies.
 */

import styled from '@emotion/styled/macro';
import { Typography } from '@mui/material';
import { defaultTheme } from 'theme/theme';

export const ContentSection = styled.section`
  display: flex;

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

export const ContentArticle = styled.article`
  background-color: #ffffff;
  padding: 30px 35px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  flex: 2;

  &.userInfo {
    flex: 1;
    margin-right: 60px;
    padding: 45px 20px;

    .MuiBadge-root {
      display: flex;
      justify-content: center;
    }

    ${(props) => props.theme.breakpoints.down('md')} {
      margin-right: 0;
      margin-bottom: 30px;
      padding: 35px 20px;

      .MuiAvatar-root {
        width: 47px;
        height: 47px;
        font-size: 1.5em;
      }
    }

    ${(props) => props.theme.breakpoints.up('lg')} {
      padding: 75px 60px;
    }
  }
`;

export const Username = styled(Typography)`
  font-size: 2em;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;
  font-weight: 500;

  ${(props) => props.theme.breakpoints.down('md')} {
    font-size: 1.5em;
    margin-top: 15px;
  }
`;

export const ProfileTitle = styled(Typography)`
  font-weight: bold;
  font-size: 1.125em;
  display: inline-block;
  margin-right: 5px;
`;

export const UserTags = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 45px;
`;

export const Tag = styled.div`
  background-color: #a0b1d3;
  margin-right: 15px;
  margin-bottom: 10px;
  padding: 4px 7px;
  border-radius: 16px;
  color: ${defaultTheme.colors.primaryColor};
  font-size: 0.875em;
`;

export const ProfileDetail = styled.span`
  font-size: 1em;
`;

export const ProfileDetailsList = styled.ul`
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  justify-content: space-between;

  li > span {
    text-transform: lowercase;
  }
`;

export const ProfileDetailsItem = styled.li`
  width: 48.5%;
  margin-bottom: 25px;

  &.itemFull {
    width: 100%;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 100%;
    margin-bottom: 15px;
  }
`;

export const UserRole = styled.span`
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

import { css } from '@mui/material';
import styled from '@emotion/styled/macro';

import DateRangeType from 'date-range/models/date-range-type.enum';
import { defaultTheme } from 'theme/theme';

export const Placeholder = styled.div``;

export const CalendarHeaderContainer = styled.li`
  height: 114px;
  &:nth-child(even) {
    background: #f7f7f7;
  }
  height: 50px;
`;

export const CalendarHeaderItems = styled.ul`
  display: flex;
  height: 100%;
  justify-content: space-between;
  list-style: none;
`;

// const UserScheduleItem = styled.li`
//   position: relative;
//   display: flex;
//   justify-content: center;
//   overflow: hidden;

//   align-items: center;
//   width: 100%;
//   border-right: 1px solid #07334a;
// `;

export const CalendarHeaderItem = styled.li<{
  width?: number;
  $dateType?: DateRangeType;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;

  align-items: center;
  border-right: 1px solid #07334a;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  flex: ${({ width }) => (width ? `0 0 ${width}px` : 'unset')};
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: -100vh;
    right: -1px;
    width: 10px;
    border-right: 1px dashed #07334a;
  }

  ${({ $dateType }) =>
    $dateType !== DateRangeType.month &&
    css`
      span {
        ${defaultTheme.breakpoints.down('md')} {
          font-size: 0;

          &:first-letter {
            font-size: 14px;
          }
        }
      }
    `};
`;

/* ${(props) => props.theme.breakpoints.down("md")} {
          font-size: 0;

          &:first-letter {
            font-size: 14px;
          }
        } */

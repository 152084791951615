import { Typography } from '@mui/material';
import AccountHeader from 'core/components/header/header';
import AccountContainer from 'core/components/layouts/account-container';
import styled from '@emotion/styled/macro';
import CustomHelmet from 'core/components/custom-helmet';

const MainSection = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #ffffff;
  margin: 40px 24px;
  padding: 30px 35px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  overflow-x: scroll;

  ${(props) => props.theme.breakpoints.up('lg')} {
    margin: 40px 60px;
  }
`;

const PageTitle = styled(Typography)`
  margin-bottom: 20px;

  ${(props) => props.theme.breakpoints.down('md')} {
    font-size: 18px;
  }
`;

interface IScaffold {
  headerTitle?: string;
  pageTitle?: string;
  children: React.ReactNode;
}

const Scaffold = ({ children, headerTitle, pageTitle }: IScaffold) => (
  <>
    <CustomHelmet pageName={headerTitle} />
    <AccountContainer>
      <AccountHeader headerTitle={headerTitle} />
      <MainSection>
        {pageTitle && <PageTitle variant='h1'>{pageTitle}</PageTitle>}
        {children}
      </MainSection>
    </AccountContainer>
  </>
);

export default Scaffold;

/**
 * Module dependencies
 */

import { AxiosResponse } from 'axios';
import { api } from 'core/services/api.service';
import { QueryClient, useMutation, useQueryClient } from 'react-query';
import {
  IIndexSchedule,
  scheduleKeys,
} from 'schedule/core/queries/schedule.keys';
import { cleanDateEntry } from 'schedule/core/queries/resize-mutation';
import {
  TaskmanagerScheduleListDto,
  TaskmanagerEventDto,
  Status,
} from 'gateway-api';

const onMutate =
  (queryClient: QueryClient, params: IIndexSchedule) =>
  async (event: TaskmanagerEventDto) => {
    await queryClient.cancelQueries(scheduleKeys.index());

    const queryKey = scheduleKeys.indexSchedule(params);

    const previousQuery =
      queryClient.getQueryData<TaskmanagerScheduleListDto>(queryKey);

    queryClient.setQueryData<TaskmanagerScheduleListDto | undefined>(
      queryKey,
      (old) => {
        if (old) {
          const oldMemo = { ...old };
          return {
            pagination: oldMemo.pagination,
            items: oldMemo.items.map((schedule) => {
              if (schedule.user?.id === event.assignee_user?.id) {
                schedule.entries = schedule.entries.map((entry) => {
                  const entryDate = entry.date_from.split(' ')[0];
                  const dragEntryDate = event.date_start.split(' ')[0];

                  if (entryDate !== dragEntryDate) return entry;

                  entry.events = entry.events.filter(
                    (stateEvent) => stateEvent.id !== event.id,
                  );

                  return cleanDateEntry(
                    entry,
                    schedule.user?.profile.available_hours,
                  );
                });
              }

              return schedule;
            }),
          };
        }

        return old;
      },
    );

    return { previousQuery };
  };

async function destroyEvent(
  event: TaskmanagerEventDto,
): Promise<AxiosResponse<Status>> {
  const response = await api.eventService.destroyTaskmanagerEvent(event.id);

  return response;
}

export function useDestroyEvent(params: IIndexSchedule) {
  const queryClient = useQueryClient();

  return useMutation(destroyEvent, {
    onMutate: onMutate(queryClient, params),
    onSettled() {
      queryClient.invalidateQueries(scheduleKeys.indexSchedule(params));
    },
  });
}

import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import styled from '@emotion/styled/macro';

interface ITooltip extends TooltipProps {
  color?: string;
}

export const ToolTip = styled(({ className, color, ...props }: ITooltip) => (
  <Tooltip {...props} classes={{ popper: className }} />
))((props) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'transparent',
    maxWidth: 424,
    minWidth: 424,
    fontSize: '1em',
    padding: 0,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: `${props.color ?? '#07334A'}`,
  },
}));

export const Container = styled.span`
  height: 100%;
`;

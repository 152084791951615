/**
 * Module dependencies.
 */

import { TaskmanagerEventTypeEnum } from 'gateway-api';

export interface IEventTypeState {
  state: {
    eventType: TaskmanagerEventTypeEnum[];
  };
}

export const initialState: IEventTypePayload = {
  eventType: [TaskmanagerEventTypeEnum.Schedule],
};

export interface IEventTypeStore extends IEventTypeState {
  setEventType: (eventTypeList: TaskmanagerEventTypeEnum[]) => void;
  setEventTypeView: (
    eventType: string | null | undefined,
    checked: boolean,
  ) => void;
}

export interface IEventTypePayload {
  eventType: TaskmanagerEventTypeEnum[];
}

export interface IShowUser {
  user?: string;
}

export const userKeys = {
  base: [{ scope: "user" }] as const,
  show: () => [{ ...userKeys.base[0], entity: "show" }] as const,
  showUser: (query: IShowUser) =>
    [
      {
        ...userKeys.show()[0],
        ...query,
      },
    ] as const,
};

import { api } from 'core/services/api.service';
import { useQueryClient, useMutation } from 'react-query';
import { notesKeys } from 'schedule/notes/queries/notes/notes.keys';
import { useDateRange } from 'date-range/store/date-range';
import { useSnackbar } from 'notistack';
import { TaskmanagerNoteDto, TaskmanagerNotePartialDto } from 'gateway-api';

export interface IModifyNotes {
  id: number;
  note: TaskmanagerNotePartialDto;
}

const modifyNotes = ({ id, note }: IModifyNotes) =>
  api.noteService.modifyTaskmanagerNote(id, note);

export const useModifyNotes = () => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    state: { startDate, endDate },
  } = useDateRange();

  const queryKey = notesKeys.indexNotes({
    startDate: startDate.toFormat('yyyy-MM-dd'),
    endDate: endDate.toFormat('yyyy-MM-dd'),
  });
  const queryClient = useQueryClient();

  return useMutation(modifyNotes, {
    onMutate: async ({ id, note }) => {
      await queryClient.cancelQueries(queryKey);

      const prevNotes =
        queryClient.getQueryData<TaskmanagerNoteDto[]>(queryKey);

      // Remove old note from previous notes
      const filteredNotes = prevNotes?.filter((obj) => obj.id !== id) ?? [];

      // Add new note to query data along with filtered notes.
      queryClient.setQueryData(queryKey, [...filteredNotes, { ...note }]);

      return { prevNotes };
    },
    onError: (err, newNote, context) => {
      queryClient.setQueryData(queryKey, context?.prevNotes);
      enqueueSnackbar('An error occurred whilst trying to update note.', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Successfully updated your note', {
        variant: 'success',
      });
    },
    onSettled: () => queryClient.invalidateQueries(queryKey),
  });
};

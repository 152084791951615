import {
  ControllerRenderProps,
  Path,
  useController,
  useFormContext,
} from 'react-hook-form';
import { UseControllerReturn } from 'react-hook-form/dist/types';

/**
 * A hook to make utilized react-hook-forms with material ui easier.
 *
 * Uses the FormContext from react-hook-forms with the provided id to get the error and state of the field
 */
const useControlledFormField = <T extends Record<string, unknown>>(
  id: Path<T>,
) => {
  const { control } = useFormContext<T>();
  const {
    field,
    fieldState: { error },
  }: UseControllerReturn<T, Path<T>> = useController<T>({ control, name: id });
  return {
    field: field as ControllerRenderProps<T, Path<T>>,
    hasError: Boolean(error),
    errorLabel: error?.message,
  } as const;
};

export default useControlledFormField;

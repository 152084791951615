import { Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import { useDateRange } from "date-range/hooks/use-date-range.hook";
import { DateFormats } from 'date-range/hooks/use-date-format';
import { useDateRange } from 'date-range/store/date-range';
import * as S from 'date-range/components/date-range/date-range.styles';

interface Iprops {
  addTime?: boolean;
}

export const DateRangeComponent = ({ addTime }: Iprops): JSX.Element => {
  const { state, prevDateRequested, nextDateRequested } = useDateRange();
  const { getWeekFormat } = DateFormats;

  return (
    <S.DateRangeSwitcher addTime={addTime}>
      <S.StyledDateRangeIcon />
      <S.StyledPrimaryButton
        buttonIcon={<ArrowBackIosIcon />}
        onClick={() => prevDateRequested()}
      />
      <S.DateContainer>
        <Typography>{getWeekFormat(state.startDate)}</Typography>
        {' - '}
        <Typography>{getWeekFormat(state.endDate)}</Typography>
      </S.DateContainer>
      <S.StyledPrimaryButton
        buttonIcon={<ArrowForwardIosIcon />}
        onClick={() => nextDateRequested()}
      />
    </S.DateRangeSwitcher>
  );
};

import { useSearchStore } from 'schedule/search/store/search/search.store';

import * as S from 'schedule/search/components/favourite-filter/favourite-filter.styles';

export const FavouriteFilter = () => {
  const { setFavourite, setPageNumber, state } = useSearchStore();

  return (
    <S.ToggleButton
      selected={state.favourite}
      value={state.favourite}
      onChange={() => {
        setFavourite(!state.favourite);
        setPageNumber(1);
      }}
    >
      Favourites
    </S.ToggleButton>
  );
};

import { AuthReducer, AuthState, initialState } from 'auth/reducers/auth';
import { refreshUser } from 'auth/reducers/auth/auth.action';
import { useReducerAsync } from 'core/reducers/thunk';
import { ReactNode, Reducer, useEffect } from 'react';
import { useLocation } from 'react-router';
import AuthContext from 'auth/store/auth/auth.context';
import { AuthThunk } from 'auth/store/auth/auth.types';

interface IAuthProvider {
  children: ReactNode;
}

const AuthProvider = ({ children }: IAuthProvider) => {
  const location = useLocation();

  const [state, dispatch] = useReducerAsync<Reducer<AuthState, AuthThunk>>(
    AuthReducer,
    initialState,
  );

  // // Remove error when page changes
  // useEffect(() => {
  //   dispatch({ type: "error", payload: undefined });
  // }, [location.pathname]);

  // Refresh the user when the Auth provider mounts for the first time.
  useEffect(() => dispatch(refreshUser()), []);

  return (
    <AuthContext.Provider value={[state, dispatch]}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

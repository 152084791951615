import { QueryFunctionContext, useQuery } from 'react-query';
import { api } from 'core/services/api.service';
import { potStatKeys } from 'schedule/core/queries/pot/pot.keys';

export type ShowPot = ReturnType<typeof potStatKeys['showPot']>;

const showPotStats = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<ShowPot>) => {
  const { data } = await api.potStatService.showTaskmanagerPotStat(id);

  return data;
};

export const useShowPotStats = (queryKey: ShowPot) =>
  useQuery(queryKey, showPotStats);

import { ListItem } from '@mui/material';
import styled from '@emotion/styled/macro';
import { defaultTheme } from 'theme/theme';

const NavigationItem = styled(ListItem)<INavItemProps>`
  && {
    width: auto;
    padding: 0;

    .MuiListItemButton-root {
      height: auto;
      padding: 0 15px;

      &.active {
        .MuiTypography-root {
          font-weight: 700;
        }
      }

      ${(props) => props.theme.breakpoints.down('lg')} {
        &.active {
          .MuiListItemText-root {
            border-bottom: solid 3px ${defaultTheme.colors.primaryColor};
            flex: none;
          }
        }
      }

      ${(props) => props.theme.breakpoints.up('lg')} {
        height: 100%;
        &.active {
          border-bottom: solid 3px ${defaultTheme.colors.primaryColor};
        }
        padding: 0 20px;
      }
    }

    .MuiTypography-root {
      font-family: ${defaultTheme.fonts.latoSanSerif};
      font-weight: normal;

      ${(props) => props.theme.breakpoints.down('lg')} {
        font-size: 0.875em;
      }

      ${(props) => props.theme.breakpoints.up('lg')} {
        font-size: 1em;
      }

      @media only screen and (min-width: 1600px) {
        font-size: 1.1875em;
      }
    }

    ${(props) => props.theme.breakpoints.down('lg')} {
      flex-direction: column;
      align-items: flex-start;

      .MuiListItemButton-root {
        padding: 10px 25px;
      }

      &:nth-last-child(2) {
        display: none;
      }

      &:last-child {
        margin: auto 0 0 0;
      }
    }

    ${(props) => props.theme.breakpoints.up('lg')} {
      &:nth-last-child(2) {
        margin: 0 20px 0 auto;
      }
      margin-right: 20px;
    }
  }
`;

interface INavItemProps {
  children?: React.ReactNode;
}

const NavItem = ({ children }: INavItemProps): JSX.Element => (
  <NavigationItem>{children}</NavigationItem>
);

export default NavItem;

import { useLayoutEffect, useState, useCallback, RefObject } from 'react';

export interface ResizeObserverEntry {
  target: HTMLElement;
  contentRect: DOMRectReadOnly;
}

export const useResizeObserver = (
  ref: RefObject<HTMLElement>,
  callback?: (entry: DOMRectReadOnly) => void,
) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const difference = 5;

  const handleResize = useCallback(
    (entries: any) => {
      if (!Array.isArray(entries)) {
        return;
      }

      const changed = (currentSize: number, oldSize: number) =>
        currentSize > oldSize + difference ||
        currentSize < oldSize - difference;

      const entry = entries[0];

      if (
        changed(entry.contentRect.width, width) ||
        changed(entry.contentRect.height, height)
      ) {
        setWidth(Math.floor(entry.contentRect.width));
        setHeight(entry.contentRect.height);
        if (callback) {
          callback(entry.contentRect);
        }
      }
    },
    [callback, height, width],
  );

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    let RO: ResizeObserver | null = new ResizeObserver((entries) =>
      handleResize(entries),
    );
    RO.observe(ref.current);

    // eslint-disable-next-line consistent-return
    return () => {
      RO?.disconnect();
      RO = null;
    };
  }, [handleResize, ref]);

  return [width, height];
};

export default useResizeObserver;

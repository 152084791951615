import UserAvatar from 'user/components/user-avatar/user-avatar';
import { useState } from 'react';
import {
  UserInfo,
  UserView,
  UserName,
  UserHeader,
  UserStatus,
  UserAction,
  StyledPopup,
  StyledButton,
  SkillContent,
} from 'users/components/user-manage-tile/user-manage-tile.styles';
import { UserForm } from 'users/components/user-form/user-form';
import { UserDto } from 'gateway-api';

export interface IUserProps {
  user: UserDto;
  children?: React.ReactNode;
}

const UserManageTile = ({ user, children }: IUserProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const userSkill = user.skills?.map((skill) => skill) ?? [];

  const userStatus = user.disabled ? 'disabled' : 'active';

  return (
    <>
      <UserHeader>
        <UserAvatar avatarWidth='50px' avatarHeight='50px' />
        <UserName variant='h4'>
          {user.first_name} {user.last_name}
        </UserName>
      </UserHeader>
      <UserInfo>
        {userSkill?.length ? (
          <>
            <SkillContent>Skills: </SkillContent>
            <SkillContent>{userSkill?.join(', ')}</SkillContent>
          </>
        ) : (
          <>
            <SkillContent color='#bc3c25 !important'>
              Profile incomplete
            </SkillContent>
          </>
        )}
      </UserInfo>
      <UserAction>
        <UserStatus userDisabled={user.disabled ?? false}>
          {userStatus}
        </UserStatus>
        <UserView>
          <StyledButton onClick={handleOpen}>View</StyledButton>
        </UserView>
      </UserAction>
      <StyledPopup
        active={open}
        closePopup={handleClose}
        title='Member profile'
        width='800px'
      >
        <UserForm user={user} handleClose={handleClose} />
      </StyledPopup>
      {children}
    </>
  );
};

export default UserManageTile;

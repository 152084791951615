import styled from '@emotion/styled/macro';
import { Pagination as MuiPagination, Typography } from '@mui/material';
import Search from 'core/components/search';
import { defaultTheme } from 'theme/theme';

export const List = styled.div``;

export const ListHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;

  .MuiTypography-root {
    margin-bottom: 0;
    margin-right: 40px;
  }
`;

export const Filters = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
`;

export const ProjectFilter = styled.div`
  display: flex;
  flex-wrap: wrap;

  .MuiCheckbox-root {
    padding: 0 6px;
  }

  .MuiTypography-root {
    margin-right: 20px;
  }

  ${defaultTheme.breakpoints.down('md')} {
    display: none;
  }
`;

export const SectionSubTitle = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0px 15px;
  width: 100%;

  img {
    margin-right: 25px;
    width: 45px;

    ${defaultTheme.breakpoints.down('md')} {
      width: 33px;
    }
  }

  .MuiTypography-h3 {
    font-size: 1.125em;
    margin-right: 5px;

    ${defaultTheme.breakpoints.down('md')} {
      font-size: 1em;
    }
  }

  .MuiTypography-body1 {
    font-weight: 300;
    margin-right: 0;

    ${defaultTheme.breakpoints.down('md')} {
      font-size: 1em;
    }
  }
`;

export const TasksList = styled.ul`
  display: flex;
  list-style: none;
  flex-wrap: wrap;

  ${defaultTheme.breakpoints.up('md')} {
    margin-left: -40px;
  }
`;

export const Task = styled.li`
  border-right: solid 1px ${defaultTheme.colors.borderGrey};
  padding: 0 40px;
  width: 100%;

  .draggable {
    width: 100%;
    margin-top: 15px;
  }

  ${defaultTheme.breakpoints.between(901, 1450)} {
    max-width: 49%;

    :nth-of-type(-n + 2) {
      .draggable {
        margin-top: 0;
      }
    }
  }

  ${defaultTheme.breakpoints.down(900)} {
    max-width: 100%;
    border-right: 0;
    padding: 0 10px;

    :nth-of-type(-n + 2) {
      .draggable {
        margin-top: 15px;
      }
    }
  }

  ${defaultTheme.breakpoints.up(1450)} {
    width: 33%;

    :nth-of-type(-n + 3) {
      .draggable {
        margin-top: 0;
      }
    }
  }
`;

export const Pagination = styled(MuiPagination)`
  && {
    margin-top: 20px;
    .MuiButtonBase-root {
      font-size: 1em;
    }

    ${defaultTheme.breakpoints.down('md')} {
      .MuiPagination-ul {
        justify-content: center;
      }
    }
  }
`;

export const DraggableContainer = styled.div`
  width: 100%;
  margin-bottom: 18px;
`;

export const SectionTitle = styled(Typography)`
  margin-bottom: 20px;

  ${defaultTheme.breakpoints.down('md')} {
    font-size: 1.125em;
  }
`;

export const NoTasks = styled.div`
  background-color: ${defaultTheme.colors.backgroundColor};
  padding: 30px;
  font-size: 1.25em;
  text-align: center;
`;

export const DroppableContainer = styled.div``;

export const SearchContainer = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  margin-right: 1rem;
`;

export const SearchList = styled(Search)`
  min-width: 280px;

  ${defaultTheme.breakpoints.down('md')} {
    margin-top: 10px;
    min-width: 100%;
  }
`;

export const RecentSearchContainer = styled.div`
  display: flex;
`;

import styled from '@emotion/styled/macro';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { css } from '@mui/material';
import PrimaryButton from 'core/components/buttons/primary-button';

export const DateRangeSwitcher = styled.div<{ addTime?: boolean }>`
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  align-items: center;
  text-align: center;

  .MuiTypography-root {
    font-size: 18px;
    font-weight: 500;
    display: inline;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 60px;
  }

  ${(props) =>
    props.addTime
      ? css`
          margin: 0;
        `
      : css`
          margin: 32px 0;
        `}
`;

export const StyledDateRangeIcon = styled(DateRangeIcon)`
  margin-right: 30px;

  ${(props) => props.theme.breakpoints.down('md')} {
    margin-right: 10px;
  }
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  && {
    background-color: transparent;
    padding: 0;
    box-shadow: none;
    color: black;
    min-width: 0;

    span {
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

export const DateContainer = styled.div``;

/**
 * Module dependencies.
 */

import styled from '@emotion/styled/macro';
import { Timeline, TimelineContent } from '@mui/lab';
import { Typography } from '@mui/material';

export const StyledTimeline = styled(Timeline)`
  padding: 30px 16px 0;
  .MuiTimelineItem-root {
    ${(props) => props.theme.breakpoints.down('md')} {
      min-height: 100px;
    }
    &:before {
      display: none;
    }

    &:last-child {
      .MuiTimelineConnector-root {
        display: none;
      }
    }
  }

  .MuiTimelineDot-root {
    background-color: black;
    margin: 0;
    border-width: 0px;
  }

  .MuiTimelineConnector-root {
    background-color: black;
    width: 1px;
  }

  .MuiTimelineContent-root {
    margin-top: -6px;
    padding: 0 14px;
    display: inline-flex;
    white-space: break-spaces;
    align-items: flex-start;
    ${(props) => props.theme.breakpoints.down('md')} {
      // flex-direction: column;
      flex-wrap: wrap;
      padding: 0 10px 20px;
    }

    .MuiTypography-root {
      display: inline-flex;

      &:last-child {
        width: auto !important;
      }
    }

    .MuiSvgIcon-root {
      font-size: 1.25em;
      margin-right: 5px;

      ${(props) => props.theme.breakpoints.down('md')} {
        display: none;
      }
    }
  }
`;

export const StyledTimelineContent = styled(TimelineContent)`
  p:nth-child(2) {
    width: 100px;
  }
`;

export const ItemRows = styled(Typography)`
  display: inline-flex;
  width: 120px;

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 50%;
    margin-bottom: 10px;

    &:first-child {
      width: 100%;
    }
  }
`;

export const TaskLabel = styled.div`
  background-color: #f27777;
  border-radius: 10px;
  text-align: center;
  padding: 2px 10px;
  margin-top: -4px;
  font-size: 0.9375em;
  color: #07334a;
  font-weight: bold;
`;

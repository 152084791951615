import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { api } from 'core/services/api.service';
import useDebounce from 'core/hooks/use-debounce';

export interface IProject {
  start_date: string;
  end_date: string;
  name: string;
  id?: string;
}

// temp timeout func
async function wait(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
type UseTagSearchReturn = [
  (query: string) => void,
  { data: string[] | undefined; isLoading: boolean },
];

const useTagSearch = (): UseTagSearchReturn => {
  const [query, setQuery] = useState<string | undefined>();

  const {
    data: results,
    isLoading,
    refetch,
  } = useQuery(
    ['search', query],
    async () => api.searchService.indexTaskmanagerSearch(query ?? ''),
    { enabled: !!query },
  );
  const debouncedSearch = useDebounce((searchQuery: string) => {
    setQuery(searchQuery);
  });

  useEffect(() => {
    refetch();
  }, [query]);

  return [
    debouncedSearch,
    {
      data: results ? results.data.items : [],
      isLoading,
    },
  ];
};
export default useTagSearch;

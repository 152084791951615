import ChatIcon from '@mui/icons-material/Chat';
import { Typography } from '@mui/material';
import { EventTooltip } from 'schedule/core/components/event-tooltip/event-tooltip';
import * as S from 'schedule/core/components/event-type-calendar/event-type-calendar.styles';
import { TaskmanagerEventDto } from 'gateway-api';
import { useState } from 'react';

interface IEventTypeLeave {
  width: number;
  event: TaskmanagerEventDto;
}

export const EventTypeCalendar = ({ event, width }: IEventTypeLeave) => {
  const isAssigned = event.pots_by_type?.time !== undefined;

  const toolTipcolor = isAssigned ? '#a7e3b8' : undefined; // default set in tooltip styles
  const toolTip = (
    <S.TooltipDetails $assignedTask={isAssigned}>
      <S.TooltipBody>
        <S.TooltipHeader>
          <S.CalendarEventTitle $assignedTask={isAssigned}>
            Meeting
          </S.CalendarEventTitle>
        </S.TooltipHeader>
        <S.CalendarEventContent>
          <Typography>
            <strong>Name: </strong>
            {event.pots_by_type?.calendar_event.name}
          </Typography>
          <Typography>
            <strong>Assigned To: </strong>
            {isAssigned ? event.pots_by_type?.ticket?.label : 'Unassigned'}
          </Typography>
          <Typography>
            <strong>Time Pot: </strong>
            {isAssigned ? event.pots_by_type?.time.name : 'Unassigned'}
          </Typography>
        </S.CalendarEventContent>
      </S.TooltipBody>
    </S.TooltipDetails>
  );

  const [open, setOpen] = useState(false);
  return (
    <EventTooltip
      tip={toolTip}
      color={toolTipcolor}
      open={open}
      setOpen={setOpen}
    >
      <S.CalendarContainer
        disabled={isAssigned}
        buttonIcon={<ChatIcon />}
        $assignedTask={isAssigned}
        width={width}
        // onClick={handleOpen}
      />
    </EventTooltip>
  );
};

/**
 * Module dependencies.
 */

import styled from '@emotion/styled/macro';
import Lottie from 'lottie-react';
/**
 * `Container` styled component.
 */

export const Container = styled(Lottie)`
  top: -60px;
  width: 100%;
  height: 80px;
  position: absolute;
`;

import styled from '@emotion/styled/macro';
import { Button, Typography } from '@mui/material';
import { defaultTheme } from 'theme/theme';
import Popup from 'core/components/popups/popup';

interface IUserStatus {
  userDisabled: boolean;
}

export const UserHeader = styled.header`
  display: flex;
  align-items: center;
  padding: 15px;

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    width: 100%;
  }
`;

export const UserName = styled(Typography)`
  && {
    margin-left: 15px;
    font-size: 1.125em;
    word-break: break-all;

    ${(props) => props.theme.breakpoints.down('md')} {
      margin-left: 0;
      font-size: 0.875em;
      margin-top: 4px;
    }
  }
`;

export const UserInfo = styled.div`
  padding: 0 15px 15px 15px;
  width: 100%;
`;

export const SkillContent = styled(Typography)`
  && {
    font-size: 1em;
    margin-bottom: 0;
    color: ${defaultTheme.colors.primaryColor};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;

    ${(props) => props.theme.breakpoints.down('sm')} {
      font-size: 0.875em;
    }
  }
`;

export const UserAction = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;

  p {
    display: inline;
    width: 50%;
  }
`;

export const UserStatus = styled.div<IUserStatus>`
  width: 50%;
  padding: 3px;
  text-align: center;

  text-transform: capitalize;
  background-color: ${(props) =>
    props.userDisabled === false || null ? '#a0ced9' : '#bc3c25'};
  color: ${(props) =>
    props.userDisabled === false || null ? '#07334A' : '#fff'};
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-size: 0.9375em;
  min-height: 34px;
  line-height: 28px;
  text-transform: capitalize;
  font-size: 1.125em;
  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 0.6875em;
  }
`;

export const UserView = styled.div`
  width: 50%;
  text-align: right;
  display: flex;
  align-items: stretch;
  min-height: 34px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 0.75em;
    padding: 0;
    align-items: center;
  }
`;

export const StyledButton = styled(Button)`
  && {
    text-transform: uppercase;
    border-radius: 0;
    padding: 0 20px 0 5px;
    justify-content: flex-end;
    width: 100%;
    color: #2e559f;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    min-width: auto;
    line-height: normal;

    &:hover {
      background-color: transparent;
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
      justify-content: center;
      font-size: 0.75em;
      min-height: 34px;
      padding: 0 2px;
    }
  }
`;

export const StyledPopup = styled(Popup)`
  overflow: scroll;
  .pop-up-content {
    margin: 20px auto;
    position: relative;
    left: auto;
    top: auto !important;
    transform: none !important;
  }
`;

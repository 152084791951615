import * as React from 'react';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled/macro';

const BgImage = styled.div`
  z-index: -1;
  width: 100%;
  position: absolute;
  background-color: #053349;

  @media only screen and (min-height: 600px) {
    min-height: 100vh;
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    background-image: url('./images/Background_Image.png');
    background-size: 130%;
  }
`;

const HeaderContainer = styled.header`
  margin: 0 auto;
  max-width: 1800px;
  padding: 50px 20px 0 20px;
  background-color: #053349;

  @media only screen and (max-height: 768px) {
    padding: 20px 20px 0 20px;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    padding: 40px 20px;
  }
`;

const Logo = styled.div`
  width: 200px;
  display: block;
  margin: 0 0 0 auto;

  @media only screen and (max-width: 768px) {
    margin: 0 auto;
  }

  img {
    width: 100%;
  }
`;

const MainContent = styled.main`
  && {
    width: 100%;
    padding: 0 20px;
    background-color: #053349;

    @media only screen and (max-height: 600px) {
      background-image: url('./images/Background_Image.png');
      background-size: 130%;
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      margin-top: -2px;
      padding-bottom: 100px;
    }
  }
`;

const SectionContainer = styled.section`
  padding: 20px;
  text-align: center;

  @media only screen and (max-width: 768px) {
    width: 100%;
    position: relative;
    padding: 0 20px;
  }

  @media only screen and (max-height: 600px) {
    position: relative !important;
    transform: none !important;
    top: 0 !important;
  }

  @media only screen and (min-width: 768px) {
    position: absolute;
    left: 0;
    right: 0;
    top: 35%;
    transform: translateY(-35%);
    max-width: 680px;
    margin: 0 auto;
  }
`;

const SectionTitle = styled(Typography)`
  color: #ffffff;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 40px;
  font-family: 'Lato', sans-serif;
  margin-top: 30px;

  ${(props) => props.theme.breakpoints.down('md')} {
    font-size: 26px;
    margin-bottom: 30px;
    margin-top: 15px;
  }
`;

const ArticleContainer = styled.article`
  background-color: white;
  border-radius: 10px 10px 10px 10px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
`;

interface ContentProps {
  sectionTitle: string;
  children?: React.ReactNode;
}

const LandingPageLayout = ({
  sectionTitle,
  children,
}: ContentProps): JSX.Element => (
  <>
    <BgImage />
    <HeaderContainer>
      <Logo dir="rtl">
        <img src="/images/recite-logo_white.svg" alt="Reciteme" />
      </Logo>
    </HeaderContainer>
    <MainContent>
      <SectionContainer>
        <SectionTitle variant="h1">{sectionTitle}</SectionTitle>
        <ArticleContainer>{children}</ArticleContainer>
      </SectionContainer>
    </MainContent>
  </>
);

export default LandingPageLayout;

/**
 * Module dependencies.
 */

import * as S from 'user/components/user-avatar/user-avatar.style';

interface IAvatarProps {
  bgSolid?: boolean;
  avatarWidth?: string;
  avatarHeight?: string;
  initials?: string | undefined;
  className?: string;
}

const UserAvatar = ({
  bgSolid,
  avatarWidth,
  avatarHeight,
  initials,
  className,
}: IAvatarProps): JSX.Element => (
  <S.StyledBadge className={className}>
    <S.StyledAvator width={avatarWidth} height={avatarHeight} bgSolid={bgSolid}>
      {initials}
    </S.StyledAvator>
  </S.StyledBadge>
);

export default UserAvatar;

import React from 'react';
import {
  DeepPartial,
  FieldValues,
  FormProvider,
  Mode,
  SubmitErrorHandler,
  SubmitHandler,
  UnpackNestedValue,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

/** Alias to strongly type yup objects */
export type IValidation<T> = Partial<Record<keyof T, yup.AnySchema>>;

export interface IFormProps<T extends FieldValues> {
  className?: string;
  children: React.ReactNode;
  onSubmit: SubmitHandler<T>;
  onError?: SubmitErrorHandler<any>;
  validations?: yup.AnyObjectSchema;
  validationMode?: Mode;
  reValidateMode?: Exclude<Mode, 'onTouched' | 'all'>;
  initialValues?: DeepPartial<T>;
}

/** html form component with onsubmit populated with the formContext */
// eslint-disable-next-line @typescript-eslint/ban-types
const Form = <T extends {}>({
  children,
  className,
  onSubmit,
  onError,
  validations,
  validationMode = 'onSubmit',
  initialValues,
  reValidateMode,
}: IFormProps<T>): JSX.Element => {
  const methods = useForm<T>({
    resolver: validations ? yupResolver(validations) : undefined,
    defaultValues: initialValues,
    mode: validationMode,
    reValidateMode,
  });
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <form className={className} onSubmit={handleSubmit(onSubmit, onError)}>
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;

import jwt_decode from 'jwt-decode';
import { AxiosError, AxiosResponse } from 'axios';
import { AuthRequest, AuthResult, AuthResultApi, UserDto } from 'sso-api';

interface IJWTData {
  user: UserDto;
}

/* eslint-disable no-underscore-dangle */
class TokenService {
  public accessToken: string | null = null;

  // used to see if there's an ongoing access token refresh
  private refreshCall: Promise<AxiosResponse<AuthResult>> | null = null;

  constructor(private authenticationService: AuthResultApi) {}

  refreshToken(value: string | null): void {
    if (value !== null) {
      localStorage.setItem('refreshToken', value);
    }
  }

  public logout(): void {
    this.accessToken = null;
    localStorage.removeItem('refreshToken');
  }

  public decodeToken(): UserDto | null {
    if (this.accessToken !== null) {
      const decodedToken: IJWTData = jwt_decode(this.accessToken);
      const user: UserDto = { ...decodedToken.user };
      return user;
    }
    return null;
  }

  public async refreshAccessToken(): Promise<string | null> {
    if (this.refreshCall) {
      // the access token is already being refreshed - wait for it
      const response = await this.refreshCall;
      return response?.data.access_token ?? null;
    }
    try {
      const refreshToken = localStorage.getItem('refreshToken');
      if (refreshToken !== null) {
        const authRequest: AuthRequest = {
          grant_type: 'refresh_token',
          client_id: process.env.REACT_APP_CLIENT_ID ?? '',
          client_secret: process.env.REACT_APP_CLIENT_SECRET ?? '',
          refresh_token: refreshToken,
        };

        this.refreshCall = this.authenticationService.auth(authRequest);

        const response = await this.refreshCall;

        this.refreshCall = null;

        const authResult: AuthResult = (await response).data;
        if (authResult.access_token && authResult.refresh_token) {
          this.accessToken = authResult.access_token;
          localStorage.setItem('refreshToken', authResult.refresh_token);
          // this.refreshToken(authResult.refresh_token);
          return authResult.access_token;
        }
      }
      return null;
    } catch (error: unknown) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.status === 401) {
        this.logout();
      }
      return null;
    }
  }
}

export default TokenService;

import ProgressBar from 'core/components/progress-bar';
import StatsTimeLine from 'statistics/components/stats-timeline/stats-timeline';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useIndexPotsStatisticsTag from 'statistics/hooks/use-pots-statistics-tags';
import * as S from 'statistics/components/task-details-statistics-tags/task-details-statistics-tags.style';
import {
  TaskmanagerTagDto,
  TaskmanagerPotStatTimelineCollectionDto,
} from 'gateway-api';

interface ITaskDetailsProp {
  tag?: TaskmanagerTagDto;
}

const TaskDetailsStatsTags = ({ tag }: ITaskDetailsProp): JSX.Element => {
  const { t } = useTranslation();

  const { items: potStatsTag } = useIndexPotsStatisticsTag(tag?.name as string);

  return (
    <>
      {tag && (
        <>
          <S.TaskTitles>
            <>
              <S.StyledTaskTitle variant="h2">{tag?.name}</S.StyledTaskTitle>
            </>
          </S.TaskTitles>

          {potStatsTag?.length ? (
            <>
              <S.StyledProgressBar>
                {potStatsTag ? (
                  <ProgressBar
                    percentComplete={
                      (((potStatsTag[0].complete_hours_total as number) /
                        potStatsTag[0].estimate_hours_total) as number) * 100
                    }
                    bgColor="#F27777"
                  />
                ) : (
                  <></>
                )}
              </S.StyledProgressBar>
              <S.TaskDateRange>
                <Typography>
                  {t('project_statistics.stats.start')}:{' '}
                  {potStatsTag ? (
                    new Date(potStatsTag[0].estimate_date_start).toLocaleString(
                      'en-GB',
                      {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                      },
                    )
                  ) : (
                    <></>
                  )}
                </Typography>
                <Typography>
                  {t('project_statistics.stats.end')}:{' '}
                  {potStatsTag ? (
                    new Date(potStatsTag[0].estimate_date_end).toLocaleString(
                      'en-GB',
                      {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                      },
                    )
                  ) : (
                    <></>
                  )}
                </Typography>
              </S.TaskDateRange>
              <S.TaskTimelinesContainer>
                {potStatsTag.map((tagstats) => (
                  <div key={tag.id}>
                    {tagstats.estimate_timeline ? (
                      <S.TimelineCol>
                        <Typography variant="h3">
                          {t('project_statistics.stats.time_estimated')}
                        </Typography>

                        <StatsTimeLine
                          timelineData={tagstats?.estimate_timeline}
                        />
                      </S.TimelineCol>
                    ) : (
                      <></>
                    )}
                    {tagstats.complete_timeline ? (
                      <S.TimelineCol>
                        <Typography variant="h3">
                          {t('project_statistics.stats.time_recorded')}
                        </Typography>
                        <StatsTimeLine
                          timelineData={
                            tagstats?.complete_timeline as unknown as TaskmanagerPotStatTimelineCollectionDto[]
                          }
                        />
                      </S.TimelineCol>
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
              </S.TaskTimelinesContainer>
            </>
          ) : (
            <S.NoData>There are no stats available for this tag</S.NoData>
          )}
        </>
      )}
    </>
  );
};

export default TaskDetailsStatsTags;

/**
 * Route Path
 *
 * A global store for all route paths across the application.
 */

const RoutePath = {
  // Public and authentication route paths
  HOME: `/`,
  CONTENT: `/content`,
  KANBAN: `/kanban`,
  NOT_FOUND: `/404`,
  FORBIDDEN: `/403`,
  REGISTER: `/register`,
  LOGIN: `/login`,
  FORGOTTEN_PASSWORD: `/reset-password`,
  DASHBOARD: `/dashboard`,
  DASHBOARD_OLD: `/dashboard-old`,
  USER_PROFILE: `/user-profile`,
  USER_MANAGEMENT: `/user-management`,
  ESTIMATE: `/estimate`,
  SCHEDULE: `/schedule`,
  ADD_TIME: `/add-time`,
  RECORDED: `/recorded`,
  MULTIVIEW: `/multi-view`,
  PROJECT_STATISTICS: `/project-statistics`,
  GANT_VIEW: `/project-view`,
  SCHEDULE_OLD: `/schedule-old`,
  ADD_TIME_OLD: `/add-time-new`,
  ESTIMATE_OLD: `/estimate-new`,
  RECORDED_OLD: `/recorded-new`,
} as const;

export default RoutePath;

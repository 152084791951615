/**
 * Module dependencies.
 */

import CustomHelmet from 'core/components/custom-helmet';
import Scaffold from 'core/components/scaffolds/scaffold';
import useAuthUser from 'auth/hooks/use-auth-user';
import { DateRangeComponent } from 'date-range/components/date-range/date-range';
import { ScheduleBody } from 'schedule/core/components/schedule-body/schedule-body';
import { DragDropContext } from 'react-beautiful-dnd';
import { useDateRange } from 'date-range/store/date-range';
import useScheduleDragEnd from 'schedule/core/hooks/schedule-drag-end';
import useDragDropStore from 'schedule/core/store/drag-drop/drag-drop.store';
import {
  EventTypeProvider,
  PageEnum,
} from 'schedule/core/providers/event-type-provider';
import { useScheduleMutation } from 'schedule/core/queries/schedule-move-mutation';
import { useDestroyEvent } from 'schedule/core/queries/schedule-destroy-event';
import ScheduleFilters from 'schedule/core/components/schedule-filters/schedule-filters';
import { IIndexSchedule } from 'schedule/core/queries/schedule.keys';
import useUserStore from 'schedule/core/store/users/users.store';
import { toApiDate } from 'core/hooks/queries/schedule/use-index-schedule';
import { TaskmanagerEventTypeEnum } from 'gateway-api';
import useEventTypeStore from 'schedule/core/store/event-type/event-type.store';
import { useEffect } from 'react';
import * as S from 'schedule/core/pages/recorded/recorded.style';

const RecordedNew = (): JSX.Element => {
  // set event type based on page
  const {
    setEventType,
    state: { eventType },
  } = useEventTypeStore();
  const user = useAuthUser();
  const {
    onBeforeCapture,
    state: { draggableEvent, draggablePot, isCloneKeyDown },
    setAddPot,
    clear,
  } = useDragDropStore();

  const {
    state: { endDate, startDate, dateRangeType },
  } = useDateRange();

  const {
    state: { users },
  } = useUserStore();

  const indexScheduleParams: IIndexSchedule = {
    endDate: toApiDate(endDate.toJSDate()),
    startDate: toApiDate(startDate.toJSDate()),
    eventType: [TaskmanagerEventTypeEnum.Complete],
    dateRangeType,
    selectedUsers: users,
  };

  const { mutate: destroyEvent } = useDestroyEvent(indexScheduleParams);
  // const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useScheduleMutation(indexScheduleParams);

  const onDragEnd = useScheduleDragEnd({
    user,
    isCloneKeyDown,
    event: draggableEvent,
    pot: draggablePot,
    page: PageEnum.RECORDED,
    handlePatch(event) {
      if (!event) return;
      mutate(event, {
        onSuccess(data, variables, context) {
          if (context.existingEvent) {
            destroyEvent(context.existingEvent);
          }
        },
      });
    },
    handleCreate(pot) {
      setAddPot(pot);
    },
    handleDelete(event) {
      destroyEvent(event);
    },
    handleClone() {},
    handleCloneSameSchedule(event) {},
    onAbort: clear,
  });

  useEffect(() => {
    setEventType([TaskmanagerEventTypeEnum.Complete]);
  }, [setEventType]);

  return (
    <>
      <CustomHelmet pageName='Home' />
      <Scaffold headerTitle='Recorded Time' pageTitle='Recorded Time'>
        <DragDropContext
          onDragEnd={onDragEnd}
          onBeforeCapture={onBeforeCapture}
        >
          <DateRangeComponent />

          <EventTypeProvider
            scheduleParams={indexScheduleParams}
            page={PageEnum.RECORDED}
          >
            <S.MainNavContainer>
              <ScheduleFilters />
            </S.MainNavContainer>
            <ScheduleBody />
          </EventTypeProvider>
        </DragDropContext>
      </Scaffold>
    </>
  );
};

export default RecordedNew;

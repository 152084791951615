/**
 * Module dependencies.
 */

import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import AddIcon from '@mui/icons-material/Add';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { FaTimes } from 'react-icons/fa';
import * as S from 'users/components/user-custom-attributes/user-custom-attributes.style';
import { TaskmanagerProfileAttributeDto } from 'gateway-api';

const UserCustomAttributeTable = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const { register, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    // control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'attributes', // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  const handleAdd = () => {
    const attributes: Array<TaskmanagerProfileAttributeDto> =
      getValues('attributes');
    const hasIncompleteFields =
      attributes.filter((attr) => attr.name === '' || attr.value === '')
        .length > 0;

    if (!hasIncompleteFields) append({ name: '', value: '' });
    else
      enqueueSnackbar('Please complete all fields before adding more rows', {
        variant: 'error',
      });
  };

  return (
    <TableContainer>
      <S.StyledTable aria-label="Users Custom Attributes">
        <TableBody>
          {fields.map((field, index) => (
            <S.StyledTableRow key={field.id}>
              <S.StyledTableCell component="th" scope="row">
                <S.StyledInput {...register(`attributes.${index}.name`)} />
              </S.StyledTableCell>
              <S.StyledTableCell align="right">
                <S.StyledInput {...register(`attributes.${index}.value`)} />
              </S.StyledTableCell>
              <S.StyledTableCell>
                <S.Remove onClick={() => remove(index)}>
                  <FaTimes />
                </S.Remove>
              </S.StyledTableCell>
            </S.StyledTableRow>
          ))}

          <S.StyledTableRow>
            <S.StyledTableCell>
              <S.AddButton
                buttonIcon={<AddIcon />}
                onClick={handleAdd}
                className="addRow"
                type="button"
              >
                Add New
              </S.AddButton>
            </S.StyledTableCell>
            <S.StyledTableCell />
            <S.StyledTableCell />
          </S.StyledTableRow>
        </TableBody>
      </S.StyledTable>
    </TableContainer>
  );
};

export default UserCustomAttributeTable;

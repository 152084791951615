import styled from '@emotion/styled/macro';
import Search from 'core/components/search';
import { defaultTheme } from 'theme/theme';

export const SearchList = styled(Search)`
  min-width: 280px;

  ${defaultTheme.breakpoints.down('md')} {
    margin-top: 10px;
    min-width: 100%;
  }
`;

export const RecentSearchContainer = styled.div`
  display: flex;
  margin-right: 2rem;
`;

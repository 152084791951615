import { api } from 'core/services/api.service';
import { useQuery } from 'react-query';

const useOrganisations = () => {
  const {
    data: organisationsResponse,
    isLoading: organisationsLoading,
    error: organisationsError,
  } = useQuery(['organisation'], () =>
    api.organisationService.indexOrganisations(),
  );
  return {
    ...organisationsResponse?.data,
    organisationsLoading,
    organisationsError,
  };
};

export default useOrganisations;

import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { FaExclamationTriangle } from 'react-icons/fa';
import DateRangeType from 'date-range/models/date-range-type.enum';
import { useDateRange } from 'date-range/store/date-range';
import useEventTicketStore from 'schedule/core/store/resizable/resize.store';
import * as S from 'schedule/core/components/remaining-hours/remaining-hours.styles';
import { useMemo } from 'react';
import useEventTypeProvider, {
  PageEnum,
} from 'schedule/core/providers/event-type-provider';
import { TaskmanagerEventTypeEnum } from 'gateway-api';

interface IRemainingTime {
  hours: number;
  date: string;
  userId: string;
  eventType: TaskmanagerEventTypeEnum;
}

export const RemainingHours = ({
  hours,
  date,
  userId,
  eventType,
}: IRemainingTime): JSX.Element => {
  const {
    state: { dateRangeType },
  } = useDateRange();

  const {
    state: { timeDifference, event },
    isResizing,
  } = useEventTicketStore();

  const { page, scheduleParams } = useEventTypeProvider();

  const time = hours - timeDifference;

  const pageValidation: boolean = useMemo(() => {
    if (page === PageEnum.ADD_TIME) {
      return scheduleParams.eventType[0] === TaskmanagerEventTypeEnum.Complete;
    }

    return true;
  }, [page, scheduleParams.eventType]);

  if (
    isResizing(date) &&
    event?.assignee_user?.id === userId &&
    pageValidation &&
    (page === PageEnum.MULTI_VIEW ? eventType === event.event_type : true)
  ) {
    return (
      <>
        {time > 1 &&
          (dateRangeType === DateRangeType.week ||
            dateRangeType === DateRangeType.day) && (
            <S.Time>
              <AccessTimeFilledIcon />
              <S.Hours>
                {`${
                  date === event?.date_start.split(' ')[0] ? time : hours
                } hrs`}
              </S.Hours>
            </S.Time>
          )}

        {time <= 1 && time > 0 && (
          <S.Time smallText>
            <AccessTimeFilledIcon sx={{ fontSize: 10 }} />
            <S.HoursPopup>
              {`${date === event?.date_start.split(' ')[0] ? time : hours} hrs`}
            </S.HoursPopup>
          </S.Time>
        )}

        {time < 0 && (
          <S.Time overTime>
            <FaExclamationTriangle fontSize={18} />
            <S.HoursPopup>
              {`${
                date === event?.date_start.split(' ')[0]
                  ? time.toFixed(2)
                  : hours.toFixed(2)
              } hrs`}
            </S.HoursPopup>
          </S.Time>
        )}
      </>
    );
  }

  return (
    <>
      {hours > 1 &&
        (dateRangeType === DateRangeType.week ||
          dateRangeType === DateRangeType.day) && (
          <S.Time>
            <AccessTimeFilledIcon />
            <S.Hours>{`${hours} hrs`}</S.Hours>
          </S.Time>
        )}

      {hours <= 1 && hours > 0 && (
        <S.Time smallText>
          <AccessTimeFilledIcon sx={{ fontSize: 10 }} />
          <S.HoursPopup>{`${hours} hrs`}</S.HoursPopup>
        </S.Time>
      )}

      {hours < 0 && (
        <S.Time overTime>
          <FaExclamationTriangle fontSize={18} />
          <S.HoursPopup>{`${hours} hrs`}</S.HoursPopup>
        </S.Time>
      )}
    </>
  );
};

/**
 * Module dependencies.
 */

import LightModeIcon from '@mui/icons-material/LightMode';
import DateRangeType from 'date-range/models/date-range-type.enum';
import { useDateRange } from 'date-range/store/date-range';
import { TaskmanagerEventDto } from 'gateway-api';
import * as S from 'schedule/core/components/event-type-leave/event-type-leave.styles';

interface IEventTypeLeave {
  width: number;
  event: TaskmanagerEventDto;
}

export const EventTypeLeave = ({ event, width }: IEventTypeLeave) => {
  const dateRangeType = useDateRange((store) => store.state.dateRangeType);
  const name =
    event.pots_by_type?.leave_booking?.name !== 'Workcation' &&
    event.pots_by_type?.leave_booking?.name;

  return (
    <S.LeaveContainer width={width}>
      {dateRangeType === DateRangeType.week && (
        <S.LeaveTitle>{`${name}`}</S.LeaveTitle>
      )}
      <LightModeIcon />
    </S.LeaveContainer>
  );
};

import styled from '@emotion/styled/macro';
import { css } from '@mui/material';
import { DateTime } from 'luxon';
import { IDateRange, DateRangeType } from 'core/hooks/use-date-range';
import { defaultTheme } from 'theme/theme';

const ScheduleHeaderItem = styled.li`
  height: 114px;
  &:nth-child(even) {
    background: #f7f7f7;
  }
  height: 50px;
`;

const HeaderList = styled.ul`
  display: flex;
  height: 100%;
  justify-content: space-between;
  list-style: none;
`;

const UserScheduleItem = styled.li`
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  width: 100%;
  border-right: 1px solid #07334a;
`;

const HeaderItem = styled(UserScheduleItem)<{
  $dateType?: DateRangeType;
}>`
  display: flex; //
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: -100vh;
    right: -1px;
    width: 10px;
    border-right: 1px dashed #07334a;
  }
  ${({ $dateType }) =>
    $dateType !== DateRangeType.month &&
    css`
      span {
        ${defaultTheme.breakpoints.down('md')} {
          font-size: 0;
          &:first-letter {
            font-size: 14px;
          }
        }
      }
    `};
  span {
    margin-right: 1.5rem;
  }
`;

const UserHeaderItem = styled(UserScheduleItem)<{ $labelWidth?: number }>`
  flex: 0 0 ${(props) => props.$labelWidth ?? 100}px;
`;

interface IScheduleHeader {
  dateRangeType: DateRangeType;
  dateRange: IDateRange | undefined;
  labelWidth?: number;
}

const getNumberSuffix = (num: number): string => {
  const th = 'th';
  const rd = 'rd';
  const nd = 'nd';
  const st = 'st';

  if (num === 11 || num === 12 || num === 13) return th;

  const lastDigit = num.toString().slice(-1);

  switch (lastDigit) {
    case '1':
      return st;
    case '2':
      return nd;
    case '3':
      return rd;
    default:
      return th;
  }
};

const ScheduleHeader = ({
  dateRange,
  dateRangeType,
  labelWidth,
}: IScheduleHeader) => (
  <ScheduleHeaderItem>
    <HeaderList>
      <UserHeaderItem $labelWidth={labelWidth}> </UserHeaderItem>
      {dateRange?.headerDates.map((date) => (
        // if matching then note  // filter
        // date={DateTime.fromJSDate(date).toFormat("MM-dd-yyyy").toString()}
        <HeaderItem key={date.toString()} $dateType={dateRangeType}>
          {dateRangeType === DateRangeType.week && (
            <>
              <span>
                {DateTime.fromJSDate(date).toFormat('cccc')}
                {` ${date.getDate()}${getNumberSuffix(date.getDate())} `}
                {DateTime.fromJSDate(date).toFormat('MMM')}
              </span>
              {/* {dateNote(date)} */}
            </>
          )}
          {dateRangeType === DateRangeType.month &&
            DateTime.fromJSDate(date).toFormat('MMMM')}

          {dateRangeType === DateRangeType.year && (
            <span>{DateTime.fromJSDate(date).toFormat('MMM')}</span>
          )}
        </HeaderItem>
      ))}
    </HeaderList>
  </ScheduleHeaderItem>
);

export default ScheduleHeader;

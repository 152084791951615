import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import UserAvatar from 'user/components/user-avatar/user-avatar';
import RoutePath from 'routes/route-paths';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'core/components/buttons/primary-button';
import NavigationHeader from 'core/components/nav/navigation';
import useAuthUser from 'auth/hooks/use-auth-user';
import {
  AvatarLink,
  HeaderRightElement,
  HeaderSection,
  MobileButton,
  MobileMenuIcon,
  StyledHeaderPageTitle,
  StyledHeaderTitle,
  StyledToolbar,
  StyledToolbar2,
  TodaysDate,
} from 'core/components/header/header.styles';

export interface HeaderProps {
  headerTitle?: string;
}

export const Header = ({ headerTitle }: HeaderProps): JSX.Element => {
  const user = useAuthUser();
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const { t } = useTranslation();
  const handleMenuClick = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  return (
    <HeaderSection>
      <StyledToolbar>
        <MobileMenuIcon
          color='inherit'
          aria-label='open menu'
          edge='start'
          onClick={handleMenuClick}
        >
          <MenuIcon />
        </MobileMenuIcon>
        <StyledHeaderTitle>{t('account.header.title')}</StyledHeaderTitle>
        <NavigationHeader showSideBar={isComponentVisible} />
        <HeaderRightElement>
          <MobileButton>
            <PrimaryButton href={RoutePath.ADD_TIME}>Add Time</PrimaryButton>
          </MobileButton>
          <AvatarLink to={RoutePath.USER_PROFILE}>
            <UserAvatar
              avatarWidth='55px'
              avatarHeight='55px'
              bgSolid={false}
              initials={`${user?.first_name?.charAt(
                0,
              )}${user?.last_name?.charAt(0)}`}
            />
          </AvatarLink>
        </HeaderRightElement>
      </StyledToolbar>
      <StyledToolbar2>
        {headerTitle && (
          <StyledHeaderPageTitle>{headerTitle}</StyledHeaderPageTitle>
        )}
        <TodaysDate>
          {new Date().toLocaleString('en-GB', {
            day: 'numeric',
            weekday: 'long',
            month: 'long',
            year: 'numeric',
          })}
        </TodaysDate>
      </StyledToolbar2>
    </HeaderSection>
  );
};

export default Header;

import useAuth from 'auth/hooks/use-auth';
import StoreStatus from 'core/models/store-status.enum';
import { Navigate } from 'react-router-dom';
import RoutePath from 'routes/route-paths';

export interface IUserProps {
  children: React.ReactNode;
}
/**
 * UserCheck - This should wrap any route that should log the user in if they have required tokens already.
 * eg. landing, login, register
 *
 * If the user is NOT logged into the application then children will be returned.
 * If the user IS logged in to the application they will be redirected to the applications home route.
 */
const UserCheck = ({ children }: IUserProps) => {
  const [{ user, status }] = useAuth();

  if (!user) {
    // The user is not logged in
    return <>{children}</>;
  }

  if (status === StoreStatus.loading) return <></>;

  // The user is logged in and should be taken to the applications home route.
  return <Navigate to={{ pathname: RoutePath.DASHBOARD }} />;
};

export default UserCheck;

import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  ReferenceArea,
  ResponsiveContainer,
  ReferenceDot,
  Tooltip,
} from 'recharts';
import CloseIcon from '@mui/icons-material/Close';

export interface IScatterGraphData {
  x: string;
  y: number;
}

interface IScatterGraphProps {
  graphData: IScatterGraphData[];
}

const CustomScatterGraph = ({ graphData }: IScatterGraphProps): JSX.Element => (
  <ResponsiveContainer width={400} height={400}>
    <ScatterChart>
      {Number(graphData[0].x) > 100 ? (
        <XAxis
          type="number"
          dataKey="x"
          domain={[0, graphData[0].x]}
          reversed
          unit="%"
          tickLine={false}
          tickCount={3}
          interval="preserveStartEnd"
          name="Time Spent vs Estimate"
          label={{
            value: 'Time Spent vs Estimate',
            position: 'insideBottom',
          }}
        />
      ) : (
        <XAxis
          type="number"
          dataKey="x"
          domain={[0, 100]}
          reversed
          unit="%"
          tickLine={false}
          tickCount={2}
          interval="preserveStartEnd"
          name="Time Spent vs Estimate"
          label={{
            value: 'Time Spent vs Estimate',
            position: 'insideBottom',
          }}
        />
      )}

      {Number(graphData[0].y) > 100 ? (
        <YAxis
          type="number"
          dataKey="y"
          domain={[0, graphData[0].y]}
          reversed
          unit="%"
          tickLine={false}
          tickCount={3}
          interval="preserveStartEnd"
          name="Budget Spent vs Estimate"
          label={{
            value: 'Budget Spent vs Estimate',
            angle: -90,
            position: 'center',
          }}
        />
      ) : (
        <YAxis
          type="number"
          dataKey="y"
          domain={[0, 100]}
          reversed
          unit="%"
          tickLine={false}
          tickCount={2}
          interval="preserveStartEnd"
          name="Budget Spent vs Estimate"
          label={{
            value: 'Budget Spent vs Estimate',
            angle: -90,
            position: 'center',
          }}
        />
      )}

      {Number(graphData[0].x) > 100 || Number(graphData[0].y) > 100 ? (
        <ReferenceLine
          stroke="green"
          strokeDasharray="3 3"
          segment={[
            { x: 0, y: graphData[0].y },
            { x: graphData[0].x, y: 0 },
          ]}
        />
      ) : (
        <ReferenceLine
          stroke="green"
          strokeDasharray="3 3"
          segment={[
            { x: 0, y: 100 },
            { x: 100, y: 0 },
          ]}
        />
      )}
      <Tooltip cursor={{ strokeDasharray: '3 3' }} />

      <Scatter data={graphData} />
    </ScatterChart>
  </ResponsiveContainer>
);

export default CustomScatterGraph;

import styled from '@emotion/styled/macro';
import { NavLink } from 'react-router-dom';
import { defaultTheme } from 'theme/theme';
import { AppBar, Toolbar, IconButton, Typography } from '@mui/material';

export const HeaderSection = styled(AppBar)`
  && {
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    position: initial;
    overflow-x: scroll;
  }
`;

export const StyledToolbar = styled(Toolbar)`
  && {
    min-height: auto;
    border-bottom: solid 1px #a7a7a7;
    display: flex;
    margin-top: 10px;
    padding: 0 24px;
    position: initial;

    ${(props) => props.theme.breakpoints.down('md')} {
      justify-content: space-between;
      margin-top: 0;
    }

    ${(props) => props.theme.breakpoints.up('md')} {
      height: 66px;
    }

    ${(props) => props.theme.breakpoints.up('lg')} {
      padding: 0 60px;
    }
  }
`;

export const StyledToolbar2 = styled(Toolbar)`
  && {
    border-bottom: none;
    min-height: auto;
    padding: 18px 24px;

    ${(props) => props.theme.breakpoints.down('md')} {
      padding: 18px 24px;
    }

    ${(props) => props.theme.breakpoints.up('lg')} {
      padding: 18px 60px;
    }
  }
`;

export const StyledHeaderTitle = styled(Typography)`
  && {
    color: ${defaultTheme.colors.primaryColor};
    font-weight: normal;
    font-family: ${defaultTheme.fonts.latoSanSerif};

    ${(props) => props.theme.breakpoints.down('md')} {
      font-size: 1em;
      margin-right: 20px;
    }

    ${(props) => props.theme.breakpoints.up('xl')} {
      font-size: 1.25em;
      margin-right: 30px;
    }

    @media only screen and (min-width: 1600px) {
      font-size: 1.5625em;
      margin-right: 50px;
    }
  }
`;

export const StyledHeaderPageTitle = styled(Typography)`
  && {
    font-size: 16px;
    color: ${defaultTheme.colors.primaryColor};
    font-weight: normal;
    margin-left: 42px;
    font-family: ${defaultTheme.fonts.latoSanSerif};

    ${(props) => props.theme.breakpoints.up('md')} {
      font-size: 22px;
      padding-right: 20px;
      border-right: solid 1px #a7a7a7;
      margin-left: 0;
    }
  }
`;

export const HeaderRightElement = styled.div`
  margin: 0 0 0 auto;
  padding: 5px 0;
  display: flex;
  align-items: center;
`;

export const TodaysDate = styled.p`
  color: #07334a;
  font-family: ${defaultTheme.fonts.latoSanSerif};
  margin-left: 20px;

  ${(props) => props.theme.breakpoints.down('md')} {
    display: none;
  }
`;

export const MobileMenuIcon = styled(IconButton)`
  && {
    display: none;

    .MuiSvgIcon-root {
      fill: ${defaultTheme.colors.primaryColor};
    }

    ${(props) => props.theme.breakpoints.down('lg')} {
      display: inline-flex;
      margin: 0 14px;
      padding: 0;

      &:hover {
        border-radius: 0;
      }
    }
  }
`;

export const AvatarLink = styled(NavLink)`
  text-decoration: none;
`;

export const MobileButton = styled.div`
  margin-right: 10px;
  .MuiListItemButton-root {
    padding: 0 10px;
    background-color: ${defaultTheme.colors.accentColor};
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    white-space: pre;
    color: white;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    display: none;
  }
`;

import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

export interface ISearchSettings {
  jql?: boolean;
  searchQuery?: string;
  jqlSearchQuery?: string;
  company?: string;
}

interface IPotsContext {
  category?: string;
  setCategory: Dispatch<SetStateAction<string | undefined>>;
  search?: string;
  setStatus: Dispatch<SetStateAction<boolean>>;
  status?: boolean;
  setSearch: Dispatch<SetStateAction<string | undefined>>;
  pageNumber: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  searchSettings?: ISearchSettings | undefined;
  setSearchSettings: Dispatch<SetStateAction<ISearchSettings | undefined>>;
}

const potsContext = createContext<IPotsContext>({} as IPotsContext);

/** A provider for pots feature */
const PotsProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [category, setCategory] = useState<string | undefined>();
  const [search, setSearch] = useState<string | undefined>();
  const [jqlSearch, setJqlSearch] = useState<string | undefined>();
  const [status, setStatus] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchSettings, setSearchSettings] = useState<ISearchSettings>();

  return (
    <potsContext.Provider
      value={{
        category,
        setCategory,
        search,
        status,
        setStatus,
        setSearch,
        pageNumber,
        setPageNumber,
        searchSettings,
        setSearchSettings,
      }}
    >
      {children}
    </potsContext.Provider>
  );
};

export const usePotsContext = () => useContext(potsContext);

export default PotsProvider;

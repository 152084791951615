import styled from '@emotion/styled/macro';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import ProjectItem from 'gantt/components/project-item/project-item';
import { Typography, css } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useDateRangeContext } from 'core/store/date-range-provider';
import ScheduleHeader from 'schedule/core/components/schedule-header';
import ProjectTag from 'gantt/components/project-tag/project-tag';
import { DateRangeType } from 'core/hooks/use-date-range';
import { defaultTheme } from 'theme/theme';
import { TaskmanagerPotGanttDto } from 'gateway-api';

const CalendarDay = styled.li<{
  columnWidth: number;
  $dateType?: DateRangeType;
  $isWeekend?: boolean;
  $hasBottomBorder?: boolean;
  $showDate?: boolean;
}>`
  position: relative;
  display: table;
  table-layout: fixed;
  justify-content: center;
  // padding: 9.5px;
  align-items: center;
  width: 100%;
  background-color: ${(props) =>
    props.$isWeekend ? '#C4C4C4' : 'transparent'};
  border-right: 1px solid #a7a7a7;
  border-top: 1px solid #a7a7a7;
  border-bottom: ${(props) =>
    props.$hasBottomBorder ? '1px solid #a7a7a7' : 'none'};
  counter-increment: date;

  ${({ $dateType, $showDate = false }) =>
    $dateType === DateRangeType.year &&
    $showDate &&
    css`
      &::before {
        position: absolute;
        top: 0;
        content: counter(date);
        color: #07334a;

        ${defaultTheme.breakpoints.down('xl')} {
          display: none;
        }
      }
    `}

  ${({ $dateType }) =>
    $dateType === DateRangeType.month &&
    css`
      &::before {
        position: absolute;
        top: 0;
        content: counter(date);
        color: #07334a;

        ${defaultTheme.breakpoints.down('md')} {
          display: none;
        }
      }
    `}
`;

const CalendarLabelContainer = styled(CalendarDay)<{
  hasBackground?: boolean;
  $labelWidth: number;
}>`
  flex: 0 0 ${(props) => props.$labelWidth}px;
  display: flex;
  flex-direction: column;
  position: relative;

  background-color: white;
  counter-increment: none;
  border-top: none;
  border-left: none;
  border-bottom: none;
  border-color: #07334a;
  ${(props) =>
    props.hasBackground &&
    css`
      background: #f7f7f7;
    `}

  && {
    .MuiTypography-root {
      font-size: 18px;
      font-weight: 500;
    }
  }
  &:before {
    display: none;
  }
`;

const ScheduleList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  transition: flex-grow 1000ms linear;
  padding-bottom: 150px;
  position: relative;

  ${(props) => props.theme.breakpoints.down('xl')} {
    overflow-x: scroll;
  }

  &:before {
    content: 'Tags';
    position: absolute;
    left: 15px;
    top: 0;
    line-height: 50px;
    font-size: 17px;
    font-weight: bold;
  }
`;

const ScheduleBodyItem = styled.li`
  height: 114px;
  background: #ffffff;
  &:nth-child(odd) {
    background: #f7f7f7;
  }
`;

const ScheduleBodyList = styled.ul`
  display: flex;
  height: 100%;
  justify-content: space-between;
  list-style: none;
  position: relative;
  counter-reset: date;
  background-color: inherit;

  &.noData {
    overflow: hidden;
  }
`;

const NoTagsList = styled(ScheduleBodyList)`
  border: dashed #a7a7a7 1px;
  overflow: hidden;

  li {
    border: none;
  }
`;

const DaysContainer = styled.div``;
const NoTags = styled.div`
  position: absolute;
  width: calc(100% - 150px);
  right: 0;
  height: 100%;
  background-color: inherit;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-top: solid 1px;
  h5 {
    color: #5c5a5a;
  }
  svg {
    color: #07334a;
    font-size: 30px;
  }

  h5:last-of-type {
    font-size: 16px;
    font-weight: normal;
  }

  &.noTags {
    width: 100%;
    height: 100%;
    border-top: none;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    top: 1%;
    width: calc(100% - 150px);
    right: 0;
    transform: none;
    left: unset;
    height: 100%;
  }
`;

interface ICalendarViewProps {
  projects?: TaskmanagerPotGanttDto[] | undefined;
  setTags: Dispatch<SetStateAction<string[]>>;
}

const CalendarView = ({
  projects,
  setTags,
}: ICalendarViewProps): JSX.Element => {
  const ITEM_PADDING = 9.5;
  const labelWidth = 150;
  const { dateRangeType, dateRange } = useDateRangeContext();
  const ref = useRef<HTMLUListElement>(null);
  const [columnWidth, setColumnWidth] = useState<number>(0);

  useEffect(() => {
    if (dateRange && ref.current && ref.current.clientWidth) {
      const itemWidth =
        (ref.current.clientWidth - labelWidth) / dateRange.scheduleDates.length;
      setColumnWidth(itemWidth);
    }
  }, [ref.current?.clientWidth, dateRange?.scheduleDates]);

  const [tagProjects, setTagProjects] = useState<
    TaskmanagerPotGanttDto[] | undefined
  >(projects);

  const handleRemove = (project: TaskmanagerPotGanttDto) => () => {
    setTags((prevState) => prevState.filter((tag) => tag !== project.name));
  };

  return (
    <ScheduleList ref={ref}>
      <div>
        <ScheduleHeader
          labelWidth={labelWidth}
          dateRangeType={dateRangeType}
          dateRange={dateRange}
        />
        <DaysContainer>
          {projects?.length === 0 && (
            <ScheduleBodyItem>
              <NoTagsList>
                <CalendarLabelContainer
                  $labelWidth={labelWidth}
                  hasBackground
                  columnWidth={columnWidth}
                />
                {dateRange?.scheduleDates.map((date) => (
                  <CalendarDay
                    $dateType={dateRangeType}
                    $hasBottomBorder
                    $showDate
                    $isWeekend={
                      dateRangeType !== DateRangeType.year &&
                      (date.getDay() === 0 || date.getDay() === 6)
                    }
                    columnWidth={columnWidth}
                    key={date.toDateString()}
                  >
                    <div style={{ width: '100%' }} />
                  </CalendarDay>
                ))}
                <NoTags className="noTags">
                  <SearchIcon scale={1.7} />
                  <Typography variant="h5">
                    No information to display
                  </Typography>
                  <Typography variant="h5">
                    Search for tags above to display information
                  </Typography>
                </NoTags>
              </NoTagsList>
            </ScheduleBodyItem>
          )}
          {projects?.map((project, i) => (
            <ScheduleBodyItem key={i}>
              <ScheduleBodyList
                className={project.date_start === '' ? 'noData' : ''}
              >
                <CalendarLabelContainer
                  $isWeekend={false}
                  $labelWidth={labelWidth}
                  hasBackground={i % 2 === 0}
                  columnWidth={columnWidth}
                >
                  <ProjectTag
                    project={project}
                    onRemove={handleRemove(project)}
                  />
                </CalendarLabelContainer>
                {dateRange?.scheduleDates.map((date) => (
                  <CalendarDay
                    $hasBottomBorder={projects?.length - 1 === i}
                    $dateType={dateRangeType}
                    $showDate={i === 0}
                    $isWeekend={
                      dateRangeType !== DateRangeType.year &&
                      (date.getDay() === 0 || date.getDay() === 6)
                    }
                    columnWidth={columnWidth}
                    key={date.toDateString()}
                  >
                    <div style={{ width: '100%' }} />
                  </CalendarDay>
                ))}
                {project.date_start !== '' ? (
                  <ProjectItem
                    zIndex={projects?.length - i}
                    labelWidth={labelWidth}
                    columnWidth={columnWidth}
                    project={project}
                  >
                    {project.name}
                  </ProjectItem>
                ) : (
                  <NoTags>
                    <SearchIcon scale={1.7} />
                    <Typography variant="h5">
                      No information to display
                    </Typography>
                    <Typography variant="h5">
                      Search for tags above to display information
                    </Typography>
                  </NoTags>
                )}
              </ScheduleBodyList>
            </ScheduleBodyItem>
          ))}
        </DaysContainer>
      </div>
    </ScheduleList>
  );
};

export default CalendarView;

/**
 * Module dependencies.
 */

import { Skeleton } from '@mui/material';
import styled from '@emotion/styled/macro';

const ScheduleBodyItem = styled.li`
  list-style-type: none;
  height: 114px;
  &:nth-child(even) {
    background: #f7f7f7;
  }
`;

const ScheduleBodyList = styled.ul`
  display: flex;
  height: 100%;
  justify-content: space-between;
  list-style: none;
  counter-reset: date;
`;

const UserScheduleItem = styled.li`
  position: relative;
  display: table;
  table-layout: fixed;
  justify-content: center;
  max-width: auto;
  padding: 9.5px;
  align-items: center;
  width: 100%;
  border-right: 1px solid #07334a;
`;

const UserItem = styled(UserScheduleItem)`
  flex: 0 0 100px;
  display: flex;
  flex-direction: column;
`;

const ScheduleDateWrapper = styled.div`
  display: table-cell;
  width: 100%;
  vertical-align: middle;
`;

const ScheduleBodyShimmer = (): JSX.Element => (
  <>
    <ScheduleBodyItem>
      <ScheduleBodyList>
        <UserItem>
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="text" width="100%" />
        </UserItem>
        <UserScheduleItem>
          <ScheduleDateWrapper>
            <Skeleton variant="rectangular" width="100%" height={60} />
          </ScheduleDateWrapper>
        </UserScheduleItem>
        <UserScheduleItem>
          <ScheduleDateWrapper>
            <Skeleton variant="rectangular" width="100%" height={60} />
          </ScheduleDateWrapper>
        </UserScheduleItem>
        <UserScheduleItem>
          <ScheduleDateWrapper>
            <Skeleton variant="rectangular" width="100%" height={60} />
          </ScheduleDateWrapper>
        </UserScheduleItem>
        <UserScheduleItem>
          <ScheduleDateWrapper>
            <Skeleton variant="rectangular" width="100%" height={60} />
          </ScheduleDateWrapper>
        </UserScheduleItem>
        <UserScheduleItem>
          <ScheduleDateWrapper>
            <Skeleton variant="rectangular" width="100%" height={60} />
          </ScheduleDateWrapper>
        </UserScheduleItem>
      </ScheduleBodyList>
    </ScheduleBodyItem>
  </>
);

export default ScheduleBodyShimmer;

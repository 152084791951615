import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { api } from 'core/services/api.service';

const useIndexProjectTickets = (key: string) => {
  const [search, setSearch] = useState<string | undefined>();
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [pageNumber, setPageNumber] = useState(1);

  const {
    data: indexPotsResponse,
    isLoading: indexPotsLoading,
    refetch,
  } = useQuery(
    [key],
    () =>
      api.potsService.indexTaskmanagerPot({
        params: {
          limit: 21,
          page: pageNumber,
          'pot_type@name': key,
          'tags,parent_pots@name': search,
        },
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    refetch();
  }, [search, selectedProjects, pageNumber]);

  return {
    ...indexPotsResponse?.data,
    indexPotsLoading,
    pageNumber,
    setPageNumber,
    setSelectedProjects,
    selectedProjects,
    setSearch,
    refetch,
  };
};

export default useIndexProjectTickets;

import { FormControl, FormHelperText } from '@mui/material';
import useFormField from 'core/hooks/use-form-field';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import * as S from 'users/components/roles-checkbox/roles-checkbox.styles';

interface CheckboxProps {
  id: string;
  options: string[];
  hideError?: boolean;
}

export const RolesCheckbox = ({
  id,
  options,
  hideError = false,
}: CheckboxProps) => {
  const { errorLabel, hasError } = useFormField(id);
  const { setValue, getValues, ...inputProps } = useFormContext();
  const [checked, setChecked] = useState<string[]>(getValues(id) ?? []);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkboxValue = event.target.value;

    if (event.target.checked) {
      setChecked((prev) => [...prev, checkboxValue]);
    } else {
      setChecked((prev) =>
        prev.filter((selectedValue) => selectedValue !== checkboxValue),
      );
    }
  };

  useEffect(() => {
    setValue(
      id,
      checked?.map((item) => item),
    );
  }, [checked, id, setValue]);

  return (
    <FormControl error={hasError}>
      <S.FlexContainer>
        {options &&
          options.map((option, index) => (
            <S.SwitchLabel
              key={option[index]}
              label={option}
              control={
                <S.Switch
                  id={id}
                  value={option}
                  onChange={handleCheckboxChange}
                  defaultChecked={
                    checked.some((value) => value === option) ?? false
                  }
                  inputProps={{ 'aria-label': 'controlled' }}
                  {...inputProps}
                />
              }
            />
          ))}
      </S.FlexContainer>
      {hasError && !hideError && <FormHelperText>{errorLabel}</FormHelperText>}
    </FormControl>
  );
};

import { useQuery } from 'react-query';
import { api } from 'core/services/api.service';
import { TaskmanagerEventTypeEnum } from 'gateway-api';
import { DateTime } from 'luxon';
import { DateRangeType } from 'core/hooks/use-date-range';
import useAuthUser from 'auth/hooks/use-auth-user';

interface IUseIndexSchedule {
  key?: string;
  eventType: TaskmanagerEventTypeEnum;
  selectedUsers: string[];
  startDate: Date;
  endDate: Date;
  dateRangeType: DateRangeType;
}

export const toApiDate = (date: Date) =>
  DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');

const useIndexSchedule = ({
  key,
  eventType,
  selectedUsers,
  startDate,
  endDate,
  dateRangeType,
}: IUseIndexSchedule) => {
  const user = useAuthUser();

  const getSelectedUsers = (): string | undefined => {
    if (
      eventType === TaskmanagerEventTypeEnum.Complete &&
      user &&
      window.location.pathname === '/add-time'
    ) {
      return user.id;
    }

    if (
      eventType === TaskmanagerEventTypeEnum.Schedule &&
      window.location.pathname === '/add-time' &&
      user
    ) {
      return user.id;
    }

    if (selectedUsers && selectedUsers.length) {
      return selectedUsers.join(',');
    }

    return undefined;
  };

  const {
    data: scheduleResponse,
    isLoading: scheduleLoading,
    isFetching: scheduleFetching,
    refetch,
  } = useQuery(
    [eventType, startDate, endDate, selectedUsers, dateRangeType],
    () =>
      api.scheduleService.indexTaskmanagerSchedule({
        params: {
          date_format: dateRangeType === DateRangeType.year ? 'week' : 'day',
          event_type: eventType,
          'date_start[gte]': toApiDate(startDate),
          'date_start[lte]': toApiDate(endDate),
          'uuid[in]': getSelectedUsers(),
        },
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    ...scheduleResponse?.data,
    refetch,
    scheduleLoading,
    scheduleFetching,
  };
};

export default useIndexSchedule;

import { DateFormats } from 'date-range/hooks/use-date-format';
import DateRangeType from 'date-range/models/date-range-type.enum';
import { useDateRange } from 'date-range/store/date-range';
import * as S from 'date-range/components/calendar-header/calendar-header.styles';
import useAuthUser from 'auth/hooks/use-auth-user';
import { isInRole } from 'routes/protected-route';
import { useIndexNotes } from 'schedule/notes/queries/notes/use-index-notes';
import { notesKeys } from 'schedule/notes/queries/notes/notes.keys';
import { DateTime } from 'luxon';
import { Notes } from 'schedule/notes/components/notes/notes';
import {
  ViewNoteIcon,
  AddNoteIcon,
} from 'schedule/notes/components/notes/notes.styles';
import EditNoteIcon from 'assets/images/note-edit.svg';

interface ICalendarHeader {
  leadingWidth?: number;
}

/**
 * Calendar Header
 *
 * This component is used to display all of the header dates for the calendar component.
 *
 * Header dates are automatically pulled from the date range context using the useDateRange hook.
 *
 * The dates within the header are formatted depending on which DateRangeType is currently selected.
 *
 * For Example:
 * * DateRangeType.day = Saturday 1st January 2022
 * * DateRangeType.week = Saturday 1st January 2022, Sunday 2nd January 2022...
 * * DateRangeType.month = January, February, March...
 * * DateRangeType.year = Jan, Feb, Mar, Apr...
 */
export const CalendarHeader = ({ leadingWidth }: ICalendarHeader) => {
  const user = useAuthUser();
  const isAdmin = !isInRole(user?.roles?.taskmanager, ['admin']);
  const {
    state: { headerDates, dateRangeType, startDate, endDate },
  } = useDateRange();
  const { getWeekFormat, getMonthFormat, getYearFormat, getNewWeekFormat } =
    DateFormats;

  const { data: noteList } = useIndexNotes(
    notesKeys.indexNotes({
      startDate: startDate.toFormat('yyyy-MM-dd').toString(),
      endDate: endDate.toFormat('yyyy-MM-dd').toString(),
    }),
  );

  const dateNote = (date: DateTime) => {
    // match date with note.date
    const dateString = date.toFormat('yyyy-MM-dd HH:mm:ss').toString();

    const dateFilter = noteList?.filter((note) => note.date === dateString);

    if (dateFilter && dateFilter[0]) {
      return (
        <Notes
          date={dateString}
          note={dateFilter[0]}
          icon={<ViewNoteIcon src={EditNoteIcon} />}
        />
      );
    }

    return (
      <>
        {isAdmin ? <></> : <Notes date={dateString} icon={<AddNoteIcon />} />}
      </>
    );
  };

  return (
    <S.CalendarHeaderContainer>
      <S.CalendarHeaderItems>
        {leadingWidth && <S.CalendarHeaderItem width={leadingWidth} />}
        {headerDates.map((date) => (
          <S.CalendarHeaderItem key={date.toString()}>
            {dateRangeType === DateRangeType.day && getWeekFormat(date)}
            {dateRangeType === DateRangeType.week && getNewWeekFormat(date)}
            {dateRangeType === DateRangeType.week && dateNote(date)}
            {dateRangeType === DateRangeType.month && getMonthFormat(date)}
            {dateRangeType === DateRangeType.year && getYearFormat(date)}
          </S.CalendarHeaderItem>
        ))}
      </S.CalendarHeaderItems>
    </S.CalendarHeaderContainer>
  );
};

import styled from '@emotion/styled/macro';
import { InputLabel, Select, SelectChangeEvent } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FilterListIcon from '@mui/icons-material/FilterList';
import { defaultTheme } from 'theme/theme';

const StyledContainer = styled.div`
  margin-right: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 50px;

  &:last-child {
    margin-right: 0;
  }
`;

const StyledSelect = styled(Select)`
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiSelect-multiple {
    font-size: 0;
    display: flex;
    flex-wrap: wrap;

    div {
      background-color: #a0b1d3;
      color: white;
      border-radius: 16px;
      padding: 4px 12px;
      font-size: 14px;
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  .MuiSelect-icon {
    top: auto;
    right: 10px;

    &Open {
      transform: none;
      right: 10px;
    }

    &:hover {
      background-color: rgba(7, 51, 74, 0.04);
    }
  }
`;

const StyledLabel = styled(InputLabel)`
  && {
    display: inline-block;
    font-size: 18px;
    color: ${defaultTheme.colors.primaryColor};
    font-weight: 500;
  }
`;

export interface IFilterDropDown {
  id: string | undefined;
  name: string | undefined;
  lastName?: string | undefined;
  disabled?: boolean | undefined;
}

export interface ISelectedValue {
  selectValue: string[];
}

interface ISelectProps {
  options: IFilterDropDown[];
  id?: string;
  label: string;
  labelId?: string;
  selectedValues: string[];
  onChange: (selectedOptions: string[]) => void;
}

const MultiSelectFilter = ({
  options,
  id,
  label,
  labelId,
  selectedValues,
  onChange,
}: ISelectProps): JSX.Element => {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const e = event as SelectChangeEvent<string>;
    const {
      target: { value },
    } = e;
    onChange([...value]);
  };

  return (
    <StyledContainer>
      <StyledLabel id={labelId}>{label}</StyledLabel>
      <StyledSelect
        multiple
        value={selectedValues}
        labelId={labelId}
        id={id}
        onChange={handleChange}
        IconComponent={(props) => (
          <i {...props}>
            <FilterListIcon />
          </i>
        )}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            <div>{option.name}</div>
          </MenuItem>
        ))}
      </StyledSelect>
    </StyledContainer>
  );
};

export default MultiSelectFilter;

import { Reducer } from 'react';
import { UserSelectState } from 'users/reducers/user-select/user-select.state';
import { UserSelectAction } from 'users/reducers/user-select/user-select.action';

const userSelectReducer: Reducer<UserSelectState, UserSelectAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case 'new_user_form_closed':
      return { ...state, newUserFormOpen: false };
    case 'new_user_form_opened':
      return { ...state, newUserFormOpen: true };
    case 'skills_selected':
      return {
        ...state,
        selectedSkills: action.payload,
      };
    case 'inactive_accounts_changed':
      return {
        ...state,
        showInactiveAccounts: action.payload,
      };
    case 'roles_selected':
      return {
        ...state,
        selectedRoles: action.payload,
      };

    case 'search_changed':
      return {
        ...state,
        search: action.payload,
      };
    default:
      return state;
  }
};
export default userSelectReducer;

/**
 * Module dependencies.
 */

import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import {
  IDragDroptStore,
  initialState,
} from 'schedule/core/store/drag-drop/drag-drop.state';

const useDragDropStore = create<IDragDroptStore>()(
  immer(
    devtools(
      (set) => ({
        state: initialState,
        setDraggableEvent(event) {
          set(
            (store) => {
              store.state.draggableEvent = event;
              store.state.draggablePot = undefined;
            },
            false,
            { type: 'drag-drop-store__setDraggableEvent', event },
          );
        },
        setIsCloneKeyDown(isDown) {
          set(
            (store) => {
              store.state.isCloneKeyDown = isDown;
            },
            false,
            { type: 'drag-drop-store__setIsKeyDown' },
          );
        },
        setDraggablePot(pot) {
          set(
            (store) => {
              store.state.draggableEvent = undefined;
              store.state.draggablePot = pot;
            },
            false,
            { type: 'drag-drop-store__setDraggablePot', pot },
          );
        },
        onBeforeCapture(before) {
          set(
            (store) => {
              store.state.draggableId = before.draggableId;
            },
            false,
            { type: 'drag-drop-store__setDraggableObject', before },
          );
        },
        setAddPot(addPot) {
          set(
            (store) => {
              store.state.addPot = addPot;
            },
            false,
            { type: 'drag-drop-store__setPotWithDate', addPot },
          );
        },
        setEditEventEntry(popupEvent) {
          set(
            (store) => {
              store.state.editEventEntry = popupEvent;
            },
            false,
            { type: 'drag-drop-store__setEditEventEntry', popupEvent },
          );
        },
        setCreateEvent(event) {
          set(
            (store) => {
              store.state.createEvent = event;
              store.state.draggablePot = undefined;
            },
            false,
            { type: 'drag-drop-store__setCreateEvent', event },
          );
        },
        clear() {
          set(
            (store) => {
              store.state = initialState;
            },
            false,
            { type: 'drag-drop-store__onAbortAction', initialState },
          );
        },
      }),
      {
        name: 'drag-drop-store',
      },
    ),
  ),
);

export default useDragDropStore;

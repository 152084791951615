import { TaskmanagerEventDto, TaskmanagerPotDto } from 'gateway-api';
import { BeforeCapture } from 'react-beautiful-dnd';
import { IAddPot } from 'schedule/core/hooks/schedule-drag-end';

/**
 * This is the `drag-drop` store, all state object can be undefined
 * because, at the start there's no draggable or when you have an event
 * you can't have a pot.
 */

export interface IDragDropState {
  state: {
    draggableId?: string;
    draggableEvent?: TaskmanagerEventDto;
    draggablePot?: TaskmanagerPotDto;
    createEvent?: TaskmanagerEventDto;
    editEventEntry?: TaskmanagerEventDto;
    addPot?: IAddPot;
    page?: string;
    isCloneKeyDown: boolean;
  };
}

export const initialState: IDragDroptPayload = {
  draggableId: undefined,
  draggableEvent: undefined,
  draggablePot: undefined,
  createEvent: undefined,
  addPot: undefined,
  editEventEntry: undefined,
  isCloneKeyDown: false,
};

export interface IDragDroptStore extends IDragDropState {
  onBeforeCapture: (before: BeforeCapture) => void;
  setDraggableEvent: (event: TaskmanagerEventDto) => void;
  setDraggablePot: (pot: TaskmanagerPotDto) => void;
  setCreateEvent: (event: TaskmanagerEventDto) => void;
  setAddPot: (event: IAddPot) => void;
  setEditEventEntry: (popupEvent: TaskmanagerEventDto) => void;
  setIsCloneKeyDown: (isDown: boolean) => void;
  clear: () => void;
}

export interface IDragDroptPayload {
  draggableId?: string;
  draggableEvent?: TaskmanagerEventDto;
  draggablePot?: TaskmanagerPotDto;
  createEvent?: TaskmanagerEventDto;
  editEventEntry?: TaskmanagerEventDto;
  addPot?: IAddPot;
  page?: string;
  isCloneKeyDown: boolean;
}

/**
 * Module dependencies,
 */

import { useContext, createContext } from 'react';
import { IIndexSchedule } from 'schedule/core/queries/schedule.keys';

export enum PageEnum {
  SCHEDULE = 'schedule',
  ADD_TIME = 'add-time',
  ESTIMATE = 'estimate',
  RECORDED = 'recorded',
  MULTI_VIEW = 'multi-view',
}

interface IEventType {
  scheduleParams: IIndexSchedule;
  page: PageEnum;
}

interface IProps extends IEventType {
  children: JSX.Element | JSX.Element[];
}

const EventTypeContext = createContext<IEventType>({} as IEventType);

/**
 * Function `EventTypeProvider` provider.
 *
 * @param props
 * @returns JSX.Element
 */

export function EventTypeProvider(props: IProps): JSX.Element {
  const { children, page, scheduleParams } = props;

  return (
    <EventTypeContext.Provider value={{ page, scheduleParams }}>
      {children}
    </EventTypeContext.Provider>
  );
}

/**
 * Function `useEventTypeProvider` hook.
 *
 * @returns { IIndexSchedule } `querykey`
 * @returns { PageEnum } `page`
 */

export default function useEventTypeProvider() {
  return useContext(EventTypeContext);
}

interface IIndexNotes {
  startDate?: string;
  endDate?: string;
}

export const notesKeys = {
  base: [{ scope: "notes" }] as const,
  index: () => [{ ...notesKeys.base[0], entity: "index" }] as const,
  indexNotes: (query: IIndexNotes) =>
    [{ ...notesKeys.index()[0], ...query }] as const,
};

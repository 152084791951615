import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import { FaSearch } from 'react-icons/fa';
import Scaffold from 'core/components/scaffolds/scaffold';

const MainSection = styled.main`
  display: flex;

  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  background-color: #ffffff;
  margin: 40px 24px;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;

  ${(props) => props.theme.breakpoints.up('lg')} {
    margin: 40px 60px;
  }
`;

const SystemMessage = styled.div`
  background-color: #f6f8f9;
  padding: 50px;
  font-size: 1.25em;
  text-align: center;
`;

const Title = styled(Typography)`
  margin-bottom: 20px;
`;

const SearchIcon = styled(FaSearch)`
  font-size: 5.625em;
  margin-bottom: 25px;
`;

const Forbidden = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Scaffold headerTitle={t('403.title')}>
      <SystemMessage>
        <SearchIcon />
        <Title variant="h2">{t('403.sub_title')}</Title>
        <Typography variant="body1">{t('403.body_text')}</Typography>
        <Typography variant="body1">{t('403.body_text_1')}</Typography>
      </SystemMessage>
    </Scaffold>
  );
};

export default Forbidden;

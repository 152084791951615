import styled from '@emotion/styled/macro';
import { LoadingButton } from '@mui/lab';
import { ButtonProps } from '@mui/material';
import { ForwardedRef, forwardRef } from 'react';

export const StyledButton = styled(LoadingButton)<{
  variant: string;
}>`
  &.MuiButton-root {
    width: auto;
    height: auto;
    font-weight: 500;
    letter-spacing: 1.25px;
    padding: 3px 16px;
    background-color: ${(props) =>
      props.variant === 'outlined' || props.variant === 'text'
        ? 'none'
        : props.theme.colors.accentColor};
  }
  & .MuiLoadingButton-loadingIndicator {
    color: ${(props) =>
      props.variant === 'outlined' || props.variant === 'text'
        ? props.theme.colors.textPrimary
        : '#ffffff'};
  }
`;

interface IPrimaryButtonProps extends ButtonProps {
  className?: string;
  loading?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  children?: React.ReactNode;
  iconPosition?: 'start' | 'end';
  buttonIcon?: React.ReactNode;
  type?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
}

const PrimaryButton = forwardRef(
  (
    {
      className,
      loading = false,
      children,
      buttonIcon,
      disabled = false,
      iconPosition = 'start',
      variant = 'contained',
      type = 'submit',
      ...rest
    }: IPrimaryButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ): JSX.Element => (
    <StyledButton
      ref={ref}
      type={type}
      startIcon={buttonIcon && iconPosition === 'start' && buttonIcon}
      endIcon={buttonIcon && iconPosition === 'end' && buttonIcon}
      loading={loading}
      variant={variant}
      className={className}
      disabled={disabled}
      {...rest}
    >
      {children}
    </StyledButton>
  ),
);

export default PrimaryButton;

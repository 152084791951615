import { Typography } from '@mui/material';
import styled from '@emotion/styled/macro';

interface ILeaveContainer {
  width: number;
}

export const LeaveContainer = styled.div<ILeaveContainer>`
  position: relative;
  background-color: #f6ac69;
  font-size: 1.125em;
  border-radius: 4px;
  height: 100%;
  width: ${({ width }) => `${width}px`};

  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  ${(props) => props.theme.breakpoints.down('md')} {
    min-width: ${({ width }) => `${width}px`};
    font-size: 0;

    p {
      font-size: 0;
    }
  }
`;

export const LeaveTitle = styled(Typography)`
  font-size: 1em;
  text-transform: capitalize;
  margin-right: 0.5rem;
`;

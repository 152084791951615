import { MenuItem, Typography } from '@mui/material';
import styled from '@emotion/styled/macro';
import UserAvatar from 'user/components/user-avatar/user-avatar';

interface IEventDetails {
  company: string;
}

export const TooltipDetails = styled.div<IEventDetails>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    background-color: ${(props) =>
      props.company === 'arch' ? '#A0CED9' : '#f27777'};
    top: 0;
    height: 8px;
  }
`;

export const TooltipBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 5px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
`;

export const TooltipHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
`;

export const TicketEventTitle = styled(Typography)<IEventDetails>`
  && {
    background-color: ${(props) =>
      props.company === 'arch' ? '#A0CED9' : '#f27777'};
    padding: 1px 15px;
    font-weight: bold;
    border-radius: 8px;
    color: #07334a;
    max-width: calc(100% - 75px);
    margin-right: auto;
  }
`;

export const TicketEventContent = styled.div`
  width: 100%;
`;

export const TicketEventDescription = styled(Typography)`
  margin-bottom: 14px;
`;

export const JiraLogo = styled.img`
  width: 32px;
  margin-left: 10px;
`;

export const JiraLink = styled.a`
  color: #2e559f;
  text-decoration: none;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  margin-left: 10px;
`;

export const TicketEventUsers = styled.div`
  /* display: flex;
  flex-wrap: wrap; */
  width: 100%;
`;

export const UsersSelectWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  justify-content: flex-start;
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  flex-wrap: wrap;
`;

export const CompanyFilter = styled.div`
  grid-row: 2;
  display: flex;
  justify-content: space-between;

  label {
    color: #07334a;
    font-size: 0.875em;
  }

  div {
    border: 1px solid #e6e6e6;
    border-radius: 5rem;
    margin-bottom: 1.5rem;
  }

  input[type='radio'] + label {
    border-radius: 5rem;
    padding: 0.5rem 0.725rem;
  }

  input[type='radio']:checked + label {
    color: white;
    background-color: #07334a;
  }
`;

export const UsersWrapper = styled.div`
  grid-row: 1;
`;

export const TicketEventUser = styled(UserAvatar)`
  && {
    margin: 0 8px 8px 0;
    div {
      background-color: #cecece;

      font-size: 14px !important;
    }
  }
`;

export const ButtonContainer = styled.div`
  && {
    display: flex;
    justify-content: flex-end;

    .MuiButton-root {
      width: 140px;
      box-shadow: 0px 3px 6px #00000029;
    }
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 13px;
`;

/**
 * Module dependencies.
 */

import useUser from 'user/hooks/use-show-user';
import { Loading } from 'schedule/core/components/ticket-content/ticket-content.styles';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import useAuthUser from 'auth/hooks/use-auth-user';
import Scaffold from 'core/components/scaffolds/scaffold';
import * as S from 'schedule/core/pages/home/home.style';

const Home = () => {
  const user = useAuthUser();
  const { data: userData, error, isLoading } = useUser(user?.id ?? 'no-id');
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  if (error) {
    enqueueSnackbar('An error occurred whilst loading your user.', {
      variant: 'error',
    });
  }

  return (
    <Scaffold
      headerTitle={t('dashboard.title')}
      pageTitle={t('dashboard.title')}
    >
      <S.FlexContainer>
        <>
          {error &&
            'Your user cannot be displayed at this time. Please contact your system administrator.'}
          {!error &&
            (isLoading ? (
              <Loading />
            ) : (
              <>
                <S.SectionContent>
                  {`${t('dashboard.intro')} ${
                    userData && userData.data?.first_name
                  } ${userData && userData.data?.last_name} (${
                    userData && userData.data?.username
                  })`}
                </S.SectionContent>
                <S.SectionContent>
                  {userData &&
                    userData.data.services.taskmanager.roles?.map((role) => (
                      <span key={role} className='roleTag'>
                        {role}
                      </span>
                    ))}
                </S.SectionContent>
              </>
            ))}
        </>
      </S.FlexContainer>
    </Scaffold>
  );
};

export default Home;

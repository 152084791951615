import styled from '@emotion/styled/macro';

interface IEvent {
  fontColor: string;
  backgroundColor: string;
}

export const Event = styled.div<IEvent>`
  position: relative;
  background-color: ${({ backgroundColor }) => backgroundColor};
  font-size: 18px;
  border-radius: 4px;
  height: 100%;
  width: '100%';
  color: ${({ fontColor }) => fontColor};
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .hover-icon {
    position: absolute;
    top: -5px;
    right: -5px;
    transform: rotate(315deg);

    &:hover {
      cursor: col-resize;
    }
  }
`;

export const TimeTicker = styled.div`
  position: absolute;
  top: -47px;
  right: -40px;
  display: flex;
  height: 30px;
  width: 80px;
  background: #08334a;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  z-index: 1;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 7px solid #08334a;
  }
`;

export const ClonePlaceholder = styled.div<{
  isDragging: boolean;
}>`
  width: 100%;
  height: 60px;
  border: 1px black;
  border-style: dashed;
  position: absolute;
  top: 0;
  background-color: #c7c7c7;
  opacity: 0.3;
  display: ${(props) => (props.isDragging ? 'block' : 'none')};
`;

export const TicketContainer = styled.div`
  height: 100%;
`;

import { Modal, Typography } from '@mui/material';
import styled from '@emotion/styled/macro';
import CloseIcon from '@mui/icons-material/Close';

interface IPopupWrapperProps {
  width?: string;
  height?: string;
}

const StyledModal = styled(Modal)`
  && {
    .MuiBackdrop-root {
      background-color: #f6f8f9bf;
    }
  }
`;

const PopupWrapper = styled.div<IPopupWrapperProps>`
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 3px 4px #00000024;
  max-width: ${(props) => (props.width ? props.width : '80vw')};
  width: calc(100% - 40px);

  margin: 20px auto;
  position: relative;
  top: 50% !important;
  transform: translateY(-50%) !important;

  &:focus-visible {
    outline: none !important;
  }
`;

const PopupHeader = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-bottom: 15px;

  @media only screen and (max-width: 768px) {
    justify-content: flex-start;
  }
`;

const StyledButton = styled.button`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  border: none;

  @media only screen and (max-width: 768px) {
    right: -15px;
  }
`;

const PopupHeaderTitle = styled(Typography)`
  && {
    font-weight: bold;
    font-size: 32px;
    margin: 0 30px;

    @media only screen and (max-width: 768px) {
      font-size: 20px;
      margin: 0;
    }
  }
`;

export interface PopupProps {
  children?: React.ReactNode;
  active: boolean;
  width?: string;
  title?: React.ReactNode;
  className?: string;
  closePopup: React.MouseEventHandler<HTMLButtonElement>;
}

const Popup = ({
  active,
  className,
  closePopup,
  title,
  width,
  children,
}: PopupProps): JSX.Element => (
  <>
    <StyledModal
      open={active}
      onClose={closePopup}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className={className}
    >
      <PopupWrapper width={width} className="pop-up-content">
        <PopupHeader>
          <PopupHeaderTitle>{title}</PopupHeaderTitle>
          <StyledButton onClick={closePopup}>
            <CloseIcon />
          </StyledButton>
        </PopupHeader>
        {children}
      </PopupWrapper>
    </StyledModal>
  </>
);

export default Popup;

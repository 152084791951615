import { api } from 'core/services/api.service';
import { useMutation, useQueryClient } from 'react-query';
import { notesKeys } from 'schedule/notes/queries/notes/notes.keys';
import { useDateRange } from 'date-range/store/date-range';
import { useSnackbar } from 'notistack';
import { TaskmanagerNoteDto, TaskmanagerNoteRequestDto } from 'gateway-api';

const storeNotes = (note: TaskmanagerNoteRequestDto) =>
  api.noteService.storeTaskmanagerNote(note);

export const useStoreNotes = () => {
  const {
    state: { startDate, endDate },
  } = useDateRange();

  const queryKey = notesKeys.indexNotes({
    startDate: startDate.toFormat('yyyy-MM-dd'),
    endDate: endDate.toFormat('yyyy-MM-dd'),
  });

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(storeNotes, {
    onMutate: async (note) => {
      await queryClient.cancelQueries(queryKey);

      const prevNotes =
        queryClient.getQueryData<TaskmanagerNoteDto[]>(queryKey);

      // Add new note to query data along with filtered notes.
      queryClient.setQueryData(
        queryKey,
        (old: TaskmanagerNoteDto[] | undefined) => [
          ...(old ?? []),
          { ...note, id: -1 },
        ],
      );

      return { prevNotes };
    },
    onError: (err, newNote, context) => {
      queryClient.setQueryData(queryKey, context?.prevNotes);
      enqueueSnackbar('An error occurred whilst trying to add note.', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Successfully added your note', {
        variant: 'success',
      });
    },
    onSettled: () => queryClient.invalidateQueries(queryKey),
  });
};

import {
  Switch,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled/macro';
import useFormField from 'core/hooks/use-form-field';
import { useFormContext } from 'react-hook-form';

const StyledSwitch = styled(Switch)`
  && {
    position: absolute;
    padding: 0;
    width: 100%;
    height: 26px;

    .MuiSwitch-track {
      border-radius: 16px;
      opacity: 1;
      z-index: 1;
      background-color: #e4e4e4;
    }

    .MuiSwitch-switchBase {
      padding: 0;
      width: 100%;
      height: 100%;

      .MuiSwitch-input {
        left: 0;
        width: 100%;
      }

      .MuiSwitch-thumb {
        display: none;
      }
    }

    .Mui-checked {
      transform: translateX(0);

      & + .MuiSwitch-track {
        background-color: #a0b1d3;
        opacity: 1;
      }
    }
  }
`;

const SwitchLabel = styled(FormControlLabel)`
  position: relative;
  margin-bottom: 15px;

  .MuiFormControlLabel-label {
    padding: 0 30px;
    z-index: 2;
  }
`;

interface ISwitchButton {
  labelLeft?: string;
  labelRight?: string;
  checked?: boolean;
}

interface ICheckboxProps {
  id: string;
  value?: boolean;
  labelLeft: string;
  labelRight: string;
  className?: string;
  disabled?: boolean;
  hideError?: boolean;
  checked?: boolean;
}

const SwitchField = ({
  id,
  labelLeft,
  labelRight,
  className,
  checked,
  value,
  hideError = false,
  disabled = false,
}: ICheckboxProps): JSX.Element => {
  const { errorLabel, hasError, onChange, ...inputProps } = useFormField(id);
  const { getValues } = useFormContext();
  return (
    <FormControl error={hasError}>
      <Stack direction='row' spacing={1} alignItems='center'>
        <Typography>{labelLeft}</Typography>
        <Switch
          id={id}
          onChange={onChange}
          defaultChecked={getValues(id)}
          disabled={disabled}
          inputProps={{ 'aria-label': 'controlled' }}
          {...inputProps}
        />
        <Typography>{labelRight}</Typography>
      </Stack>
      {hasError && !hideError && <FormHelperText>{errorLabel}</FormHelperText>}
    </FormControl>
  );
};

export default SwitchField;

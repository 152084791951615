import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

interface ITime {
  smallText?: boolean;
  overTime?: boolean;
}

/**
 * Hours Popup
 *
 * Used when the hours left for a day doesnt leave enough space to show hours.
 * Hours Popup is shown on hover of the empty space for a day.
 */
export const HoursPopup = styled.span`
  position: absolute;
  top: -20px;
  display: flex;
  opacity: 0;
  transform: translateY(-10px);
  height: 30px;
  width: 80px;
  background: #08334a;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  z-index: 1;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: 0.4s ease;

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: -7px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 7px solid #08334a;
  }
`;

export const Time = styled.div<ITime>`
  flex-grow: 1;
  text-align: center;
  font-size: ${(props) => (props.smallText ? '5px' : 'auto')};
  display: flex;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  align-items: center;

  ${(props) =>
    props.overTime &&
    css`
      padding: 10px;
      color: #d32f2e;

      & span {
        background: #d32f2e;
      }
      & span:before {
        border-top-color: #d32f2e;
      }
    `}

  &:hover span {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Hours = styled.span`
  font-weight: bold;
  margin-left: 10px;
`;

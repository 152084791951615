import axios, { AxiosError, AxiosInstance } from 'axios';
import TokenService from 'core/services/token.service';
import {
  OrganisationApi,
  TaskmanagerEventApi,
  TaskmanagerNoteApi,
  TaskmanagerScheduleApi,
  TaskmanagerSkillApi,
  TaskmanagerSearchApi,
  TaskmanagerTagApi,
  TaskmanagerPotGanttApi,
  TaskmanagerPotApi,
  TaskmanagerUserApi,
  TaskmanagerPotTypeApi,
  TaskmanagerPotStatApi,
  TaskmanagerSalaryBandApi,
  UserApi as GatewayUsersApi,
  UserDirectoryOrganisationApi as UserOrganisationApi,
} from 'gateway-api';
import { AuthResultApi, Configuration, UserApi, StatusApi } from 'sso-api';

type AxiosConfig = [
  Configuration | undefined,
  string | undefined,
  AxiosInstance | undefined,
];

class Api {
  public axiosInstance: AxiosInstance = axios.create({});

  public baseUrl: string | undefined = process.env.REACT_APP_SSO_BASE_URL;

  public gatewayBaseUrl: string | undefined = process.env.REACT_APP_GATEWAY_URL;

  // public usersBaseUrl: string | undefined =
  //   process.env.REACT_APP_USERS_BASE_URL;

  // public tasksBaseUrl: string | undefined =
  //   process.env.REACT_APP_TASKS_BASE_URL;

  /**
   * Public axios config to be used for all public requests.
   *
   * When initialized this config doesn't include the axios instance or the reauth flow.
   */
  public publicConfig: AxiosConfig;

  /**
   * Users axios config to be used for all users api requests.
   *
   * When initialized this config includes the axios instance and the reauth flow.
   */
  // public usersAxiosConfig: AxiosConfig;

  /**
   * Tasks axios config to be used for all tasks api requests.
   *
   * When initialized this config includes the axios instance and the reauth flow.
   */
  // public taskAxiosConfig: AxiosConfig;

  public authConfig: AxiosConfig;

  public gatewayAxiosConfig: AxiosConfig;

  public tokenService: TokenService;

  public authenticationService: AuthResultApi;

  public userService: UserApi;

  // public roleService: RoleApi;

  public forgotPasswordService: StatusApi;

  public resetPasswordService: StatusApi;

  public taskUserService: TaskmanagerUserApi; // task users

  public gatewayUserService: GatewayUsersApi; // gateway users

  public skillService: TaskmanagerSkillApi;

  // public groupServices: GroupApi;

  public eventService: TaskmanagerEventApi;

  public potsService: TaskmanagerPotApi;

  public scheduleService: TaskmanagerScheduleApi;

  public tagService: TaskmanagerTagApi;

  public searchService: TaskmanagerSearchApi;

  public potGanttService: TaskmanagerPotGanttApi;

  public potTypeService: TaskmanagerPotTypeApi;

  public potStatService: TaskmanagerPotStatApi;

  public salaryBandService: TaskmanagerSalaryBandApi;

  public noteService: TaskmanagerNoteApi;

  public organisationService: OrganisationApi;

  public userOrganisationService: UserOrganisationApi;

  constructor() {
    this.initializeInterceptors();
    this.authConfig = [undefined, this.baseUrl, this.axiosInstance];
    this.gatewayAxiosConfig = [
      undefined,
      this.gatewayBaseUrl,
      this.axiosInstance,
    ];
    this.publicConfig = [undefined, this.baseUrl, undefined];

    // this.usersAxiosConfig = [undefined, this.gatewayBaseUrl, this.axiosInstance];
    // this.taskAxiosConfig = [undefined, this.tasksBaseUrl, this.axiosInstance];

    this.authenticationService = new AuthResultApi(...this.publicConfig);
    this.forgotPasswordService = new StatusApi(...this.publicConfig);
    this.resetPasswordService = new StatusApi(...this.publicConfig);
    this.tokenService = new TokenService(this.authenticationService);
    this.userService = new UserApi(...this.authConfig);
    // this.roleService = new RoleApi(...this.usersAxiosConfig);
    this.taskUserService = new TaskmanagerUserApi(...this.gatewayAxiosConfig);
    this.gatewayUserService = new GatewayUsersApi(...this.gatewayAxiosConfig);
    this.skillService = new TaskmanagerSkillApi(...this.gatewayAxiosConfig);
    // this.groupServices = new GroupApi(...this.usersAxiosConfig);
    this.eventService = new TaskmanagerEventApi(...this.gatewayAxiosConfig);
    this.potsService = new TaskmanagerPotApi(...this.gatewayAxiosConfig);
    this.scheduleService = new TaskmanagerScheduleApi(
      ...this.gatewayAxiosConfig,
    );
    this.tagService = new TaskmanagerTagApi(...this.gatewayAxiosConfig);
    this.searchService = new TaskmanagerSearchApi(...this.gatewayAxiosConfig);
    this.potGanttService = new TaskmanagerPotGanttApi(
      ...this.gatewayAxiosConfig,
    );
    this.potTypeService = new TaskmanagerPotTypeApi(...this.gatewayAxiosConfig);
    this.potStatService = new TaskmanagerPotStatApi(...this.gatewayAxiosConfig);
    this.salaryBandService = new TaskmanagerSalaryBandApi(
      ...this.gatewayAxiosConfig,
    );
    this.noteService = new TaskmanagerNoteApi(...this.gatewayAxiosConfig);
    this.organisationService = new OrganisationApi(...this.gatewayAxiosConfig);
    this.userOrganisationService = new UserOrganisationApi(...this.gatewayAxiosConfig);
    // Initialize services here...
  }

  /**
   * Initialize the request and response interceptors for the applications axios instance
   */
  private initializeInterceptors(): void {
    this.axiosInstance.interceptors.request.use(
      (request) => {
        const token: string | null = this.tokenService.accessToken;

        request.headers = {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        };

        if (token !== null) {
          request.headers.Authorization = `Bearer ${this.tokenService.accessToken}`;
        }

        // request.paramsSerializer = (params) => qs.stringify(params, {
        //   serializeDate: (date: Date) => dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ') });
        // return config;

        return request;
      },
      (error) => Promise.reject(error),
    );

    this.axiosInstance.interceptors.response.use(
      (response) => response,

      async (error: AxiosError) => {
        const { config, response } = error;
        if (response && response.status === 401) {
          const accessToken = await this.tokenService.refreshAccessToken();
          if (accessToken && config.headers !== undefined) {
            config.headers.Authorization = accessToken;
            return this.axiosInstance.request(config);
          }
        }
        // window.location.href = "/";
        return Promise.reject(error);
      },
    );
  }
}

export const api = new Api();

import { CalendarContainer } from 'date-range/components/calendar-container/calendar-container';
import { scheduleKeys } from 'schedule/core/queries/schedule.keys';
import { useIndexSchedule } from 'schedule/core/queries/use-index-schedule';
import { useDateRange } from 'date-range/store/date-range';
import useEventTypeProvider, {
  PageEnum,
} from 'schedule/core/providers/event-type-provider';
import { UserInformation } from 'schedule/core/components/user-information/user-information';
import { UserDay } from 'schedule/core/components/user-day/user-day';
import * as S from 'schedule/core/components/schedule-body/schedule-body.styles';
import useDragDropStore from 'schedule/core/store/drag-drop/drag-drop.store';
import useKeyPress from 'schedule/core/hooks/use-key-press';
import ScheduleBodyShimmer from 'schedule/core/components/shimmers/schedule-body-shimmer';
import { UserDto } from 'gateway-api';
import useEventTypeStore from 'schedule/core/store/event-type/event-type.store';

/**
 * Schedule Body
 *
 * The main body for all schedule types:
 * * Estimate
 * * Schedule
 * * Complete
 *
 * This component lists all users and schedule events for them users.
 */

export const ScheduleBody = (): JSX.Element => {
  const { scheduleParams, page } = useEventTypeProvider();
  const {
    state: { eventType },
  } = useEventTypeStore();
  const setIsCloneKeyDown = useDragDropStore(
    (store) => store.setIsCloneKeyDown,
  );

  const {
    state: { calendarDates },
  } = useDateRange();

  useKeyPress({ targetKey: 'Alt', setIsCloneKeyDown });

  const queryKey = scheduleKeys.indexSchedule(scheduleParams);

  const { data: schedules, isLoading } = useIndexSchedule(queryKey);

  if (!schedules || isLoading)
    return (
      <>
        <ScheduleBodyShimmer />
        <ScheduleBodyShimmer />
        <ScheduleBodyShimmer />
      </>
    );

  // The width of the first item of every row including the header.
  const leadingWidth = 100;

  return (
    <CalendarContainer leadingWidth={leadingWidth}>
      {(width) => (
        <>
          {schedules.items?.map((userSchedule, index) => (
            <S.UserSchedule
              key={userSchedule.user.id}
              multiView={page === PageEnum.MULTI_VIEW}
            >
              {scheduleParams.eventType.map((type, key) => (
                <S.UserScheduleItems
                  key={type}
                  backgroundColor={key % 2 === 0}
                  multiView={
                    page === PageEnum.MULTI_VIEW &&
                    scheduleParams.eventType.length > 1
                  }
                >
                  <UserInformation
                    showUser={key === 0}
                    user={userSchedule.user}
                    width={leadingWidth}
                  />
                  {calendarDates.map((calendarDate) => (
                    <UserDay
                      key={`${userSchedule.user.id}_${calendarDate}`}
                      index={
                        page === PageEnum.MULTI_VIEW &&
                        scheduleParams.eventType.length > 1
                          ? key
                          : index
                      }
                      width={(width - leadingWidth) / calendarDates.length}
                      date={calendarDate}
                      userSchedule={userSchedule}
                      eventType={type}
                    />
                  ))}
                </S.UserScheduleItems>
              ))}
            </S.UserSchedule>
          ))}
        </>
      )}
    </CalendarContainer>
  );
};

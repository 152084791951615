import { useReducer } from 'react';
import userSelectReducer from 'users/reducers/user-select/user-select.reducer';
import UserSelectContext from 'users/store/user-select/user-select.context';
import { userSelectInitialState } from 'users/reducers/user-select/user-select.state';

const UserSelectProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [state, dispatch] = useReducer(
    userSelectReducer,
    userSelectInitialState,
  );

  return (
    <UserSelectContext.Provider value={[state, dispatch]}>
      {children}
    </UserSelectContext.Provider>
  );
};

export default UserSelectProvider;

import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { api } from 'core/services/api.service';

const useIndexProject = (potType: string) => {
  const [search, setSearch] = useState<string | undefined>('');

  const {
    data: indexPotsResponse,
    isLoading: indexProjectsLoading,
    refetch,
  } = useQuery(
    [potType],
    () =>
      api.potsService.indexTaskmanagerPot({
        params: {
          'pot_type@name': potType,
          name: search,
        },
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    refetch();
  }, [search]);

  return {
    ...indexPotsResponse?.data,
    indexProjectsLoading,
    setSearch,
    refetch,
  };
};

export default useIndexProject;

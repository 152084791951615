import styled from '@emotion/styled/macro';

interface IDropContainer {
  isEven: boolean;
  isDraggingOver: boolean;
}

export const DropContainer = styled.div<IDropContainer>`
  display: flex;
  align-items: center;
  background: ${({ isEven }) => (isEven ? '#fff' : '#F7F7F7')};
  border-radius: 4px;
  width: 100%;
  height: 60px;
  & .react-resizable-handle {
    visibility: ${({ isDraggingOver }) =>
      isDraggingOver ? 'hidden !important' : 'visible'};
  }
`;

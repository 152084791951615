// TODO: add an api request to get all skills

const useSkills = () => {
  const skillsList = [
    'Java',
    'JavaScript',
    'NodeJS',
    'React',
    'PHP',
    'Zend/Laminas',
    'Laravel',
    'Rust',
    'Docker',
    'NestJS',
    'TypeScript',
  ];

  return skillsList;
};

export default useSkills;

/**
 * Module dependencies.
 */

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from 'auth/pages/login/login.page';
import Dashboard from 'schedule/core/pages/home/home';
import Schedule from 'schedule/core/pages/schedule/schedule';
import UserProfilePage from 'user/pages/user-profile/user-profile';
import UserManagement from 'users/pages/user-management/user-management';
import ProjectStatistics from 'statistics/pages/project-statistics/project-statistics';
import Forbidden from 'core/pages/403';
import NotFound from 'core/pages/404';
import AddTime from 'schedule/core/pages/add-time/add-time';
import Estimate from 'schedule/core/pages/estimate/estimate';
import PotsProvider from 'core/store/pots-provider';
import Recorded from 'schedule/core/pages/recorded/recorded';
import AuthProvider from 'auth/store/auth/auth.provider';
import RoutePath from 'routes/route-paths';
import ProtectedRoute from 'routes/protected-route';
import UserCheck from 'routes/user-check';
import GantView from 'gantt/pages/gant-view/gant-view';
import UserSelectProvider from 'users/store/user-select/user-select.provider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import MultiView from 'schedule/core/pages/multi-view/multi-view';

/**
 * App Router
 *
 * Top level routing for the application
 */
const AppRouter = () => (
  <BrowserRouter>
    <AuthProvider>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''}
      >
        <Routes>
          <Route
            path={RoutePath.HOME}
            element={
              <UserCheck>
                <LoginPage />
              </UserCheck>
            }
          />

          {/*  protected routes  */}
          <Route
            path={RoutePath.DASHBOARD}
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePath.USER_PROFILE}
            element={
              <ProtectedRoute>
                <UserProfilePage />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePath.SCHEDULE}
            element={
              <ProtectedRoute>
                <PotsProvider>
                  <Schedule />
                </PotsProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePath.MULTIVIEW}
            element={
              <ProtectedRoute requiredRoles={['admin']}>
                <PotsProvider>
                  <MultiView />
                </PotsProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePath.ESTIMATE}
            element={
              <ProtectedRoute requiredRoles={['admin']}>
                <PotsProvider>
                  <Estimate />
                </PotsProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePath.RECORDED}
            element={
              <ProtectedRoute requiredRoles={['admin']}>
                <PotsProvider>
                  <Recorded />
                </PotsProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePath.ADD_TIME}
            element={
              <ProtectedRoute>
                <PotsProvider>
                  <AddTime />
                </PotsProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePath.GANT_VIEW}
            element={
              <ProtectedRoute requiredRoles={['admin']}>
                <GantView />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePath.PROJECT_STATISTICS}
            element={
              <ProtectedRoute requiredRoles={['admin']}>
                <PotsProvider>
                  <ProjectStatistics />
                </PotsProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePath.USER_MANAGEMENT}
            element={
              <ProtectedRoute requiredRoles={['admin']}>
                <UserSelectProvider>
                  <UserManagement />
                </UserSelectProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePath.FORBIDDEN}
            element={
              <ProtectedRoute>
                <Forbidden />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePath.NOT_FOUND}
            element={
              <ProtectedRoute>
                <NotFound />
              </ProtectedRoute>
            }
          />
          <Route path='*' element={<Navigate to={RoutePath.NOT_FOUND} />} />
        </Routes>
      </GoogleOAuthProvider>
    </AuthProvider>
  </BrowserRouter>
);

export default AppRouter;

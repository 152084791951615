/* eslint-disable arrow-body-style */
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import Box from '@mui/material/Box';
import useIndexPotTypes from 'schedule/core/hooks/use-index-pot-types';
import RadioButtonStyle from 'core/components/form-components/radio-button-style';
import * as S from 'schedule/core/components/timepot-tabs/timepot-tabs.style';
import { Typography } from '@mui/material';
import { TaskmanagerEventDto } from 'gateway-api';

interface IProps {
  potIdType: string;
  setPotIdType: Dispatch<SetStateAction<string>>;
  event?: TaskmanagerEventDto;
}

export const TimepotTabs = (props: IProps) => {
  const { potIdType, setPotIdType, event } = props;
  const [tabIndex, setTabIndex] = useState(
    (event && event.time_pot?.pot_type.parent.id) ?? 1,
  );

  const { items: potTypeList } = useIndexPotTypes();

  const handleTabChange = (_: React.SyntheticEvent, newTabIndex: number) => {
    setTabIndex(newTabIndex);
    setPotIdType('');
  };

  const handlePotTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPotIdType(value);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box>
          <S.TabButtons
            value={tabIndex}
            onChange={handleTabChange}
            aria-label='timepot-category'
          >
            {potTypeList?.[0].children?.map((category) => {
              return (
                <S.TabButtonItem
                  label={category.name.replace('_', ' ')}
                  key={category.id}
                  value={category.id}
                  {...a11yProps(category.id)}
                />
              );
            })}
          </S.TabButtons>
        </Box>
        {potTypeList ? (
          potTypeList?.[0].children?.map((item) => (
            <S.TabContent key={item.id} value={tabIndex} index={item.id}>
              {item.children?.map((child) => (
                <S.RadioContainer key={child.id}>
                  <RadioButtonStyle
                    checked={
                      child.id === parseInt(potIdType) ||
                      child.id === (event && event.time_pot?.pot_type_id)
                    }
                    name='pot_type'
                    key={child.id}
                    value={child.id}
                    variant='outlined'
                    onChange={handlePotTypeChange}
                  >
                    {child.name}
                  </RadioButtonStyle>
                </S.RadioContainer>
              ))}
            </S.TabContent>
          ))
        ) : (
          <Typography>An error occurred loading time pots</Typography>
        )}
      </Box>
    </>
  );
};

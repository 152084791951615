/**
 * Module dependencies.
 */

import { useEffect, useMemo, useState } from 'react';
import * as S from 'schedule/core/components/edit-event-form/edit-event-form.style';
import * as yup from 'yup';
import useIndexPotTypes from 'schedule/core/hooks/use-index-pot-types';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import TextInput from 'core/components/form-components/text-input';
import StyledDatePicker from 'core/components/form-components/date-picker';
import PrimaryButton from 'core/components/buttons/primary-button';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useScheduleMutation } from 'schedule/core/queries/schedule-move-mutation';
import useDragDropStore from 'schedule/core/store/drag-drop/drag-drop.store';
import { useDestroyEvent } from 'schedule/core/queries/schedule-destroy-event';
import { IAddPot } from 'schedule/core/hooks/schedule-drag-end';
import useCreateEvent from 'schedule/core/queries/create-event-mutation';
import useAuthUser from 'auth/hooks/use-auth-user';
import useEventTypeProvider from 'schedule/core/providers/event-type-provider';
import { TimepotTabs } from 'schedule/core/components/timepot-tabs/timepot-tabs';
import { TaskmanagerEventDto, TaskmanagerEventRequestDto } from 'gateway-api';

interface ITicketProps {
  pot?: IAddPot;
  event?: TaskmanagerEventDto;
  handleClose: () => void;
}

interface IFormPot {
  hours: number;
  date_start: Date | undefined;
  potType: string;
  pot_type_id: number;
}

const EditTaskEstTimeForm = ({ pot, handleClose, event }: ITicketProps) => {
  const { scheduleParams } = useEventTypeProvider();
  const [potTypeIdValue, setPotTypeIdValue] = useState('');
  const [dateValue, setDateValue] = useState<Date>(
    pot
      ? new Date(pot.dateStart)
      : new Date(event?.date_start.split(' ')[0] ?? ''),
  );

  const { items: potTypeList } = useIndexPotTypes();

  const defaultTime =
    pot && pot.pot_type.name === 'time' ? pot.name : 'meeting';

  const user = useAuthUser();

  const validations = useMemo(
    () =>
      yup.object({
        hours: yup.number().required('You must add hours against this event'),
      }),
    [],
  );

  const methods = useForm<IFormPot>({
    resolver: validations ? yupResolver(validations) : undefined,
    defaultValues: {
      hours: event ? event.hours : 1,
      potType: defaultTime,
      date_start: dateValue,
    },
  });

  const { handleSubmit, setValue } = methods;

  const handleHoursBlur = (e: any) => {
    const { value } = e.target;
    const hours =
      value === '0' ? 0.25 : parseFloat((Math.ceil(value * 4) / 4).toFixed(2));
    setValue('hours', hours);
  };

  const { clear } = useDragDropStore();

  const { mutate } = useScheduleMutation(scheduleParams);
  const { mutate: createEventMutation, isLoading } = useCreateEvent();
  const { mutate: destroyEvent } = useDestroyEvent(scheduleParams);

  const submit = handleSubmit((updatedEvent: IFormPot) => {
    if (!potTypeList) return;
    // TODO: Hours are not being set correctly by setValue in handleHoursBlur. Having to redo calc for now
    const hours =
      updatedEvent.hours === 0
        ? 0.25
        : parseFloat((Math.ceil(updatedEvent.hours * 4) / 4).toFixed(2));

    // const dateStart = `${dateValue?.getFullYear()}-${dateValue?.getMonth()}-${dateValue?.getDate()} 00:00:00`;

    // const wrapperPotName =
    // updatedEvent.wrapper_pot ?? potTypeList[0].suggested_names[0];
    // updatedEvent.wrapper_pot ?? potTypeList[0].name[0];

    // add second string (child) to push up
    if (pot) {
      const createEventPayload: TaskmanagerEventRequestDto = {
        date_start: DateTime.fromJSDate(dateValue).toFormat('yyyy-MM-dd'),
        date_end: DateTime.fromJSDate(dateValue).toFormat('yyyy-MM-dd'),
        event_type: pot.eventType,
        hours,
        hours_from: 7.5,
        priority: 1,
        assigner_user_id: user?.id ?? '',
        pot_ids: [pot.id],
        assignee_user_id: pot.assigneeId,
        time_pot_type_id: parseInt(potTypeIdValue),
      };

      createEventMutation(createEventPayload, {
        onSuccess() {
          clear();
        },
        onError() {
          clear();
        },
      });
    }
    if (event) {
      mutate(
        {
          patchEvent: {
            id: event.id,
            hours,
            pot_ids: [event.time_pot?.parent_pot_id ?? ''],
            time_pot_type_id:
              potTypeIdValue === ''
                ? event.time_pot?.pot_type.id
                : parseInt(potTypeIdValue),
            date_start: DateTime.fromJSDate(dateValue).toFormat('yyyy-MM-dd'),
            assigner_user_id: user?.id,
          },
          newEvent: event,
        },
        {
          onSuccess(data, variables, context) {
            if (context.existingEvent) {
              destroyEvent(context.existingEvent);
            }
            clear();
          },
          onError() {
            clear();
          },
        },
      );
    }
  });

  useEffect(() => {
    if (pot && pot.pot_type.name === 'time') {
      setValue('potType', pot.name);
    } else if (potTypeList !== undefined) {
      setValue('potType', '');
    }
  }, [pot, potTypeList, setValue]);

  const handleDateChange = (newValue: Date | null) => {
    if (newValue) {
      setDateValue(newValue);
    }
  };

  const potName =
    pot?.name ??
    (event?.pots_by_type[`${event.time_pot?.pot_type.name}`] &&
      event?.pots_by_type[`${event.time_pot?.pot_type.name}`].name) ??
    '';

  const potLabel = pot?.label ?? event?.time_pot?.parent_pot?.label;

  const date = pot?.dateStart ?? event?.date_start.split(' ')[0] ?? '';

  return (
    <FormProvider {...methods}>
      <form onSubmit={submit}>
        <S.FlexContainer>
          <S.FlexCol>
            <S.FlexContent>
              <S.PotLabel company={potName}>{potLabel}</S.PotLabel>

              <S.PotTitle>{potName}</S.PotTitle>
            </S.FlexContent>
          </S.FlexCol>
          <S.FlexCol>
            <S.FlexFormField>
              <TextInput
                id='hours'
                label='Time'
                fieldInline={false}
                options={{ onBlur: handleHoursBlur }}
              />
            </S.FlexFormField>
            <S.FlexFormField>
              <StyledDatePicker
                label='Date'
                value={dateValue as Date}
                id='date_start'
                handleChange={handleDateChange}
                defaultValue={new Date(date)}
              />
            </S.FlexFormField>
          </S.FlexCol>
          <S.FlexCol>
            <S.StyledFormGroup>
              <S.StyledFieldset>
                <S.StyledLegend>Select category and time pot</S.StyledLegend>

                <TimepotTabs
                  potIdType={potTypeIdValue}
                  setPotIdType={setPotTypeIdValue}
                  event={event}
                />
              </S.StyledFieldset>
            </S.StyledFormGroup>
          </S.FlexCol>
          <S.FlexCol>
            {event ? (
              <S.StyledDeleteButton
                variant='text'
                type='button'
                onClick={() => {
                  destroyEvent(event);
                  clear();
                }}
              >
                <DeleteOutlineOutlinedIcon />
              </S.StyledDeleteButton>
            ) : (
              <S.StyledDeleteButton disabled />
            )}
            <S.FlexButton isEvent={event}>
              <PrimaryButton variant='text' type='button' onClick={handleClose}>
                Cancel
              </PrimaryButton>
              <S.ConfirmButton
                type='submit'
                disabled={!event && potTypeIdValue === ''}
                loading={isLoading}
              >
                Confirm
              </S.ConfirmButton>
            </S.FlexButton>
          </S.FlexCol>
        </S.FlexContainer>
      </form>
    </FormProvider>
  );
};

export default EditTaskEstTimeForm;

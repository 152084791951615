import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import * as S from 'statistics/components/stats-custom-attributes/stats-custom-attributes.style';
import { TaskmanagerPotStatsProgressEntryDto } from 'gateway-api';

interface ITableProps {
  data: TaskmanagerPotStatsProgressEntryDto[] | undefined;
}

const JiraStatsTable = ({ data }: ITableProps): JSX.Element => (
  <>
    {data ? (
      <TableContainer>
        <S.StyledTable aria-label="Users Custom Attributes">
          <TableBody>
            {data.map((progress, index) => (
              <S.StyledTableRow key={index}>
                <S.StyledTableCell component="th" scope="row">
                  {progress.label}
                </S.StyledTableCell>
                <S.StyledTableCell align="right">
                  {progress.value.toFixed(0)}%
                </S.StyledTableCell>
              </S.StyledTableRow>
            ))}
          </TableBody>
        </S.StyledTable>
      </TableContainer>
    ) : (
      <></>
    )}
  </>
);

export default JiraStatsTable;

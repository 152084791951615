import { Draggable } from 'react-beautiful-dnd';
import styled from '@emotion/styled/macro';
import { ResizableBox, ResizeCallbackData } from 'react-resizable';
import useResizeMutation from 'schedule/core/queries/resize-mutation';
import { useSnackbar } from 'notistack';
import useEventTicketStore from 'schedule/core/store/resizable/resize.store';
import useDragDropStore from 'schedule/core/store/drag-drop/drag-drop.store';
import useEventTypeProvider, {
  PageEnum,
} from 'schedule/core/providers/event-type-provider';
import * as S from 'schedule/core/components/ticket-container/ticket-container.styles';
import { EventTooltip } from 'schedule/core/components/event-tooltip/event-tooltip';
import { TicketTooltip } from 'schedule/core/components/tooltip/tooltip';
import { TicketContent } from 'schedule/core/components/ticket-content/ticket-content';
import { useMemo, useState } from 'react';
import useAuthUser from 'auth/hooks/use-auth-user';
import { TaskmanagerEventDto, TaskmanagerEventTypeEnum } from 'gateway-api';

interface IEventTypeLeave {
  width: number;
  event: TaskmanagerEventDto;
  containerWidth: number;
  remainingTime: number;
  // date: string;
}

interface IResizeableBox {
  unassigned: boolean;
  isDragging: boolean;
  static: boolean;
  isResizable: boolean;
}

const StyledResizableBox = styled(ResizableBox, {
  shouldForwardProp: (prop) =>
    prop !== 'isDragging' &&
    prop !== 'isResizable' &&
    prop !== 'static' &&
    prop !== 'unassigned',
})<IResizeableBox>`
  & .react-resizable-handle {
    filter: ${(props) => (props.unassigned ? 'invert(1)' : 'invert(0)')};
    display: ${(props) => (props.isResizable ? 'block' : 'none')};
    visibility: ${(props) =>
      props.isDragging || props.static ? 'hidden !important' : 'visible'};
  }
`;

export const TicketContainer = (props: IEventTypeLeave) => {
  const [open, setOpen] = useState(false);
  const { containerWidth, event, width, remainingTime } = props;
  const user = useAuthUser();

  const { page, scheduleParams } = useEventTypeProvider();
  const { mutate } = useResizeMutation(scheduleParams);
  const isLoading = event.id.length === 0; // If event has no id then we are waiting for one from the server.
  const isUnassigned = event.hours === undefined; // Whether an event has been assigned a time pot or not.
  const companyName = event.pots_by_type.category?.name;
  const { enqueueSnackbar } = useSnackbar();
  const hourWidth = containerWidth / remainingTime;
  const maxWidth = width + hourWidth * remainingTime;
  const minWidth = containerWidth / (remainingTime / 0.25);
  const companyColor = companyName === 'arch' ? '#A0CED9' : '#f27777';
  const fontColor = isUnassigned ? 'white' : 'black';
  const backgroundColor = isUnassigned || isLoading ? '#07334A' : companyColor;
  const setDraggable = useDragDropStore((store) => store.setDraggableEvent);
  const isCloneKeyDown = useDragDropStore(
    (store) => store.state.isCloneKeyDown,
  );

  const {
    resetStore,
    setEvent,
    setTimeDifference,
    isResizing,
    state: { event: stateEvent },
  } = useEventTicketStore();

  const onResizeStart = () => {
    setEvent(event);
  };

  const localResize = (e: unknown, data: ResizeCallbackData) => {
    const { size } = data;
    const newHours = parseFloat((size.width / hourWidth).toFixed(2));
    if (stateEvent && (stateEvent.hours < 7.5 || newHours <= 7.5)) {
      setTimeDifference(newHours - event.hours);
      setEvent({ ...stateEvent, hours: newHours });
    }
  };

  const onResizeStop = async () => {
    if (stateEvent && stateEvent.assigner_user) {
      mutate(
        {
          event: {
            ...stateEvent,
            assigner_user: { ...stateEvent.assigner_user, id: user?.id ?? '' },
          },
          id: stateEvent.id,
        },
        {
          onError: () => {
            enqueueSnackbar('Unsuccessful updated event in schedule', {
              variant: 'error',
              autoHideDuration: 2000,
            });
          },
          onSuccess: () => {
            enqueueSnackbar('Successfully updated event in schedule', {
              variant: 'success',
              autoHideDuration: 2000,
            });
          },
        },
      );
      resetStore();
    }
  };

  const isResizable: boolean = useMemo(() => {
    if (page === PageEnum.ADD_TIME) {
      return event.event_type !== TaskmanagerEventTypeEnum.Schedule;
    }

    if (page === PageEnum.RECORDED) {
      return false;
    }
    return true;
  }, [event.event_type, page]);

  return (
    <Draggable draggableId={event.id} index={event.priority}>
      {(provided, snapshot) => (
        <>
          <StyledResizableBox
            width={snapshot.isDragging ? 0 : width}
            height={snapshot.isDragging ? 0 : 60}
            axis='x'
            resizeHandles={['ne']}
            draggableOpts={{ grid: [minWidth] }}
            minConstraints={[minWidth, 60]}
            maxConstraints={[maxWidth, 60]}
            onResize={localResize}
            onResizeStop={onResizeStop}
            onResizeStart={onResizeStart}
            handleSize={[100, 100]}
            unassigned={false}
            isDragging={snapshot.isDragging}
            static={false}
            isResizable={isResizable}
          >
            {isResizing(event) && (
              <S.TimeTicker>{`${stateEvent?.hours} hrs`}</S.TimeTicker>
            )}
            <S.TicketContainer>
              <EventTooltip
                tip={
                  <TicketTooltip
                    event={event}
                    company={companyName}
                    setOpen={setOpen}
                  />
                }
                eventId={event.id}
                color={companyColor}
                open={open}
                setOpen={setOpen}
              >
                <S.Event
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  onMouseDown={() => setDraggable(event)}
                  fontColor={fontColor}
                  backgroundColor={backgroundColor}
                >
                  <TicketContent
                    event={event}
                    isLoading={isLoading}
                    isUnassigned={isUnassigned}
                  />
                </S.Event>
              </EventTooltip>
            </S.TicketContainer>
          </StyledResizableBox>
          <S.ClonePlaceholder
            isDragging={snapshot.isDragging && isCloneKeyDown}
          />
        </>
      )}
    </Draggable>
  );
};

import { FormControlLabel, Switch as MuiSwitch } from '@mui/material';
import styled from '@emotion/styled/macro';

export const Switch = styled(MuiSwitch)`
  && {
    position: absolute;
    padding: 0;
    width: 100%;
    height: 26px;

    .MuiSwitch-track {
      border-radius: 16px;
      opacity: 1;
      z-index: 1;
      background-color: #e4e4e4;
    }

    .MuiSwitch-switchBase {
      padding: 0;
      width: 100%;
      height: 100%;

      .MuiSwitch-input {
        left: 0;
        width: 100%;
      }

      .MuiSwitch-thumb {
        display: none;
      }
    }

    .Mui-checked {
      transform: translateX(0);

      & + .MuiSwitch-track {
        background-color: #a0b1d3;
        opacity: 1;
      }
    }
  }
`;

export const SwitchLabel = styled(FormControlLabel)`
  position: relative;
  margin: 0 6px 0;

  .MuiFormControlLabel-label {
    padding: 0 30px;
    z-index: 2;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
`;

import { CircularProgress, Typography } from '@mui/material';
import PrimaryButton from 'core/components/buttons/primary-button';
import styled from '@emotion/styled/macro';

export const UnassignedButton = styled(PrimaryButton)`
  && {
    overflow: hidden;
    height: 100%;
    width: 100%;
    background-color: transparent;
    font-size: 18px;
    color: white !important;
  }
`;

export const Loading = styled(CircularProgress)`
  color: white;
  width: 10px;
`;

export const Tag = styled.span`
  position: absolute;
  display: block;
  min-width: auto;
  letter-spacing: unset !important;
  top: 3px;
  left: 3px;
  font-size: 9px;
  background: #f7f7f7 !important;
  border-radius: 2px;
  padding: 0px 4px !important;
  text-transform: lowercase;
  color: #06334a;
  font-weight: bold !important;
  transition: 0.4s ease;
  &:hover {
    font-size: 16px;
    font-weight: normal !important;
    padding: 0 10px !important;
    border-radius: 4px;
  }
`;

export const TaskHours = styled(Typography)`
  && {
    font-size: 12px;
    width: 100%;
    position: absolute;
    bottom: 0;
    text-align: center;
  }
`;

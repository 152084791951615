import styled from '@emotion/styled';
import { defaultTheme } from 'theme/theme';
import { Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabButtons = styled(Tabs)`
  && {
    justify-content: space-around;
    flex-wrap: wrap;
    border-radius: 10px 10px 0 0;
    background-color: #fff;

    div {
      flex-wrap: wrap;
    }

    .MuiTabs-indicator {
      display: none;
    }

    .Mui-selected {
      color: #fff;
      background-color: ${defaultTheme.colors.primaryColor};
      box-shadow: none;
    }
  }
`;

export const TabButtonItem = styled(Tab)`
  && {
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: ${defaultTheme.colors.textPrimary};
    background-color: #fff;
    text-transform: capitalize;
    font-size: 0.9375em;
    font-family: ${defaultTheme.fonts.lexendSanSerif};
    min-width: 6rem;
    padding: 0rem 1.8rem;
    margin: 0.5rem 0.5rem 1rem 0;
    border: solid 1px #e6e6e6;
    border-radius: 0.325rem;

    .Mui-selected {
      color: #fff;
      background-color: ${defaultTheme.colors.primaryColor};
      box-shadow: none;
    }
  }
`;

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

export const TabContent = styled(TabPanel)`
  && {
    background-color: #fff;
    border-radius: 0 0 10px 10px;
  }
`;

export const RadioContainer = styled.div`
  display: inline;
  div {
    margin: 0 0.75rem 1rem 0;
    background-color: ${defaultTheme.colors.lightGrey};
    border: 0px;

    input[type='radio']:checked + label {
      color: #fff;
      background-color: #a0b1d3;
    }
  }
`;

/**
 * Module dependencies.
 */

import styled from '@emotion/styled/macro';
import { Typography } from '@mui/material';
import DateRangeType from 'date-range/models/date-range-type.enum';

export const Popup = styled.div`
  position: absolute;
  left: 50%;
  top: calc(100% + 12px);
  width: 250px;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s ease;
  transform: translateX(-50%);
  z-index: 9999;

  &:hover {
    opacity: 1;
    visibility: visible;
  }

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: -7px;
    left: 50%;
    right: -7px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #94d0cc;
  }
`;

export const PopupBody = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  padding-top: 18px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    background-color: #94d0cc;
    top: 0;
    height: 8px;
  }
  h6 {
    &:first-of-type {
      margin-top: 14px;
    }
    display: flex;
    span {
      margin-left: 5px;
      font-weight: normal;
    }
  }
`;

export const ProjectName = styled(Typography)`
  font-weight: 550;
`;

export const ProjectBox = styled.div<{
  $width: number;
  $start: number;
  $expandsBefore: boolean;
  $expandsAfter: boolean;
  $dateType: DateRangeType;
  $zIndex: number;
}>`
  background-color: #94d0cc;
  position: absolute;
  top: 50%;
  transform: translate(
    ${({ $expandsBefore, $dateType }) =>
      !$expandsBefore && $dateType !== DateRangeType.year ? '14px' : 0},
    -50%
  );
  left: ${(props) => props.$start}px;
  width: ${(props) => props.$width}px;
  border-bottom-left-radius: ${({ $expandsBefore }) =>
    $expandsBefore ? 0 : '4px'};
  border-top-left-radius: ${({ $expandsBefore }) =>
    $expandsBefore ? 0 : '4px'};

  border-top-right-radius: ${({ $expandsAfter }) =>
    $expandsAfter ? 0 : '4px'};

  border-bottom-right-radius: ${({ $expandsAfter }) =>
    $expandsAfter ? 0 : '4px'};
  height: 56px;
  z-index: ${(props) => props.$zIndex};
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const HoverArea = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .hover-icon {
    position: absolute;
    top: -5px;
    right: -5px;
    transform: rotate(315deg);

    &:hover {
      cursor: col-resize;
    }
  }

  &:hover + div {
    opacity: 1;
    visibility: visible;
  }
`;

import { Typography } from '@mui/material';
import { TaskmanagerUserDto } from 'gateway-api';
import * as S from 'schedule/core/components/user-information/user-information.styles';

interface IScheduleUser {
  width?: number;
  user: TaskmanagerUserDto;
  showUser: boolean;
}

/**
 * User Information
 *
 * Used to display the user within all of the schedule calendar controls. ie Estimate, Schedule and Complete.
 *
 * @param user The user to display.
 */
export const UserInformation = ({ user, width, showUser }: IScheduleUser) => (
  <S.UserContainer width={width}>
    {showUser && (
      <>
        <S.Avatar avatarWidth='44px' avatarHeight='44px' />
        <S.UserTooltip
          title={
            <Typography color='inherit'>
              {user?.user_directory?.first_name}{' '}
              {user?.user_directory?.last_name}
            </Typography>
          }
        >
          <Typography>
            {user?.user_directory?.first_name} <br />
            {user?.user_directory?.last_name}
          </Typography>
        </S.UserTooltip>
      </>
    )}
  </S.UserContainer>
);

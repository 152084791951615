interface IIndexUserTasks {
  user?: string;
}

export const userTasksKeys = {
  base: [{ scope: "user" }] as const,
  show: () => [{ ...userTasksKeys.base[0], entity: "show" }] as const,
  showUser: (query: IIndexUserTasks) =>
    [
      {
        ...userTasksKeys.show()[0],
        ...query,
      },
    ] as const,
};

/**
 * Module dependencies.
 */

import { IFilterDropDown } from 'core/components/filters/multi-select-filters';
import UserFilterSearch from 'core/components/filters/user-filter-search';
import RadioButtonStyle from 'core/components/form-components/radio-button-style';
import useScheduleUserFilterLogic from 'schedule/core/hooks/use-schedule-user-filter-logic';
import DateRangeType from 'date-range/models/date-range-type.enum';
import { useDateRange } from 'date-range/store/date-range';
import * as S from 'schedule/core/components/schedule-filters/schedule-filters.style';
import useEventTypeProvider, {
  PageEnum,
} from 'schedule/core/providers/event-type-provider';
import useUserStore from 'schedule/core/store/users/users.store';
import ScheduleFiltersShimmer from 'schedule/core/components/shimmers/schedule-filters-shimmer';
import { TaskmanagerEventTypeEnum, UserDto } from 'gateway-api';

const ScheduleFilters = (): JSX.Element => {
  const { users: userList, usersLoading } = useScheduleUserFilterLogic();

  const { scheduleParams, page } = useEventTypeProvider();
  const {
    setUsers,
    state: { users: storedUsers },
  } = useUserStore();

  const dateRangeType = useDateRange((store) => store.state.dateRangeType);
  const setDateRangeType = useDateRange((store) => store.dateRangeTypeChanged);

  let users: Array<IFilterDropDown> | undefined;

  if (userList) {
    users = userList
      .sort((a, b) => (a.first_name > b.first_name ? 1 : -1))
      .map((user: UserDto) => ({
        id: user.id,
        name: user.first_name,
        lastName: user.last_name,
        disabled: user.disabled,
      }));
  }

  return (
    <S.FilterHeader>
      <S.Filter>
        <RadioButtonStyle
          id='1'
          value='day'
          name='duration'
          variant='solid'
          onChange={() => setDateRangeType(DateRangeType.day)}
          checked={dateRangeType === DateRangeType.day}
        >
          Day
        </RadioButtonStyle>
      </S.Filter>
      <S.Filter>
        <RadioButtonStyle
          id='2'
          value='week'
          name='duration'
          variant='solid'
          onChange={() => setDateRangeType(DateRangeType.week)}
          checked={dateRangeType === DateRangeType.week}
        >
          Week
        </RadioButtonStyle>
      </S.Filter>
      <S.Filter>
        <RadioButtonStyle
          id='3'
          value='month'
          name='duration'
          variant='solid'
          onChange={() => setDateRangeType(DateRangeType.month)}
          checked={dateRangeType === DateRangeType.month}
        >
          Month
        </RadioButtonStyle>
      </S.Filter>
      <S.Filter>
        <RadioButtonStyle
          id='4'
          value='year'
          name='duration'
          variant='solid'
          onChange={() => setDateRangeType(DateRangeType.year)}
          checked={dateRangeType === DateRangeType.year}
        >
          Year
        </RadioButtonStyle>
      </S.Filter>

      <S.Filter>
        {usersLoading &&
          scheduleParams.eventType.filter(
            (type) => type !== TaskmanagerEventTypeEnum.Complete,
          )[0] !== TaskmanagerEventTypeEnum.Complete && (
            <ScheduleFiltersShimmer />
          )}
        {!usersLoading && users && page !== PageEnum.ADD_TIME && (
          <UserFilterSearch
            options={users.filter(
              (user) => user.disabled === false || user.disabled === null,
            )}
            label='Filter by team member'
            selectedValues={storedUsers ?? []}
            onChange={(newSelectedUsers) => setUsers(newSelectedUsers ?? [])}
          />
        )}
      </S.Filter>
    </S.FilterHeader>
  );
};

export default ScheduleFilters;

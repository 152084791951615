import { DateTime, Duration } from 'luxon';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

export enum DateRangeType {
  week,
  month,
  year,
}

export interface IDateRange {
  startDate: Date;
  endDate: Date;
  headerDates: Date[];
  scheduleDates: Date[];
}

function getDatesArray(startDate: Date, endDate: Date): Date[] {
  const dates: Date[] = [];
  for (
    let date = new Date(startDate);
    date <= endDate;
    date.setDate(date.getDate() + 1)
  ) {
    dates.push(new Date(date));
  }
  return dates;
}

function getFirstOfWeek(date?: Date): Date {
  const today = date ?? new Date();
  const day = today.getDay();
  const difference = today.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(today.setDate(difference));
}

function getLastOfWeek(date?: Date): Date {
  const start: Date = getFirstOfWeek(date);
  return new Date(start.setDate(start.getDate() + 4)); // Add 4 to get to Friday
}

function setWeek(dateTime: DateTime): IDateRange {
  const lastWeek = dateTime.toJSDate();
  const startDate = getFirstOfWeek(lastWeek);

  const endDate = getLastOfWeek(startDate);

  const dates = getDatesArray(startDate, endDate);
  const headerDates = dates;
  const scheduleDates = dates;

  return { startDate, endDate, headerDates, scheduleDates };
}

function buildWeekDateRange(): IDateRange {
  const startDate = getFirstOfWeek();
  startDate.setHours(0, 0, 0, 0);
  const endDate = getLastOfWeek();
  endDate.setHours(0, 0, 0, 0);
  const dates = getDatesArray(startDate, endDate);
  const headerDates = dates;
  const scheduleDates = dates;
  return { startDate, endDate, headerDates, scheduleDates };
}

function getFirstOfMonth(date?: Date): Date {
  const today = date ?? new Date();
  return new Date(today.getFullYear(), today.getMonth(), 1);
}

function getLastOfMonth(date?: Date): Date {
  const today = date ?? new Date();
  return new Date(today.getFullYear(), today.getMonth() + 1, 0);
}

function setMonth(dateTime: DateTime): IDateRange {
  const lastMonth = dateTime.toJSDate();
  const startDate = getFirstOfMonth(lastMonth);
  const endDate = getLastOfMonth(startDate);
  const scheduleDates = getDatesArray(startDate, endDate);
  const headerDates = [startDate];
  return { startDate, endDate, headerDates, scheduleDates };
}

function buildMonthDateRange(dateRange: IDateRange): IDateRange {
  const startDate = getFirstOfMonth(dateRange.startDate);
  const endDate = getLastOfMonth(dateRange.startDate);
  const headerDates = [startDate];
  const scheduleDates = getDatesArray(startDate, endDate);
  return { startDate, endDate, headerDates, scheduleDates };
}

function getMonthsOfYear(date?: Date) {
  const today = date ?? new Date();
  const dates: Date[] = [];
  for (let i = 0; i < 12; i++) {
    dates.push(new Date(today.getFullYear(), i, 1));
  }
  return dates;
}

function getWeeksOfYear(date: Date) {
  const dates: Date[] = [];
  const dateTime = DateTime.fromJSDate(date).startOf('week');
  for (let i = 0; i < dateTime.weeksInWeekYear; i++) {
    dates.push(dateTime.plus({ weeks: i }).toJSDate());
  }
  return dates;
}

function setYear(dateTime: DateTime): IDateRange {
  const lastYear = dateTime.toJSDate();
  const startDate = new Date(lastYear.getFullYear(), 0, 1);
  const endDate = new Date(lastYear.getFullYear(), 12, 0);
  const scheduleDates = getWeeksOfYear(lastYear);
  const headerDates = getMonthsOfYear();
  return { startDate, endDate, headerDates, scheduleDates };
}

function buildYearDateRange(): IDateRange {
  const startDate = DateTime.now().startOf('year').toJSDate();
  const endDate = DateTime.now().endOf('year').toJSDate();
  const headerDates = getMonthsOfYear();
  const scheduleDates = getWeeksOfYear(startDate);
  return { startDate, endDate, headerDates, scheduleDates };
}

const useDateRange = () => {
  const [dateRangeType, setDateRangeType] = useState<DateRangeType>(
    DateRangeType.week,
  );
  const [dateRange, setDateRange] = useState<IDateRange>(buildWeekDateRange());

  useEffect(() => {
    switch (dateRangeType) {
      case DateRangeType.month:
        setDateRange(
          buildMonthDateRange({ ...dateRange, startDate: new Date() }),
        );
        break;
      case DateRangeType.year:
        setDateRange(buildYearDateRange());
        break;
      default:
        setDateRange(buildWeekDateRange());
        break;
    }
  }, [dateRangeType]);

  const previousDates = () => {
    if (dateRange !== undefined) {
      switch (dateRangeType) {
        case DateRangeType.month:
          setDateRange(
            setMonth(
              DateTime.fromJSDate(dateRange.startDate).minus({ months: 1 }),
            ),
          );
          break;
        case DateRangeType.year:
          setDateRange(
            setYear(
              DateTime.fromJSDate(dateRange.startDate).minus({ years: 1 }),
            ),
          );
          break;
        default:
          setDateRange(
            setWeek(
              DateTime.fromJSDate(dateRange.startDate).minus({ weeks: 1 }),
            ),
          );
      }
    }
  };

  const nextDates = () => {
    if (dateRange !== undefined) {
      switch (dateRangeType) {
        case DateRangeType.month:
          setDateRange(
            setMonth(
              DateTime.fromJSDate(dateRange.startDate).plus({ months: 1 }),
            ),
          );
          break;
        case DateRangeType.year:
          setDateRange(
            setYear(
              DateTime.fromJSDate(dateRange.startDate).plus({ years: 1 }),
            ),
          );
          break;
        default:
          setDateRange(
            setWeek(
              DateTime.fromJSDate(dateRange.startDate).plus({ weeks: 1 }),
            ),
          );
      }
    }
  };

  return {
    dateRangeType,
    dateRange,
    setDateRangeType,
    previousDates,
    nextDates,
  };
};
interface UseDateRange {
  setDateRangeType: Dispatch<SetStateAction<DateRangeType>>;
  dateRange: IDateRange;
  previousDates: () => void;
  nextDates: () => void;
  dateRangeType: DateRangeType;
}

export default useDateRange;

const DateRangeContext = createContext<UseDateRange>({} as UseDateRange);
const DateRangeProvider = ({ children }: { children: React.ReactNode }) => {
  const dateState = useDateRange();
  return (
    <DateRangeContext.Provider value={dateState}>
      {children}
    </DateRangeContext.Provider>
  );
};

import styled from '@emotion/styled/macro';
import { Pagination, Typography } from '@mui/material';
import Popup from 'core/components/popups/popup';
import SwitchFilter from 'core/components/filters/switch-filter';

export const MainSection = styled.main`
  display: flex;

  flex-direction: column;
  flex-grow: 1;
  background-color: #ffffff;
  margin: 40px 24px;
  padding: 30px 35px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;

  ${(props) => props.theme.breakpoints.up('lg')} {
    margin: 40px 60px;
  }
`;

export const SectionHeader = styled.header`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SectionHeaderTitle = styled.div`
  width: 33.33%;

  ${(props) => props.theme.breakpoints.down('md')} {
    order: 1;
    width: 100%;
  }
`;

export const SectionTitle = styled(Typography)`
  margin-bottom: 0;

  ${(props) => props.theme.breakpoints.down('md')} {
    margin-bottom: 20px;
    font-size: 1.125em;
  }
`;

export const SectionHeaderSearch = styled.div`
  width: 33.33%;
  .user-management-search {
    height: 40px;
    max-width: 370px;
    margin: 0 auto;
  }
  ${(props) => props.theme.breakpoints.down('md')} {
    order: 4;
    width: 100%;

    .user-management-search {
      max-width: 100%;
    }
  }
`;

export const SectionHeaderButton = styled.div`
  width: 33.33%;
  text-align: right;
  ${(props) => props.theme.breakpoints.down('md')} {
    text-align: left;
    order: 2;
    width: 100%;
    margin-bottom: 15px;
  }
`;

export const SectionHeaderFilter = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 28px;
  align-items: center;

  ${(props) => props.theme.breakpoints.down('lg')} {
    flex-direction: column;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    order: 5;
    margin-bottom: 15px;
    margin-top: 22px;
    flex-direction: row;
    .MuiFormControlLabel-root {
      display: none;
    }
  }
`;

export const SectionHeaderSub = styled.div`
  .MuiTypography-root {
    font-size: 1.5em;
    font-weight: 500;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    order: 3;
    margin-bottom: 15px;

    .MuiTypography-root {
      font-size: 1.125em;
      padding: 10px 0;
    }
  }
`;

export const SectionHeaderFilterSection = styled.div`
  margin-right: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 30px;

  &:last-child {
    margin-right: 0;
  }
`;

export const UserList = styled.ul`
  display: flex;
  list-style: none;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;

  ${(props) => props.theme.breakpoints.down('md')} {
    justify-content: space-between;
  }
`;

export const UserItem = styled.li`
  margin-right: 2%;
  margin-bottom: 2%;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  flex-wrap: wrap;
  overflow: hidden;

  ${(props) => props.theme.breakpoints.down('xl')} {
    width: 32%;

    &:nth-child(3n + 3) {
      margin-right: 0;
    }
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 47%;
    margin-right: 0;
  }

  ${(props) => props.theme.breakpoints.up('xl')} {
    width: 23.5%;

    &:nth-child(3n + 3) {
      margin-right: 2%;
    }

    &:nth-child(4n + 4) {
      margin-right: 0;
    }
  }
  @media only screen and (min-width: 1440px) {
    width: 15%;

    &:nth-child(3n + 3) {
      margin-right: 2%;
    }

    &:nth-child(4n + 4) {
      margin-right: 2%;
    }

    &:nth-child(6n + 6) {
      margin-right: 0;
    }
  }
`;

export const StyledPopup = styled(Popup)`
  overflow: scroll;
  .pop-up-content {
    margin: 20px auto;
    position: relative;
    left: auto;
    top: auto !important;
    transform: none !important;
  }
`;

export const SectionContent = styled.section`
  margin-top: 45px;
`;

export const NoUsers = styled.div`
  background-color: #f6f8f9;
  padding: 30px;
  font-size: 1.25em;
  text-align: center;
`;

export const StyledPagination = styled(Pagination)`
  && {
    margin-top: 20px;
    .MuiButtonBase-root {
      font-size: 1em;
      min-width: auto;

      &.Mui-selected {
        background-color: transparent;
        font-weight: bold;
      }
    }

    .MuiPagination-ul {
      justify-content: flex-end;
    }

    ${(props) => props.theme.breakpoints.down('md')} {
      .MuiPagination-ul {
        justify-content: center;
      }
    }
  }
`;

export const StyledUserSwitch = styled(SwitchFilter)`
  && {
    margin-bottom: 0;
  }
`;

/**
 * Module dependencies.
 */

import styled from '@emotion/styled/macro';

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${(props) => props.theme.breakpoints.down('md')} {
    font-size: 1.125em;
    flex-direction: column;
  }
`;

export const SectionContent = styled.span`
  font-size: 1.25em;

  ${(props) => props.theme.breakpoints.down('md')} {
    margin-bottom: 20px;
  }

  .roleTag {
    background-color: #a0b1d3;
    color: #07334a;
    border-radius: 16px;
    font-size: 0.875em;
    padding: 6px 12px;
    margin-left: 20px;

    ${(props) => props.theme.breakpoints.down('md')} {
      margin-left: 0;
      margin-right: 20px;
    }
  }
`;

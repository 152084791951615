/**
 * Module dependencies.
 */

import { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import StatsTable from 'statistics/components/stats-table/stats-table';
import * as S from 'statistics/components/task-card/task-card.style';
import { TaskmanagerPotDto } from 'gateway-api';

export interface ITaskProps {
  pot: TaskmanagerPotDto;
}

const TaskCard = ({ pot }: ITaskProps): JSX.Element => {
  const [selectedPot, setSelectedPot] = useState('');

  const [open, setOpen] = useState(false);

  const handleShow = (id: string) => {
    setOpen(!open);
    setSelectedPot(id);
  };

  return (
    <>
      <S.TaskCardContainer>
        <S.TaskHeader>
          <S.TaskTitle>{pot.label}</S.TaskTitle>
          <S.TaskHeaderIcons>
            <S.TaskStyledLogo src="images/jira_logo_icon.png" />
          </S.TaskHeaderIcons>
          <S.TaskContent>{pot.name}</S.TaskContent>
        </S.TaskHeader>
        <S.TicketFooter>
          <S.StyledLink rel="noreferrer" target="_blank" href={pot.remote_url}>
            View on Jira
          </S.StyledLink>
          <S.ViewButton variant="text" onClick={() => handleShow(pot.id)}>
            {open ? (
              <>
                <ArrowDropDownIcon /> Close
              </>
            ) : (
              <>
                <ArrowRightIcon /> Quick Stats
              </>
            )}
          </S.ViewButton>
        </S.TicketFooter>
        <>{open ? <StatsTable pot_id={selectedPot} /> : <></>}</>
      </S.TaskCardContainer>
    </>
  );
};

export default TaskCard;

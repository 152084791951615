import produce from 'immer';
import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import {
  IEventTicketPayload,
  IEventTicketStore,
  IEventTypeTicketState,
} from 'schedule/core/store/resizable/resize.state';

const initialState: IEventTicketPayload = {
  cellWidth: 0,
  event: null,
  timeDifference: 0,
};

const useEventTicketStore = create<IEventTicketStore>()(
  immer(
    devtools(
      (set, get) => ({
        state: initialState,
        isResizing: (e) => {
          let isSameEvent = true;
          const stateEvent = get().state.event;
          let eventDate: Date;

          if (typeof e === 'string') {
            eventDate = new Date(e);
          } else {
            isSameEvent = stateEvent?.id === e.id;
            eventDate = new Date(e.date_start);
          }

          if (!stateEvent) return false;
          const stateEventDate = new Date(stateEvent.date_start);

          return (
            isSameEvent &&
            eventDate.getFullYear() === stateEventDate?.getFullYear() &&
            eventDate.getMonth() === stateEventDate?.getMonth() &&
            eventDate.getDate() === stateEventDate?.getDate()
          );
        },
        setCellWidth: (width) => {
          set(
            produce((store: IEventTypeTicketState) => {
              store.state.cellWidth = width;
            }),
            false,
            { type: 'event-type-ticket-store__setWidth', width },
          );
        },
        setEvent: (event) => {
          set(
            produce((store: IEventTypeTicketState) => {
              store.state.event = event;
            }),
            false,
            {
              type: 'event-type-ticket-store__setColumnWidth',
              event,
            },
          );
        },
        setTimeDifference: (hours) => {
          set(
            produce((store: IEventTypeTicketState) => {
              store.state.timeDifference = hours;
            }),
            false,
            { type: 'event-type-ticket-store__setTimeDiference', hours },
          );
        },
        resetStore() {
          set(
            produce((store: IEventTypeTicketState) => {
              store.state = initialState;
            }),
            false,
            { type: 'event-type-ticket-store__setTimeDiference' },
          );
        },
      }),
      {
        name: 'event-type-ticket-store',
      },
    ),
  ),
);

export default useEventTicketStore;

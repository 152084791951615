/**
 * Module depencencies.
 */

import styled from '@emotion/styled/macro';

export const MainNavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 60px;
  justify-content: start;
`;

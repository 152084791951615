import StatsTable from 'statistics/components/stats-table/stats-table';
import PrimaryButton from 'core/components/buttons/primary-button';
import useDragDropStore from 'schedule/core/store/drag-drop/drag-drop.store';
import { Dispatch, SetStateAction, useMemo, useRef, useState } from 'react';
import * as S from 'schedule/core/components/tooltip/tooltip.styles';
import useEventTypeProvider, {
  PageEnum,
} from 'schedule/core/providers/event-type-provider';
import { TaskmanagerEventDto, TaskmanagerEventTypeEnum } from 'gateway-api';
import { SelectChangeEvent } from '@mui/material';
import useOnClickOutside from 'core/hooks/use-on-click-outside';
import RadioButtonStyle from 'core/components/form-components/radio-button-style';
import { useShowPotStats } from 'statistics/hooks/use-pots-statistics';
import { potStatKeys } from 'schedule/core/queries/pot/pot.keys';
import AssignedUsers from 'schedule/core/components/assigned-users/assigned-users';

interface ITicketTooltip {
  company: string;
  event: TaskmanagerEventDto;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export enum ScheduleStatsType {
  'ALL' = 'ALL',
  'SCHED VS REC' = 'SCHED VS REC',
  'EST VS SCHED' = 'EST VS SCHED',
  'EST VS REC' = 'EST VS REC',
}

export const TicketTooltip = ({ event, company, setOpen }: ITicketTooltip) => {
  const [timeRemainingEvents, setTimeRemainingEvents] = useState(
    ScheduleStatsType.ALL,
  );

  const handleChangeEvent = (
    selectEvent: SelectChangeEvent<ScheduleStatsType>,
  ) => {
    setTimeRemainingEvents(
      ScheduleStatsType[
        selectEvent.target.value as keyof typeof ScheduleStatsType
      ],
    );
  };

  const setEditEventEntry = useDragDropStore(
    (store) => store.setEditEventEntry,
  );

  const { scheduleParams, page } = useEventTypeProvider();
  const isEditEntryButtonVisible: boolean = useMemo(() => {
    if (page === PageEnum.RECORDED) return false;
    if (
      page === PageEnum.ADD_TIME &&
      scheduleParams.eventType[0] === TaskmanagerEventTypeEnum.Schedule
    )
      return false;
    return true;
  }, [page, scheduleParams.eventType]);

  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const queryKey = potStatKeys.showPot({
    id: event.time_pot?.parent_pot_id ?? '',
  });

  const { data } = useShowPotStats(queryKey);

  const users = useMemo(() => {
    if (event.event_type === TaskmanagerEventTypeEnum.Complete) {
      return data?.complete_users;
    }

    if (event.event_type === TaskmanagerEventTypeEnum.Estimate) {
      return data?.estimate_users;
    }

    return data?.schedule_users;
  }, [
    data?.complete_users,
    data?.estimate_users,
    data?.schedule_users,
    event.event_type,
  ]);

  if (event.pots_by_type.ticket) {
    return (
      <S.TooltipDetails company={company} ref={ref}>
        <S.TooltipHeader>
          <S.TicketEventTitle company={company}>
            {event.pots_by_type.ticket.label}
          </S.TicketEventTitle>
          <S.JiraLink
            rel='noreferrer'
            target='_blank'
            href={event.pots_by_type.ticket.remote_url}
          >
            View on Jira
          </S.JiraLink>
          <S.JiraLogo src='images/jira_logo_icon.png' />
        </S.TooltipHeader>
        <S.TicketEventContent>
          <S.TicketEventDescription>
            {event.pots_by_type.ticket.name}
          </S.TicketEventDescription>
          <S.TicketEventUsers>
            <S.UsersSelectWrapper>
              <S.UsersWrapper>
                {users && <AssignedUsers usersData={users} />}
              </S.UsersWrapper>
              <S.CompanyFilter>
                {Object.keys(ScheduleStatsType).map((key) => (
                  <RadioButtonStyle
                    key={key}
                    name='company'
                    value={key}
                    variant='outlined'
                    checked={timeRemainingEvents === key}
                    onChange={handleChangeEvent}
                  >
                    {key as ScheduleStatsType}
                  </RadioButtonStyle>
                ))}
              </S.CompanyFilter>
            </S.UsersSelectWrapper>
            <StatsTable
              pot_id={event?.time_pot?.parent_pot_id}
              statsType={timeRemainingEvents}
            />
            {isEditEntryButtonVisible && (
              <S.ButtonContainer>
                <PrimaryButton
                  type='button'
                  variant='outlined'
                  onClick={() => {
                    setEditEventEntry(event);
                  }}
                >
                  Edit Entry
                </PrimaryButton>
              </S.ButtonContainer>
            )}
          </S.TicketEventUsers>
        </S.TicketEventContent>
      </S.TooltipDetails>
    );
  }

  return <></>;
};

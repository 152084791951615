import { TicketContainer } from 'schedule/core/components/ticket-container/ticket-container';

/**
 * Event Type Ticket
 *
 * The event type used to display user tasks within the schedule.
 *
 * This component is split over multiple components all nested within the "event-type-ticket" folder.
 */
export const EventTypeTicket = TicketContainer;

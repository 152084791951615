import styled from '@emotion/styled/macro';
import { Dispatch, SetStateAction } from 'react';
import RadioButtonStyle from 'core/components/form-components/radio-button-style';
import { useDateRange } from 'date-range/store/date-range';
import DateRangeType from 'date-range/models/date-range-type.enum';

const Filter = styled.div`
  margin-right: 14px;
`;

const DateTypeSelect = (): JSX.Element => {
  const setDateRangeType = useDateRange((store) => store.dateRangeTypeChanged);
  return (
    <>
      <Filter>
        <RadioButtonStyle
          id="1"
          value="week"
          name="duration"
          variant="solid"
          onChange={() => setDateRangeType(DateRangeType.week)}
        >
          Week
        </RadioButtonStyle>
      </Filter>
      <Filter>
        <RadioButtonStyle
          id="2"
          value="month"
          name="duration"
          variant="solid"
          onChange={() => setDateRangeType(DateRangeType.month)}
        >
          Month
        </RadioButtonStyle>
      </Filter>
      <Filter>
        <RadioButtonStyle
          id="3"
          value="year"
          name="duration"
          variant="solid"
          onChange={() => setDateRangeType(DateRangeType.year)}
        >
          Year
        </RadioButtonStyle>
      </Filter>
    </>
  );
};

export default DateTypeSelect;

import useAuthUser from 'auth/hooks/use-auth-user';
import { useShowUser } from 'user/queries/use-show-user';
import { userKeys } from 'user/queries/user.keys';

import * as S from 'schedule/search/components/favourite-filter/favourite-filter.styles';
import { TaskmanagerUserPartialDto } from 'gateway-api';
import { useModifyUserFavourite } from 'user/queries/use-modify-user-favourite';

interface IFavProps {
  potId: string;
}

export const FavouriteStar = ({ potId }: IFavProps) => {
  const user = useAuthUser();

  const queryKey = userKeys.showUser({
    user: user?.id,
  });

  const { data: userData } = useShowUser(queryKey);
  const { mutate: update } = useModifyUserFavourite();

  const handleAddFavourite = (favPotId: string) => {
    const favArr = [favPotId];
    if (userData?.id) {
      const newUser = {
        favorite_pot_ids: [
          ...(favArr ?? []),
          ...(userData?.taskmanager?.favorite_pot_ids ?? []),
        ],
      };
      update({
        id: userData.id,
        body: newUser,
      });
    }
  };

  const userFavouritePots = userData?.taskmanager?.favorite_pot_ids?.filter(
    (item: string) => item === potId,
  );

  const handleRemoveFavourite = (favPotId: string) => {
    if (userData?.taskmanager?.favorite_pot_ids && userData?.id) {
      userData.taskmanager.favorite_pot_ids =
        userData.taskmanager.favorite_pot_ids.filter((id) => id !== favPotId);
      const removedFaves = [
        {
          $operation: 'destroy',
          $value: favPotId,
        },
      ];
      const updatedUser = {
        favorite_pot_ids: removedFaves,
      };
      update({
        id: userData.id,
        body: updatedUser as unknown as TaskmanagerUserPartialDto, // TODO: hacked until swagger updated
      });
    }
  };

  return (
    <>
      <S.StarIconButton
        potId={potId}
        onClick={
          userFavouritePots?.includes(potId)
            ? () => handleRemoveFavourite(potId)
            : () => handleAddFavourite(potId)
        }
      >
        {userFavouritePots?.includes(potId) ? (
          <S.StarIconFilled />
        ) : (
          <S.StarIconOutlined />
        )}
      </S.StarIconButton>
    </>
  );
};

import { useQuery } from 'react-query';
import { api } from 'core/services/api.service';

interface IUseIndexUser {
  disabled?: boolean;
  enabled?: boolean;
  selectedUsers?: string[];
  selectedRoles?: string[];
  selectedSkills?: string[];
  search?: string;
  pageNumber?: number;
}

const useAllUser = ({
  disabled,
  selectedUsers,
  selectedRoles,
  selectedSkills,
  search,
  pageNumber,
  enabled = true,
}: IUseIndexUser) => {
  const {
    data: usersResponse,
    isLoading: usersLoading,
    error: usersError,
    refetch,
  } = useQuery(
    [
      'users',
      'list',
      { search, selectedSkills, pageNumber, selectedRoles, disabled },
    ],
    async () => {
      const res = await api.gatewayUserService.indexUser({
        params: {
          limit: 1000,
          page: pageNumber,
          'property_value:username,property_value:first_name[like]': search,
          'first_name,last_name': selectedUsers?.length
            ? selectedUsers.join(',')
            : undefined,
          'property_value:services@taskmanager@roles[in]': selectedRoles?.length
            ? selectedRoles.join(',')
            : undefined,
          'property_value:skills[in]': selectedSkills?.length
            ? selectedSkills?.join(',')
            : undefined,
          'property_value:disabled[not]': disabled ? 'true' : undefined,
        },
      });
      return res.data;
    },
    { enabled },
  );

  return {
    ...usersResponse,
    usersLoading,
    usersError,
    selectedSkills,
    selectedRoles,
    selectedUsers,
    pageNumber,
    refetch,
  };
};

export default useAllUser;

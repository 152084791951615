import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import styled from '@emotion/styled/macro';
import UserAvatar from 'user/components/user-avatar/user-avatar';

export const UserContainer = styled.li<{ width?: number }>`
  flex: ${({ width }) => (width ? `0 0 ${width}px` : 'unset')};
  display: flex;
  flex-direction: column;
  counter-increment: none;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${(props) => props.theme.colors.primaryColor};
  min-height: 118px;

  && {
    .MuiTypography-root {
      font-size: 18px;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      width: 79px;
      text-overflow: ellipsis;
      text-align: center;
    }
  }
`;

export const Avatar = styled(UserAvatar)`
  margin-bottom: 2px;
`;

export const UserTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

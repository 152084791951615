import { createContext, useContext } from 'react';
import useDateRange from 'core/hooks/use-date-range';

const DateRangeContext = createContext({});

/** A provider for the useDateRange Hook */
const DateRangeProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const dateRange = useDateRange();
  return (
    <DateRangeContext.Provider value={dateRange}>
      {children}
    </DateRangeContext.Provider>
  );
};

export const useDateRangeContext = () =>
  useContext(DateRangeContext) as ReturnType<typeof useDateRange>;

export default DateRangeProvider;

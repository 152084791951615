interface IIndexSearch {
  search?: string;
  category?: string;
  categoryId?: string;
  status?: boolean;
  pageNumber?: number;
  jql_search?: boolean;
  jira_query?: string;
  jira_type?: string;
  favourite?: string;
  sort?: string;
}

export const searchKeys = {
  base: [{ scope: 'available-pots' }] as const,
  index: () => [{ ...searchKeys.base[0], entity: 'index' }] as const,
  indexSearch: (query: IIndexSearch) =>
    [
      {
        ...searchKeys.index()[0],
        ...query,
      },
    ] as const,
};

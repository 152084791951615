/**
 * Module dependencies.
 */

import styled from '@emotion/styled/macro';
import { Avatar, Badge } from '@mui/material';
import { defaultTheme } from 'theme/theme';
import isPropValid from '@emotion/is-prop-valid';

interface IStyledAvatarProps {
  width?: string;
  height?: string;
  bgSolid?: boolean;
}

export const StyledBadge = styled(Badge)`
  && {
    :after {
      position: absolute;
      top: 0;
      right: 0;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      content: '';

      ${(props) => props.theme.breakpoints.down('md')} {
        width: 10px;
        height: 10px;
      }
    }
  }
`;

export const StyledAvator = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'bgSolid',
})<IStyledAvatarProps>`
  && {
    background-color: ${(props) =>
      props.bgSolid !== false ? '#EAEAEA' : '#ffffff'};
    border: ${(props) =>
      props.bgSolid !== false ? 'none' : '1px solid#707070'};
    color: ${defaultTheme.colors.primaryColor};
    width: ${(props) => (props.width != null ? props.width : '55px')};
    height: ${(props) => (props.height != null ? props.height : '55px')};
    font-size: 24px;
    font-weight: 600;

    ${(props) => props.theme.breakpoints.down('md')} {
      width: 30px;
      height: 30px;
      font-size: 12px;
    }
  }
`;

// export const StyledAvator = styled(Avatar)<IStyledAvatarProps>`
// && {
//   background-color: ${(props) =>
//     props.bgSolid !== false ? "#EAEAEA" : "#ffffff"};
//     border: ${(props) =>
//       props.bgSolid !== false ? "none" : "1px solid#707070"};
//       color: ${defaultTheme.colors.primaryColor};
//       width: ${(props) => (props.width != null ? props.width : "55px")};
//     height: ${(props) => (props.height != null ? props.height : "55px")};
//     font-size: 24px;
//     font-weight: 600;

//     ${(props) => props.theme.breakpoints.down("md")} {
//       width: 30px;
//       height: 30px;
//       font-size: 12px;
//     }
//   }
// `;

import { Thunk } from 'core/reducers/thunk';
import { api } from 'core/services/api.service';
import { AuthRequest, UserDto } from 'sso-api';
import { AuthState } from 'auth/reducers/auth/auth.state';

export type AuthAction =
  | { type: 'initial' }
  | { type: 'loading' }
  | { type: 'logout' }
  | { type: 'error'; payload?: string }
  | { type: 'update_success'; payload: UserDto }
  | { type: 'login_success'; payload: UserDto };

export const loginWithGoogle: Thunk<string, AuthState, AuthAction> =
  (googleCode: string) => async (_, dispatch) => {
    dispatch({ type: 'loading' });

    const authRequest: AuthRequest = {
      grant_type: 'social',
      code: googleCode,
      service: 'google',
      client_id: process.env.REACT_APP_CLIENT_ID ?? '',
      client_secret: process.env.REACT_APP_CLIENT_SECRET ?? '',
      redirect_uri: window.location.href.replace(/\/$/, ''),
    };

    try {
      const response = await api.authenticationService.auth(authRequest);
      const { data: authResult } = response;
      if (authResult.access_token && authResult.refresh_token) {
        api.tokenService.accessToken = authResult.access_token;
        localStorage.setItem('refreshToken', authResult.refresh_token);
        const user = api.tokenService.decodeToken();
        if (user) {
          dispatch({ type: 'login_success', payload: user });
        }
      }
    } catch (error) {
      dispatch({
        type: 'error',
        payload: 'An error occurred whilst logging in with google',
      });
    }
  };

/**
 * Gets the currently logged in user by decoding the access token.
 * If the access token doesn't exist in memory then we refresh it using the refresh token.
 * If the refresh token doesn't exist then the user is returned to the login screen.
 */
export const refreshUser: Thunk<never, AuthState, AuthAction> =
  () => async (state, dispatch) => {
    let user = api.tokenService.decodeToken();

    if (!user) {
      // No access token is stored in memory.
      // Proceed to try and refresh the access token using the stored refresh token.
      dispatch({ type: 'loading' });
      await api.tokenService.refreshAccessToken();

      user = api.tokenService.decodeToken();
    }

    if (user) {
      dispatch({ type: 'login_success', payload: user });
    } else {
      dispatch({ type: 'initial' });
    }
  };

export const logout: Thunk<{}, AuthState, AuthAction> =
  () => async (_, dispatch) => {
    api.tokenService.logout();
    dispatch({ type: 'logout' });
  };

import Scaffold from 'core/components/scaffolds/scaffold';
import { useDateRangeContext } from 'core/store/date-range-provider';
import useTagResults from 'gantt/hooks/gant-view/use-tag-results';
import CalendarView from 'core/components/calendar-view/calendar-view';
import DateTypeSelect from 'schedule/core/components/date-type-select';
import GantSearch from 'gantt/components/gant-search';
import GantProvider from 'gantt/store/gant-provider';
import { DateRangeComponent } from 'date-range/components/date-range/date-range';
import * as S from 'gantt/pages/gant-view/gant-view.style';
import { useDateRange } from 'date-range/store/date-range';

const GantView = (): JSX.Element => {
  const setDateRangeType = useDateRange((store) => store.dateRangeTypeChanged);

  const { setTags, items: projects } = useTagResults();

  return (
    <S.Container>
      <Scaffold headerTitle="Gant Chart" pageTitle="Gant Chart">
        <GantProvider>
          <S.Flex>
            <DateTypeSelect />
            <GantSearch setTags={setTags} />
          </S.Flex>
          <DateRangeComponent />
          <CalendarView projects={projects} setTags={setTags} />
        </GantProvider>
      </Scaffold>
    </S.Container>
  );
};

export default GantView;

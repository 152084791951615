import styled from '@emotion/styled/macro';
import { defaultTheme } from 'theme/theme';

interface IProgressBar {
  percentComplete?: number;
  bgColor?: string;
}

const StyledProgressBar = styled.div<IProgressBar>`
  background-color: #f7f7f7;
  border-radius: 10px;
  width: 100%;
  height: 10px;
  font-size: 0;
  position: relative;

  &:before {
    content: '';
    height: 10px;
    position: absolute;
    width: ${(props) => props.percentComplete}%;
    background-color: ${(props) =>
      props.bgColor != null ? props.bgColor : defaultTheme.colors.primaryColor};
    border-radius: 10px;
    max-width: 100%;
  }
`;

const ProgressBar = ({
  percentComplete,
  bgColor,
}: IProgressBar): JSX.Element => (
  <StyledProgressBar percentComplete={percentComplete} bgColor={bgColor}>
    {percentComplete}
  </StyledProgressBar>
);

export default ProgressBar;

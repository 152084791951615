import { QueryFunctionContext, useQuery } from 'react-query';
import { api } from 'core/services/api.service';

import { notesKeys } from 'schedule/notes/queries/notes/notes.keys';

type IndexNotes = ReturnType<typeof notesKeys['indexNotes']>;

const indexNotes = async ({
  queryKey: [{ startDate, endDate }],
}: QueryFunctionContext<IndexNotes>) => {
  const {
    data: { items: notes },
  } = await api.noteService.indexTaskmanagerNote({
    params: {
      'date[gte]': startDate,
      'date[lte]': endDate,
    },
  });
  return notes ?? [];
};

export const useIndexNotes = (queryKey: IndexNotes) =>
  useQuery(queryKey, indexNotes);

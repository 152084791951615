/**
 * Module dependencies.
 */

import styled from '@emotion/styled/macro';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow/TableRow';

export const StyledTable = styled(Table)`
  width: auto;
  font-size: 1em;
  min-width: 300px;
  margin-right: 100px;

  ${(props) => props.theme.breakpoints.down('md')} {
    min-width: auto;
    margin-right: 0;
  }
`;

export const StyledTableRow = styled(TableRow)`
  background-color: #eaeaea;
`;

export const StyledTableCell = styled(TableCell)`
  border: solid #ffffff 1px;
  text-align: left;
  padding: 10px;
  width: 50%;

  &.MuiTableCell-body {
    font-size: 1em;

    ${(props) => props.theme.breakpoints.down('sm')} {
      font-size: 0.875em;
    }
  }
`;

import StoreStatus from 'core/models/store-status.enum';
import { UserDto } from 'sso-api';

export interface AuthState {
  status: StoreStatus;
  message?: String;
  user?: UserDto;
}

export const initialState: AuthState = {
  status: StoreStatus.loading,
};

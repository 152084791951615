/* eslint-disable no-else-return */
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Typography } from '@mui/material';
import UnscheduledPotsShimmer from 'schedule/core/components/shimmers/unscheduled-pots-shimmer';
import { CategoryFilter } from 'schedule/search/components/category-filter/category-filter';
import useDragDropStore from 'schedule/core/store/drag-drop/drag-drop.store';
import useEventTypeProvider, {
  PageEnum,
} from 'schedule/core/providers/event-type-provider';
import { useIndexSearch } from 'schedule/search/queries/use-index-search';
import { searchKeys } from 'schedule/search/queries/search.keys';
import { ChangeEvent } from 'react';
import { Search } from 'schedule/search/components/search/search';
import { AvailablePot } from 'schedule/search/components/available-pot/available-pot';
import { FavouriteFilter } from 'schedule/search/components/favourite-filter/favourite-filter';
import useAuthUser from 'auth/hooks/use-auth-user';
import { useSearchStore } from 'schedule/search/store/search/search.store';
import { PotsSortFilter } from 'schedule/search/components/pots-sort-filter/pots-sort-filter';

import * as S from 'schedule/search/components/available-pots/available-pots.styles';

interface IAvailablePots {
  visible?: boolean;
}

export const AvailablePots = ({ visible }: IAvailablePots) => {
  const user = useAuthUser();
  const { page: providerPage } = useEventTypeProvider();

  const {
    state: { draggableId: storeDraggableId },
  } = useDragDropStore();

  const { state, setPageNumber } = useSearchStore();

  const handlePageChange = (event: ChangeEvent<unknown>, page: number) => {
    setPageNumber(page);
  };

  const queryKey = searchKeys.indexSearch({
    pageNumber: state.pageNumber,
    search: state.search,
    categoryId: state.categoryId === 'all' ? undefined : state.categoryId,
    status: state.status,
    favourite: state.favourite ? user?.id : undefined,
    jql_search: state.jql_search,
    jira_query: state.jira_query,
    sort: state.sort,
  });

  const { data: pots, isLoading } = useIndexSearch(queryKey);

  if (state.favourite && pots?.pagination.items_this_page === 0) {
    setPageNumber(pots.pagination.page_current - 1 ?? 1);
  }

  return (
    <>
      {visible ? (
        <S.List>
          <S.ListHeader>
            <S.SectionTitle variant='h2'>
              {providerPage === PageEnum.SCHEDULE && 'Tickets for Scheduling'}
              {providerPage === PageEnum.ESTIMATE && 'Tickets for Estimation'}
              {providerPage === PageEnum.ADD_TIME &&
                'Tickets to record time against'}
              {providerPage === PageEnum.MULTI_VIEW && 'Unscheduled List'}
            </S.SectionTitle>
            <S.SearchContainer>
              <S.RecentSearchContainer>
                <Search />
                <FavouriteFilter />
              </S.RecentSearchContainer>
            </S.SearchContainer>
            <S.Filters>
              <S.ProjectFilter>
                <CategoryFilter />
              </S.ProjectFilter>
            </S.Filters>
            <S.SectionSubTitle>
              <img src='images/jira_logo_icon.png' alt='Jira Logo' />
              <Typography variant='h3'>Jira Tasks</Typography>
              <Typography>(Ready for Development)</Typography>
              <PotsSortFilter />
            </S.SectionSubTitle>
          </S.ListHeader>
          {isLoading && <UnscheduledPotsShimmer />}
          {pots && pots?.items?.length > 0 && (
            <S.TasksList>
              {pots?.items.map((pot, index) => (
                <S.Task key={`event_${pot.id}`}>
                  <Droppable
                    key={`drop__${pot.id}`}
                    droppableId={`drop__${pot.id}`}
                    direction='horizontal'
                    isDropDisabled
                  >
                    {(provided) => {
                      const draggableId = `unscheduled_${pot.id}`;
                      const isSelected = draggableId === storeDraggableId;
                      return (
                        <S.DroppableContainer
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          <Draggable
                            draggableId={draggableId}
                            index={index}
                            key={pot.id}
                            isDragDisabled={false}
                          >
                            {(providedDraggable) => (
                              <S.DraggableContainer>
                                <AvailablePot
                                  pot={pot}
                                  provided={providedDraggable}
                                  isSelected={isSelected}
                                />
                              </S.DraggableContainer>
                            )}
                          </Draggable>
                          {provided.placeholder}
                        </S.DroppableContainer>
                      );
                    }}
                  </Droppable>
                </S.Task>
              ))}
            </S.TasksList>
          )}
          {pots?.items?.length === 0 && !isLoading ? (
            <S.NoTasks>No Tickets Found</S.NoTasks>
          ) : (
            <S.Pagination
              count={pots?.pagination?.page_total}
              page={state.pageNumber}
              onChange={handlePageChange}
            />
          )}
        </S.List>
      ) : (
        <></>
      )}
    </>
  );
};
